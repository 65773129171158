<template>
  <div :class="$route.name === 'collection-streaming-id' ? 'mb-8' : 'fill-height'">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="px-0 pt-0">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-divider class="mb-10" style="border: 2px solid #e9e9e9" />

                <div class="d-flex align-end mb-1">
                  <div class="d-flex align-center">
                    <v-icon size="30" class="mr-1">mdi-toolbox</v-icon>
                    <span class="title font-weight-bold mb-0 ml-1">
                      <span class="color-first mr-1">
                        Alat
                      </span>
                      Yang Perlu Disiapkan
                    </span>
                  </div>

                  <v-spacer/>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container style="background: #fff; border-radius: 5px;" class="pa-0">
            <v-row v-if="detail.kelas_alat.length > 0">
              <v-col>
                <v-card flat>
                  <v-card-text class="body-1 font-weight-light line-text-second">
                    <span v-html="detail.kelas_alat"></span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-else
              class="py-12">
              <v-col>
                <v-img 
                  class="mx-auto"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Tidak ada alat yang perlu disiapkan!
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Alat yang perlu disiapkan tidak tersedia dikelas ini.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        
      }
    },
    components: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>