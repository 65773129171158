<template>
  <div class="fill-height pt-12 mt-6">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Instruktur
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container>
            <v-row class="py-6">
              <v-col cols="12" md="3"
                v-for="(item, index) in process.run ? 8 : list"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.run"
                  type="card, article">
                  <div>
                    <v-card v-if="!process.run && list.length > 0"
                      class="border-radius box-shadow">
                      <v-img
                        width="100%"
                        height="220"
                        :src="item.mentor_image_cover_url">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div class="pa-2">
                        <v-card-text>
                          <p class="body-1 font-weight-bold line-text-first text-first text-capitalize mb-2" style="height: 50px;">
                            <span class="two-line">
                              {{ item.mentor_kelas_user_nama }}
                            </span>
                          </p>

                          <div class="d-flex align-center">
                            <v-rating dense
                              :value="5"
                              small
                              readonly
                              color="yellow darken-2"
                              background-color="yellow darken-2">
                            </v-rating>
                            <span class="text-second caption font-weight-bold ml-1">
                              ({{ 5 }})
                            </span>
                          </div>
                        </v-card-text>
                      </div>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row justify="center" align="center" v-if="!process.run && pagination.total_page < 1">
              <v-col cols="12" class="mt-16">
                <v-img 
                  class="mx-auto mt-16"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Instruktur belum tersedia!
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk cari kelas belajar mu di E-Learning Bidan Kita.
                </p>

                <div class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    to="/elearning">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Cari Kelas Belajar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            
            <Pagination v-if="pagination.total_page > 1"
              @changePage="fetch($event)" 
              @reloadPage="fetch($event)"
              :model="pagination" />
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        filter: {
          title: ''
        },
        list: [],
        pagination: {},
        process: {
          run: false
        },
        // SEO
        content: {
          url: 'instructor',
          title: 'Instruktur',
          description: 'Instruktur E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      
    },
    computed: {
      token() {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(pagination = { row: 20, page: 1 }) {
        this.process.run = true

        let params = {
          limit: pagination.row,
          page: pagination.page
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/mentor`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false

            this.list = response.data.results
            this.pagination = response.data.pagination
          }
        })
      }
    }
  }
</script>