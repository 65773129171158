<template>
  <div class="py-12">
    <DialogAuth :dialogauth="dialog" @close="dialog = ''" />

    <section>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12" md="6">
            <v-card
              class="border-radius box-shadow pa-12 mt-12">
              <v-row justify="center" align="center">
                <v-col cols="12" md="10" v-if="process.run">
                   <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.run"
                    type="image, article, article, actions">
                   </v-skeleton-loader>
                </v-col>

                <v-col cols="12" md="10" v-if="!process.run">
                  <v-card-title class="justify-center py-12">
                    <v-img 
                      contain
                      width="250"
                      height="250"
                      :src="!onSuccess ? require('@/assets/icon/emailverify_failed.svg') : require('@/assets/icon/emailverify.svg')">
                    </v-img>
                  </v-card-title>

                  <v-card-text class="subtitle-1 text-center text-second">
                    Verifikasi Email Anda {{ !onSuccess ? 'Gagal' : 'Berhasil' }}.
                  </v-card-text>

                  <v-card-text class="body-2 text-center text-third line-text-first pt-0 pb-5">
                    <span v-if="!onSuccess">
                      Silahkan melakukan verifikasi email ulang dengan menekan tombol di bawah ini. 
                    </span>
                    <span v-else>
                      Selamat akun anda telah terdaftar di <b>Bidan Kita</b>, silahkan login dengan menekan tombol di bawah ini. 
                    </span>
                  </v-card-text>

                  <v-card-text class="text-center pb-12">
                    <v-btn
                      v-if="!onSuccess"
                      rounded
                      width="200"
                      color="background-first"
                      elevation="3"
                      class="body-2 white--text text-capitalize"
                      @click="dialog = 'verification'">
                      Verifikasi Email
                    </v-btn>
                    <v-btn
                      v-else
                      rounded
                      width="200"
                      color="background-first"
                      elevation="3"
                      class="body-2 white--text text-capitalize"
                      @click="dialog = 'login'">
                      Login Sekarang
                    </v-btn>
                    <div class="text-center text-second mt-5">
                      <div class="text-second">
                        Belum punya akun ?
                        <span class="text-first cursor-pointer"
                          @click="dialog = 'register'">
                          Daftar disini
                        </span>
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        dialog: '',
        sosmed: [
          require("@/assets/sosmed/ic_share_fb.svg"),
          require("@/assets/sosmed/ic_share_ig.svg"),
          require("@/assets/sosmed/ic_share_tw.svg")
        ],
        process: {
          run: false
        },
        onSuccess: false,
        success: {
          message: ''
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'verification/:slug',
          title: 'Verifikasi Akun',
          description: 'Verifikasi Akun E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png'),
        }
      }
    },
    head () {
      this.content.url = `verification/${this.$route.params.slug}`
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content),
        script: [
          {
            type: 'text/javascript',
            src: process.env.MIDTRANS_SRC_LIVE,
            'data-client-key': process.env.MIDTRANS_KEY_LIVE
          }
        ]
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth')
    },
    watch: {
      
    },
    computed: {
      token() {
        return this.$store.state.member.auth.token
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      if (this.token) {
        this.$router.push(`/`)
      } else {
        this.fetch()
      }
    },
    methods: {
      async fetch() {
        this.process.run = true
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/register/email_verification`, { 
          code: this.$route.params.slug
         })
        .then((response) => {
          if (response.status === 200) {
            this.onSuccess = true
            this.process.run = false

            // this.onSnap(response.data.transaction.transaction_midtrans_data.token)
          } else {
            this.onSuccess = false
            this.process.run = false
            this.error.message = response.message
          }
        })
      },
      onSnap(token) {
        let t = this
        snap.pay(token, {
          onSuccess: function (result) {
            console.log('success', result)
            t.$router.push(`/payment/finish`)
          },
          onPending: function (result) {
            console.log('pending', result)
            t.$router.push(`/payment/finish`)
          },
          onError: function(result){
            console.log('pending', result)
            t.$router.push(`/payment/error`)
          }
        })
      }
    }
  }
</script>