<template>
  <v-app>
    <HeadersPublic v-if="!token" style="z-index: 99;"/>
    <HeadersUser v-if="token && user.user_roles === 'user'" style="z-index: 99;"/>
    <v-sheet 
      light 
      :color="
        $route.name === 'streaming' || 
        $route.name === 'streaming-special' || 
        $route.name === 'collection-streaming' || 
        $route.name === 'collection-streaming-id' || 
        $route.name === 'streaming-slug' ? '#f9f9f9' : ''"
      min-height="80%">
      <nuxt/>
    </v-sheet>
    <Footers />
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        elevation="3"
        fab
        dark
        fixed
        bottom
        right
        color="#333333"
        @click="$vuetify.goTo('#app', {duration: 500, offset: 0})">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import HeadersPublic from "~/components/public/Header.vue"
  import HeadersUser from "~/components/private/Header.vue"
  import Footers from "~/components/Footer.vue"
  import firebase from "firebase/app"
  import "firebase/messaging"
  export default {
    data () {
      return {
        fab: false
      }
    },
    components: {
      HeadersPublic,
      HeadersUser,
      Footers
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      }
    },
    created() {
      if (process.browser) {
        this.setStore()

        if ('Notification' in window && navigator.serviceWorker) {
          this.checkTokenFirebase()
        }
      }
    },
    mounted () {
      
    },
    methods: {
      onScroll(e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.fab = top > window.innerHeight-100
      },
      async setStore() {
        // let user = await localStorage.getItem('user')
        // user = JSON.parse(user === null ? '{}' : user)

        // let profile = await localStorage.getItem('profile')
        // profile = JSON.parse(profile === null ? '{}' : profile)

        // let token = await localStorage.getItem('token')

        // this.$store.commit('member/auth/setToken', token)
        // this.$store.commit('member/auth/setUser', user)
        // this.$store.commit('member/auth/setProfile', profile)
        // this.$store.commit('member/auth/setFcm', Cookie.get('fcm'))

        if (Cookie.get('notif') === undefined) {
          Cookie.set('notif', [], { domain: 'bidankita.com' })
        }

        this.$store.commit('header/setNotif', JSON.parse(Cookie.get('notif')))

        Cookie.set('special', true, { domain: 'bidankita.com' })
      },
      checkTokenFirebase () {
        if (firebase.messaging.isSupported()) {
          if (!this.token) {
            navigator.permissions.query({ name: 'notifications' })
            .then(function(result) {
              if (result.state === 'granted') {
                firebase.messaging().getToken()
                .then(oldToken => {
                  if (oldToken) {
                    firebase.messaging().deleteToken(oldToken)
                  }
                })
              }
            })
          } else {
            navigator.permissions.query({ name: 'notifications' })
            .then(function(result) {
              if (result.state !== 'granted') {
                firebase.messaging().requestPermission()
                .then(function() {
                  window.location.reload()
                })
                .catch(function(err) {
                  console.log('Unable to get permission to notify', err)
                })
              }
            })

            firebase.messaging().getToken({vapidKey: 'BD4yEsDFXQK6EFs9Z7NUb3_xe-XFDqeA4XihKafMXSjVGrCuIygS6FcAPA17pC44BfIYpk2l4xvTnQfIgqh_WZs'})
            .then(currentToken => {
              this.updateTokenFirebase(currentToken)
            })
            .catch(function(err) {
              console.log('Unable to retrieve token', err)
            })
          }
        }
      },
      async updateTokenFirebase(currentToken) {
        if (currentToken !== this.fcm) {
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/account/profile/update_fcm`, {
            fcm: currentToken
          })
          .then((response) => {
            this.$store.commit('member/auth/setFcm', currentToken)
            Cookie.set('fcm', currentToken, { domain: 'bidankita.com' })
          })
        }
      }
    }
  }
</script>
