<template>
  <div>
    <v-flex>
      <section v-if="type_player === 'docs' && Object.keys(play).length > 0"
        :style="`border-radius: ${border};`"
        style="background: #333638;">
        <div v-if="process.run"
          :style="`height: ${height}px`">
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#FF3177">
            </v-progress-circular>
          </v-row>
        </div>

        <div v-if="!process.run">
          <div v-if="rps !== 'sm' && rps !== 'xs'">
            <v-container v-if="is_play"
              class="py-0" 
              style="position: absolute; top: 0px; background: #333638;"
              :style="
                  selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                  selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                  selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                  'border-radius: 10px;'">
              <v-row>
                <v-expansion-panels flat v-model="selected">
                  <v-expansion-panel style="background: #333638;"
                    :style="
                      selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                      selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                      selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                      'border-radius: 10px;'">
                    <v-expansion-panel-header class="white--text font-weight-bold"
                      :class="rps === 'xs' ? 'body-1 font-weight-bold' : 'title'">
                      <span class="one-line">
                        Deskripsi Materi
                      </span>

                      <template v-slot:actions>
                        <v-icon color="#FFF">
                          $expand
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div class="pa-6 py-0">
                        <v-divider color="white"/>
                      </div>

                      <div class="body-2 pa-6 pt-5"
                        style="overflow-y: auto;"
                        :style="`height: ${height - 58}px`">
                        <div class="mb-4">
                          <a class="body-1 font-weight-bold text-capitalize blue--text"
                            :href="play.content_jsonobject.value.url"
                            target="_blank">
                            <v-icon color="blue">mdi-download</v-icon>
                            Download Materi
                          </a>
                        </div>
                        
                        <p class="title line-text-first white--text">
                          {{ play.title }}
                        </p>

                        <p class="font-weight-light line-text-second white--text mb-0">
                          <span v-html="play.deskripsi"></span>
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
            
            <iframe v-if="is_play"
              :style="`border-radius: ${border};`"
              frameborder="0" 
              width="100%"
              :height="height"
              :src="play.content_jsonobject.value.url"
              :type="play.content_jsonobject.value.mime">
            </iframe>

            <!-- <div :class="is_play ? 'hide-button-play' : 'hide-button-stop'"></div> -->
            
            <v-img v-if="!is_play"
              gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
              :style="`border-radius: ${border};`"
              class="d-flex align-center"
              width="100%"
              :height="height"
              :src="play.content_jsonobject.thumbnail">
              <div class="d-flex align-center justify-center">
                <div class="text-center">
                  <v-icon size="125" color="white">
                    mdi-information-outline
                  </v-icon>

                  <p class="text-uppercase font-weight-bold white--text mt-3">
                    DOWNLOAD MATERI.
                  </p>

                  <p class="body-1 font-weight-light white--text mb-5">
                    Silahkan dowload materi dibawah ini.
                  </p>
                  
                  <v-btn
                    elevation="0"
                    rounded
                    color="#FF3177"
                    class="body-1 text-capitalize white--text"
                    :href="play.content_jsonobject.value.url"
                    target="_blank">
                    <v-icon class="mr-1" color="white">mdi-download</v-icon>
                    Download Materi
                  </v-btn>
                </div>
              </div>
            </v-img>
          </div>

          <v-img v-else
            :style="`height: ${height}px; border-radius: ${border};`"
            class="d-flex align-center justify-center pa-6"
            gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
            width="100%"
            :height="height"
            :src="play.content_jsonobject.thumbnail">
            <div class="d-flex align-center justify-center">
              <div class="text-center">
                <v-icon :size="rps === 'xs' ? 75 : 125" color="white">
                  mdi-information-outline
                </v-icon>

                <p class="text-uppercase font-weight-bold white--text mt-3">
                  FILE TIDAK SUPORT.
                </p>

                <p class="body-1 font-weight-light white--text mb-5">
                  Silahkan dowload aplikasi kami di playstore.
                </p>

                <v-btn
                  elevation="0"
                  rounded
                  width="215"
                  color="green"
                  class="body-1 text-capitalize white--text pl-2"
                  href="https://play.google.com/store/apps/details?id=com.elearningbidankita"
                  target="_blank">
                  <v-icon class="mr-1" color="white">mdi-google-play</v-icon>
                  Download Sekarang
                </v-btn>

                <p class="body-1 white--text my-3">
                  Atau
                </p>

                <v-btn
                  elevation="0"
                  rounded
                  width="215"
                  color="#FF3177"
                  class="body-1 text-capitalize white--text"
                  :href="play.content_jsonobject.value.url"
                  target="_blank">
                  <v-icon class="mr-1" color="white">mdi-download</v-icon>
                  Download Materi
                </v-btn>
              </div>
            </div>
          </v-img>
        </div>
      </section>

      <section v-if="type_player === 'audio' && Object.keys(play).length > 0"
        :style="`border-radius: ${border};`"
        style="background: #f0f3f4;">
        <div v-if="process.run"
          :style="`height: ${height}px`">
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#FF3177">
            </v-progress-circular>
          </v-row>
        </div>
        
        <div v-if="!process.run">
          <v-img
            :style="`border-radius: ${border};`"
            class="d-flex align-end"
            width="100%"
            :height="height"
            :src="play.content_jsonobject.thumbnail">
            <v-container v-if="is_play"
              class="py-0" 
              style="position: absolute; top: 0px; background: #333638;"
              :style="
                  selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                  selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                  selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                  'border-radius: 10px;'">
              <v-row>
                <v-expansion-panels flat v-model="selected">
                  <v-expansion-panel style="background: #333638;"
                    :style="
                      selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                      selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                      selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                      'border-radius: 10px;'">
                    <v-expansion-panel-header class="white--text font-weight-bold"
                      :class="rps === 'xs' ? 'body-1 font-weight-bold' : 'title'">
                      <span class="one-line">
                        Deskripsi Materi
                      </span>

                      <template v-slot:actions>
                        <v-icon color="#FFF">
                          $expand
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div class="pa-6 py-0">
                        <v-divider color="white"/>
                      </div>

                      <div class="body-2 pa-6 pt-5"
                        style="overflow-y: auto;"
                        :style="`height: ${height - 58}px`">
                        <div class="mb-4">
                          <a class="body-1 font-weight-bold text-capitalize blue--text"
                            :href="play.content_jsonobject.value.url"
                            target="_blank">
                            <v-icon color="blue">mdi-download</v-icon>
                            Download Materi
                          </a>
                        </div>

                        <p class="title line-text-first white--text">
                          {{ play.title }}
                        </p>

                        <p class="font-weight-light line-text-second white--text mb-0">
                          <span v-html="play.deskripsi"></span>
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
            
            <audio v-if="is_play"
              style="width: 100%; position: relative; bottom: -8px; background: #f0f3f4;"
              autoplay
              controls
              preload="none">
              <source :src="play.content_jsonobject.value.url" :type="play.content_jsonobject.value.mime">
            </audio>

            <v-img v-if="!is_play"
              gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
              :style="`border-radius: ${border};`"
              class="d-flex align-center"
              width="100%"
              :height="height"
              :src="play.content_jsonobject.thumbnail">
              <div class="d-flex align-center justify-center">
                <div class="text-center">
                  <v-icon size="125" color="white">
                    mdi-information-outline
                  </v-icon>

                  <p class="text-uppercase font-weight-bold white--text mt-3">
                    DOWNLOAD MATERI.
                  </p>

                  <p class="body-1 font-weight-light white--text mb-5">
                    Silahkan dowload materi dibawah ini.
                  </p>
                  
                  <v-btn
                    elevation="0"
                    rounded
                    color="#FF3177"
                    class="body-1 text-capitalize white--text"
                    :href="play.content_jsonobject.value.url"
                    target="_blank">
                    <v-icon class="mr-1" color="white">mdi-download</v-icon>
                    Download Materi
                  </v-btn>
                </div>
              </div>
            </v-img>
          </v-img>
        </div>
      </section>

      <section v-if="type_player === 'video' && Object.keys(play).length > 0"
        :style="`border-radius: ${border};`"
        style="background: black;">
        <div v-if="process.run"
          :style="`height: ${height}px`">
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#FF3177">
            </v-progress-circular>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-container v-if="is_play"
            class="py-0" 
            style="position: absolute; top: 0px; background: #333638;"
            :style="
                selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                'border-radius: 10px;'">
            <v-row>
              <v-expansion-panels flat v-model="selected">
                <v-expansion-panel style="background: #333638;"
                  :style="
                    selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                    selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                    selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                    'border-radius: 10px;'">
                  <v-expansion-panel-header class="white--text font-weight-bold"
                    :class="rps === 'xs' ? 'body-1 font-weight-bold' : 'title'">
                    <span class="one-line">
                      Deskripsi Materi
                    </span>

                    <template v-slot:actions>
                      <v-icon color="#FFF">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="pa-6 py-0">
                      <v-divider color="white"/>
                    </div>

                    <div class="body-2 pa-6 pt-5"
                      style="overflow-y: auto;"
                      :style="`height: ${height - 58}px`">
                      <p class="title line-text-first white--text">
                        {{ play.title }}
                      </p>

                      <p class="font-weight-light line-text-second white--text mb-0">
                        <span v-html="play.deskripsi"></span>
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
          
          <video v-if="is_play"
            autoplay
            controls 
            controlsList="nodownload"
            width="100%" 
            :height="height"
            :style="`object-fit: contain; border-radius: ${border};`"
            :poster="play.content_jsonobject.thumbnail">
            <source :src="play.content_jsonobject.value.url" :type="play.content_jsonobject.value.mime">
          </video>

          <v-img v-if="!is_play"
            :style="`border-radius: ${border};`"
            width="100%"
            :height="height"
            :src="play.content_jsonobject.thumbnail">
          </v-img>
        </div>
      </section>

      <section v-if="type_player === 'youtube' && Object.keys(play).length > 0" 
        :style="`border-radius: ${border};`"
        style="background: black;">
        <div v-if="process.run"
          :style="`height: ${height}px`">
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#FF3177">
            </v-progress-circular>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-container v-if="is_play"
            class="py-0" 
            style="position: absolute; top: 0px; background: #333638;"
            :style="
                selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                'border-radius: 10px;'">
            <v-row>
              <v-expansion-panels flat v-model="selected">
                <v-expansion-panel style="background: #333638;"
                  :style="
                    selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                    selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                    selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                    'border-radius: 10px;'">
                  <v-expansion-panel-header class="white--text font-weight-bold"
                    :class="rps === 'xs' ? 'body-1 font-weight-bold' : 'title'">
                    <span class="one-line">
                      Deskripsi Materi
                    </span>

                    <template v-slot:actions>
                      <v-icon color="#FFF">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="pa-6 py-0">
                      <v-divider color="white"/>
                    </div>

                    <div class="body-2 pa-6 pt-5"
                      style="overflow-y: auto;"
                      :style="`height: ${height - 58}px`">
                      <p class="title line-text-first white--text">
                        {{ play.title }}
                      </p>

                      <p class="font-weight-light line-text-second white--text mb-0">
                        <span v-html="play.deskripsi"></span>
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>

          <iframe v-if="is_play"
            :style="`border-radius: ${border};`"
            width="100%"
            :height="height"
            :src="`https://www.youtube.com/embed/${play.content_jsonobject.value.id}?autoplay=true`" 
            :type="play.content_jsonobject.value.mime"
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowtransparency
            allowfullscreen>
          </iframe>

          <v-img v-if="!is_play"
            :style="`border-radius: ${border};`"
            width="100%"
            :height="height"
            :src="play.content_jsonobject.thumbnail">
          </v-img>
        </div>
      </section>

      <section v-if="type_player === 'drive' && Object.keys(play).length > 0" 
        :style="`border-radius: ${border};`"
        style="background: black;">
        <div v-if="process.run"
          :style="`height: ${height}px`">
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#FF3177">
            </v-progress-circular>
          </v-row>
        </div>

        <div v-if="!process.run">
          <v-container v-if="is_play"
            class="py-0" 
            style="position: absolute; top: 0px; background: #333638;"
            :style="
                selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                'border-radius: 10px;'">
            <v-row>
              <v-expansion-panels flat v-model="selected">
                <v-expansion-panel style="background: #333638;"
                  :style="
                    selected === undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 0px;' : 
                    selected !== undefined && $route.name === 'elearning-slug' ? 'border-radius: 10px 0px 0px 10px;' : 
                    selected === undefined && $route.name !== 'elearning-slug' ? 'border-radius: 10px 10px 0px 0px;' : 
                    'border-radius: 10px;'">
                  <v-expansion-panel-header class="white--text font-weight-bold"
                    :class="rps === 'xs' ? 'body-1 font-weight-bold' : 'title'">
                    <span class="one-line">
                      Deskripsi Materi
                    </span>

                    <template v-slot:actions>
                      <v-icon color="#FFF">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div class="pa-6 py-0">
                      <v-divider color="white"/>
                    </div>

                    <div class="body-2 pa-6 pt-5"
                      style="overflow-y: auto;"
                      :style="`height: ${height - 58}px`">
                      <p class="title line-text-first white--text">
                        {{ play.title }}
                      </p>

                      <p class="font-weight-light line-text-second white--text mb-0">
                        <span v-html="play.deskripsi"></span>
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>

          <iframe v-if="is_play"
            :style="`border-radius: ${border};`"
            width="100%"
            :height="height"
            :src="`https://drive.google.com/file/d/${play.content_jsonobject.value.id}/preview`" 
            :type="play.content_jsonobject.value.mime"
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowtransparency
            allowfullscreen>
          </iframe>

          <v-img v-if="!is_play"
            :style="`border-radius: ${border};`"
            width="100%"
            :height="height"
            :src="play.content_jsonobject.thumbnail">
          </v-img>
        </div>
      </section>
    </v-flex>
  </div>
</template>

<script>
  export default {
    props: {
      player: {
        type: Object,
        default: () => {}
      },
      play: {
        type: Object,
        default: () => {}
      },
      height: {
        type: Number,
        default: 0
      },
      border: {
        type: String,
        default: '10px'
      }
    },
    data () {
      return {
        selected: undefined,
        type_player: '',
        is_play: false,
        process: {
          run: false
        }
      }
    },
    watch: {
      'player': function(val) {
        this.type_player = val.type
        this.is_play = val.is_play

        this.process.run = this.is_play

        setTimeout(() => {
          this.selected = undefined

          this.process.run = false
        }, 3000)
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.type_player = this.$props.player.type
      this.is_play = this.$props.player.is_play

      this.process.run = this.is_play

      setTimeout(() => {
        this.selected = undefined
        
        this.process.run = false
      }, 3000)
    }
  }
</script>

<style>
.hide-button-play {
  background: #333638;
  height: 50px;
  width: 200px;
  position: absolute;
  z-index: 9;
  top: 5px;
  right: 5px;
}
.hide-button-stop {
  background: transparent;
  height: 50px;
  width: 200px;
  position: absolute;
  z-index: 9;
  top: 5px;
  right: 5px;
}
</style>