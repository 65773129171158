<template>
  <div class="fill-height mb-12">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="dialog.share"
      width="375"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="body-1 font-weight-bold text-second">
              Share {{ detail.kelas_nama }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third">
              Klik icon di bawah untuk share event ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                :network="item.network"
                :url="`${host}${$route.path}`"
                :title="detail.kelas_slug"
                :description="detail.kelas_nama"
                hashtags="e-learning,bidankita">
                <v-img
                  width="45"
                  height="45"
                  contain
                  alt="e-learning"
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#333333"
            elevation="3"
            class="body-2 font-weight-bold white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="dialog.snackbar"
      top
      color="#333333"
      vertical>
      <div class="body-1 font-weight-light white--text"
        v-html="dialog.stock">
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          class="body-1 text-capitalize"
          color="red"
          text
          v-bind="attrs"
          @click="dialog.snackbar = false">
          Ya, Saya mengerti
        </v-btn>
      </template>
    </v-snackbar>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="pa-0">
            <v-row>
              <v-col>
                <v-card
                  v-for="(item, index) in detail.kelas_schedule"
                  :key="index"
                  class="box-shadow mb-6"
                  style="border-radius: 5px;"
                  :color="select === index ? '#ff31770d' : ''"
                  :style="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime ? 'opacity: 50%;' : ''">
                  <v-card-text class="pa-0">
                    <v-row>
                      <v-col :cols="rps !== 'xs' ? 2 : 4" class="d-flex align-center justify-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <div v-if="item.schedule_is_purchased === '1' || detail.kelas_is_discount"
                          :class="item.schedule_is_purchased === '1' ? 'badge-overlay-buy' : 'badge-overlay-discount'">
                          <span class="font-weight-bold"
                            :class="item.schedule_is_purchased === '1' ? 'top-left-buy badge-buy buy' : 'top-left-discount badge-discount discount'">
                            <v-icon v-if="item.schedule_is_purchased === '1'"
                              color="white"
                              style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                              mdi-check-bold
                            </v-icon>

                            <div v-else
                              style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                              -{{ detail.kelas_diskon_jsonobject.percent }}%
                            </div>
                          </span>
                        </div>
                        
                        <div style="position: absolute;" :style="rps !== 'xs' ? 'left: -18px;' : 'left: -8px;'">
                          <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                        </div>
                        
                        <v-spacer/>

                        <div class="text-center">
                          <p class="font-weight-bold text-first mb-0"
                            :class="rps !== 'xs' ? 'text-h5' : 'title'">
                            {{ item.schedule_start_datetime | day }}

                            <span v-if="item.schedule_time_jsonarray.length > 1">
                              - {{ item.schedule_end_datetime | day }}
                            </span>
                          </p>

                          <p class="text-second text-uppercase mb-0"
                            :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                            {{ item.schedule_start_datetime | month }}
                          </p>

                          <p class="text-second mb-0"
                            :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                            {{ item.schedule_start_datetime | year }}
                          </p>
                        </div>

                        <v-spacer/>

                        <v-divider vertical style="border-style: dashed;"/>
                      </v-col>

                      <v-col :cols="rps !== 'xs' ? 5 : 8" class="d-flex align-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <v-row align="start">
                          <v-col :cols="rps !== 'xs' ? 9 : 12" class="py-0">
                            <div class="mb-2">
                              <div v-if="dateNow <= item.schedule_end_datetime">
                                <div v-if="item.schedule_status !== 'Selesai'">
                                  <v-chip v-if="dateNow > item.schedule_early_price_end_datetime"
                                    style="border: 1px solid #6161614d;"
                                    color="#61616133"
                                    label
                                    :small="rps !== 'xs'"
                                    :x-small="rps === 'xs'"
                                    text-color="#616161"
                                    class="font-weight-bold text-uppercase">
                                    NORMAL PRICE
                                  </v-chip>

                                  <v-chip v-else
                                    style="border: 1px solid #0080004d"
                                    color="#4caf5033"
                                    label
                                    :small="rps !== 'xs'"
                                    :x-small="rps === 'xs'"
                                    text-color="green"
                                    class="font-weight-bold text-uppercase">
                                    EARLY PRICE
                                  </v-chip>

                                  <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                    s.d. 
                                    <span v-if="dateNow >= item.schedule_early_price_end_datetime">
                                      {{ item.schedule_normal_price_end_datetime | date }}
                                    </span>

                                    <span v-else>
                                      {{ item.schedule_early_price_end_datetime | date }}
                                    </span>
                                  </span>
                                </div>

                                <div v-else>
                                  <v-chip
                                    style="border: 1px solid #f443364d;"
                                    color="#f4433633"
                                    label
                                    :small="rps !== 'xs'"
                                    :x-small="rps === 'xs'"
                                    text-color="red"
                                    class="font-weight-bold text-uppercase">
                                    {{ item.schedule_status }}
                                  </v-chip>

                                  <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                    pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                  </span>
                                </div>
                              </div>

                              <div v-else>
                                <v-chip
                                  style="border: 1px solid #f443364d;"
                                  color="#f4433633"
                                  label
                                  :small="rps !== 'xs'"
                                  :x-small="rps === 'xs'"
                                  text-color="red"
                                  class="font-weight-bold text-uppercase">
                                  {{ item.schedule_status !== 'Selesai' ? 'Pendaftaran Berakhir' : item.schedule_status }}
                                </v-chip>

                                <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                  pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                </span>
                              </div>
                            </div>

                            <div class="burst_special_price_mini" v-if="rps === 'xs' && detail.kelas_is_special_price"
                              style="position: absolute; top: 40px; right: 20px;">
                              <span>
                                Harga Spesial
                              </span>
                            </div>
                          </v-col>

                          <v-col cols="3" class="d-flex justify-center" v-if="rps !== 'xs'">
                            <div class="burst_special_price" v-if="detail.kelas_is_special_price">
                              <span>
                                Harga Spesial
                              </span>
                            </div>
                          </v-col>

                          <v-col cols="12" class="pb-0" v-if="rps !== 'xs'">
                            <v-row align="center">
                              <v-col :cols="item.schedule_is_purchased === '0' ? 9 : 7" class="d-flex align-center py-0">
                                <div>
                                  <p class="caption text-third text-uppercase mb-0">
                                    JAM KELAS
                                  </p>

                                  <p class="body-2 font-weight-bold text-second mb-0">
                                    {{ item.schedule_start_datetime | time }} - {{ item.schedule_end_datetime | time }}
                                  </p>
                                </div>

                                <v-spacer v-if="item.schedule_is_purchased === '0'"/>

                                <v-divider vertical v-if="item.schedule_is_purchased === '0'"/>
                                
                                <v-spacer v-if="item.schedule_is_purchased === '0'"/>

                                <div v-if="item.schedule_is_purchased === '0'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    HARGA KELAS
                                  </p>

                                  <p class="body-2 font-weight-bold mb-0">
                                    <span v-if="!item.kelas_is_discount">
                                      <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                        Gratis
                                      </span>

                                      <span class="color-first" v-else>
                                        <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                          {{ parseInt(item.schedule_early_price) | price }}
                                        </span>

                                        <span v-else>
                                          {{ parseInt(item.schedule_normal_price) | price }}
                                        </span>
                                      </span>
                                    </span>

                                    <span class="orange--text" v-if="item.kelas_is_discount">
                                      <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                        {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>

                                      <span v-else>
                                        {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>
                                    </span>
                                  </p>
                                </div>

                                <v-spacer/>

                                <v-divider vertical/>

                                <v-spacer/>

                                <div v-if="item.schedule_is_purchased === '0'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    SISA KUOTA
                                  </p>

                                  <p class="body-2 text-second mb-0">
                                    <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                                  </p>
                                </div>

                                <div v-if="item.schedule_is_purchased === '1'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    INFO KELAS
                                  </p>

                                  <p class="body-2 font-weight-bold mb-0">
                                    <span
                                      :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                              item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                      {{ item.schedule_status }}
                                    </span>
                                  </p>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="12" class="d-flex align-center pb-0" v-if="rps === 'xs'">
                            <div>
                              <p class="caption text-third text-uppercase mb-0">
                                HARGA KELAS
                              </p>

                              <p class="body-2 font-weight-bold mb-0">
                                <span v-if="!item.kelas_is_discount">
                                  <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                    Gratis
                                  </span>

                                  <span class="color-first" v-else>
                                    <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                      {{ parseInt(item.schedule_early_price) | price }}
                                    </span>

                                    <span v-else>
                                      {{ parseInt(item.schedule_normal_price) | price }}
                                    </span>
                                  </span>
                                </span>

                                <span class="orange--text" v-if="item.kelas_is_discount">
                                  <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                    {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                  </span>

                                  <span v-else>
                                    {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                  </span>
                                </span>
                              </p>
                            </div>

                            <v-spacer/>

                            <div class="pr-3" v-if="item.schedule_is_purchased === '0'">
                              <p class="caption text-third text-uppercase mb-0">
                                SISA KUOTA
                              </p>

                              <p class="body-2 text-second mb-0">
                                <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                              </p>
                            </div>

                            <div class="pr-3" v-if="item.schedule_is_purchased === '1'">
                              <p class="caption text-third text-uppercase mb-0">
                                INFO KELAS
                              </p>

                              <p class="body-2 font-weight-bold mb-0">
                                <span
                                  :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                          item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                  {{ item.schedule_status }}
                                </span>
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col :cols="rps !== 'xs' ? 5 : 12" 
                        class="d-flex align-center justify-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <div class="d-flex align-center" style="position: absolute;" :style="rps !== 'xs' ? 'right: -18px;' : 'right: -8px;'">
                          <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                        </div>

                        <div class="d-flex align-center">
                          <div v-if="item.schedule_is_purchased === '0'">
                            <v-btn
                              block
                              rounded
                              elevation="0"
                              color="#FF3177"
                              :small="rps === 'xs'"
                              class="text-capitalize white--text"
                              :class="rps === 'xs' ? 'caption' : 'body-1'"
                              :disabled="process.cart || process.buynow || (item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime)"
                              :loading="process.cart && selected.index === index"
                              @click="selected.index = index; addToCart('cart');">
                              <v-icon size="16" class="mr-1">mdi-plus</v-icon>
                              Ke Keranjang
                            </v-btn>
                          </div>

                          <div v-if="item.schedule_is_purchased === '1'">
                            <v-btn
                              style="opacity: 0%;"
                              block
                              rounded
                              disabled
                              elevation="0"
                              :small="rps === 'xs'"
                              color="transparent"
                              class="text-capitalize white--text"
                              :class="rps === 'xs' ? 'caption' : 'body-1'">
                              <v-icon size="16" class="mr-1">mdi-plus</v-icon>
                              Ke Keranjang
                            </v-btn>
                          </div>

                          <v-spacer/>

                          <div class="mx-3" v-if="item.schedule_is_purchased === '0'">
                            <v-btn
                              outlined
                              block
                              rounded
                              elevation="0"
                              :small="rps === 'xs'"
                              color="#FF3177"
                              class="text-capitalize color-first"
                              :class="rps === 'xs' ? 'caption' : 'body-1'"
                              :disabled="process.buynow || process.cart || (item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime)"
                              :loading="process.buynow && selected.index === index"
                              @click="selected.index = index; addToCart('buynow');">
                              Beli Sekarang
                            </v-btn>
                          </div>

                          <div class="mx-3" v-if="item.schedule_is_purchased === '1'">
                            <v-btn
                              outlined
                              block
                              rounded
                              elevation="0"
                              :small="rps === 'xs'"
                              color="#FF3177"
                              class="text-capitalize color-first"
                              :class="rps === 'xs' ? 'caption' : 'body-1'"
                              :href="`https://kelas.bidankita.com/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`"
                              target="_blank">
                              Masuk Kelas
                            </v-btn>
                          </div>
                          
                          <v-spacer/>

                          <div>
                            <v-btn
                              fab
                              color="transparent"
                              :width="rps === 'xs' ? 30 : 40"
                              :height="rps === 'xs' ? 30 : 40"
                              elevation="0"
                              :disabled="process.buynow || process.cart || (item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime)"
                              @click="dialog.share = true">
                              <v-icon :size="rps === 'xs' ? 20 : 30">mdi-share-variant</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      select: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
        dialog: {
          auth: '',
          message: '',
          share: false,
          snackbar: false,
          stock: ''
        },
        selected: {
          index: ''
        },
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        process: {
          cart: false,
          buynow: false
        },
        message: ''
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message')
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      
    },
    methods: {
      async addToCart(type) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          if (this.detail.kelas_schedule[this.selected.index].schedule_quota_left < 1) {
            this.dialog.stock = 'Kuota habis, silahkan pilih jadwal lainnya.'
            this.dialog.snackbar = true
          } else {
            this.saveCart(type)
          }
        }
      },
      async saveCart(type) {
        this.process.cart = type === 'cart' ? true : false
        this.process.buynow = type === 'buynow' ? true : false

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: this.detail.kelas_id,
          schedule_id: this.detail.kelas_schedule[this.selected.index].schedule_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false
            this.process.buynow = false

            this.$store.commit('header/setCart', this.headers.cart + 1)

            if (type === 'cart') {
              this.dialog.message = 'success'
              this.message = 'Berhasil ditambahkan ke Keranjang.'
            } else {
              this.$router.push('/cart')
            }
          } else {
            this.process.cart = false
            this.process.buynow = false
            
            this.dialog.message = 'error'
            this.message = response.message
          }
        })
      }
    }
  }
</script>