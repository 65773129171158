<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex v-if="process.run && Object.keys(detail).length < 1">
        <section class="my-16">
          <v-app-bar 
            app
            fixed
            class="header"
            :class="rps !== 'xs' && rps !== 'sm' ? 'px-12' : ''"
            style="z-index: 999;">
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="8" :class="rps !== 'xs' && rps !== 'sm' ? '' : ' px-0'">
                  <v-skeleton-loader
                    type="list-item-avatar">
                  </v-skeleton-loader>
                </v-col>

                <v-col cols="4" :class="rps !== 'xs' && rps !== 'sm' ? '' : ' px-0'">
                  <v-skeleton-loader
                    type="list-item">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
          </v-app-bar>

          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-skeleton-loader
                  type="article">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="4">
                <v-skeleton-loader
                  type="article">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12" md="8">
                <v-skeleton-loader
                  class="skeleton-image-detail"
                  type="image">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="4">
                <v-skeleton-loader
                  type="article, list-item-three-line, article, actions">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="article, article">
                </v-skeleton-loader>

                <v-row>
                  <v-col cols="12" md="2" sm="6"
                    v-for="(item, index) in 6"
                    :key="index">
                    <v-skeleton-loader
                      type="card, article">
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-card flat v-for="i in 5" :key="i"
              class="mt-3 mb-6">
              <v-row>
                <v-col cols="2">
                  <v-skeleton-loader
                    type="article">
                  </v-skeleton-loader>
                </v-col>

                <v-col cols="7">
                  <v-skeleton-loader
                    type="article">
                  </v-skeleton-loader>
                </v-col>

                <v-col cols="3">
                  <v-skeleton-loader
                    type="list-item-two-line, actions">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </section>
      </v-flex>

      <v-flex v-if="!process.run &&  Object.keys(detail).length > 0">
        <section class="mt-16">
          <v-container class="pb-0">
            <v-row>
              <v-col class="pb-0">
                <v-breadcrumbs :items="items" class="pa-0">
                  <template v-slot:divider>
                    <v-icon color="grey">mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section :class="rps !== 'sm' && rps !== 'xs' ? 'px-16' : 'px-3'">
          <v-app-bar 
            app
            fixed
            class="header"
            :class="rps !== 'xs' && rps !== 'sm' ? 'px-12' : ''"
            style="z-index: 999;">
            <v-row align="center" justify="center">
              <v-col cols="8" class="d-flex align-center">
                <div class="mr-6">
                  <v-btn
                    small
                    icon
                    color="#FF3177"
                    :href="`https://kelas.bidankita.com/collection/streaming`"
                    target="_blank">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </div>

                <div>
                  <span class="title one-line">
                    {{ detail.kelas_nama }}
                  </span>
                </div>
              </v-col>

              <v-col cols="4" class="title text-right">
                <span class="text-uppercase font-weight-bold">
                  <span :class="detail.schedule_status === 'Belum Mulai' ? 'blue--text' :
                          detail.schedule_status === 'Sedang Berlangsung' ? 'green--text' :
                          detail.schedule_status === 'Selesai' ? 'red--text' :
                          detail.schedule_status === 'Dibatalkan' ? 'grey--text' : 'orange--text'">
                    {{ detail.schedule_status }}
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-app-bar>

          <v-container :class="rps !== 'xs' ? '' : 'px-0'">
            <v-row align="start">
              <v-col cols="12" md="8">
                <p class="text-h5 font-weight-bold text-second line-text-first mb-0">
                  <span class="color-first">[LIVE STREAM]</span> {{ detail.kelas_nama }}
                </p>

                <p class="body-1 font-weight-light text-second mb-0">
                  {{ detail.kelas_kategori_title }}
                </p>
              </v-col>

              <v-col cols="12" md="4" v-if="parseInt(detail.kelas_rating_summary.total) > 0">
                <div class="text-right">
                  <p class="text-h4 font-weight-bold text-second line-text-first mb-0 mr-1">
                    {{ parseFloat(detail.kelas_rating_summary.value).toFixed(1) }}
                  </p>

                  <div style="position: relative; top: -10px;">
                    <v-rating dense
                      size="20"
                      :value="parseFloat(detail.kelas_rating_summary.value)"
                      readonly
                      color="orange"
                      background-color="orange"
                      half-increments>
                    </v-rating>

                    <p class="body-1 font-weight-light text-second mt-1 mb-0 mr-1">
                      ({{ parseInt(detail.kelas_rating_summary.total) }})
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12" md="8" v-if="selected.menu !== 3">
                <v-card v-if="player.media.is_play"
                  flat
                  color="black"
                  :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" 
                  style="border-radius: 15px;"
                  class="mb-3">
                  <Players 
                    :player="player.media" 
                    :play="player.data" 
                    :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" 
                    border="10px" />
                </v-card>

                <v-card v-else
                  flat
                  style="border-radius: 10px;">
                  <v-img
                    width="100%"
                    height="500"
                    class="d-flex align-center justify-center"
                    :src="detail.kelas_image_jsonobejct.kelas_image_cover_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <div v-if="detail.kelas_materi_jsonarray.length > 0"
                      @click="playing(detail.kelas_materi_jsonarray[0]); player.media.is_play = true;"
                      class="text-center cursor-pointer">
                      <v-icon color="#fff" size="100">mdi-play-circle</v-icon>
                    </div>
                  </v-img>
                </v-card>
              </v-col>

              <v-col cols="12" md="8" v-else>
                <v-card v-if="player_khusus.media.is_play"
                  flat
                  color="black"
                  :height="rps !== 'xs' ? 500 : player_khusus.media.type !== 'docs' ? 300 : 375" 
                  style="border-radius: 15px;"
                  class="mb-3">
                  <Players 
                    :player="player_khusus.media" 
                    :play="player_khusus.data" 
                    :height="rps !== 'xs' ? 500 : player_khusus.media.type !== 'docs' ? 300 : 375" 
                    border="10px" />
                </v-card>

                <v-card v-else
                  flat
                  style="border-radius: 10px;">
                  <v-img
                    width="100%"
                    height="500"
                    class="d-flex align-center justify-center"
                    :src="detail.kelas_image_jsonobejct.kelas_image_cover_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <div v-if="detail.schedule_materi_jsonarray.length > 0"
                      @click="playing_khusus(detail.schedule_materi_jsonarray[0]); player_khusus.media.is_play = true;"
                      class="text-center cursor-pointer">
                      <v-icon color="#fff" size="100">mdi-play-circle</v-icon>
                    </div>
                  </v-img>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card flat
                  style="border-radius: 0 10px 10px 0;"
                  min-height="450">
                  <v-card-text class="pa-6">
                    <div class="d-flex align-center">
                      <div class="text-center mr-6">
                        <p class="title font-weight-bold mb-0">
                          {{ detail.schedule_summary_jsonobject.start_datetime | day }}
                        </p>

                        <p class="body-2 text-second text-uppercase mb-0">
                          {{ detail.schedule_summary_jsonobject.start_datetime | month }}
                        </p>

                        <p class="body-2 text-second mb-0">
                          {{ detail.schedule_summary_jsonobject.start_datetime | year }}
                        </p>
                      </div>

                      <div>
                        <v-chip 
                          :style="
                            detail.schedule_status === 'Belum Mulai' ? 'border: 1px solid #2196f34d;' :
                            detail.schedule_status === 'Sedang Berlangsung' ? 'border: 1px solid #4caf504d;' :
                            detail.schedule_status === 'Selesai' ? 'border: 1px solid #f443364d;' :
                            detail.schedule_status === 'Dibatalkan' ? 'border: 1px solid #6161614d;' : 'border: 1px solid #ff98004d;'"
                          :color="
                            detail.schedule_status === 'Belum Mulai' ? '#2196f333' :
                            detail.schedule_status === 'Sedang Berlangsung' ? '#4caf5033' :
                            detail.schedule_status === 'Selesai' ? '#f4433633' :
                            detail.schedule_status === 'Dibatalkan' ? '#61616133' : '#ff980033'"
                          label
                          :text-color="
                            detail.schedule_status === 'Belum Mulai' ? '#2196F3' :
                            detail.schedule_status === 'Sedang Berlangsung' ? '#4CAF50' :
                            detail.schedule_status === 'Selesai' ? '#F44336' :
                            detail.schedule_status === 'Dibatalkan' ? '#616161' : '#FF9800'"
                          class="font-weight-bold text-uppercase">
                          {{ detail.schedule_status }}
                        </v-chip>

                        <div class="mt-2">
                          <span class="text-second" :class="rps !== 'xs' ? '' : 'caption'">
                            <span v-if="detail.schedule_status !== 'Selesai'">
                              s.d. {{ detail.schedule_summary_jsonobject.end_datetime | date }}
                            </span>

                            <span v-else>
                              pada {{ detail.schedule_summary_jsonobject.end_datetime | date }} Pukul {{ detail.schedule_summary_jsonobject.end_datetime | time }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="mt-8 mb-3">
                      <p class="body-2 text-third mb-0">
                        JAM LIVE STREAM
                      </p>

                      <p class="body-1 font-weight-bold mb-0">
                        {{ detail.schedule_summary_jsonobject.start_datetime | time }} -
                        {{ detail.schedule_summary_jsonobject.end_datetime | time }}
                      </p>
                    </div>

                    <div class="mb-3">
                      <p class="body-2 text-third mb-0">
                        LINK LIVE STREAM
                      </p>

                      <p class="body-1 font-weight-bold mb-0">
                        <span v-if="detail.schedule_summary_jsonobject.link === ''">
                          Belum Tersedia
                        </span>

                        <span v-else>
                          <a 
                            :href="detail.schedule_summary_jsonobject.link"
                            target="_blank">
                            {{ detail.schedule_summary_jsonobject.link }}
                          </a>
                        </span>
                      </p>
                    </div>

                    <div class="mb-3">
                      <p class="body-2 text-third mb-0">
                        PASSWORD LIVE STREAM
                      </p>

                      <p class="body-1 font-weight-bold mb-0">
                        {{ detail.schedule_summary_jsonobject.password === '' ? 'Belum Tersedia' : detail.schedule_summary_jsonobject.password }} 
                      </p>
                    </div>

                    <v-card-text v-if="detail.schedule_status === 'Belum Mulai'"
                      class="body-2 font-weight-light font-italic line-text-first px-0">
                      <p>
                        "Live streaming kelas <b>{{ detail.kelas_nama }}</b> akan dimulai pada tanggal <b>{{ detail.schedule_summary_jsonobject.start_datetime | date }}</b> pukul <b>{{ detail.schedule_summary_jsonobject.start_datetime | time }}</b>. Link zoom akan tersedia pada saat kelas dimulai."
                      </p>

                      <p class="mb-0">
                        Jika ingin melihat jadwal lainnya, silahkan buka menu <b>Jadwal Lainnya</b>.
                      </p>
                    </v-card-text>

                    <v-card-text v-if="detail.schedule_status === 'Selesai'"
                      class="body-2 font-weight-light font-italic line-text-first px-0">
                      <p>
                        "Live streaming kelas <b>{{ detail.kelas_nama }}</b> telah selesai pada tanggal <b>{{ detail.schedule_summary_jsonobject.end_datetime | date }}</b> pukul <b>{{ detail.schedule_summary_jsonobject.end_datetime | time }}</b>."
                      </p>

                      <p class="mb-0">
                        Jika ingin melihat jadwal lainnya, silahkan buka menu <b>Jadwal Lainnya</b>.
                      </p>
                    </v-card-text>

                    <v-card-text v-if="detail.schedule_status === 'Dibatalkan' || detail.schedule_status === 'Reschedule'"
                      class="body-2 font-weight-light font-italic line-text-first px-0">
                      <p>
                        "Live streaming kelas <b>{{ detail.kelas_nama }}</b> telah dibatalkan atau digantikan jadwal karena alasan tertentu. Silahkanlihat jadwal pengganti di kelas live streaming."
                      </p>

                      <p class="mb-0">
                        Jika ingin melihat jadwal lainnya, silahkan buka menu <b>Jadwal Lainnya</b>.
                      </p>
                    </v-card-text>

                    <v-card-text v-if="detail.schedule_status === 'Sedang Berlangsung'"
                      class="body-2 font-weight-light font-italic line-text-first px-0">
                      "Yuk bergabung di kelas {{ detail.kelas_nama }} sekarang, dengan menekan tombol dibawah ini."
                    </v-card-text>

                    <v-card-text class="px-0" v-if="detail.schedule_status === 'Sedang Berlangsung'">
                      <v-btn
                        block
                        rounded
                        elevation="0"
                        color="#FF3177"
                        class="body-1 text-capitalize white--text"
                        :href="detail.schedule_summary_jsonobject.link"
                        target="_blank">
                        Bergabung Sekarang
                      </v-btn>
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="10" class="pt-0">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow
                  slider-size="4">
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2"
                    :style="index === 4 || index === 6 ? 'min-width: 100px; max-width: 100px;' : 'min-width: 140px; max-width: 140px;'">
                    <span class="body-1 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item }}
                    </span>
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-tabs-items v-model="selected.menu"
                  style="background: transparent;">
                  <v-tab-item 
                    v-for="(item, index) in menu"
                    :key="index">
                    <Information v-if="selected.menu === 0"
                      :detail="detail"
                      :panel="selected.panel"
                      :panel_khusus="selected.panel_khusus"
                      :play="player"
                      @changePlay="playing($event)" 
                      @changePanel="selected.panel = $event"
                      @changePlayKhusus="playing_khusus($event)" 
                      @changePanelKhusus="selected.panel_khusus = $event"
                      @changeTab="selected.menu = $event"  />
                    <Schedule v-if="selected.menu === 1"
                      :detail="detail"  />
                    <MateriUmum v-if="selected.menu === 2"
                      :detail="detail"
                      :panel="selected.panel"
                      :play="player"
                      @changePlay="playing($event)" 
                      @changePanel="selected.panel = $event"  />
                    <MateriKhusus v-if="selected.menu === 3"
                      :detail="detail"
                      :panel="selected.panel_khusus"
                      :play="player_khusus"
                      @changePlay="playing_khusus($event)" 
                      @changePanel="selected.panel_khusus = $event"  />
                    <Discus v-if="selected.menu === 4"
                      :detail="detail" />
                    <Announcement v-if="selected.menu === 5"
                      :detail="detail" />
                    <Instructor v-if="selected.menu === 6"
                      :detail="detail" />
                    <Review v-if="selected.menu === 7"
                      :detail="detail" />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    data () {
      return {
        items: [
          {
            text: 'Live Streaming Saya',
            disabled: false,
            href: 'https://kelas.bidankita.com/collection/streaming'
          },
          {
            text: 'Detail',
            disabled: true,
            href: '#'
          },
        ],
        selected: {
          panel: 0,
          panel_khusus: 0,
          menu: 0,
          menu_materi: 'Umum'
        },
        menu: ['Tentang Kelas', 'Jadwal Lainnya', 'Materi Umum', 'Materi Khusus', 'Diskusi', 'Pengumuman', 'Instruktur', 'Rating & Ulasan'],
        menu_materi: ['Umum', 'Khusus'],
        detail: {},
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        player_khusus: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        list: [],
        pagination: {},
        process: {
          run: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'collection/:slug',
          title: 'E-Learning Bidan Kita',
          description: 'E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Schedule: () => import('@/components/collection/schedule'),
      Information: () => import('@/components/collection/information'),
      MateriUmum: () => import('@/components/collection/materi_umum'),
      MateriKhusus: () => import('@/components/collection/materi_khusus'),
      Announcement: () => import('@/components/collection/announcement'),
      Discus: () => import('@/components/collection/discus'),
      Instructor: () => import('@/components/collection/instructor'),
      Review: () => import('@/components/collection/review'),
      Players: () => import('@/components/Player')
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      },
      headers() {
        return this.$store.state.header
      }
    },
    beforeCreate () {
      let params = {
        kelas_id: this.$route.params.id,
        schedule_id: this.$route.query.schedule_id,
        device_id: 'mobile'
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/member/class/live/detail`, { params })
      .then((response) => {
        this.content = {
          url: `collection/${response.data.kelas_id}`,
          title: response.data.kelas_nama,
          description: response.data.kelas_deskripsi,
          image: response.data.kelas_image_jsonobejct.kelas_image_cover_url
        }
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          kelas_id: this.$route.params.id,
          schedule_id: this.$route.query.schedule_id,
          device_id: 'mobile'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/class/live/detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data
          }
        })
      },
      playing(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: this.player.media.is_play
        }
        this.player.data = item

        this.$vuetify.goTo(0)
      },
      playing_khusus(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        this.player_khusus.media = {
          type: item.content_jsonobject.type,
          is_play: this.player_khusus.media.is_play
        }
        this.player_khusus.data = item

        this.$vuetify.goTo(0)
      }
    }
  }
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.materi-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.skeleton-image-detail .v-skeleton-loader__image {
  height: 500px !important;
}

.v-tabs-slider {
  border-radius: 50px !important;
}
</style>