<template>
  <div class="fill-height py-12 mt-6">
    <v-dialog v-model="filter.dialog"
      width="500"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius pa-2">
        <v-card-text class="pa-0 pb-3 text-right">
          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="filter.dialog = false; reset();">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text>
          <v-form>
            <v-text-field
              dense 
              outlined
              single-line
              color="#FF3177"
              placeholder="Judul Kelas"
              clearable
              v-model="filter.title">
            </v-text-field>

            <v-row>
              <v-col cols="12" md="6" sm="6" :class="rps === 'xl' || rps === 'lg' ? 'py-0' : ''">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      block
                      outlined
                      elevation="0"
                      color="grey darken-1"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      {{ filter.label_type }}
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense>
                    <v-list-item-group
                      color="#FF3177">
                      <v-list-item class="hover-list"
                        v-for="(item, index) in type"
                        :key="index"
                        @click="filter.type = item.value; filter.label_type = item.label;">
                        <v-list-item-title class="body-2">
                          {{ item.label }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6" sm="6" :class="rps === 'xl' || rps === 'lg' ? 'py-0' : ''">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      block
                      outlined
                      elevation="0"
                      color="grey darken-1"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      <span style="max-width: 160px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;">
                        {{ filter.label_category }}
                      </span>
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense max-height="300">
                    <v-list-item-group
                      style="background: white;"
                      color="#FF3177">
                      <div 
                        v-for="(item, index) in category"
                        :key="index">
                        <v-list-item class="hover-list" v-if="item.sub.length < 1"
                          @click="
                            filter.category = item.value; 
                            filter.label_category = item.label; 
                            filter.category_sub = '';">
                          <v-list-item-title class="body-2">
                            {{ item.label }}
                          </v-list-item-title>
                        </v-list-item>

                        <div v-if="item.sub.length > 0">
                          <v-list-group no-action color="#FF3177" @click.stop>
                            <template v-slot:activator>
                              <v-list-item class="px-0">
                                <v-list-item-title>
                                  {{ item.label }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>

                            <v-list-item
                              class="px-0 hover-list"
                              v-for="(sub, i) in item.sub"
                              :key="i"
                              @click="
                                filter.category_sub = sub.kelas_kategori_id; 
                                filter.label_category = sub.kelas_kategori_title; 
                                filter.category = '';">
                              <v-list-item-title class="pl-8">
                                {{ sub.kelas_kategori_title }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list-group>
                        </div>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            
            <v-row justify="end" class="mt-3">
              <v-col cols="12" md="4" sm="4" class="text-right pb-0">
                <v-btn
                  block
                  rounded
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize white--text"
                  @click="filter.dialog = false; fetch();">
                  <v-icon class="mr-1">mdi-magnify</v-icon>
                  Cari
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row
              justify="center"
              align="end">
              <v-col cols="12" md="4" :class="rps === 'xs' ? 'text-center' : ''">
                <span class="body-1 font-weight-bold text-second">
                  Live Streaming Saya
                </span>
              </v-col>

              <v-col cols="12" md="8" class="d-flex align-center" :class="rps === 'xs' ? 'justify-center' : 'justify-end'">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow
                  class="d-flex" :class="rps === 'xs' ? 'justify-start' : 'justify-end'"
                  :style="rps === 'xs' ? 'max-width: 290px;' : ''">
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mx-1"
                    :style="index === 0 || index === 3 ? 'min-width: 55px; max-width: 55px;' : 
                      index === 1 || index === 2 ? 'min-width: 90px; max-width: 90px;' : 'min-width: 75px; max-width: 75px;'">
                    <span class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-tab>
                </v-tabs>
                
                <v-icon class="ml-6 cursor-pointer" @click="filter.dialog = true; fetchType(); fetchCategory();">mdi-filter-variant</v-icon>
              </v-col>
            </v-row>

            <v-row class="py-3">
              <v-col cols="12" md="3"
                v-for="(item, index) in process.run ? 8 : list"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.run"
                  type="card, article">
                  <div>
                    <v-card v-if="!process.run && list.length > 0"
                      class="border-radius box-shadow"
                      :href="`https://kelas.bidankita.com/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`"
                      target="_blank">
                      <v-img
                        width="100%"
                        height="220"
                        :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-chip v-if="item.schedule_status === 'Sedang Berlangsung'"
                          small
                          class="white--text ma-3 pl-1"
                          color="green">
                          <v-icon size="20" class="mr-1">mdi-record</v-icon>
                          {{ item.schedule_status }}
                        </v-chip>
                      </v-img>

                      <div class="pa-2">
                        <v-card-text>
                          <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                            <span class="two-line">
                              {{ item.kelas_nama }}
                            </span>
                          </p>
                          <p class="body-2 text-second mb-0">
                            <span class="one-line">
                              {{ item.kelas_kategori_title }}
                            </span>
                          </p>
                        </v-card-text>

                        <v-card-text>
                          <div class="d-flex align-center">
                            <div class="d-flex align-center">
                              <v-rating
                                :value="parseFloat(item.kelas_total_rating)"
                                background-color="yellow darken-2"
                                color="yellow darken-2"
                                dense
                                disabled
                                size="18">
                              </v-rating>
                              <span class="text-second caption font-weight-bold ml-1">
                                ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                              </span>
                            </div>

                            <v-spacer/>

                            <div class="text-second" v-if="item.kelas_total_materi > 0">
                              <span class="font-weight-bold">
                                {{ item.kelas_total_materi }}
                              </span>
                              <span class="body-2">
                                Materi
                              </span>
                            </div>
                          </div>
                        </v-card-text>

                        <v-divider class="mb-2 mx-4"/>

                        <v-card-actions class="body-2 px-4">
                          <div>
                            <span class="font-weight-bold text-first">
                              {{ item.schedule_start_datetime | date }}
                            </span>
                          </div>

                          <v-spacer/>

                          <div>
                            <span 
                              :class="item.schedule_status === 'Belum Mulai' ? 'grey--text' :
                                item.schedule_status === 'Sedang Berlangsung' ? 'green--text' :
                                item.schedule_status === 'Selesai' ? 'blue--text' :
                                item.schedule_status === 'Dibatalkan' ? 'red--text' : 'orange--text'">
                              {{ item.schedule_status === 'Sedang Berlangsung' ? 'Berlangsung' : item.schedule_status }}
                            </span>
                            <v-icon small 
                              :color="item.schedule_status === 'Sedang Berlangsung' ? 'green' :
                                item.schedule_status === 'Selesai' ? 'blue' :
                                item.schedule_status === 'Dibatalkan' ? 'red' : 'orange'"
                              v-if="item.schedule_status !== 'Belum Mulai'">
                              mdi-arrow-right
                            </v-icon>
                          </div>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row justify="center" align="center" v-if="!process.run && pagination.total_page < 1">
              <v-col cols="12" class="mt-16">
                <v-img 
                  class="mx-auto mt-16"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  <span v-if="filter.empty">
                    Kelas yang kamu cari tidak ada!
                  </span>
                  <span v-else>
                    Kelas mu masih kosong!
                  </span>
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk beli kelas belajar di E-Learning Bidan Kita.
                </p>

                <div class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    to="/elearning">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Cari Kelas Belajar
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <Pagination v-if="pagination.total_page > 1"
              @changePage="fetch($event)" 
              @reloadPage="fetch($event)"
              :model="pagination" />
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        menu: [
          {
            label: 'Semua',
            value: ''
          },
          {
            label: 'Belum Mulai',
            value: 'Belum Mulai'
          },
          {
            label: 'Berlangsung',
            value: 'Sedang Berlangsung'
          },
          {
            label: 'Selesai',
            value: 'Selesai'
          },
          {
            label: 'Dibatalkan',
            value: 'Dibatalkan'
          },
          {
            label: 'Reschedule',
            value: 'Reschedule'
          }
        ],
        filter: {
          dialog: false,
          empty: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: ''
        },
        type: [
          {
            label: 'Semua Tipe',
            value: ''
          }
        ],
        category: [
          {
            label: 'Semua Kategori',
            value: '',
            sub: []
          }
        ],
        selected: {
          menu: 0
        },
        list: [],
        pagination: {},
        process: {
          run: false
        },
        // SEO
        content: {
          url: 'collection',
          title: 'Kelas Bidan Kita',
          description: 'Kelas Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      'filter.type': function(val) {
        this.filter.label_category = 'Semua Kategori'
        this.filter.category = ''
        this.filter.category_sub = ''
        
        this.fetchCategory()
      },
      'selected.menu': function(val) {
        this.fetch()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetchType() {
        this.type = [{
          label: 'Semua Tipe',
          value: ''
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/type`)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data.results
            for (let i in data) {
              this.type.push({
                label: data[i].kelas_type_title,
                value: data[i].kelas_type_id
              })
            }
          }
        })
      },
      async fetchCategory() {
        let params = {
          type_id: this.filter.type,
          sort: 'kelas_kategori_title',
          dir: 'ASC'
        }
        
        this.category = [{
          label: 'Semua Kategori',
          value: '',
          sub: []
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/category_nested`, { params })
        .then((response) => {
          if (response.status === 200) {
            let data = response.data.results
            for (let i in data) {
              this.category.push({
                label: data[i].kelas_kategori_title,
                value: data[i].kelas_kategori_id,
                sub: data[i].kelas_kategori_child
              })
            }
          }
        })
      },
      async fetch(pagination = { row: 20, page: 1 }) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        this.process.run = true
        this.filter.empty = false

        let params = {
          limit: pagination.row,
          page: pagination.page,
          sort: 'kelas_create_datetime',
          dir: 'DESC',
          filter: [
            {
              type: 'string',
              field: 'kelas_type_id',
              value: this.filter.type
            },
            {
              type: 'string',
              field: 'kelas_kategori_id',
              value: this.filter.category
            },
            {
              type: 'string',
              field: 'kelas_kategori_child_id',
              value: this.filter.category_sub
            },
            {
              type: 'string',
              field: 'kelas_nama',
              value: this.filter.title,
              comparison: '<>'
            },
            {
              type: 'string',
              field: 'collection_kelas_schedule_status',
              value: this.menu[this.selected.menu].value
            }
          ]
        }

        this.list = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/class/live`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.push(v)
            })
            this.pagination = response.data.pagination

            this.filter.empty = this.filter.category !== '' || (this.filter.title !== '' && this.filter.title !== null) ? true : false
          }
        })
      },
      reset() {
        this.filter = {
          dialog: false,
          empty: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: ''
        }

        this.fetch()
      }
    }
  }
</script>