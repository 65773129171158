<template>
  <div class="fill-height">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="dialog.player"
      width="1175"
      persistent>
      <v-card flat style="border-radius: 10px;">
        <v-row class="ma-0">
          <v-col cols="12" md="7" :class="rps === 'xl' || rps === 'lg' && rps === 'md' ? 'pl-0 py-0' : 'pa-0'">
            <v-card 
              flat
              color="transparent">
              <Players 
                :player="player.media" 
                :play="player.data" 
                :height="rps === 'xl' || rps === 'lg' ? 700 : rps === 'md' ? 720 : rps === 'sm' ? 400 : player.media.type !== 'docs' ? 300 : 375" 
                :border="rps === 'xl' || rps === 'lg' || rps === 'md' ? `10px 0px 0px 10px` : '10px'" />
            </v-card>
          </v-col>
          
          <v-col cols="12" md="5" class="px-6">
            <v-card-title class="d-flex align-center px-0">
              <div class="title text-first">
                <span class="color-first">
                  Preview
                </span>
                Kelas
              </div>

              <v-spacer/>

              <div class="cursor-pointer" @click="dialog.player = false; player.media = { type: '', is_play: false }; player.data = {};">
                <v-icon color="#FF3177">mdi-close</v-icon>
              </div>
            </v-card-title>

            <v-card-text class="body-1 font-weight-bold line-text-first px-0">
              {{ detail.kelas_nama }}
            </v-card-text>

            <v-divider/>

            <v-list flat three-line dense color="transparent"
              style="height: 460px; overflow-y: auto; overflow-x: hidden;">
              <v-list-item-group>
                <v-list-item :class="detail.kelas_preview_jsonarray.length > 5 ? 'pl-0' : 'px-0'" 
                  v-for="(item, index) in detail.kelas_preview_jsonarray" 
                  :key="index"
                  @click="playing(item)">
                  <v-list-item-content>
                    <div class="d-flex align-start">
                      <div>
                        <v-img
                          width="65"
                          height="65"
                          class="d-flex align-center justify-center"
                          gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                          :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                          style="border-radius: 5px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div class="body-2 ml-6">
                        <p class="font-weight-bold text-capitalize mb-1">
                          <span class="one-line">{{ item.title }}</span>
                        </p>

                        <p class="font-weight-light line-text-first text-second mb-0">
                          <span class="two-line" v-html="item.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                        </p>
                      </div>
                    </div>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="text-center">
                      <v-icon v-if="item.title !== player.data.title || !player.media.is_play"
                        color="#FF3177">
                        {{ 
                          item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                          item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                          item.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                          item.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                        }}
                      </v-icon>

                      <v-icon v-else
                        color="#333333">
                        mdi-stop-circle-outline
                      </v-icon>

                      <p class="caption text-capitalize color-first mb-0"
                        :class="item.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                        <span v-if="item.content_jsonobject.type !== 'docs'">
                          {{ item.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                        </span>
                        <span v-else>
                          {{ item.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                        </span>
                      </p>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-divider/>

            <v-card-text class="body-2 font-weoght-bold px-0">
              Beli kelas ini, untuk akses semua materi.
            </v-card-text>

            <v-card-actions class="pa-0">
              <v-btn
                :width="rps === 'xl' || rps === 'lg' ? 210 : rps === 'md' ? 175 : rps === 'sm' ? 225 : 120"
                elevation="0"
                color="#FF3177"
                class="text-capitalize white--text"
                :disabled="process.cart || process.buynow"
                :loading="process.cart"
                @click="addToCart('cart')">
                <v-icon small class="mr-1">mdi-cart-arrow-down</v-icon>
                Keranjang
              </v-btn>

              <v-spacer/>

              <v-btn
                :width="rps === 'xl' || rps === 'lg' ? 210 : rps === 'md' ? 175 : rps === 'sm' ? 225 : 120"
                outlined
                elevation="0"
                color="#FF3177"
                class="text-capitalize color-first"
                :disabled="process.buynow || process.cart"
                :loading="process.buynow"
                @click="addToCart('buynow')">
                <v-icon small class="mr-1">mdi-credit-card-outline</v-icon>
                Beli
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.share"
      width="375"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="body-1 font-weight-bold text-second">
              Share {{ detail.kelas_nama }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third">
              Klik icon di bawah untuk share event ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in list.sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                :network="item.network"
                :url="`${host}${$route.path}`"
                :title="detail.kelas_slug"
                :description="detail.kelas_nama"
                hashtags="e-learning,bidankita">
                <v-img
                  width="45"
                  height="45"
                  contain
                  alt="e-learning"
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#333333"
            elevation="3"
            class="body-2 font-weight-bold white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex v-if="process.run && Object.keys(detail).length < 1">
        <section class="my-16">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="image">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="8">
                <v-skeleton-loader
                  type="article, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="4">
                <v-skeleton-loader
                  type="image, article, table-heading, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
      
      <v-flex v-if="!process.run &&  Object.keys(detail).length > 0">
        <section class="mt-16">
          <div v-if="rps === 'sm' || rps === 'xs'">
            <v-card-text>
              <div class="d-flex align-center">
                <div class="d-flex align-center">
                  <v-rating
                    :value="parseFloat(detail.kelas_total_rating)"
                    background-color="yellow darken-2"
                    color="yellow darken-2"
                    dense
                    disabled
                    size="18">
                  </v-rating>
                  <span class="text-second caption font-weight-bold ml-1">
                    ({{ parseFloat(detail.kelas_total_rating).toFixed(1) }})
                  </span>
                </div>

                <v-spacer/>

                <div class="text-second">
                  <span class="font-weight-bold">
                    {{ detail.kelas_total_materi }}
                  </span>
                  <span class="body-2">
                    Materi
                  </span>
                </div>
              </div>
            </v-card-text>
          </div>

          <v-img
            width="100%"
            height="50vh"
            :src="detail.kelas_image_jsonobejct.kelas_image_banner_url">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#FF3177">
                </v-progress-circular>
              </v-row>
            </template>

            <div style="background: rgb(255 49 119 / 60%); width: 100%; height: 100%;">
              <v-container style="width: 100%; height: 100%;">
                <v-row align="start" justify="start" style="width: 100%; height: 100%;">
                  <v-col cols="12" md="7" style="width: 100%; height: 100%;">
                    <v-card flat color="transparent" style="width: 100%; height: 100%;">
                      <div class="body-1  white--text">
                        {{ detail.kelas_type_title }}
                        <v-icon color="white">mdi-chevron-right</v-icon>
                        {{ detail.kelas_kategori_title }}
                        <span v-if="detail.kelas_kategori_child_title !== ''">
                          <v-icon color="white">mdi-chevron-right</v-icon>
                          {{ detail.kelas_kategori_child_title }}
                        </span>
                      </div>
                      
                      <v-card-text :class="rps === 'sm' || rps === 'xs' ? 'title' : 'text-h4'"
                        class="line-text-first text-capitalize font-weight-bold white--text px-0 mt-12"
                        style="min-height: 250px;">
                        <span class="four-line">
                          {{ detail.kelas_nama }}
                        </span>
                      </v-card-text>

                      <v-card-text class="px-0" style="position: absolute; bottom: 0px;">
                        <v-btn
                          elevation="0"
                          color="white"
                          class="body-1 text-capitalize color-first mr-3"
                          @click="dialog.share = true">
                          <v-icon small class="mr-1">mdi-share-variant</v-icon>
                          Bagikan
                        </v-btn>

                        <v-btn
                          elevation="0"
                          color="white"
                          class="body-1 text-capitalize color-first"
                          :disabled="process.wishlist"
                          :loading="process.wishlist"
                          @click="addWishlist">
                          <v-icon small class="mr-1">
                            {{ detail.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                          </v-icon>
                          Wishlist
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-img>
        </section>

        <section>
          <div v-if="rps === 'sm' || rps === 'xs'">
            <v-card-actions class="body-1 pa-4" v-if="!detail.kelas_is_discount">
              <div class="green--text font-weight-bold" v-if="!detail.kelas_berbayar">
                Gratis
              </div>

              <div class="color-first" v-else>
                <span class="font-weight-bold">
                  {{ parseInt(detail.kelas_harga_jsonobject.value_after_discount) | price }}
                </span> 
                <span class="body-2 text-second">
                  /
                  <span v-if="!detail.kelas_harga_jsonobject.is_lifetime">
                    {{ detail.kelas_harga_jsonobject.subcribe_value }} 
                    {{ detail.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : detail.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                  </span>

                  <span v-else>
                    Lifetime
                  </span>
                </span>
              </div>
            </v-card-actions>

            <v-card-actions class="body-1 pa-4" v-if="detail.kelas_is_discount">
              <div class="body-1 text-second" v-if="detail.kelas_is_discount">
                <span class="text-decoration-line-through">
                  {{ parseInt(detail.kelas_harga_jsonobject.value) | price }}
                </span>
              </div>

              <v-spacer/>

              <div class="color-first">
                <span class="font-weight-bold">
                  {{ parseInt(detail.kelas_harga_jsonobject.value_after_discount) | price }}
                </span> 
                <span class="body-2 text-second">
                  /
                  <span v-if="!detail.kelas_harga_jsonobject.is_lifetime">
                    {{ detail.kelas_harga_jsonobject.subcribe_value }} 
                    {{ detail.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : detail.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                  </span>

                  <span v-else>
                    Lifetime
                  </span>
                </span>
              </div>
            </v-card-actions>

            <div v-if="detail.kelas_is_purchased === '0'">
              <v-card-text class="pt-0">
                <v-btn
                  block
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize white--text"
                  :disabled="process.cart || process.buynow"
                  :loading="process.cart"
                  @click="addToCart('cart')">
                  Tambah Ke Keranjang
                </v-btn>
              </v-card-text>

              <v-card-text class="py-0">
                <v-btn
                  block
                  outlined
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize color-first"
                  :disabled="process.buynow || process.cart"
                  :loading="process.buynow"
                  @click="addToCart('buynow')">
                  Beli Sekarang
                </v-btn>
              </v-card-text>
            </div>

            <div v-if="detail.kelas_is_purchased === '1'">
              <v-card-text class="py-0">
                <v-btn
                  block
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize white--text"
                  :to="`/collection/elearning/${detail.kelas_id}`">
                  <div>
                    Selengkapnya
                  </div>

                  <v-spacer/>

                  <div>
                    <v-icon small color="white">mdi-arrow-right</v-icon>
                  </div>
                </v-btn>
              </v-card-text>
            </div>
          </div>

          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12" class="px-0">
                    <v-card flat>
                      <v-card-title class="text-capitalize">
                        {{ detail.kelas_nama }}
                      </v-card-title>

                      <v-card-text class="body-1 font-weight-light line-text-second">
                        <span v-html="detail.kelas_deskripsi"></span>
                      </v-card-text>

                      <v-card-text>
                        <v-icon>mdi-bookshelf</v-icon>
                        <span class="text-second body-1 font-weight-bold mr-1">
                          {{ detail.kelas_total_materi }}
                        </span> 
                        <span class="text-second body-2 font-weight-light">
                          Materi
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <div v-if="rps === 'sm' || rps === 'xs'">
                  <p class="title mt-6 px-4">
                    Instruktur
                  </p>

                  <v-card
                    class="box-shadow border-radius">
                    <div class="pa-2">
                      <v-card-text class="d-flex align start">
                        <div>
                          <v-avatar size="50">
                            <v-img
                              :src="detail.kelas_mentor_jsonarray[0].kelas_mentor_image_url"
                              alt="mentor elearning bidan kita">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </div>

                        <v-spacer/>

                        <div>
                          <div class="d-flex align-center mb-2">
                            <div>
                              <v-rating
                                :value="5"
                                background-color="yellow darken-2"
                                color="yellow darken-2"
                                dense
                                disabled
                                size="18">
                              </v-rating>
                            </div>
                            <div class="text-second caption font-weight-bold ml-1">
                              (5)
                            </div>
                          </div>
                        </div>
                      </v-card-text>

                      <v-card-text>
                        <p class="body-1 font-weight-bold text-capitalize mb-2">
                          {{ detail.kelas_mentor_jsonarray[0].kelas_mentor_nama }}
                        </p>

                        <p class="body-2 font-weight-light line-text-second mb-0">
                          N.A
                        </p>
                      </v-card-text>

                      <!-- <v-card-text>
                        <v-btn
                          rounded
                          outlined
                          elevation="0"
                          color="grey"
                          class="text-capitalize">
                          <div>
                            Selengkapnya
                          </div>

                          <v-spacer/>

                          <div>
                            <v-icon small color="grey" class="ml-2">mdi-arrow-right</v-icon>
                          </div>
                        </v-btn>
                      </v-card-text> -->
                    </div>
                  </v-card>

                  <p class="body-1 mt-6 mb-0 px-4" v-if="detail.kelas_mentor_jsonarray.length > 1">
                    Instruktur Lainnya
                  </p>

                  <v-list two-line>
                    <v-list-item
                      class="px-0"
                      v-for="(item, index) in detail.kelas_mentor_jsonarray" 
                      :key="index"
                      v-show="index > 0">
                      <v-list-item-avatar size="50">
                        <v-img
                          :src="item.kelas_mentor_image_url"
                          alt="mentor elearning bidan kita">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold text-capitalize mb-2">
                          {{ item.kelas_mentor_nama }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-2 font-weight-light line-text-second">
                          N.A
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <v-row>
                  <v-col cols="12" class="px-0">
                    <v-expansion-panels flat v-model="selected.panel">
                      <v-expansion-panel
                        v-for="(item, index) in detail.kelas_materi_jsonarray" 
                        :key="index">
                        <v-expansion-panel-header style="background: #f0f0f0;">
                          <v-list dense class="py-0" color="transparent">
                            <v-list-item class="py-0 pl-0">
                              <v-list-item-content :style="rps === 'xs' ? 'width: 100px;' : 'width: 400px;'">
                                <span class="body-1 font-weight-bold text-capitalize"
                                  :class="selected.panel !== index ? 'one-line' : ''">
                                  {{ item.title }}
                                </span>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <template v-slot:actions>
                            <div style="background: #ffffff; border-radius: 5px; padding: 5px 15px;">
                              <span class="body-2 font-weight-bold">
                                {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                              </span>
                              <span class="caption text-second">
                                Materi
                              </span>
                              <v-icon color="#7B7B7B" small>
                                $expand
                              </v-icon>
                            </div>
                          </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="pa-0">
                          <v-list flat three-line dense class="py-0" color="transparent">
                            <v-list-item-group>
                              <v-list-item 
                                :class="{
                                  'my-6': item.total_sub_materi > 0,
                                  'mt-6': item.total_sub_materi < 1,
                                  'px-0': rps === 'xl' || rps === 'lg'
                                }"
                                @click="item.content_jsonobject.type !== '' && item.preview ? playing(item) : ''">
                                <v-list-item-content>
                                  <div class="d-flex align-center">
                                    <div v-if="item.content_jsonobject.type !== ''"
                                      class="text-center">
                                      <v-icon 
                                        large
                                        :color="item.preview ? '#FF3177' : '#cacaca'">
                                        {{ 
                                          item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                          item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                          item.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                          item.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                        }}
                                      </v-icon>

                                      <p class="caption text-capitalize mb-0"
                                        :class="item.preview ? 'color-first' : 'preview-lock'">
                                        <span v-if="item.content_jsonobject.type !== 'docs'">
                                          {{ item.preview ? 'view' : 'play' }}
                                        </span>
                                        <span v-else>
                                          {{ item.preview ? 'view' : 'baca' }}
                                        </span>
                                      </p>
                                    </div>

                                    <div v-if="item.content_jsonobject.type !== ''"
                                      class="mx-3"
                                      style="border-left: 7px solid #f0f0f0; border-radius: 5px; height: 75px;">
                                    </div>
                                    
                                    <div class="mr-6">
                                      <v-img
                                        width="75"
                                        height="75"
                                        class="d-flex align-center justify-center"
                                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                        :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>

                                        <div class="d-flex align-center justify-center" v-if="!item.preview && item.content_jsonobject.type !== ''">
                                          <v-icon style="font-size: 25px;" color="#FF3177">mdi-lock</v-icon>
                                        </div>
                                      </v-img>
                                    </div>

                                    <div class="body-1 font-weight-light text-second"
                                      style="line-height: 1.75;">
                                      <span
                                        class="three-line"
                                        v-html="item.deskripsi === '' ? `Materi ${item.title}` : item.deskripsi.replace(/<\/?[^>]+>/ig, '')">
                                      </span>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>

                              <div v-if="item.total_sub_materi > 0">
                                <v-list-item :class="rps === 'xl' || rps === 'lg' ? 'px-0' : ''"
                                  v-for="(sub, key) in item.sub_materi" :key="key"
                                  @click="sub.content_jsonobject.typr !== '' && sub.preview ? playing(sub) : ''">
                                  <v-list-item-content>
                                    <div class="d-flex align-center">
                                      <div v-if="sub.content_jsonobject.type !== ''"
                                        class="text-center">
                                        <v-icon 
                                          large
                                          :color="sub.preview ? '#FF3177' : '#cacaca'">
                                          {{ 
                                            sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                            sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                            sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                            sub.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                          }}
                                        </v-icon>
                                        
                                        <p class="caption text-capitalize mb-0"
                                          :class="sub.preview ? 'color-first' : 'preview-lock'">
                                          <span v-if="sub.content_jsonobject.type !== 'docs'">
                                            {{ sub.preview ? 'view' : 'play' }}
                                          </span>
                                          
                                          <span v-else>
                                            {{ sub.preview ? 'view' : 'baca' }}
                                          </span>
                                        </p>
                                      </div>
                                      
                                      <div v-if="sub.content_jsonobject.type !== ''"
                                        class="mx-3" 
                                        style="border-left: 7px solid #f0f0f0; border-radius: 5px; height: 50px;">
                                      </div>

                                      <div class="mr-6">
                                        <v-img
                                          width="50"
                                          height="50"
                                          class="d-flex align-center justify-center"
                                          gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                          :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                          style="border-radius: 5px;">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#FF3177">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>

                                          <div class="d-flex align-center justify-center" v-if="!sub.preview && sub.content_jsonobject.type !== ''">
                                            <v-icon style="font-size: 25px" color="#FF3177">mdi-lock</v-icon>
                                          </div>
                                        </v-img>
                                      </div>
                                      
                                      <div>
                                        <p class="body-1 font-weight-bold text-capitalize mb-1"
                                          :class="rps !== 'xs' ? 'body-1' : 'body-1'">
                                          <span class="one-line">
                                            {{ sub.title }}
                                          </span>
                                        </p>

                                        <p class="body-1 font-weight-light line-text-first text-second mb-0">
                                          <span 
                                            class="one-line" 
                                            v-html="sub.deskripsi === '' ? sub.title : sub.deskripsi.replace(/<\/?[^>]+>/ig, '')">
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                
                <v-row align="center" v-if="!process.review && list.review.length > 0">
                  <v-col cols="6">
                    <p class="font-weight-bold mb-0"
                      :class="rps === 'sm' || rps === 'xs' ? 'body-1' : 'title'">
                      <span class="color-first mr-1">
                        Review
                      </span>
                      & Ulasan
                    </p>
                  </v-col>

                  <v-col cols="6" class="body-1 text-right">
                    <span class="text-first font-weight-bold">
                      {{ pagination.review.total_data }}
                    </span>
                    <span class="body-2 font-weight-light text-first">
                      Ulasan
                    </span>
                  </v-col>
                </v-row>

                <v-divider v-if="!process.review && list.review.length > 0"/>

                <v-row v-if="!process.review && list.review.length > 0">
                  <v-col cols="12">
                    <v-card-text>
                      <v-row class="align-center">
                        <v-col cols="12" md="2" class="text-center">
                          <div class="text-h2 font-weight-bold mb-2">
                            {{ parseFloat(total_rating).toFixed(1) }}
                          </div>

                          <v-rating dense
                            size="15"
                            :value="total_rating"
                            readonly
                            color="#FF3177"
                            background-color="#FF3177"
                            half-increments>
                          </v-rating>

                          <div class="body-2 font-weight-light text-second mt-1">
                            Rating Kelas
                          </div>
                        </v-col>

                        <v-col cols="12" md="10">
                          <div 
                            v-for="(item, index) in data_rating"
                            :key="index">
                            <div class="d-flex align-center">
                              <div :class="rps === 'xl' || rps === 'lg' ? 'rating-progress' : rps === 'md' ? 'rating-progress-md' : 'rating-progress-sm-xs'">
                                <v-progress-linear
                                  color="#FF3177"
                                  background-color="#ffe6ee"
                                  height="10"
                                  rounded
                                  :value="item.percent">
                                </v-progress-linear>
                              </div>

                              <div class="mr-auto mb-1 pl-8">
                                <v-icon 
                                  :small="rps === 'lg' || rps === 'md'"
                                  :size="rps === 'sm' || rps === 'xs' ? 12 : 0"
                                  color="#FF3177" 
                                  v-for="(s, i) in 5 - index" 
                                  :key="i">
                                  mdi-star
                                </v-icon>
                              </div>
                              
                              <div 
                                class="body-1 font-weight-light ml-auto grey--text text--darken-1">
                                <span>
                                  {{ parseInt(item.percent) }}%
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-row v-if="!process.review && list.review.length > 0">
                  <v-col cols="12" class="d-flex align-center">
                    <div class="mr-2" v-show="rps !== 'xs'"
                      v-for="(item, index) in rating" :key="index">
                      <v-btn 
                        outlined
                        rounded
                        :color="selected.rating === item.value ? '#FF3177' : 'grey'"
                        class="body-1 text-capitalize"
                        @click="selected.rating = item.value; fetchReview();">
                        <v-icon small class="mr-1" :color="selected.rating === item.value ? '#FF3177' : 'grey'">
                          {{ selected.rating === item.value ? 'mdi-star-check' : 'mdi-star-outline' }}
                        </v-icon>
                        {{ item.label }}
                      </v-btn>
                    </div>

                    <v-select v-show="rps === 'xs'"
                      dense
                      outlined
                      color="#FF3177"
                      hide-details
                      single-line
                      :items="rating"
                      item-text="label"
                      item-value="value"
                      item-color="#FF3177"
                      v-model="selected.rating"
                      v-on:change="fetch()">
                    </v-select>
                  </v-col>
                </v-row>

                <p class="body-1 font-weight-bold mt-6" v-if="!process.review && list.review.length > 0">
                  Semua Ulasan ({{ pagination.review.total_data }})
                </p>

                <v-skeleton-loader
                  v-for="(item, index) in process.review ? 5 : list.review" :key="index"
                  class="ma-auto"
                  :loading="process.review"
                  type="list-item-avatar-three-line">
                  <div>
                    <v-row v-if="!process.review && list.review.length > 0">
                      <v-col cols="12">
                        <v-list dense three-line>
                          <v-list-item class="px-0">
                            <v-list-item-avatar size="50">
                              <v-img
                                :src="item.rating_user_image_url !=='' ? item.rating_user_image_url : require('@/assets/image/member-default.jpg')">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="body-1 font-weight-bold mb-2 d-flex align-center">
                                <div>
                                  {{ item.rating_user_name }}
                                </div>

                                <v-spacer/>

                                <div class="d-flex align-center">
                                  <v-rating dense
                                    :value="parseInt(item.rating_value)"
                                    small
                                    readonly
                                    color="yellow darken-2"
                                    background-color="yellow darken-2">
                                  </v-rating>
                                  <span class="text-second caption font-weight-bold ml-1">
                                    ({{ parseFloat(item.rating_value).toFixed(1) }})
                                  </span>
                                </div>
                              </v-list-item-title>
                              <v-list-item-subtitle class="caption font-weight-light">
                                {{ item.rating_update_datetime | ago }}
                              </v-list-item-subtitle>

                              <p class="body-2 font-weight-light line-text-second mt-1 mb-0">
                                {{ item.rating_content_jsonobject.value }}
                              </p>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </div>
                </v-skeleton-loader>

                <v-card-text class="text-center" v-if="pagination.review.total_data > 8 && limit < pagination.review.total_data">
                  <v-btn
                    small
                    elevation="0"
                    color="#f1f1f1"
                    class="caption text-second text-capitalize"
                    @click="limit += 8">
                    Lihat lebih banyak (+{{ pagination.review.total_data - limit}})
                    <v-icon small color="#7B7B7B" class="ml-1">mdi-chevron-down</v-icon>
                  </v-btn>
                </v-card-text>

                <!-- <v-row class="py-12" v-if="!process.review && pagination.review.total_page < 1">
                  <v-col>
                    <v-img 
                      class="mx-auto"
                      contain
                      width="175"
                      height="175"
                      :src="require('@/assets/image/empty.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <p class="text-h5 text-second font-weight-bold text-center">
                      Ulasan masih kosong!
                    </p>
                    <p class="body-1 font-weight-light mb-6 text-center">
                      Yuk beri ulasan mu untuk kelas ini.
                    </p>
                  </v-col>
                </v-row> -->
              </v-col>

              <v-col cols="12" md="4" class="pl-12" v-if="rps === 'xl' || rps === 'lg' || rps === 'md'">
                <div :class="scroll ? 'on-scroll-sticky' : 'on-scroll-ralative'" v-scroll="onScroll">
                  <v-card
                    class="box-shadow border-radius">
                    <v-img
                      width="100%"
                      height="250"
                      :src="detail.kelas_image_jsonobejct.kelas_image_cover_url">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>

                      <v-chip v-if="detail.kelas_is_special_price"
                        small
                        class="font-weight-bold white--text px-2 ma-2"
                        color="primary"
                        style="position: absolute; top: 0; right: 0px;">
                        Special Price
                      </v-chip>
                    </v-img>

                    <div class="burst" v-if="detail.kelas_is_discount">
                      <span>
                        OFF {{ detail.kelas_diskon_jsonobject.percent }}%
                      </span>
                    </div>

                    <div class="burst_free" v-if="!detail.kelas_berbayar">
                      <span>
                        FREE
                      </span>
                    </div>

                    <div class="pa-2">
                      <v-card-text>
                        <p class="title font-weight-bold line-text-first text-first text-capitalize">
                          {{ detail.kelas_nama }}
                        </p>
                        <p class="body-1 text-second mb-0">
                          {{ detail.kelas_kategori_title }}
                        </p>
                      </v-card-text>

                      <div v-if="detail.kelas_is_purchased === '0'">
                        <v-card-text class="pb-0">
                          <v-btn
                            block
                            elevation="0"
                            color="#FF3177"
                            class="text-capitalize white--text"
                            :disabled="process.cart || process.buynow"
                            :loading="process.cart"
                            @click="addToCart('cart')">
                            Tambah Ke Keranjang
                          </v-btn>
                        </v-card-text>

                        <v-card-text>
                          <v-btn
                            outlined
                            block
                            elevation="0"
                            color="#FF3177"
                            class="text-capitalize color-first"
                            :disabled="process.buynow || process.cart"
                            :loading="process.buynow"
                            @click="addToCart('buynow')">
                            Beli Sekarang
                          </v-btn>
                        </v-card-text>
                      </div>

                      <div v-if="detail.kelas_is_purchased === '1'">
                        <v-card-text>
                          <v-btn
                            block
                            elevation="0"
                            color="#FF3177"
                            class="text-capitalize white--text"
                            :to="`/collection/elearning/${detail.kelas_id}`">
                            <div>
                              Selengkapnya
                            </div>

                            <v-spacer/>

                            <div>
                              <v-icon small color="white">mdi-arrow-right</v-icon>
                            </div>
                          </v-btn>
                        </v-card-text>
                      </div>

                      <v-card-text>
                        <div class="d-flex align-center">
                          <div class="d-flex align-center">
                            <v-rating
                              :value="parseFloat(detail.kelas_total_rating)"
                              background-color="yellow darken-2"
                              color="yellow darken-2"
                              dense
                              disabled
                              size="18">
                            </v-rating>
                            <span class="text-second caption font-weight-bold ml-1">
                              ({{ parseFloat(detail.kelas_total_rating).toFixed(1) }})
                            </span>
                          </div>

                          <v-spacer/>

                          <div class="text-second">
                            <span class="font-weight-bold">
                              {{ detail.kelas_total_materi }}
                            </span>
                            <span class="body-2">
                              Materi
                            </span>
                          </div>
                        </div>
                      </v-card-text>

                      <v-divider class="mb-2 mx-4"/>

                      <v-card-actions class="body-1 px-4" v-if="!detail.kelas_is_discount">
                        <div class="green--text font-weight-bold" v-if="!detail.kelas_berbayar">
                          Gratis
                        </div>

                        <div class="color-first" v-else>
                          <span class="font-weight-bold">
                            {{ parseInt(detail.kelas_harga_jsonobject.value_after_discount) | price }}
                          </span> 
                          <span class="body-2 text-second">
                            /
                            <span v-if="!detail.kelas_harga_jsonobject.is_lifetime">
                              {{ detail.kelas_harga_jsonobject.subcribe_value }} 
                              {{ detail.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : detail.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                            </span>

                            <span v-else>
                              Lifetime
                            </span>
                          </span>
                        </div>
                      </v-card-actions>

                      <v-card-actions class="body-1 px-4" v-if="detail.kelas_is_discount">
                        <div class="body-1 text-second" v-if="detail.kelas_is_discount">
                          <span class="text-decoration-line-through">
                            {{ parseInt(detail.kelas_harga_jsonobject.value) | price }}
                          </span>
                        </div>

                        <v-spacer/>

                        <div class="color-first">
                          <span class="font-weight-bold">
                            {{ parseInt(detail.kelas_harga_jsonobject.value_after_discount) | price }}
                          </span> 
                          <span class="body-2 text-second">
                            /
                            <span v-if="!detail.kelas_harga_jsonobject.is_lifetime">
                              {{ detail.kelas_harga_jsonobject.subcribe_value }} 
                              {{ detail.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : detail.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                            </span>

                            <span v-else>
                              Lifetime
                            </span>
                          </span>
                        </div>
                      </v-card-actions>
                    </div>
                  </v-card>

                  <p class="title mt-6">
                    Instruktur
                  </p>

                  <v-card
                    class="box-shadow border-radius">
                    <div class="pa-2">
                      <v-card-text class="d-flex align start">
                        <div>
                          <v-avatar size="50">
                            <v-img
                              :src="detail.kelas_mentor_jsonarray[0].kelas_mentor_image_url"
                              alt="mentor elearning bidan kita">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </div>

                        <v-spacer/>

                        <div>
                          <div class="d-flex align-center mb-2">
                            <div>
                              <v-rating
                                :value="5"
                                background-color="yellow darken-2"
                                color="yellow darken-2"
                                dense
                                disabled
                                size="18">
                              </v-rating>
                            </div>
                            <div class="text-second caption font-weight-bold ml-1">
                              (5)
                            </div>
                          </div>
                        </div>
                      </v-card-text>

                      <v-card-text>
                        <p class="body-1 font-weight-bold text-capitalize mb-2">
                          {{ detail.kelas_mentor_jsonarray[0].kelas_mentor_nama }}
                        </p>

                        <p class="body-2 font-weight-light line-text-second mb-0">
                          N.A
                        </p>
                      </v-card-text>

                      <!-- <v-card-text>
                        <v-btn
                          rounded
                          outlined
                          elevation="0"
                          color="grey"
                          class="text-capitalize">
                          <div>
                            Selengkapnya
                          </div>

                          <v-spacer/>

                          <div>
                            <v-icon small color="grey" class="ml-2">mdi-arrow-right</v-icon>
                          </div>
                        </v-btn>
                      </v-card-text> -->
                    </div>
                  </v-card>

                  <p class="body-1 mt-6 mb-0" v-if="detail.kelas_mentor_jsonarray.length > 1">
                    Instruktur Lainnya
                  </p>

                  <v-list two-line>
                    <v-list-item
                      class="px-0"
                      v-for="(item, index) in detail.kelas_mentor_jsonarray" 
                      :key="index"
                      v-show="index > 0">
                      <v-list-item-avatar size="50">
                        <v-img
                          :src="item.kelas_mentor_image_url"
                          alt="mentor elearning bidan kita">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold text-capitalize mb-2">
                          {{ item.kelas_mentor_nama }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-2 font-weight-light line-text-second">
                          N.A
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section :class="!process.other && list.other.length > 0 ? 'mb-16' : ''">
          <v-container>
            <v-row v-if="!process.other && list.other.length > 0">
              <v-col cols="12">
                <p class="title font-weight-bold mb-0">
                  <span class="color-first mr-1">
                    Kelas
                  </span>
                  Lainnya
                </p>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" md="3" sm="6"
                v-for="(item, index) in process.other ? 4 : list.other"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.other"
                  transition="scale-transition"
                  :tile="false"
                  type="card">
                  <div>
                    <v-card v-if="!process.other && list.other.length > 0"
                      class="border-radius box-shadow"
                      :to="item.kelas_is_purchased === '0' ? `/elearning/${item.kelas_slug}` : `/collection/elearning/${item.kelas_id}`">
                      <v-img
                        width="100%"
                        height="220"
                        :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-chip v-if="item.kelas_is_special_price"
                          small
                          class="font-weight-bold white--text px-2 ma-2"
                          color="primary"
                          style="position: absolute; top: 0; right: 0px;">
                          Special Price
                        </v-chip>
                      </v-img>

                      <div class="burst" v-if="item.kelas_is_discount">
                        <span>
                          OFF {{ item.kelas_diskon_jsonobject.percent }}%
                        </span>
                      </div>

                      <div class="burst_free" v-if="!item.kelas_berbayar">
                        <span>
                          FREE
                        </span>
                      </div>

                      <div class="pa-2">
                        <v-card-text>
                          <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                            <span class="two-line">
                              {{ item.kelas_nama }}
                            </span>
                          </p>
                          <p class="body-2 text-second mb-0">
                            <span class="one-line">
                              {{ item.kelas_kategori_title }}
                            </span>
                          </p>
                        </v-card-text>

                        <v-card-text>
                          <div class="d-flex align-center">
                            <div class="d-flex align-center">
                              <v-rating
                                :value="parseFloat(item.kelas_total_rating)"
                                background-color="yellow darken-2"
                                color="yellow darken-2"
                                dense
                                disabled
                                size="18">
                              </v-rating>
                              <span class="text-second caption font-weight-bold ml-1">
                                ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                              </span>
                            </div>

                            <v-spacer/>

                            <div class="text-second">
                              <span class="font-weight-bold">
                                {{ item.kelas_total_materi }}
                              </span>
                              <span class="body-2">
                                Materi
                              </span>
                            </div>
                          </div>
                        </v-card-text>

                        <v-divider class="mb-2 mx-4"/>

                        <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '1'">
                          <div class="color-first">
                            Selengkapnya
                          </div>

                          <v-spacer/>

                          <div class="color-first">
                            <v-icon small color="#FF3177">mdi-arrow-right</v-icon>
                          </div>
                        </v-card-actions>

                        <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && !item.kelas_is_discount">
                          <div class="green--text font-weight-bold" v-if="!item.kelas_berbayar">
                            Gratis
                          </div>

                          <div class="color-first" v-else>
                            <span class="font-weight-bold">
                              {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                            </span> 
                            <span class="caption text-second">
                              /
                              <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                              </span>

                              <span v-else>
                                Lifetime
                              </span>
                            </span>
                          </div>
                        </v-card-actions>

                        <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && item.kelas_is_discount">
                          <div class="body-2 text-second" v-if="item.kelas_is_discount">
                            <span class="text-decoration-line-through">
                              {{ parseInt(item.kelas_harga_jsonobject.value) | price }}
                            </span>
                          </div>

                          <v-spacer/>

                          <div class="color-first">
                            <span class="font-weight-bold">
                              {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                            </span> 
                            <span class="caption text-second">
                              /
                              <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                              </span>

                              <span v-else>
                                Lifetime
                              </span>
                            </span>
                          </div>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row class="mt-6" v-if="!process.other && pagination.other.total_page > 1">
              <v-col cols="12" class="text-right">
                <nuxt-link to="/elearning" class="color-first">
                  <span>
                    Lihat kelas lainnya
                  </span>
                  <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                </nuxt-link>
              </v-col>
            </v-row>

            <!-- <v-row justify="center" align="center" v-if="!process.other && pagination.other.total_page < 1">
              <v-col cols="12" class="d-flex align-center my-12">
                <v-img 
                  class="my-12"
                  contain
                  width="150"
                  height="150"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row> -->
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        scroll: false,
        dialog: {
          auth: '',
          message: '',
          player: false,
          share: false
        },
        selected: {
          panel: 0,
          rating: ''
        },
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        detail: {},
        rating: [
          {
            label: 'Semua',
            value: ''
          },
          {
            label: 5,
            value: 5
          },
          {
            label: 4,
            value: 4
          },
          {
            label: 3,
            value: 3
          },
          {
            label: 2,
            value: 2
          },
          {
            label: 1,
            value: 1
          }
        ],
        total_rating: 0,
        data_rating: [],
        list: {
          review: [],
          other: [],
          sosmed: [
            { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
            { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
            { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
            { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
          ]
        },
        limit: 8,
        pagination: {
          review: {},
          other: {}
        },
        process: {
          run: false,
          other: false,
          cart: false,
          buynow: false,
          review: false,
          share: false,
          wishlist: false
        },
        message: '',
        // SEO
        content: {
          url: 'elearning:slug',
          title: 'E-Learning Bidan Kita',
          description: 'E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message'),
      Players: () => import('@/components/Player')
    },
    watch: {
      'limit': function() {
        this.fetchReview(false)
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    beforeCreate () {
      let params = {
        slug: this.$route.params.slug
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/detail`, { params })
      .then((response) => {
        this.content = {
          url: `elearning/${response.data.kelas_slug}`,
          title: response.data.kelas_nama,
          description: response.data.kelas_deskripsi,
          image: response.data.kelas_image_jsonobejct.kelas_image_cover_url
        }
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      onScroll(e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.scroll = top > window.innerHeight - 500
      },
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          slug: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data

            this.fetchOther()
            this.fetchReview()
          }
        })
      },
      async fetchOther() {
        this.process.other = true
        
        let params = {
          limit: 4,
          slug_kelas: this.$route.params.slug,
          id_kategori: this.detail.kelas_kategori_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/recommend`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.other = false
            this.list.other = response.data.results
            this.pagination.other = response.data.pagination
          }
        })
      },
      async fetchReview(type) {
        this.process.review = type === undefined ? true : false
        
        let params = {
          limit: this.limit,
          id: this.detail.kelas_id,
          filter: [
            {
              type: 'string',
              field: 'rating_value',
              value: this.selected.rating
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/rating`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.review = false

            this.total_rating = parseFloat(response.data.total_rating)
            this.data_rating = response.data.data_rating

            this.list.review = response.data.results
            this.pagination.review = response.data.pagination
          }
        })
      },
      playing(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        let status = false
        if (item.title === this.player.data.title) {
          if(!this.player.media.is_play) {
            status = true
          } else {
            status = false
          }
        } else {
          status = true
        }

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: status
        }
        this.player.data = item

        this.dialog.player = true
      },
      async addToCart(type) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveCart(type)
        }
      },
      async saveCart(type) {
        this.process.cart = type === 'cart' ? true : false
        this.process.buynow = type === 'buynow' ? true : false

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: this.detail.kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false
            this.process.buynow = false

            this.$store.commit('header/setCart', this.headers.cart + 1)

            if (type === 'cart') {
              this.dialog.message = 'success'
              this.message = 'Berhasil ditambahkan ke Keranjang.'
            } else {
              this.$router.push('/cart')
            }
          } else {
            this.process.cart = false
            this.process.buynow = false
            
            this.dialog.message = 'error'
            this.message = response.message
          }
        })
      },
      async addWishlist() {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveWishlist()
        }
      },
      async saveWishlist() {
        this.process.wishlist = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/wishlist/add_remove`, {
          kelas_id: this.detail.kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.wishlist = false
            
            this.dialog.message = 'wishlist'
            this.message = this.detail.kelas_is_wishlist === '1' ? 'Berhasil ditambahkan ke Wishlist.' : 'Berhasil dihapus dari Wishlist.'

            this.fetch(true)
          }
        })
      },
      async addShare() {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveShare()
        }
      },
      async saveShare() {
        this.process.share = true

        
      }
    }
  }
</script>

<style scoped>
.rating-progress {
  max-width: 70%;
  min-width: 70%;
}
.rating-progress-md {
  max-width: 60%;
  min-width: 60%;
}
.rating-progress-sm-xs {
  max-width: 50%;
  min-width: 50%;
}
.footer-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}
.on-scroll-ralative {
  position: relative; top: -350px;
}
.on-scroll-sticky {
  position: sticky; top: 100px;
}
.preview-lock {
  color: #cacaca;
}
</style>