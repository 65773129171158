<template>
  <div class="fill-height">
    <v-dialog v-model="dialog.contact" 
      persistent
      scrollable
      width="800">
      <v-card class="border-radius box-shadow">
        <v-card-title class="title white--text background-first">
          Ubah Kontak
          <v-spacer />
          <v-btn
            color="#FF3177"
            small
            elevation="0"
            fab
            class="text-capitalize white--text"
            @click="reset(); dialog.contact = false;">
            <v-icon color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div :class="rps !== 'xs' ? 'pa-5 pb-0' : 'pt-5'">
            <ValidationObserver ref="observer">
              <div>
                <p class="body-2 text-second mb-2">
                  <span class="red--text">*</span>
                  Telepon
                </p>

                <ValidationProvider name="Telepon" rules="required|min:10|max:13" v-slot="{ errors }">
                  <v-text-field
                    :style="rps !== 'xs' ? 'width: 40%;' : ''"
                    dense
                    outlined
                    label="Ex. 081322051369"
                    single-line
                    color="#FF3177"
                    type="number"
                    :counter="13"
                    v-model="form.mobilephone"
                    :error-messages="errors"
                    require>
                  </v-text-field>
                </ValidationProvider>
                
                <p class="body-2 text-second mb-2">
                  Alamat (bisa dikosongi)
                </p>
                
                <div class="d-flex" :style="rps !== 'xs' ? 'width: 80%;' : ''">
                  <div class="v-input v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined"
                    style="border-radius: 3px 0px 0px 3px;">
                    <div class="v-input__control">
                      <div class="v-input__slot">
                        <div class="v-input__prepend-inner">
                          <div class="v-input__icon v-input__icon--prepend-inner">
                            <i aria-hidden="true" class="v-icon notranslate mdi mdi-map-marker theme--light"></i>
                          </div>
                        </div>
                        <fieldset aria-hidden="true">
                          <legend style="width: 0px;">
                            <span>​</span>
                          </legend>
                        </fieldset>
                        <div class="v-text-field__slot">
                          <label for="input-147" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">
                            <!-- Alamat / Lokasi -->
                          </label>
                          <gmap-autocomplete placeholder="Tuliskan alamat" 
                            :value="form.profile.address" 
                            :select-first-on-enter="true" 
                            @place_changed="setPlace">
                          </gmap-autocomplete>
                        </div>
                      </div>
                      <div class="v-text-field__details">
                        <div class="v-messages theme--light">
                          <div class="v-messages__wrapper">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <p class="body-2 text-second mb-2">
                      Provinsi (bisa dikosongi)
                    </p>

                    <v-select
                      dense
                      outlined
                      single-line
                      placeholder="ex: DI Yogyakarta"
                      color="#FF3177"
                      :items="config.province"
                      item-value="province_id"
                      item-text="province_name"
                      item-color="color-first"
                      v-model="form.profile.province_id">
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <p class="body-2 text-second mb-2">
                      Kabupaten (bisa dikosongi)
                    </p>

                    <v-select
                      dense
                      outlined
                      single-line
                      label="ex: Kab. Sleman"
                      color="#FF3177"
                      :items="config.city"
                      item-value="city_id"
                      item-text="city_name"
                      item-color="color-first"
                      :disabled="form.profile.province_id === ''"
                      v-model="form.profile.city_id">
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <p class="body-2 text-second mb-2">
                      Kecamatan (bisa dikosongi)
                    </p>

                    <v-select
                      dense
                      outlined
                      single-line
                      label="ex: Prambanan"
                      color="#FF3177"
                      :items="config.subdistrict"
                      item-value="subdistrict_id"
                      item-text="subdistrict_name"
                      item-color="color-first"
                      :disabled="form.profile.city_id === ''"
                      v-model="form.profile.subdistrict_id">
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </ValidationObserver>
          </div>

          <v-alert 
            type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>
        </v-card-text>

        <v-divider class="mx-4 my-2"/>

        <v-card-actions class="pa-5">
          <v-spacer/>
          <v-btn
            color="#FF3177"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save()">
            <v-icon small class="mr-1">mdi-check</v-icon>
            Simpan
          </v-btn>

          <v-btn
            outlined
            class="text-capitalize pink--text mx-1"
            :disabled="process.form"
            @click="reset(); dialog.contact = false;">
            <v-icon color="#FF3177" small class="mr-1">mdi-close-circle</v-icon>
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.general" 
      persistent
      scrollable
      width="1000">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-2 white--text background-first">
          Ubah General
          <v-spacer />
          <v-btn
            color="#FF3177"
            small
            elevation="0"
            fab
            class="text-capitalize white--text"
            @click="reset(); dialog.general = false;">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <ValidationObserver ref="observer">
            <div :class="rps !== 'xs' ? 'pa-1 pb-0' : 'pt-5'">
              <v-row>
                <v-col col="6">
                  <div class="mb-6">
                    <image-input v-model="avatar" style="width: 200px; height: 200px;">
                      <div slot="activator">
                        <v-avatar 
                          tile
                          size="200" color="white" style="border: 3px solid #fff; border-radius: 20px !important;"
                          class="cursor-pointer" 
                          v-ripple>
                          <v-progress-circular v-if="process.image"
                            indeterminate
                            color="#FF3177">
                          </v-progress-circular>

                          <v-img v-else
                            :src="form.image_url !== '' ? form.image_url : require('@/assets/image/member-default.jpg')" 
                            gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                            :alt="form.image_url">
                            <v-icon size="60" color="white">mdi-camera</v-icon>
                          </v-img>
                        </v-avatar>
                      </div>
                    </image-input>
                  </div>

                  <p class="body-2 text-second mb-2">
                    <span class="red--text">*</span>
                    Nama Lengkap
                  </p>
                  <ValidationProvider name="Nama" rules="required" v-slot="{ errors }">
                    <v-text-field
                      :style="rps !== 'xs' ? 'width: 75%;' : ''"
                      dense
                      outlined
                      label="Ex. Dohn Joe"
                      single-line
                      color="#FF3177"
                      v-model="form.name"
                      :error-messages="errors"
                      require>
                    </v-text-field>
                  </ValidationProvider>

                  <p class="body-2 text-second mb-2">
                    Tempat Lahir (bisa dikosongi)
                  </p>
                  <v-text-field
                    :style="rps !== 'xs' ? 'width: 55%;' : ''"
                    dense
                    outlined
                    single-line
                    label="Ex: Yogyakarta"
                    color="#FF3177"
                    v-model="form.profile.birth.place"
                    prepend-inner-icon="mdi-map-marker-radius-outline">
                  </v-text-field>

                  <p class="body-2 text-second mb-2">
                    Tanggal Lahir (bisa dikosongi)
                  </p>
                  <v-menu
                    v-model="picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :style="rps !== 'xs' ? 'width: 45%;' : ''"
                        v-model="form.profile.birth.date"
                        outlined
                        dense
                        color="#FF3177"
                        single-line
                        label="Ex. 1992-08-20"
                        readonly
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="form.profile.birth.date"
                      @input="picker = false"
                      color="#FF3177">
                    </v-date-picker>
                  </v-menu>

                  <p class="body-2 text-second mb-2">
                    Jenis Kelamin (bisa dikosongi)
                  </p>
                  <v-radio-group
                    class="mt-0"
                    v-model="form.profile.gender"
                    row>
                    <v-radio
                      label="Laki-laki"
                      color="#FF3177"
                      value="Laki-laki">
                    </v-radio>
                    <v-radio
                      label="Perempuan"
                      color="#FF3177"
                      value="Perempuan">
                    </v-radio>
                  </v-radio-group>

                  <p class="body-2 text-second mb-2">
                    Pekerjaan (bisa dikosongi)
                  </p>
                  <v-text-field
                    :style="rps !== 'xs' ? 'width: 60%;' : ''"
                    dense
                    outlined
                    single-line
                    label="Ex: Guru"
                    color="#FF3177"
                    v-model="form.profile.job"
                    prepend-inner-icon="mdi-bag-checked">
                  </v-text-field>

                  <p class="body-2 text-second mb-2">
                    Hobi (bisa dikosongi)
                  </p>
                  <v-text-field
                    :style="rps !== 'xs' ? 'width: 80%;' : ''"
                    dense
                    outlined
                    single-line
                    label="Ex: Bersepeda"
                    color="#FF3177"
                    v-model="form.profile.hobby"
                    prepend-inner-icon="mdi-alert-circle-outline">
                  </v-text-field>

                  <p class="body-2 text-second mb-2">
                    Status Perkawinan (bisa dikosongi)
                  </p>
                  <v-text-field
                    :style="rps !== 'xs' ? 'width: 45%;' : ''"
                    dense
                    outlined
                    single-line
                    label="Ex: Belum Kawin"
                    color="#FF3177"
                    v-model="form.profile.married"
                    prepend-inner-icon="mdi-human-male-female">
                  </v-text-field>
                </v-col>

                <v-col col="6">
                  <quill-editor 
                    ref="myTextEditor"
                    :options="option"
                    v-model="form.profile.desc">
                  </quill-editor>
                </v-col>
              </v-row>
            </div>
          </ValidationObserver>

          <v-alert 
            type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>
        </v-card-text>

        <v-divider class="mx-4 my-2"/>

        <v-card-actions class="pa-5">
          <v-spacer/>
          <v-btn
            color="#FF3177"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save()">
            <v-icon small class="mr-1">mdi-check</v-icon>
            Simpan
          </v-btn>

          <v-btn
            outlined
            class="text-capitalize pink--text mx-1"
            :disabled="process.form"
            @click="reset(); dialog.general = false;">
            <v-icon color="#FF3177" small class="mr-1">mdi-close-circle</v-icon>
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.sosmed" 
      persistent
      scrollable
      width="500">
      <v-card class="border-radius box-shadow">
        <v-card-title class="title white--text background-first">
          Ubah Sosial Media
          <v-spacer />
          <v-btn
            color="#FF3177"
            small
            elevation="0"
            fab
            class="text-capitalize white--text"
            @click="reset(); dialog.sosmed = false;">
            <v-icon color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div :class="rps !== 'xs' ? 'pa-5 pb-0' : 'pt-5'">
            <p class="body-2 text-second mb-2">
              Facebook (bisa dikosongi)
            </p>
            <v-text-field
              dense
              outlined
              single-line
              label="Ex: facebook"
              color="#FF3177"
              v-model="form.profile.sosmed.facebook"
              prepend-inner-icon="mdi-facebook">
            </v-text-field>

            <p class="body-2 text-second mb-2">
              Instagram (bisa dikosongi)
            </p>
            <v-text-field
              dense
              outlined
              single-line
              label="Ex: Instagram"
              color="#FF3177"
              v-model="form.profile.sosmed.instagram"
              prepend-inner-icon="mdi-instagram">
            </v-text-field>

            <p class="body-2 text-second mb-2">
              Twitter (bisa dikosongi)
            </p>
            <v-text-field
              dense
              outlined
              single-line
              label="Ex: Twitter"
              color="#FF3177"
              v-model="form.profile.sosmed.twitter"
              prepend-inner-icon="mdi-twitter">
            </v-text-field>

            <p class="body-2 text-second mb-2">
              Youtube (bisa dikosongi)
            </p>
            <v-text-field
              dense
              outlined
              single-line
              label="Ex: Youtube"
              color="#FF3177"
              v-model="form.profile.sosmed.youtube"
              prepend-inner-icon="mdi-youtube">
            </v-text-field>
          </div>

          <v-alert 
            type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>
        </v-card-text>

        <v-divider class="mx-4 my-2"/>

        <v-card-actions class="pa-5">
          <v-spacer/>
          <v-btn
            color="#FF3177"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save()">
            <v-icon small class="mr-1">mdi-check</v-icon>
            Simpan
          </v-btn>

          <v-btn
            outlined
            class="text-capitalize pink--text mx-1"
            :disabled="process.form"
            @click="reset(); dialog.sosmed = false;">
            <v-icon color="#FF3177" small class="mr-1">mdi-close-circle</v-icon>
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.success"
      width="350"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <v-col cols="12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.success = false; $emit('close');">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">BERHASIL</div>
                  <v-divider color="white"></v-divider>
                </div>
                <div class="d-flex justify-center">
                  <div class="sa">
                    <div class="sa-success">
                      <div class="sa-success-tip"></div>
                      <div class="sa-success-long"></div>
                      <div class="sa-success-placeholder"></div>
                      <div class="sa-success-fix"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center body-1 font-weight-light text-second line-text-first mb-8">
                  Anda berhasil mengubah password
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <section class="pt-16">
      <v-container>
        <v-row v-if="process.run">
          <v-col cols="12" md="4">
            <v-skeleton-loader
              class="ma-auto"
              width="100%"
              :loading="process.run"
              v-show="process.run"
              type="image, article">
            </v-skeleton-loader>
          </v-col>

          <v-col cols="12" md="8">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.run"
              v-for="i in 6"
              :key="i"
              v-show="process.run"
              type="article">
            </v-skeleton-loader>
          </v-col>
        </v-row>

        <v-row v-if="!process.run">
          <v-col cols="12" md="4">
            <v-card flat style="position: sticky; top: 95px;">
              <v-img
                contain
                width="100%"
                height="275"
                class="border-radius box-shadow"
                :src="detail.user_image_url === '' ? require('@/assets/image/member-default.jpg') : detail.user_image_url">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#FF3177">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-card-text v-if="rps === 'xl' || rps === 'lg' || rps === 'md'">
                <v-list dense>
                  <v-list-item class="px-0"
                    v-for="(item, index) in menu" :key="index"
                    @click="selected.menu = index">
                    <v-list-item-icon class="mr-2">
                      <v-icon style="font-size: 18px;"
                        :color="selected.menu === index ? '#FF3177' : '#7B7B7B'">
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <div class="body-1"
                        :class="selected.menu === index ? 'color-first font-weight-bold' : 'text-second'">
                        {{ item.label }}
                      </div>
                    </v-list-item-content>

                    <v-list-item-action class="my-0">
                      <v-btn icon>
                        <v-icon :color="selected.menu === index ? '#FF3177' : 'grey lighten-1'">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>

              <v-card-text v-if="rps === 'sm' || rps === 'xs'">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2">
                    <span class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-tab>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="8">
            <v-card flat v-if="selected.menu === 0">
              <v-card-title class="d-flex align-end text-h5 py-0">
                <div>
                  <span class="mr-1 text-capitalize">
                    {{ detail.user_name }}
                  </span>
                  <span class="body-2 text-second" v-if="detail.user_profile && detail.user_profile.city_name !== undefined && detail.user_profile.city_name.length > 0">
                    <v-icon small color="grey">mdi-map-marker</v-icon>
                    <span v-if="detail.user_profile.city_name.length > 0">                    
                      {{ detail.user_profile.city_name }}
                    </span>
                  </span>
                </div>

                <v-spacer/>

                <div class="body-1 font-weight-bold text-capitalize mt-1">
                  <div v-if="detail.user_is_verified === 'verified'" class="color-first">
                    <v-icon color="#FF3177">mdi-seal</v-icon>
                    Premium
                  </div>
                  <div v-if="detail.user_is_verified !== 'verified'" class="grey--text d-flex align-center">
                    <v-icon color="grey" class="mr-1">mdi-license</v-icon>
                    <div>
                      Reguler
                    </div>
                  </div>
                </div>
              </v-card-title>
              <v-card-subtitle class="body-2 font-weight-light text-capitalize text-first mt-1">
                Terdaftar pada {{ detail.user_create_datetime | date }}
              </v-card-subtitle>

              <v-card-text class="mt-3">
                <p class="body-2 text-second mb-1">
                  Status Membership
                </p>

                <p class="body-1 font-weight-bold green--text mb-2" v-if="detail.user_is_verified === 'verified'">
                  <v-icon color="green" style="font-size: 20px;">mdi-credit-card-check-outline</v-icon>
                  Pembayaran Selesai
                </p>

                 <p class="body-1 font-weight-bold orange--text mb-2" v-if="detail.user_is_verified !== 'verified'">
                  <v-icon color="orange" style="font-size: 20px;">mdi-credit-card-sync-outline</v-icon>
                  Menunggu Pembayaran
                </p>
              </v-card-text>

              <v-divider class="mx-4 mb-3"/>

              <v-card-text>
                <div class="d-flex align-center">
                  <div>
                    <p class="body-1 text-first">
                      Informasi
                      <span class="color-first">
                        Kontak
                      </span>
                    </p>
                  </div>
                  <div class="ml-auto">
                    <v-btn
                      icon
                      small
                      color="#FF3177"
                      @click="reset(); dialog.contact = true;">
                      <v-icon size="20">mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Email
                  </div>
                  <div class="mb-2">
                    {{ detail.user_email }}
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Telepon
                  </div>
                  
                  <div class="mb-2">
                    {{ detail.user_mobilephone === '' ? '-' : detail.user_mobilephone }}
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Alamat
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile && detail.user_profile.address !== undefined">
                      <p class="mb-1">
                        {{ detail.user_profile.address === '' ? '-' : detail.user_profile.address }}
                      </p>

                      <p class="mb-2" v-if="detail.user_profile.province_name.length > 0 || detail.user_profile.city_name.length > 0 || detail.user_profile.subdistrict_name.length > 0">
                        <span v-if="detail.user_profile.subdistrict_name.length > 0">                    
                          {{ detail.user_profile.subdistrict_name }},
                        </span>
                        <span v-if="detail.user_profile.city_name.length > 0">                    
                          {{ detail.user_profile.city_name }},
                        </span>
                        <span v-if="detail.user_profile.province_name.length > 0">                    
                          {{ detail.user_profile.province_name }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div v-if="detail.user_profile && detail.user_profile.address === undefined">
                    -
                  </div>
                </div>
              </v-card-text>

              <v-divider class="mx-4 mb-3"/>

              <v-card-text>
                <div class="d-flex align-center">
                  <div>
                    <p class="body-1 text-first">
                      Informasi
                      <span class="color-first">
                        General
                      </span>
                    </p>
                  </div>
                  <div class="ml-auto">
                    <v-btn
                      icon
                      small
                      color="#FF3177"
                      @click="reset(); dialog.general = true;">
                      <v-icon size="20">mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Tempat / Tanggal Lahir
                  </div>
                  <div class="mb-2">
                    <div v-if="detail.user_profile && detail.user_profile.birth !== undefined">
                      <span v-if="detail.user_profile.birth.date === ''">
                        -
                      </span>

                      <span v-else>
                        <span v-if="detail.user_profile.birth.place !== ''">
                          {{ detail.user_profile.birth.place }},
                        </span>
                        {{ detail.user_profile.birth.date | date }}
                      </span>
                    </div>
                    <div v-if="detail.user_profile && detail.user_profile.birth === undefined">
                      -
                    </div>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Jenis Kelamin
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile && detail.user_profile.gender !== undefined">
                      {{ detail.user_profile.gender === '' ? '-' : detail.user_profile.gender }}
                    </div>
                    <div v-if="detail.user_profile && detail.user_profile.gender === undefined">
                      -
                    </div>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Pekerjaan
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile && detail.user_profile.job !== undefined">
                      {{ detail.user_profile.job === '' ? '-' : detail.user_profile.job }}
                    </div>
                    <div v-if="detail.user_profile && detail.user_profile.job === undefined">
                      -
                    </div>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Hobi
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile && detail.user_profile.hobby !== undefined">
                      {{ detail.user_profile.hobby === '' ? '-' : detail.user_profile.hobby }}
                    </div>
                    <div v-if="detail.user_profile && detail.user_profile.hobby === undefined">
                      -
                    </div>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Status Perkawinan
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile && detail.user_profile.married !== undefined">
                      {{ detail.user_profile.married === '' ? '-' : detail.user_profile.married }}
                    </div>
                    <div v-if="detail.user_profile && detail.user_profile.married === undefined">
                      -
                    </div>
                  </div>
                </div>
              </v-card-text>

              <v-divider class="mx-4 mb-3"/>

              <v-card-text>
                <div class="d-flex align-center">
                  <div>
                    <p class="body-1 text-first">
                      Sosial
                      <span class="color-first">
                        Media
                      </span>
                    </p>
                  </div>
                  <div class="ml-auto">
                    <v-btn
                      icon
                      small
                      color="#FF3177"
                      @click="reset(); dialog.sosmed = true;">
                      <v-icon size="20">mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Facebook
                  </div>
                  <div class="mb-2">
                    <div v-if="detail.user_profile &&  detail.user_profile.sosmed !== undefined && detail.user_profile.sosmed.facebook !== undefined">
                      {{ detail.user_profile.sosmed.facebook === '' ? '-' : detail.user_profile.sosmed.facebook }}
                    </div>
                    <div v-else>
                      -
                    </div>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Instagram
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile &&  detail.user_profile.sosmed !== undefined && detail.user_profile.sosmed.instagram !== undefined">
                      {{ detail.user_profile.sosmed.instagram === '' ? '-' : detail.user_profile.sosmed.instagram }}
                    </div>
                    <div v-else>
                      -
                    </div>
                  </div>
                </div>

                <div class="body-1 font-weight-light text-first d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Twitter
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile &&  detail.user_profile.sosmed !== undefined && detail.user_profile.sosmed.twitter !== undefined">
                      {{ detail.user_profile.sosmed.twitter === '' ? '-' : detail.user_profile.sosmed.twitter }}
                    </div>
                    <div v-else>
                      -
                    </div>
                  </div>
                </div>

                <div class="body-1 font-weight-light d-flex align-start">
                  <div class="mb-2" style="width: 200px;">
                    Youtube
                  </div>
                  
                  <div class="mb-2">
                    <div v-if="detail.user_profile &&  detail.user_profile.sosmed !== undefined && detail.user_profile.sosmed.youtube !== undefined">
                      {{ detail.user_profile.sosmed.youtube === '' ? '-' : detail.user_profile.sosmed.youtube }}
                    </div>
                    <div v-else>
                      -
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card flat v-if="selected.menu === 1"
              style="border-radius: 10px; min-height: 500px;">
              <v-card-title class="body-1 font-weight-bold text-first mb-2 pt-0">
                Ubah Password
              </v-card-title>

              <v-card-subtitle class="body-1 font-weight-light text-second">
                Anda dapat mengubah password akun anda, dengan cara menekan tombol ubah password.
              </v-card-subtitle>

              <v-divider class="mx-4 mb-2"/>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="5" class="py-0">
                    <ValidationObserver ref="observer">
                      <v-form>
                        <ValidationProvider name="Password Lama" rules="required|min:5|max:20" v-slot="{ errors }">
                          <v-text-field
                            filled
                            rounded
                            dense 
                            color="#FF3177"
                            placeholder="Password Lama"
                            prepend-inner-icon="mdi-form-textbox-password"
                            :disabled="!edit"
                            v-model="password.old_password"
                            :type="show.old_password ? 'text' : 'password'"
                            :append-icon="show.old_password ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show.old_password = !show.old_password"
                            :counter="20"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                        <ValidationProvider name="Password Baru" rules="required|min:5|max:20" v-slot="{ errors }">
                          <v-text-field
                            filled
                            rounded
                            dense 
                            color="#FF3177"
                            placeholder="Password Baru"
                            prepend-inner-icon="mdi-lock-reset"
                            :disabled="!edit"
                            v-model="password.new_password"
                            :type="show.new_password ? 'text' : 'password'"
                            :append-icon="show.new_password ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show.new_password = !show.new_password"
                            :counter="20"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                        
                        <v-alert
                          type="error" 
                          text
                          dense
                          prominent
                          v-show="error.message.length > 0"
                          v-html="error.message">
                        </v-alert>

                        <v-btn
                          v-if="!edit"
                          rounded
                          width="150"
                          color="orange"
                          elevation="0"
                          class="body-2 white--text text-capitalize"
                          @click="edit = true">
                          <v-icon small class="mr-1">mdi-pencil-outline</v-icon>
                          Ubah Password
                        </v-btn>

                        <v-btn
                          v-if="edit"
                          rounded
                          width="140"
                          color="#FF3177"
                          elevation="0"
                          class="body-2 white--text text-capitalize"
                          :disabled="process.password"
                          :loading="process.password"
                          @click="updatesPassword()">
                          <v-icon small class="mr-1">mdi-check</v-icon>
                          Simpan
                        </v-btn>
                      </v-form>
                    </ValidationObserver>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  const Cookie = process.client ? require('js-cookie') : undefined
  import ImageInput from "@/components/ImageInput.vue";
  export default {
    middleware: 'authenticated',
    data () {
      return {
        selected: {
          menu: 0
        },
        menu: [
          {
            label: 'Informasi Akun',
            icon: 'mdi-account-edit-outline'
          },
          {
            label: 'Ubah Password',
            icon: 'mdi-shield-key-outline'
          }
        ],
        option: {
          placeholder: 'Tentang . . .'
        },
        dialog: {
          general: false,
          sosmed: false,
          contact: false,
          success: false
        },
        picker: false,
        config: {
          province: [],
          city: [],
          subdistrict: []
        },
        detail: {},
        avatar: null,
        form: { 
          name: '',
          mobilephone: '',
          image: '',
          image_path: '',
          image_url: '',
          profile: {
            desc: '',
            address: '',
            province_id: '',
            province_name: '',
            city_id: '',
            city_name: '',
            subdistrict_id: '',
            subdistrict_name: '',
            job: '',
            hobby: '',
            gender: 'Laki-laki',
            married: '',
            birth: {
              date: '',
              place: ''
            },
            sosmed: {
              facebook: '',
              instagram: '',
              twitter: '',
              youtube: ''
            }
          },
          latitude: '',
          longitude: ''
        },
        edit: false,
        show: {
          old_password: false,
          new_password: false
        },
        password: {
          old_password: '',
          new_password: ''
        },
        process: {
          run: false,
          form: false,
          password: false,
          image: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'dashboard/profile',
          title: 'Profil Saya',
          description: 'Profil Saya E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      ImageInput
    },
    watch: {
      'selected.menu': function(val) {
        if (val > 0) {
          this.resetPassword()
        } else {
          this.reset()
        }

        this.edit = false
      },
      avatar: {
        handler: function () {
          if (this.avatar) {
            this.process.image = this.avatar.process 

            if (!this.process.image) {
              this.form.image = this.avatar ? this.avatar.filename : ''
              this.form.image_path = this.avatar ? this.avatar.filepath : ''
              this.form.image_url = this.avatar ? this.avatar.fileuri : ''
            }
          }
        },
        deep: true
      },
      'form.profile.province_id': function() {
        let t = this
        let p = this.config.province.filter(function(v) {
          return t.form.profile.province_id == v.province_id
        })

        if (p.length > 0) {
          this.form.profile.province_name = p[0].province_name

          this.form.profile.city_id = ''
          this.form.profile.city_name = ''
          this.form.profile.subdistrict_id = ''
          this.form.profile.subdistrict_name = ''
        }


        this.setting().city()
      },
      'form.profile.city_id': function() {
        let t = this
        let c = this.config.city.filter(function(v) {
          return t.form.profile.city_id == v.city_id
        })

        if (c.length > 0) {
          this.form.profile.city_name = c[0].city_name

          this.form.profile.subdistrict_id = ''
          this.form.profile.subdistrict_name = ''
        }


        this.setting().subdistrict()
      },
      'form.profile.subdistrict_id': function() {
        let t = this
        let s = this.config.subdistrict.filter(function(v) {
          return t.form.profile.subdistrict_id == v.subdistrict_id
        })

        if (s.length > 0) {
          this.form.profile.subdistrict_name = s[0].subdistrict_name
        }
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch() {
        this.process.run = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/account/profile`)
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data
            let user = {
              user_id: response.data.user_id,
              user_name: response.data.user_name,
              user_username: response.data.user_username,
              user_email: response.data.user_email,
              user_mobilephone: response.data.user_mobilephone,
              user_image_url: response.data.user_image_url,
              user_is_verified: response.data.user_is_verified,
              user_roles: response.data.user_roles,
              user_partnership_selected: response.data.user_partnership_selected,
              user_edit: this.user.user_edit
            }

            this.$store.state.member.auth.user = user
            
            Cookie.set('user', JSON.stringify(user), { domain: 'bidankita.com' })
            Cookie.set('profile', JSON.stringify(response.data.user_profile), { domain: 'bidankita.com' })
          }
        })
      },
      setting() {
        let t = this
        return {
          async province() {
            t.$axios.$get(`https://api.bidankita.com/general/options/province`)
            .then(response => {
              if (response.status == 200) {
                t.config.province = response.data.results
              }
            })
          },
          async city() {
            t.$axios.$get(`https://api.bidankita.com/general/options/city`, {
              params: { province_id: t.form.profile.province_id }
            })
            .then(response => {
              if (response.status == 200) {
                t.config.city = response.data.results
              }
            })
          },
          async subdistrict() {
            t.$axios.$get(`https://api.bidankita.com/general/options/subdistrict`, {
              params: { city_id: t.form.profile.city_id }
            })
            .then(response => {
              if (response.status == 200) {
                t.config.subdistrict = response.data.results
              }
            })
          }
        }
      },
      getGeoLocation() {
        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        })
      },
      setPlace(place) {
        this.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }

        this.form.latitude = place.geometry.location.lat()
        this.form.longitude = place.geometry.location.lng()

        this.form.profile.address = place.formatted_address
      },
      changeAddress() {
        if(!this.add.address) {
          this.form.profile.address = ''
          this.form.profile.province_id = ''
          this.form.profile.province_name = ''
          this.form.profile.city_id = ''
          this.form.profile.city_name = ''
          this.form.profile.subdistrict_id = ''
          this.form.profile.subdistrict_name = ''
          this.form.latitude = ''
          this.form.longitude = ''
        }

      },
      reset() {
        this.avatar = {
          filename: this.detail.user_image,
          filepath: this.detail.user_image_path,
          fileuri: this.detail.user_image_url
        }


        this.form = { 
          name: this.detail.user_name,
          mobilephone: this.detail.user_mobilephone,
          image: this.detail.user_image,
          image_path: this.detail.user_image_path,
          image_url: this.detail.user_image_url,
          profile: {
            desc: this.detail.user_profile.desc !== undefined ? this.detail.user_profile.desc : '',
            address: this.detail.user_profile.address !== undefined ? this.detail.user_profile.address : '',
            province_id: this.detail.user_profile.province_id !== undefined ? this.detail.user_profile.province_id : '',
            province_name: this.detail.user_profile.province_name !== undefined ? this.detail.user_profile.province_name : '',
            city_id: this.detail.user_profile.city_id !== undefined ? this.detail.user_profile.city_id : '',
            city_name: this.detail.user_profile.city_name !== undefined ? this.detail.user_profile.city_name : '',
            subdistrict_id: this.detail.user_profile.subdistrict_id !== undefined ? this.detail.user_profile.subdistrict_id : '',
            subdistrict_name: this.detail.user_profile.subdistrict_name !== undefined ? this.detail.user_profile.subdistrict_name : '',
            job: this.detail.user_profile.job !== undefined ? this.detail.user_profile.job : '',
            hobby: this.detail.user_profile.hobby !== undefined ? this.detail.user_profile.hobby : '',
            gender: this.detail.user_profile.gender !== undefined ? this.detail.user_profile.gender : 'Laki-laki',
            married: this.detail.user_profile.married !== undefined ? this.detail.user_profile.married : '',
            birth: this.detail.user_profile.birth !== undefined ? this.detail.user_profile.birth : {
              date: '',
              place: ''
            },
            sosmed: {
              facebook: this.detail.user_profile.sosmed !== undefined ? this.detail.user_profile.sosmed.facebook : '',
              instagram: this.detail.user_profile.sosmed !== undefined ? this.detail.user_profile.sosmed.instagram : '',
              twitter: this.detail.user_profile.sosmed !== undefined ? this.detail.user_profile.sosmed.twitter : '',
              youtube: this.detail.user_profile.sosmed !== undefined ? this.detail.user_profile.sosmed.youtube : ''
            }
          },
          latitude: this.detail.user_latitude === null ? '' : parseFloat(this.detail.user_latitude),
          longitude: this.detail.user_longitude === null ? '' : parseFloat(this.detail.user_longitude)
        }

        this.setting().province()
        this.setting().city()
        this.setting().subdistrict()
        this.getGeoLocation()

        this.process.form = false

        this.error.message = ''
      },
      resetPassword() {
        this.password= {
          old_password: '',
          new_password: ''
        }
      },
      async save() {
        this.error.message = ''
        // const isValid = this.dialog.contact || this.dialog.sosmed ? true : await this.$refs.observer.validate()
        const isValid = this.dialog.sosmed ? true : await this.$refs.observer.validate()
        if (isValid) {
          this.process.form = true

          let forms = Object.assign({}, this.form)
          forms.profile = JSON.stringify(forms.profile)
          
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/account/profile/update`, forms)
          .then((response) => {
            if (response.status === 200) {
              this.dialog.general = false
              this.process.form = false
              this.dialog.contact = false
              this.dialog.sosmed = false

              // this.$store.state.member.auth.user.user_image_url = this.form.image_url
              
              this.fetch()
            } else {
              this.process.form = false
              this.error.message = response.message
            }
          })
        }
      },
      async updatesPassword() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.process.password = true

          await this.$axios.$post(`${process.env.PREFIX_PROXY}/account/profile/change_password`, this.password)
          .then((response) => {
            if (response.status === 200) {
              this.process.password = false
              this.dialog.success = true
              this.resetPassword()
              this.$refs.observer.reset();
            } else {
              this.process.password = false
              this.error.message = response.message
            }
          })
        }
      }
    }
  }
</script>