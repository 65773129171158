<template>
  <div class="fill-height mb-12">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="4" class="pa-0">
                <v-card flat color="transparent">
                  <v-card-text class="pa-0">
                    <p class="title">
                      Instruktur
                    </p>

                    <v-card flat>
                      <div class="pa-2">
                        <v-card-text class="d-flex align start">
                          <div>
                            <v-avatar size="50">
                              <v-img
                                :src="detail.kelas_mentor_jsonarray[0].kelas_mentor_image_url"
                                alt="mentor elearning bidan kita">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>
                          </div>

                          <v-spacer/>

                          <div>
                            <div class="d-flex align-center mb-2">
                              <div>
                                <v-rating
                                  :value="5"
                                  background-color="yellow darken-2"
                                  color="yellow darken-2"
                                  dense
                                  disabled
                                  size="18">
                                </v-rating>
                              </div>
                              <div class="text-second caption font-weight-bold ml-1">
                                (5)
                              </div>
                            </div>
                          </div>
                        </v-card-text>

                        <v-card-text>
                          <p class="body-1 font-weight-bold text-capitalize mb-0">
                            {{ detail.kelas_mentor_jsonarray[0].kelas_mentor_nama }}
                          </p>

                          <!-- <p class="body-2 font-weight-light line-text-second mb-0">
                            N.A
                          </p> -->
                        </v-card-text>
                      </div>
                    </v-card>

                    <p class="body-1 mt-6" v-if="detail.kelas_mentor_jsonarray.length > 1">
                      Instruktur Lainnya
                    </p>

                    <v-list two-line
                      v-for="(item, index) in detail.kelas_mentor_jsonarray" 
                      :key="index"
                      v-show="index > 0"
                      :class="index !== detail.kelas_mentor_jsonarray.length - 1 ? 'mb-4' : ''">
                      <v-list-item>
                        <v-list-item-avatar size="50">
                          <v-img
                            :src="item.kelas_mentor_image_url"
                            alt="mentor elearning bidan kita">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="font-weight-bold text-capitalize mb-0">
                            {{ item.kelas_mentor_nama }}
                          </v-list-item-title>
                          <!-- <v-list-item-subtitle class="body-2 font-weight-light line-text-second">
                            N.A
                          </v-list-item-subtitle> -->
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        
      }
    },
    components: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>