<template>
  <div class="fill-height py-12 mt-6">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Panduan
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="3"
                v-for="(item, index) in list"
                :key="index">
                <v-card
                  height="300"
                  class="box-shadow border-radius pa-6">
                  <v-card-text class="pa-0 text-center">
                    <v-avatar size="75" color="#f0f0f0">
                      <v-img 
                        contain
                        width="75"
                        height="75"
                        :src="item.icon">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-card-text>

                  <div class="body-1 line-text-second text-first font-weight-light mt-6 mb-5" style="height: 100px;">
                    "{{ item.desc }}"
                  </div>
                </v-card>
                
                <!-- <v-card
                  height="290"
                  class="box-shadow border-radius pa-6">
                  <v-card-text class="pa-0">
                    <v-avatar size="60" color="#f0f0f0">
                      <v-img 
                        contain
                        width="60"
                        height="60"
                        :src="item.icon">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-card-text>

                  <div class="body-1 line-text-second text-first font-weight-light mt-6 mb-5" style="height: 100px;">
                    "{{ item.desc }}"
                  </div>
                </v-card> -->
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        list: [
          {
            icon:"https://freeiconshop.com/wp-content/uploads/edd/person-girl-flat.png",
            desc:"Budhe, aku pengen banget lahiran normal, tapi aku bingung harus mulai belajar dari mana ya?",
          },
          {
            icon:"https://jual-properti.com/wp-content/uploads/2020/12/039_girl_avatar_profile_woman_headband-512-19babd11.png",
            desc:"Budhe, umur kehamilanku sudah 39w, tapi aku belum ada tanda tanda, aku harus ngapain?",
          },
          {
            icon:"https://fastbuildingintegrators.com/wp-content/uploads/2020/03/050_girl_avatar_profile_woman_suit_student_officer-512.png",
            desc:"Budhe, lahiran kemaren aku Sesar, lahiran yang ini aku pengen normal bisa nggak ya?",
          },
          {
            icon:"https://hkgroup.co.id/assets/images/friends-production/works/219961.png",
            desc:"Budhe, biar pas lahiran gak robek tu, caranya bagaimana ya?",
          },
          {
            icon:"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7b6f5523240799.5632176f43717.png",
            desc:"Tulang kemaluanku sakit bude! Apalagi kalau aku ganti posisi, Aku kudu pie?",
          },
          {
            icon:"https://i.pinimg.com/originals/a6/58/32/a65832155622ac173337874f02b218fb.png",
            desc:"Budhe, aku udah 37 minggu, tapi kepala janinku belum masuk panggul. katanya harus sesar, aku harus gimana?",
          },
          {
            icon:"https://esquimaltmfrc.com/wp-content/uploads/2015/09/flat-faces-icons-circle-woman-9.png",
            desc:"Aku perut gantung budhe! bisa normal enggak ya?",
          },
          {
            icon:"https://esquimaltmfrc.com/wp-content/uploads/2015/09/flat-faces-icons-circle-woman-3.png",
            desc:"Mataku minus bude, bisakah melahirkan normal?",
          },
          {
            icon:"https://img1.pngdownload.id/20180408/see/kisspng-computer-icons-user-profile-avatar-woman-business-woman-5ac9fb6b6ec5c4.6954886415231865394537.jpg",
            desc:"kalau wasir bisa normal gak ya budhe?",
          },
          {
            icon:"https://cdn1.iconfinder.com/data/icons/user-pictures/100/female1-512.png",
            desc:"aku hamil kembar, apakah bisa melahirkan normal? bagaimana caranya?",
          },
          {
            icon:"https://cdn0.iconfinder.com/data/icons/avatar-78/128/7-512.png",
            desc:"Budhe, gimana caranya supaya berhasil hamil?",
          },
          {
            icon:"https://i.pinimg.com/736x/64/5f/6a/645f6adba05c8bb959a0c5c4dc69284f.jpg",
            desc:"Aku pengen ASI ku Lancar dan melimpah budhe, tapi puting susuku mendelep, aku harus gimana?",
          }
        ],
        // SEO
        content: {
          url: 'guide',
          title: 'Panduan Bidan Kita',
          description: 'Panduan Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>