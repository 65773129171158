<template>
  <div class="fill-height mb-12">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="pa-0">
            <v-row align="center">
              <v-col cols="6">
                <p class="font-weight-bold mb-0"
                  :class="rps === 'sm' || rps === 'xs' ? 'body-1' : 'title'">
                  <span class="color-first mr-1">
                    Review
                  </span>
                  & Ulasan
                </p>
              </v-col>

              <v-col cols="6" class="body-1 text-right">
                <span class="text-first font-weight-bold">
                  {{ pagination.total_data }}
                </span>
                <span class="body-2 font-weight-light text-first">
                  Ulasan
                </span>
              </v-col>
            </v-row>

            <v-divider/>

            <v-row align="center" class="py-3">
              <v-col cols="12" md="2" class="text-center">
                <div class="text-h2 font-weight-bold mb-2">
                  {{ parseFloat(total_rating).toFixed(1) }}
                </div>

                <v-rating dense
                  size="20"
                  :value="total_rating"
                  readonly
                  color="orange"
                  background-color="orange"
                  half-increments>
                </v-rating>

                <div class="body-2 font-weight-light text-second mt-2">
                  Rating Kelas
                </div>
              </v-col>

              <v-col cols="12" md="10">
                <div 
                  v-for="(item, index) in data_rating"
                  :key="index">
                  <div class="d-flex align-center">
                    <div :class="rps === 'xl' || rps === 'lg' ? 'rating-progress' : rps === 'md' ? 'rating-progress-md' : 'rating-progress-sm-xs'">
                      <v-progress-linear
                        color="orange"
                        background-color="#ff980033"
                        height="12"
                        rounded
                        :value="item.percent">
                      </v-progress-linear>
                    </div>

                    <div class="mr-auto mb-1 pl-8">
                      <v-icon 
                        :size="rps === 'sm' || rps === 'xs' ? 12 : 20"
                        color="orange" 
                        v-for="(s, i) in 5 - index" 
                        :key="i">
                        mdi-star
                      </v-icon>
                    </div>
                    
                    <div 
                      class="body-1 font-weight-light ml-auto grey--text text--darken-1">
                      <span>
                        {{ parseInt(item.percent) }}%
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex align-center">
                <div class="mr-2" v-show="rps !== 'xs'"
                  v-for="(item, index) in rating" :key="index">
                  <v-btn 
                    outlined
                    rounded
                    :color="selected.rating === item.value ? 'orange' : 'grey'"
                    class="body-1 text-capitalize"
                    @click="selected.rating = item.value; fetch();">
                    <v-icon small class="mr-1" :color="selected.rating === item.value ? 'orange' : 'grey'">
                      {{ selected.rating === item.value ? 'mdi-star-check' : 'mdi-star-outline' }}
                    </v-icon>
                    {{ item.label }}
                  </v-btn>
                </div>

                <v-select v-show="rps === 'xs'"
                  dense
                  outlined
                  color="orange"
                  hide-details
                  single-line
                  :items="rating"
                  item-text="label"
                  item-value="value"
                  item-color="orange"
                  v-model="selected.rating"
                  v-on:change="fetch()">
                </v-select>
              </v-col>
            </v-row>
            
            <p class="body-1 font-weight-bold mt-6">
              Semua Ulasan ({{ pagination.total_data }})
            </p>

            <v-skeleton-loader
              v-for="(item, index) in process.run ? 5 : list" :key="index"
              class="ma-auto"
              :loading="process.run"
              type="list-item-avatar-three-line">
              <div>
                <v-row v-if="!process.run && list.length > 0">
                  <v-col cols="12">
                    <v-list dense three-line
                      style="border-radius: 5px;">
                      <v-list-item>
                        <v-list-item-avatar size="50">
                          <v-img
                            :src="item.rating_user_image_url !=='' ? item.rating_user_image_url : require('@/assets/image/member-default.jpg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="body-1 font-weight-bold mb-2 d-flex align-center">
                            <div>
                              {{ item.rating_user_name }}
                            </div>

                            <v-spacer/>

                            <div class="d-flex align-center">
                              <v-rating dense
                                :value="parseInt(item.rating_value)"
                                small
                                readonly
                                color="yellow darken-2"
                                background-color="yellow darken-2">
                              </v-rating>
                              <span class="text-second caption font-weight-bold ml-1">
                                ({{ parseFloat(item.rating_value).toFixed(1) }})
                              </span>
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle class="caption font-weight-light">
                            {{ item.rating_update_datetime | ago }}
                          </v-list-item-subtitle>

                          <p class="body-2 font-weight-light line-text-second mt-1 mb-0">
                            {{ item.rating_content_jsonobject.value }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </div>
            </v-skeleton-loader>

            <v-row class="py-12" v-if="!process.run && pagination.total_page < 1">
              <v-col>
                <v-img 
                  class="mx-auto"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Ulasan masih kosong!
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk beri rating & ulasan mu untuk kelas ini.
                </p>
              </v-col>
            </v-row>

            <v-card-text class="text-center" v-if="pagination.total_data > 8 && limit < pagination.total_data">
              <v-btn
                small
                elevation="0"
                color="#f1f1f1"
                class="caption text-second text-capitalize"
                @click="limit += 8">
                Lihat lebih banyak (+{{ pagination.total_data - limit}})
                <v-icon small color="#7B7B7B" class="ml-1">mdi-chevron-down</v-icon>
              </v-btn>
            </v-card-text>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        selected: {
          rating: ''
        },
        rating: [
          {
            label: 'Semua',
            value: ''
          },
          {
            label: 5,
            value: 5
          },
          {
            label: 4,
            value: 4
          },
          {
            label: 3,
            value: 3
          },
          {
            label: 2,
            value: 2
          },
          {
            label: 1,
            value: 1
          }
        ],
        total_rating: 0,
        data_rating: [],
        list: [],
        limit: 8,
        pagination: {},
        process: {
          run: false
        }
      }
    },
    components: {
      
    },
     watch: {
      'limit': function() {
        this.fetch(false)
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          limit: this.limit,
          id: this.detail.kelas_id,
          filter: [
            {
              type: 'string',
              field: 'rating_value',
              value: this.selected.rating
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/rating`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false

            this.total_rating = parseFloat(response.data.total_rating)
            this.data_rating = response.data.data_rating

            this.list = response.data.results
            this.pagination = response.data.pagination
          }
        })
      }
    }
  }
</script>

<style scoped>
.rating-progress {
  max-width: 80%;
  min-width: 80%;
}
.rating-progress-md {
  max-width: 70%;
  min-width: 70%;
}
.rating-progress-sm-xs {
  max-width: 60%;
  min-width: 60%;
}
.footer-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}
.on-scroll-ralative {
  position: relative; top: -350px;
}
.on-scroll-sticky {
  position: sticky; top: 100px;
}
.preview-lock {
  color: #cacaca;
}
</style>