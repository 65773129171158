<template>
  <div :class="$route.name === 'collection-streaming-id' ? '' : 'fill-height'">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container style="background: #fff; border-radius: 5px;" class="pa-0">
            <v-row>
              <v-col>
                <v-card flat>
                  <v-card-text class="py-0" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                    <div class="font-weight-bold text-second">
                      <v-icon>mdi-seal</v-icon>
                      {{ detail.kelas_type_title }}
                      <v-icon small>mdi-chevron-double-right</v-icon>
                      {{ detail.kelas_kategori_title }}
                      <span v-if="detail.kelas_kategori_child_title !== ''">
                        <v-icon small>mdi-chevron-double-right</v-icon>
                        {{ detail.kelas_kategori_child_title }}
                      </span>
                    </div>
                  </v-card-text>

                  <v-card-text class="px-0" v-if="detail.is_read_persentase > 0 && $route.name !== 'collection-streaming-id'">
                    <p class="font-weight-bold mb-2">
                      {{ detail.is_read_persentase }}% Selesai
                    </p>
                    <v-progress-linear
                      :color="detail.is_read_persentase < 25 ? 'red' : detail.is_read_persentase > 25 && detail.is_read_persentase < 75 ? 'orange' : '#65ea6a'"
                      :background-color="detail.is_read_persentase < 25 ? '#f4433640' : detail.is_read_persentase > 25 && detail.is_read_persentase < 75 ? '#ff980040' : '#65ea6a40'"
                      rounded
                      height="15"
                      :value="detail.is_read_persentase">
                    </v-progress-linear>
                  </v-card-text>
                  
                  <v-card-title class="text-capitalize" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                    {{ detail.kelas_nama }}
                  </v-card-title>

                  <v-card-text class="body-1 font-weight-light line-text-second" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                    <span v-html="detail.kelas_deskripsi"></span>
                  </v-card-text>

                  <v-card-text v-if="detail.kelas_terkait_elearning.length > 0 || detail.kelas_terkait_zoom.length > 0"
                    class="body-1 font-weight-light font-italic text-decoration-underline py-0"
                    :class="$route.name !== 'collection-streaming-id' ? 'px-0' : ''">
                    #Dengan membeli kelas ini Anda akan memdapatkan harga khusus / spesial untuk kelas terkait.
                  </v-card-text>

                  <v-card-text class="py-0" v-if="detail.kelas_terkait_zoom.length > 0 && $route.name === 'collection-streaming-id'">
                    <v-row>
                      <v-col cols="12">
                        <p class="title font-weight-bold mb-0">
                          <span class="color-first mr-1">
                            Zoom
                          </span>
                          Class Terkait
                        </p>

                        <p class="body-1 font-weight-light text-second mb-0">
                          Kelas lainnya yang berkaitan dengan kelas ini dengan penawaran menarik.
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="2" sm="6"
                        v-for="(item, index) in detail.kelas_terkait_zoom"
                        :key="index"
                        v-show="index < 4">
                        <v-card
                          class="border-radius box-shadow"
                          :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}`"
                          target="_blank">
                          <v-img
                            width="100%"
                            height="150"
                            :src="item.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-2">
                            <v-card-text class="pa-2">
                              <p class="body-1 font-weight-bold line-text-first text-first text-capitalize mb-0" style="height: 25px;">
                                <span class="one-line">
                                  {{ item.kelas_nama }}
                                </span>
                              </p>
                              <p class="body-2 text-second mb-0">
                                <span class="one-line">
                                  {{ item.kelas_kategori_title }}
                                </span>
                              </p>
                            </v-card-text>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-if="detail.kelas_terkait_zoom.length > 6">
                      <v-col cols="12" class="text-right">
                        <a href="https://kelas.bidankita.com/streaming" target="_blank" class="color-first">
                          <span>
                            Lihat kelas lainnya
                          </span>
                          <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                        </a>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="py-0" v-if="detail.kelas_terkait_elearning.length > 0"
                    :class="$route.name !== 'collection-streaming-id' ? 'px-0' : ''">
                    <v-row>
                      <v-col cols="12">
                        <p class="title font-weight-bold mb-0">
                          <span class="color-first mr-1">
                            Kelas
                          </span>
                          E-Learning Terkait
                        </p>

                        <p class="body-1 font-weight-light text-second mb-0">
                          Kelas e-learning lainnya yang berkaitan dengan kelas ini dengan penawaran menarik.
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="2" sm="6"
                        v-for="(item, index) in detail.kelas_terkait_elearning"
                        :key="index"
                        v-show="index < 4">
                        <v-card
                          class="border-radius box-shadow"
                          :to="`/elearning/${item.kelas_slug}`">
                          <v-img
                            width="100%"
                            height="150"
                            :src="item.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-2">
                            <v-card-text>
                              <p class="body-1 font-weight-bold line-text-first text-first text-capitalize mb-0" style="height: 25px;">
                                <span class="one-line">
                                  {{ item.kelas_nama }}
                                </span>
                              </p>
                              <p class="body-2 text-second mb-0">
                                <span class="one-line">
                                  {{ item.kelas_kategori_title }}
                                </span>
                              </p>
                            </v-card-text>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-if="detail.kelas_terkait_elearning.length > 6">
                      <v-col cols="12" class="text-right">
                        <nuxt-link to="/elearning" class="color-first">
                          <span>
                            Lihat kelas lainnya
                          </span>
                          <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                        </nuxt-link>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="py-0 pb-3 px-0" v-if="detail.kelas_terkait_zoom.length > 0 && $route.name !== 'collection-streaming-id'">
                    <v-row>
                      <v-col cols="12">
                        <p class="title font-weight-bold mb-0">
                          <span class="color-first mr-1">
                            Zoom
                          </span>
                          Class Terkait
                        </p>

                        <p class="body-1 font-weight-light text-second mb-0">
                          Kelas lainnya yang berkaitan dengan kelas ini dengan penawaran menarik.
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="2" sm="6"
                        v-for="(item, index) in detail.kelas_terkait_zoom"
                        :key="index"
                        v-show="index < 4">
                        <v-card
                          class="border-radius box-shadow"
                          :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}`"
                          target="_blank">
                          <v-img
                            width="100%"
                            height="150"
                            :src="item.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-2">
                            <v-card-text class="pa-2">
                              <p class="body-1 font-weight-bold line-text-first text-first text-capitalize mb-0" style="height: 25px;">
                                <span class="one-line">
                                  {{ item.kelas_nama }}
                                </span>
                              </p>
                              <p class="body-2 text-second mb-0">
                                <span class="one-line">
                                  {{ item.kelas_kategori_title }}
                                </span>
                              </p>
                            </v-card-text>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-if="detail.kelas_terkait_zoom.length > 6">
                      <v-col cols="12" class="text-right">
                        <a href="https://kelas.bidankita.com/streaming" target="_blank" class="color-first">
                          <span>
                            Lihat kelas lainnya
                          </span>
                          <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                        </a>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="px-0" v-if="$route.name === 'collection-streaming-id'">
            <v-row>
              <v-col>
                <div class="d-flex align-center my-4">
                  <div class="d-flex align-center">
                    <v-icon size="30" class="mr-1">mdi-calendar-month</v-icon>
                    <span class="title font-weight-bold mb-0">
                      <span class="color-first mr-1">
                        {{ detail.schedule_time_jsonarray.length }}
                      </span>
                      Jadwal
                    </span>
                  </div>

                  <v-spacer/>
                </div>

                <v-card
                  v-for="(item, index) in detail.schedule_time_jsonarray"
                  :key="index"
                  class="box-shadow mb-6"
                  style="border-radius: 5px;"
                  :color="
                    item.status === 'Belum Mulai' ? '#2196f333' :
                    item.status === 'Sedang Berlangsung' ? '#4caf5033' :
                    item.status === 'Selesai' ? '#f4433633' :
                    item.status === 'Dibatalkan' ? '#61616133' : '#ff980033'">
                  <v-card-text class="pa-0">
                    <v-row>
                      <v-col :cols="rps !== 'xs' ? 2 : 4" class="d-flex align-center justify-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <div style="position: absolute;" :style="rps !== 'xs' ? 'left: -18px;' : 'left: -8px;'">
                          <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                        </div>
                        
                        <v-spacer/>

                        <div class="text-center">
                          <p class="font-weight-bold text-first mb-0"
                            :class="rps !== 'xs' ? 'text-h5' : 'title'">
                            {{ item.date | day }}
                          </p>

                          <p class="text-second text-uppercase mb-0"
                            :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                            {{ item.date | month }}
                          </p>

                          <p class="text-second mb-0"
                            :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                            {{ item.date | year }}
                          </p>
                        </div>

                        <v-spacer/>

                        <v-divider vertical style="border-style: dashed;"/>
                      </v-col>

                      <v-col :cols="rps !== 'xs' ? 6 : 8" class="d-flex align-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <v-row align="start">
                          <v-col cols="12" class="py-0">
                            <div class="mb-2">
                              <v-chip 
                                :style="
                                  item.status === 'Belum Mulai' ? 'border: 1px solid #2196f34d;' :
                                  item.status === 'Sedang Berlangsung' ? 'border: 1px solid #4caf504d;' :
                                  item.status === 'Selesai' ? 'border: 1px solid #f443364d;' :
                                  item.status === 'Dibatalkan' ? 'border: 1px solid #6161614d;' : 'border: 1px solid #ff98004d;'"
                                :color="
                                  item.status === 'Belum Mulai' ? '#2196f333' :
                                  item.status === 'Sedang Berlangsung' ? '#4caf5033' :
                                  item.status === 'Selesai' ? '#f4433633' :
                                  item.status === 'Dibatalkan' ? '#61616133' : '#ff980033'"
                                label
                                :small="rps !== 'xs'"
                                :x-small="rps === 'xs'"
                                :text-color="
                                  item.status === 'Belum Mulai' ? '#2196F3' :
                                  item.status === 'Sedang Berlangsung' ? '#4CAF50' :
                                  item.status === 'Selesai' ? '#F44336' :
                                  item.status === 'Dibatalkan' ? '#616161' : '#FF9800'"
                                class="font-weight-bold text-uppercase">
                                Pertemuan Ke - {{ index + 1 }}
                              </v-chip>

                              <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                {{ item.status !== 'Selesai' ? 's.d.' : 'pada' }} {{ item.date | date }} Pukul {{ item.end }}
                              </span>
                            </div>
                          </v-col>

                          <v-col cols="12">
                            <div v-if="item.status === 'Belum Mulai'"
                              class="body-1 font-weight-light line-text-first px-0 two-line">
                                Kelas <b>{{ detail.kelas_nama }}</b> akan dimulai pada tanggal <b>{{ item.date | date }}</b> pukul <b>{{ item.start }}</b>. Link zoom akan tersedia pada saat kelas dimulai.
                            </div>

                            <div v-if="item.status === 'Selesai'"
                              class="body-1 font-weight-light line-text-first px-0 two-line">
                                Kelas <b>{{ detail.kelas_nama }}</b> telah selesai pada tanggal <b>{{ detail.date | date }}</b> pukul <b>{{ detail.end }}</b>."
                            </div>

                            <div v-if="detail.schedule_status === 'Dibatalkan' || detail.schedule_status === 'Reschedule'"
                              class="body-1 font-weight-light line-text-first px-0 two-line">
                                Kelas <b>{{ detail.kelas_nama }}</b> telah dibatalkan atau digantikan jadwal karena alasan tertentu. Silahkan lihat jadwal pengganti di kelas.
                            </div>

                            <div v-if="item.status === 'Sedang Berlangsung'"
                              class="body-1 font-weight-light line-text-first px-0 two-line">
                                Kelas <b>{{ detail.kelas_nama }}</b> sedang berlangsung, yuk bergabung di kelas {{ detail.kelas_nama }} sekarang, dengan menekan tombol <b>Bergabung Sekarang</b>.
                            </div>

                            <div class="mt-3" v-if="rps === 'xs'">
                              <div class="d-flex align-center" style="position: absolute;">
                                <v-icon small color="#f9f9f9">mdi-circle</v-icon>
                              </div>

                              <div class="body-1 font-weight-bold d-flex align-center"
                                :class="
                                  item.status === 'Belum Mulai' ? 'blue--text' :
                                  item.status === 'Sedang Berlangsung' ? 'green--text' :
                                  item.status === 'Selesai' ? 'red--text' :
                                  item.status === 'Dibatalkan' ? 'grey--text' : 'orange--text'">
                                <v-icon
                                  class="mr-1"
                                  :color="
                                  item.status === 'Belum Mulai' ? 'blue' :
                                  item.status === 'Sedang Berlangsung' ? 'green' :
                                  item.status === 'Selesai' ? 'red' :
                                  item.status === 'Dibatalkan' ? 'grey' : 'orange'">mdi-information</v-icon>
                                {{ item.status }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col :cols="rps !== 'xs' ? 4 : 4" v-if="rps !== 'xs'" class="d-flex align-center justify-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <div class="d-flex align-center" style="position: absolute;" :style="rps !== 'xs' ? 'right: -18px;' : 'right: -8px;'">
                          <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                        </div>

                        <div class="body-1 font-weight-bold d-flex align-center"
                          :class="
                            item.status === 'Belum Mulai' ? 'blue--text' :
                            item.status === 'Sedang Berlangsung' ? 'green--text' :
                            item.status === 'Selesai' ? 'red--text' :
                            item.status === 'Dibatalkan' ? 'grey--text' : 'orange--text'">
                          <v-icon
                            class="mr-1"
                            :color="
                            item.status === 'Belum Mulai' ? 'blue' :
                            item.status === 'Sedang Berlangsung' ? 'green' :
                            item.status === 'Selesai' ? 'red' :
                            item.status === 'Dibatalkan' ? 'grey' : 'orange'">mdi-information</v-icon>
                          {{ item.status }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section v-if="$route.name !== 'collection-streaming-id'">
          <v-divider class="mb-6"/>
          <p class="title font-weight-bold mb-6">
            <span class="color-first mr-1">
              Instruktur
            </span>
            Bidan Kita
          </p>

          <v-card
            width="250"
            flat
            class="border-radius">
            <div>
              <v-card-text class="d-flex align start px-0">
                <div>
                  <v-avatar size="50">
                    <v-img
                      :src="detail.kelas_mentor_jsonarray[0].kelas_mentor_image_url"
                      alt="mentor elearning bidan kita">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </div>

                <v-spacer/>

                <div>
                  <div class="d-flex align-center mb-2">
                    <div>
                      <v-rating
                        :value="3.8"
                        background-color="yellow darken-2"
                        color="yellow darken-2"
                        dense
                        disabled
                        size="18">
                      </v-rating>
                    </div>
                    <div class="text-second caption font-weight-bold ml-1">
                      (3.8)
                    </div>
                  </div>
                </div>
              </v-card-text>

              <v-card-text class="px-0">
                <p class="body-1 font-weight-bold text-capitalize mb-0">
                  {{ detail.kelas_mentor_jsonarray[0].kelas_mentor_nama }}
                </p>

                <!-- <p class="body-2 font-weight-light line-text-second mb-0">
                  N.A
                </p> -->
              </v-card-text>

              <!-- <v-card-text class="px-0">
                <v-btn
                  rounded
                  outlined
                  elevation="0"
                  color="grey"
                  class="text-capitalize">
                  <div>
                    Selengkapnya
                  </div>

                  <v-spacer/>

                  <div>
                    <v-icon small color="grey" class="ml-2">mdi-arrow-right</v-icon>
                  </div>
                </v-btn>
              </v-card-text> -->
            </div>
          </v-card>

          <p class="body-1 font-weight-light mt-6" v-if="detail.kelas_mentor_jsonarray.length > 1">
            *Instruktur Lainnya :
          </p>

          <v-list two-line>
            <v-list-item
              class="px-0"
              v-for="(item, index) in detail.kelas_mentor_jsonarray" 
              :key="index"
              v-show="index > 0">
              <v-list-item-avatar size="50">
                <v-img
                  :src="item.kelas_mentor_image_url"
                  alt="mentor elearning bidan kita">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-capitalize mb-0">
                  {{ item.kelas_mentor_nama }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle class="body-2 font-weight-light line-text-second">
                  N.A
                </v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      // panel: {
      //   type: Number,
      //   default: 0
      // },
      // panel_khusus: {
      //   type: Number,
      //   default: 0
      // },
      // play: {
      //   type: Object,
      //   default: {}
      // }
    },
    data () {
      return {
        // selected: {
        //   panel: 0,
        //   panel_khusus: 0
        // },
        // player: {
        //   media: {
        //     type: '',
        //     is_play: false
        //   },
        //   data: {}
        // }
      }
    },
    components: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      // this.selected.panel = this.panel
      // this.selected.panel_khusus = this.panel_khusus
      // this.player = this.play
    },
    methods: {
      // changePlay(item) {
      //   item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

      //   let status = false
      //   if (item.title === this.player.data.title) {
      //     if(!this.player.media.is_play) {
      //       status = true
      //     } else {
      //       status = false
      //     }
      //   } else {
      //     status = true
      //   }

      //   this.player.media = {
      //     type: item.content_jsonobject.type,
      //     is_play: status
      //   }
      //   this.player.data = item

      //   this.$emit('changePlay', item)
      //   this.$emit('changePanel', this.selected.panel)
      // },
      // changePlayKhusus(item) {
      //   item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

      //   let status = false
      //   if (item.title === this.player.data.title) {
      //     if(!this.player.media.is_play) {
      //       status = true
      //     } else {
      //       status = false
      //     }
      //   } else {
      //     status = true
      //   }

      //   this.player.media = {
      //     type: item.content_jsonobject.type,
      //     is_play: status
      //   }
      //   this.player.data = item

      //   this.$emit('changePlayKhusus', item)
      //   this.$emit('changePanelKhusus', this.selected.panel_khusus)
      // },
      // changeTab(type) {
      //   this.$emit('changeTab', type === 'schedule' ? 1 : 2)
      // }
    }
  }
</script>