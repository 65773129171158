
<template>
  
</template>

<script>
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  mounted(){
    this.onInit()
  },
  methods:{
    async onInit(){
      let email = this.$route.query.email
      let hash = this.$route.query.hash
      let redirect = this.$route.query.redirect
      redirect = redirect ? redirect.split(',') : []
      let last = this.$route.query.last

      let res = await this.postData({
        email,
        hash,
        from: 'elearning'
      })

      if(res.status == 200){
        let user = {
          user_id: res.results.data.user_id,
          user_name: res.results.data.user_name,
          user_username: res.results.data.user_username,
          user_email: res.results.data.user_email,
          user_mobilephone: res.results.data.user_mobilephone,
          user_image_url: res.results.data.user_image_url,
          user_is_verified: res.results.data.user_is_verified,
          user_roles: res.results.data.user_roles,
          user_partnership_selected: {},
          user_edit: false
        }
        Cookie.set('token', res.results.token, { domain: 'bidankita.com' })
        Cookie.set('user', JSON.stringify(user), { domain: 'bidankita.com' })
        Cookie.set('profile', JSON.stringify(res.results.data.user_profile), { domain: 'bidankita.com' })

        // localStorage.setItem('token', response.data.token);
        // localStorage.setItem('user', JSON.stringify(user));
        // localStorage.setItem('profile', JSON.stringify(response.data.user.user_profile));

        if(redirect.length > 0){
          let link = redirect[0]
          redirect.shift()
          let nextLink = redirect.toString()
          window.location.replace(`${link}?email=${email}&hash=${hash}&redirect=${nextLink}&last=${last}`)
        }else{
          window.location.replace(last)
        }
      }else{
        if(redirect.length > 0){
          let link = redirect[0]
          redirect.shift()
          let nextLink = redirect.toString()
          window.location.replace(`${link}?email=${email}&hash=${hash}&redirect=${nextLink}&last=${last}`)
        }else{
          window.location.replace(last)
        }
      }
      
    },
    async postData(data){
      return new Promise(resolve => {
        let linkApi = window.location.hostname !== 'elearning.bidankita.com' ? 'https://api-dev.bidankita.com/new/keluarga/' : 'https://api.bidankita.com/new/keluarga/'
        fetch(linkApi + 'auth/login/auto', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Basic QktOZXdBcGlDSTRLb2Rla3JlYXRpdjpLb2RlS3JlYXRpdlN1a3Nlc1RlcnVz'
          },
          body: JSON.stringify(data),
        }).then((response) => { 
          return response.json() 
        }).then((responseData) => {
          if (responseData.status === 404) {
            return resolve({
              status: 404,
              message: 'Page Not Found.',
            });
          } else {
            return resolve(responseData);
          }
        }).catch((error) => {
          return resolve({
            status: 500,
            message: 'Terjadi Kesalahan Server.',
            error
          });
        });
      })
    }
  }
}
</script>
