<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <!-- <v-flex>
        <section>
          <v-app-bar 
            app
            fixed
            class="header"
            style="z-index: 999;">
            <v-container>
              <v-row align="center">
                <v-col cols="6">
                  <v-skeleton-loader
                    type="list-item-avatar">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
          </v-app-bar>

          <v-container>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-skeleton-loader
                  type="article, article, article, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex> -->

      <v-flex>
        <section>
          <v-app-bar 
            app
            fixed
            class="header"
            style="z-index: 999;">
            <v-container>
              <v-row align="center">
                <v-col cols="6" class="d-flex align-center">
                  <div class="mr-6">
                    <v-btn
                      small
                      icon
                      color="#FF3177"
                      to="/collection/streaming">
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </div>

                  <div>
                    <span class="title one-line">
                      Formulir Pendaftaran (*WAJIB DIISI)
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-app-bar>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card flat
                  v-for="(item, index) in list"
                  :key="index">
                  <v-card-text class="pb-0">
                    <v-divider style="border: 3px solid #FF3177; width: 75px; border-radius: 10px;"/>
                  </v-card-text>

                  <v-card-text class="text-h4 font-weight-bold text-first line-text-first pb-2">
                    {{ item.title }}
                  </v-card-text>

                  <v-card-subtitle class="title text-first line-text-first pt-0"
                    v-for="(sub, key) in item.sub_title"
                    :key="key">
                    {{ sub }}
                  </v-card-subtitle>

                  <v-card-text class="my-3">
                    <ValidationObserver ref="observer">
                      <v-form
                        v-for="(form, keyForm) in item.form"
                        :key="keyForm">
                        <div v-if="form.type === 'textfield'">
                          <ValidationProvider :name="form.placeholder" :rules="form.required ? 'required' : ''" v-slot="{ errors }">
                            <p class="body-1 text-first mt-3 mb-0">
                              <span class="red--text">*</span>
                              {{ form.label }}
                            </p>

                            <v-text-field
                              :style="rps === 'xs' ? 'width: 100%;' : `width: ${form.width}%;`"
                              :type="form.type"
                              clearable
                              color="#FF3177"
                              :placeholder="form.placeholder"
                              v-model="form.value"
                              :error-messages="errors"
                              :required="form.required">
                            </v-text-field>
                          </ValidationProvider>
                        </div>

                        <div v-if="form.type === 'number'">
                          <ValidationProvider :name="form.placeholder" :rules="form.required ? 'required' : ''" v-slot="{ errors }">
                            <p class="body-1 text-first mt-3 mb-0">
                              <span class="red--text">*</span>
                              {{ form.label }}
                            </p>
                            
                            <v-text-field
                              :style="rps === 'xs' ? 'width: 100%;' : `width: ${form.width}%;`"
                              :type="form.type"
                              clearable
                              color="#FF3177"
                              :placeholder="form.placeholder"
                              v-model="form.value"
                              :error-messages="errors"
                              :required="form.required">
                            </v-text-field>
                          </ValidationProvider>
                        </div>

                        <div v-if="form.type === 'textarea'">
                          <ValidationProvider :name="form.placeholder" :rules="form.required ? 'required' : ''" v-slot="{ errors }">
                            <p class="body-1 text-first mt-3 mb-0">
                              <span class="red--text">*</span>
                              {{ form.label }}
                            </p>

                            <v-textarea
                              :style="rps === 'xs' ? 'width: 100%;' : `width: ${form.width}%;`"
                              :placeholder="form.placeholder"
                              auto-grow
                              rows="3"
                              color="#FF3177"
                              v-model="form.value"
                              :error-messages="errors"
                              :required="form.required">
                            </v-textarea>
                          </ValidationProvider>
                        </div>

                        <div v-if="form.type === 'checkbox'"
                          class="mb-8">
                          <ValidationProvider :name="form.placeholder" :rules="form.required ? 'required' : ''" v-slot="{ errors }">
                            <p class="body-1 text-first mt-3 mb-0">
                              <span class="red--text">*</span>
                              {{ form.label }}
                            </p>

                            <v-checkbox
                              v-for="(check, keyCheck) in form.value"
                              :key="keyCheck"
                              v-model="check.value"
                              :label="check.label"
                              color="#FF3177"
                              :value="check.value"
                              hide-details
                              :false-value="false"
                              :true-value="true">
                              <template v-slot:label>
                                <div class="body-1 text-first">
                                  {{ check.label }}
                                </div>
                              </template>
                            </v-checkbox>
                          </ValidationProvider>
                        </div>

                        <div v-if="form.type === 'radio'"
                          class="mb-6">
                          <p class="body-1 text-first mt-3 mb-0">
                            <span class="red--text">*</span>
                            {{ form.label }}
                          </p>

                          <v-radio-group hide-details v-model="form.value.selected">
                            <v-radio
                              v-for="(radio, keyRadio) in form.value.data"
                              :key="keyRadio"
                              :label="radio.label"
                              :value="radio.value"
                              color="#FF3177">
                              <template v-slot:label>
                                <div class="body-1 text-first">
                                  {{ radio.label }}
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                          
                          <ValidationProvider :name="form.placeholder" :rules="form.required ? 'required' : ''" v-slot="{ errors }">
                            <v-text-field v-if="form.value.selected === 'Other'"
                              :style="rps === 'xs' ? 'width: 100%;' : 'width: 55%;'"
                              class="pl-8"
                              clearable
                              color="#FF3177"
                              :placeholder="form.placeholder"
                              v-model="form.value.other"
                              :error-messages="errors"
                              :required="form.required">
                            </v-text-field>
                          </ValidationProvider>
                        </div>
                      </v-form>
                    </ValidationObserver>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    data () {
      return {
        list: [
          {
            title: 'Formulir Penda aran Online Class Bidan Kita',
            sub_title: ['Prenatal Gentle Yoga'],
            form: [
              {
                label: 'NAMA ISTRI/NAMA PANGGILAN',
                type: 'textfield',
                value: '',
                placeholder: 'ex. Yesie Aprillia',
                required: true,
                width: 45
              },
              {
                label: 'NAMA SUAMI/NAMA PANGGILAN',
                type: 'textfield',
                value: '',
                placeholder: 'ex. Yesie Aprillia',
                required: true,
                width: 45
              },
              {
                label: 'ALAMAT',
                type: 'textarea',
                value: '',
                placeholder: 'ex. Klaten',
                required: true,
                width: 75
              },
              {
                label: 'KOTA TINGGAL',
                type: 'textfield',
                value: '',
                placeholder: 'ex. Klaten',
                required: true,
                width: 35
              },
              {
                label: 'NOMOR TELEPHONE',
                type: 'number',
                value: '',
                placeholder: 'ex. 081XXXXXXXXXXXX',
                required: true,
                width: 30
              },
              {
                label: 'ALAMAT EMAIL (kalau ada email yang di gunakan untuk mendaftar di elearning bidankita)',
                type: 'textarea',
                value: '',
                placeholder: 'ex. Klaten',
                required: true,
                width: 75
              },
              {
                label: 'STATUS',
                type: 'checkbox',
                value: [
                  {
                    label: 'HAMIL',
                    value: false
                  },
                  {
                    label: 'TENAGA KESEHATAN',
                    value: false
                  },
                  {
                    label: 'UMUM',
                    value: false
                  },
                  {
                    label: 'PROGRAM HAMIL',
                    value: false
                  }
                ],
                placeholder: '',
                required: true,
                width: 100
              },
              {
                label: 'JIKA HAMIL, KEHAMILAN KE- :',
                type: 'number',
                value: '',
                placeholder: 'ex. 2',
                required: true,
                width: 25
              },
              {
                label: 'JIKA HAMIL, UMUR KEHAMILAN (WEEKS) terhitung saat kelas berlangsung :',
                type: 'number',
                value: '',
                placeholder: 'ex. 7',
                required: true,
                width: 25
              },
              {
                label: 'JIKA HAMIL, RIWAYAT PERSALINAN :',
                type: 'textarea',
                value: '',
                placeholder: 'ex. Normal',
                required: true,
                width: 75
              }
            ]
          },
          {
            title: 'PAIN ASSISMENT',
            sub_title: [],
            form: [
              {
                label: 'Dimana letak rasa nyeri atau ketidaknyamanan yang Anda rasakan?',
                type: 'textfield',
                value: '',
                placeholder: 'ex. Perut',
                required: true,
                width: 60
              },
              {
                label: 'Kapan keluhan atau ketidaknyamanan itu dirasakan?',
                type: 'textfield',
                value: '',
                placeholder: 'ex. 2 minggu yang lalu',
                required: true,
                width: 60
              },
              {
                label: 'Seberapa sering Anda merasakan ketidaknyamanan tersebut?',
                type: 'radio',
                value: {
                  selected: 'Setiap hari, sepanjang hari',
                  data: [
                    {
                      label: 'Setiap hari, sepanjang hari',
                      value: 'Setiap hari, sepanjang hari'
                    },
                    {
                      label: 'Setiap hari, sesekali dirasakan dan tidak tentu waktunya',
                      value: 'Setiap hari, sesekali dirasakan dan tidak tentu waktunya'
                    },
                    {
                      label: 'Sesekali dalam seminggu',
                      value: 'Sesekali dalam seminggu'
                    },
                    {
                      label: 'Jarang',
                      value: 'Jarang'
                    }
                  ]
                },
                placeholder: '',
                required: true,
                width: 100
              },
              {
                label: 'Apakah keluhan yang Anda rasakan hingga mengganggu aktifitas sehari hari?',
                type: 'radio',
                value: {
                  selected: 'Ya',
                  data: [
                    {
                      label: 'Ya',
                      value: 'Ya'
                    },
                    {
                      label: 'Tidak',
                      value: 'Tidak'
                    },
                    {
                      label: 'Kadang- kadang',
                      value: 'Kadang- kadang'
                    }
                  ]
                },
                placeholder: '',
                required: true,
                width: 100
              },
              {
                label: 'Apakah keluhan/ketidaknyaman yang Anda rasakan mengganggu tidur Anda?',
                type: 'radio',
                value: {
                  selected: 'Ya',
                  data: [
                    {
                      label: 'Ya',
                      value: 'Ya'
                    },
                    {
                      label: 'Tidak',
                      value: 'Tidak'
                    },
                    {
                      label: 'Kadang- kadang',
                      value: 'Kadang- kadang'
                    }
                  ]
                },
                placeholder: '',
                required: true,
                width: 100
              },
              {
                label: 'Tolong jelaskan jenis rasa sakit yang Anda alami.',
                type: 'radio',
                value: {
                  selected: 'Tajam/ nyeri tajam',
                  other: '',
                  data: [
                    {
                      label: 'Tajam/ nyeri tajam',
                      value: 'Tajam/ nyeri tajam'
                    },
                    {
                      label: 'Tumpul/terasa ketekan',
                      value: 'Tumpul/terasa ketekan'
                    },
                    {
                      label: 'Seperti kesetrum',
                      value: 'Seperti kesetrum'
                    },
                    {
                      label: 'Seperti mati rasa',
                      value: 'Seperti mati rasa'
                    },
                    {
                      label: 'Ada rasa Panas',
                      value: 'Ada rasa Panas'
                    },
                    {
                      label: 'Other',
                      value: 'Other'
                    }
                  ]
                },
                placeholder: 'ex. Nyeri',
                required: true,
                width: 100
              },
              {
                label: 'Seberapa parah rasa sakitnya?',
                type: 'radio',
                value: {
                  selected: 'Nyeri ringan (mengganggu, mengganggu, tetapi tidak mengganggu sebagian besar aktivitas sehari-hari)',
                  data: [
                    {
                      label: 'Nyeri ringan (mengganggu, mengganggu, tetapi tidak mengganggu sebagian besar aktivitas sehari-hari)',
                      value: 'Nyeri ringan (mengganggu, mengganggu, tetapi tidak mengganggu sebagian besar aktivitas sehari-hari)'
                    },
                    {
                      label: 'Nyeri sedang (mengganggu aktivitas sehari-hari secara signifikan)',
                      value: 'Nyeri sedang (mengganggu aktivitas sehari-hari secara signifikan)'
                    },
                    {
                      label: 'Nyeri parah (tidak dapat melakukan aktivitas sehari-hari, tidak dapat berfungsi secara mandiri)',
                      value: 'Nyeri parah (tidak dapat melakukan aktivitas sehari-hari, tidak dapat berfungsi secara mandiri)'
                    }
                  ]
                },
                placeholder: '',
                required: true,
                width: 100
              },
              {
                label: 'Kapan paling menyakitkan?',
                type: 'radio',
                value: {
                  selected: 'Bangun Tidur',
                  data: [
                    {
                      label: 'Bangun Tidur',
                      value: 'Bangun Tidur'
                    },
                    {
                      label: 'Pagi',
                      value: 'Pagi'
                    },
                    {
                      label: 'Siang',
                      value: 'Siang'
                    },
                    {
                      label: 'Sore',
                      value: 'Sore'
                    },
                    {
                      label: 'Sebelum Tidur',
                      value: 'Sebelum Tidur'
                    },
                    {
                      label: 'Malam',
                      value: 'Malam'
                    }
                  ]
                },
                placeholder: '',
                required: true,
                width: 100
              },
              {
                label: 'Apa yang membuatnya lebih sakit?',
                type: 'textfield',
                value: '',
                placeholder: 'ex. Kontraksi',
                required: true,
                width: 60
              },
              {
                label: 'Apa yang membuat rasa nyeri berkurang?',
                type: 'textfield',
                value: '',
                placeholder: 'ex. Olahraga',
                required: true,
                width: 60
              },
            ]
          }
        ],
        process: {
          run: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'form/:id',
          title: 'Form E-Learning Bidan Kita',
          description: 'Form E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      },
      headers() {
        return this.$store.state.header
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>