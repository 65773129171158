<template>
  <div class="fill-height py-12 mt-6">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Tentang Kami
              </v-col>
            </v-row>
            
            <v-row justify="center">
              <v-col cols="12" md="10" sm="10">
                <v-card flat>
                  <v-card-text class="body-1 font-weight-light line-text-second text-first">
                    Bidan Kita adalah pusat layanan kesehatan yang berfokus pada women and family center yang dilengkapi dengan edukasi yang berkaitan dengan ibu dan anak.
                  </v-card-text>

                  <v-card-text class="body-1 font-weight-light line-text-second text-first">
                    E-learning bidankita merupakan salah satu bagian layanan dari <a href="https://www.bidankita.com" target="_blank">bidankita.com</a>. Tujuan dibuatnya platform e-learning yaitu Bidan Kita ingin mengedukasi para wanita yang sedang menjalani kehamilan Dan pasca melahirkan. Sebuah platform Yang memberikan edukasi untuk perempuan Di Indonesia agar mendapatkan pengetahuan yang baik seputar kehamilan.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        // SEO
        content: {
          url: 'site/contactus',
          title: 'Kontak Bidan Kita',
          description: 'Kontak Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>