import { render, staticRenderFns } from "./indexNew.vue?vue&type=template&id=921f218c&scoped=true&"
import script from "./indexNew.vue?vue&type=script&lang=js&"
export * from "./indexNew.vue?vue&type=script&lang=js&"
import style0 from "./indexNew.vue?vue&type=style&index=0&id=921f218c&prod&scoped=true&lang=css&"
import style1 from "./indexNew.vue?vue&type=style&index=1&id=921f218c&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "921f218c",
  null
  
)

export default component.exports