<template>
  <div class="py-12">
    <DialogAuth :dialogauth="dialog" :message="success.message" @close="dialog = ''" />

    <section>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12" md="6">
            <v-card
              class="border-radius box-shadow pa-12">
              <v-row justify="center" align="center">
                <v-col cols="12" md="10">
                  <v-card-title class="justify-center py-12">
                    <v-img 
                      contain
                      width="150"
                      height="150"
                      :src="require('@/assets/image/register-img.png')">
                    </v-img>
                  </v-card-title>
                  <v-card-text>
                    <ValidationObserver ref="observer">
                      <v-form>
                        <ValidationProvider name="Password" vid="confirm" rules="required|min:5|max:20" v-slot="{ errors }">
                          <v-text-field
                            outlined
                            rounded
                            dense 
                            color="#FF3177"
                            placeholder="Password"
                            prepend-inner-icon="mdi-form-textbox-password"
                            v-model="form.password"
                            :type="show.password ? 'text' : 'password'"
                            :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show.password = !show.password"
                            :counter="20"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                        <ValidationProvider name="Konfirmasi Password" rules="required|min:5|max:20|confirmed:confirm" v-slot="{ errors }">
                          <v-text-field
                            outlined
                            rounded
                            dense 
                            color="#FF3177"
                            placeholder="Konfirmasi Password"
                            ref="password"
                            prepend-inner-icon="mdi-lock-reset"
                            v-model="form.password_confirm"
                            :type="show.password_confirm ? 'text' : 'password'"
                            :append-icon="show.password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show.password_confirm = !show.password_confirm"
                            :counter="20"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                    </ValidationObserver>
                  </v-card-text>
                  <v-card-text class="subtitle-1 text-center text-second">
                    Atur Ulang Password Anda.
                  </v-card-text>
                  <v-card-text class="body-2 text-center text-third line-text-first pt-0 pb-5">
                    Silahkan masukkan password baru anda, kemudian tekan tombol di bawah ini.
                  </v-card-text>
                  <v-card-text class="text-center pb-12">
                    <v-alert 
                      type="error" 
                      text
                      dense
                      prominent
                      v-show="error.message.length > 0"
                      v-html="error.message">
                    </v-alert>
                    <v-btn
                      rounded
                      width="200"
                      color="background-first"
                      elevation="3"
                      class="body-2 white--text text-capitalize"
                      :disabled="process.run"
                      :loading="process.run"
                      @click="save()">
                      Reset Password
                    </v-btn>
                    <div class="text-center text-second mt-5">
                      <div class="text-second">
                        Belum punya akun ?
                        <span class="text-first cursor-pointer"
                          @click="dialog = 'register'">
                          Daftar disini
                        </span>
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script> 
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        dialog: '',
        resetSuccess: true,
        show: {
          password: false,
          password_confirm: false
        },
        sosmed: [
          require("@/assets/sosmed/ic_share_fb.svg"),
          require("@/assets/sosmed/ic_share_ig.svg"),
          require("@/assets/sosmed/ic_share_tw.svg")
        ],
        form: {
          password: '',
          password_confirm: '',
          code: ''
        },
        process: {
          run: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'reset/:slug',
          title: 'Reset Password',
          description: 'Reset Password E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png'),
        }
      }
    },
    head () {
      this.content.url = `reset/${this.$route.params.slug}`
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth')
    },
    watch: {
      
    },
    computed: {
      token() {
        return this.$store.state.member.auth.token
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      if (this.token) {
        this.$router.push(`/`)
      } else {
        this.form.code = this.$route.params.slug
      }
    },
    methods: {
      reset() {
        this.form = {
          password: '',
          password_confirm: '',
          code: this.$route.params.slug
        }
        
        this.process.run = false

        this.error.message = ''
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
           this.process.run = true
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/new_password`, this.form)
          .then((response) => {
            if (response.status === 200) {
              this.process.run = false
              this.dialog = 'success'
              this.success.message = 'Password anda berhasil di reset, silahkan login dengan password baru anda.'

              this.reset()
              this.resetSuccess = true
            } else {
              this.process.run = false
              this.error.message = response.message
            }
          })
        }
      },
      toHome() {
        this.$router.push(`/`)
      }
    }
  }
</script>