<template>
  <v-layout column class="py-12">
    <div class="text-center">
      <section
        :class="$vuetify.breakpoint.name === 'xl' ? 'container' : ''">
        <v-container>
          <v-row justify="center" align="center" style="min-height: 77.6vh;">
            <v-col cols="12" md="6">
              <v-card
                flat
                class="border-radius pa-12">
                <v-row justify="center" align="center">
                  <v-col cols="12" md="10">
                    <div class="py-9">
                      <v-img 
                        alt="elearningbidankita" 
                        :src="require('@/assets/logo/logo_bidankita_color.png')"
                        max-height="150"
                        max-width="150"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <div class="pb-12">
                      <p class="title text-first font-weight-bold mb-1 pt-12 mb-6">
                        Halaman Tidak Ditemukan
                      </p>
                      <p class="body-1 font-weight-light text-second pb-12">
                        Sepertinya halaman yang Anda tuju tidak terdaftar dalam website kami.
                      </p>
                      <v-btn
                        light
                        rounded
                        color="#FF3177"
                        elevation="3"
                        class="white--text text-capitalize px-6"
                        to="/home">
                        <v-icon class="mr-2">mdi-chevron-left-circle-outline</v-icon>
                        Kembali Ke Beranda
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>
  </v-layout>
</template>

<script> 
  import { createSEOMeta } from '@/utils/seo'
  export default {
    name: 'error-404',
    data () {
      return {
        // SEO
        content: {
          url: '',
          title: '404',
          description: 'Halaman tidak ditemukan',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    props: {
      error: {
        type: Object,
        default: () => {},
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      token() {
        return this.$store.state.member.auth.token
      }
    }
  }
</script>