<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex v-if="process.run && Object.keys(detail).length < 1">
        <section :class="rps !== 'sm' && rps !== 'xs' ? 'px-16' : 'px-3'">
          <v-app-bar 
            app
            fixed
            class="header"
            :class="rps !== 'xs' && rps !== 'sm' ? 'px-12' : ''"
            style="z-index: 999;">
            <v-row align="center" justify="center">
              <v-col cols="8">
                <v-skeleton-loader
                  type="list-item-avatar">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="4">
                <v-skeleton-loader
                  type="list-item">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-app-bar>

          <v-row class="mt-3">
            <v-col cols="12" md="8">
              <v-skeleton-loader
                type="image, article, article, article">
              </v-skeleton-loader>

              <v-row>
                <v-col cols="12" md="3" sm="6"
                  v-for="(item, index) in 4"
                  :key="index">
                  <v-skeleton-loader
                    type="card, article">
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <v-skeleton-loader
                type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
              </v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="4">
              <v-skeleton-loader
                type="article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </section>
      </v-flex>

      <v-flex v-if="!process.run &&  Object.keys(detail).length > 0">
        <section :class="rps !== 'sm' && rps !== 'xs' ? 'px-16' : 'px-3'">
          <v-app-bar 
            app
            fixed
            class="header"
            :class="rps !== 'xs' && rps !== 'sm' ? 'px-12' : ''"
            style="z-index: 999;">
            <v-row align="center" justify="center">
              <v-col cols="8" class="d-flex align-center">
                <div class="mr-6">
                  <v-btn
                    small
                    icon
                    color="#FF3177"
                    to="/collection/elearning">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </div>

                <div>
                  <span class="title one-line">
                    {{ detail.kelas_nama }}
                  </span>
                </div>
              </v-col>

              <v-col cols="4" class="title text-right">
                <div v-if="detail.is_read_persentase < 1">
                  <span class="font-weight-bold red--text">
                    0%
                  </span>
                  
                  <span v-if="rps !== 'sm' && rps !== 'xs'"
                    class="font-weight-bold text-first">
                    Selesai
                  </span>
                </div>

                <div v-if="detail.is_read_persentase > 0">
                  <span class="font-weight-bold"
                    :class="detail.is_read_persentase <= 50 ? 'orange--text' : 
                      detail.is_read_persentase > 50 && detail.is_read_persentase < 75 ? 'blue--text' : 'green--text'">
                    {{ detail.is_read_persentase }}%
                  </span>

                  <span v-if="rps !== 'sm' && rps !== 'xs'"
                    class="font-weight-bold text-first">
                    Selesai
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-app-bar>

          <v-row class="mt-3">
            <v-col cols="12" md="8">
              <v-card 
                flat
                color="black"
                :height="rps !== 'xs' ? 650 : player.media.type !== 'docs' ? 300 : 375" 
                style="border-radius: 15px;"
                class="mb-3">
                <Players :player="player.media" :play="player.data" :height="rps !== 'xs' ? 650 : player.media.type !== 'docs' ? 300 : 375" />
              </v-card>

              <v-tabs
                v-model="selected.menu"
                background-color="transparent"
                color="#FF3177"
                dense
                grow
                slider-size="4">
                <v-tab
                  v-for="(item, index) in menu"
                  :key="index"
                  class="px-0 mr-2"
                  :style="
                    (index === 1 ||  index === 3 || index === 4) && rps !== 'xs' ? 'min-width: 100px; max-width: 100px;' : 
                    (index === 1 ||  index === 2 || index === 4 || index === 5) && rps === 'xs' ? 'min-width: 100px; max-width: 100px;' : 
                    'min-width: 125px; max-width: 125px;'">
                  <span class="body-1 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                    {{ item.label }}
                  </span>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="selected.menu">
                <v-tab-item
                  v-for="(item, index) in menu"
                  :key="index">
                  <v-card v-if="rps === 'xl' || rps === 'lg' || rps === 'md'"
                    flat
                    class="py-6">
                    <Information :detail="detail" v-if="selected.menu === 0" />
                    <Discus :detail="detail" v-if="selected.menu === 1" />
                    <Announcement :detail="detail" v-if="selected.menu === 2" />
                    <Review :detail="detail" v-if="selected.menu === 3" />
                    <Subscribe :detail="detail" v-if="selected.menu === 4" />
                  </v-card>

                  <v-card v-if="rps === 'sm' || rps === 'xs'"
                    flat
                    :class="selected.menu !== 1 ? 'py-6' : ''">
                    <Information :detail="detail" v-if="selected.menu === 0" />
                    <Discus :detail="detail" v-if="selected.menu === 2" />
                    <Announcement :detail="detail" v-if="selected.menu === 3" />
                    <Review :detail="detail" v-if="selected.menu === 4" />
                    <Subscribe :detail="detail" v-if="selected.menu === 5" />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <v-col cols="12" md="4" v-if="rps === 'xl' || rps === 'lg' || rps === 'md' || selected.menu === 1">
              <v-card flat style="position: sticky; top: 90px;">
                <v-list dense class="py-0" color="transparent">
                  <v-list-item class="px-0">
                    <v-list-item-content class="pt-0">
                      <v-list-item-title class="title font-weight-bold text-capitalize">
                        <p class="text-first mb-0">
                          <span class="color-first">
                            Materi
                          </span>
                          Kelas
                        </p>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="mt-0">
                      <!-- <div style="background: #f0f0f0; border-radius: 5px; padding: 5px 15px;">
                        <span class="body-1 font-weight-bold">
                          {{ detail.kelas_total_materi }}
                        </span>
                        <span class="body-2 text-second">
                          Materi
                        </span>
                      </div> -->

                      <div class="d-flex align-center" style="border-radius: 5px; padding: 5px 15px;">
                        <span>
                          <v-icon size="25" color="#000" class="mr-1">mdi-book-open-blank-variant</v-icon>
                        </span>
                        <span class="body-1 font-weight-bold mr-1">
                          {{ detail.kelas_total_materi }}
                        </span>
                        <span class="body-2">
                          Materi
                        </span>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-divider style="border-color: rgb(0 0 0 / 8%);"/>

                <v-expansion-panels flat v-model="selected.panel">
                  <v-expansion-panel
                    v-for="(item, index) in detail.kelas_materi_jsonarray" 
                    :key="index">
                    <v-expansion-panel-header class="pa-0">
                      <v-list dense class="py-0" color="transparent">
                        <v-list-item class="py-0 pl-0">
                          <v-list-item-content :style="rps !== 'xs' ? 'width: 250px;' : ''">
                            <span class="body-1 font-weight-bold text-capitalize"
                              :class="selected.panel !== index ? 'one-line' : ''">
                              {{ item.title }}
                            </span>
                          </v-list-item-content>

                          <v-list-item-action>
                            <!-- <div style="border-radius: 5px; padding: 5px 15px;"
                              :style="item.materi_is_read ? 'background: #FF3177;' : 'background: #f0f0f0;'">
                              <span class="body-1 font-weight-bold"
                                :class="item.materi_is_read ? 'white--text' : ''">
                                {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                              </span>
                              <span class="body-2" :class="item.materi_is_read ? 'white--text' : 'text-second'">
                                Materi
                              </span>
                            </div> -->
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      
                      <template v-slot:actions>
                        <!-- <v-icon color="#FF3177">
                          $expand
                        </v-icon> -->

                        <div class="d-flex align-center" style="border-radius: 5px; padding: 5px 15px;">
                          <span>
                            <v-icon size="20" class="mr-1" :color="item.materi_is_read ? '#FF3177' : '#000'">mdi-book-open-blank-variant</v-icon>
                          </span>
                          <span class="body-2 font-weight-bold mr-1"
                            :class="item.materi_is_read ? 'color-first' : ''">
                            {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                          </span>
                          <span class="caption mr-1"
                            :class="item.materi_is_read ? 'color-first' : ''">
                            Materi
                          </span>
                          <v-icon :color="item.materi_is_read ? '#FF3177' : '#000'" small>
                            $expand
                          </v-icon>
                        </div>
                      </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="pa-0">
                      <v-divider style="border-color: rgb(0 0 0 / 8%);"/>

                      <v-list flat three-line dense class="py-0" color="transparent">
                        <v-list-item-group color="#FF3177">
                          <v-list-item class="px-0" color="#FF3177"
                            v-if="item.total_sub_materi < 1"
                            @click="item.content_jsonobject.type !== '' ? playing(item) : ''">
                            <v-list-item-avatar color="transparent" size="24">
                              <v-icon v-if="item.content_jsonobject.type === ''"
                                color="#7B7B7B">
                                mdi-pound-box
                              </v-icon>

                              <!-- <v-icon v-if="item.content_jsonobject.type !== ''"
                                :color="
                                  item.materi_is_read && item.id === player.data.id ? 'green' : 
                                  item.materi_is_read && item.id !== player.data.id ? '#FF3177' : '#cacaca'">
                                mdi-checkbox-marked
                              </v-icon> -->

                              <v-icon v-if="item.content_jsonobject.type !== ''"
                                :color="
                                  item.materi_is_read && item.id === player.data.id && player.media.is_play ? 'green' : 
                                  (item.materi_is_read && item.id === player.data.id && !player.media.is_play) ||
                                  (item.materi_is_read && item.id !== player.data.id && !player.media.is_play) ||
                                  (item.materi_is_read && item.id !== player.data.id && player.media.is_play)
                                    ? '#FF3177' : '#cacaca'">
                                mdi-checkbox-marked
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div class="d-flex align-start">
                                <div class="mr-4" style="margin-top: 2px;">
                                  <v-img 
                                    width="60"
                                    height="60"
                                    class="d-flex align-center justify-center"
                                    gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                    :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                    style="border-radius: 5px;">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#FF3177">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>

                                    <a v-if="item.content_jsonobject.type === 'docs' || item.content_jsonobject.type === 'audio'"
                                      :href="item.content_jsonobject.value.url"
                                      target="_blank"
                                      class="d-flex align-center justify-center">
                                      <v-icon color="white">mdi-download</v-icon>
                                    </a>
                                  </v-img>
                                </div>

                                <div class="body-2">
                                  <p v-if="item.deskripsi === ''"
                                    class="font-weight-bold text-capitalize mb-0">
                                    <span class="one-line">{{ item.title }}</span>
                                  </p>

                                  <p v-else
                                    class="font-weight-light line-text-first text-second mb-0">
                                    <span class="three-line" v-html="item.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                  </p>
                                </div>
                              </div>
                            </v-list-item-content>

                            <v-list-item-action v-if="item.content_jsonobject.type !== ''">
                              <v-icon v-if="item.id !== player.data.id || !player.media.is_play"
                                color="#FF3177">
                                {{ 
                                  item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                  item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                  item.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                  item.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                }}
                              </v-icon>

                              <v-icon v-else
                                color="#333333">
                                mdi-stop-circle-outline
                              </v-icon>

                              <p class="caption text-capitalize color-first mb-0"
                                :class="item.id !== player.data.id || !player.media.is_play ? 'color-first' : 'text-first'">
                                <span v-if="item.content_jsonobject.type !== 'docs'">
                                  {{ item.id !== player.data.id || !player.media.is_play ? 'play' : 'stop' }}
                                </span>

                                <span v-else>
                                  {{ item.id !== player.data.id || !player.media.is_play ? 'baca' : 'tutup' }}
                                </span>
                              </p>
                            </v-list-item-action>
                          </v-list-item>

                          <div v-if="item.total_sub_materi > 0"
                            class="materi-scroll" 
                            :style="item.total_sub_materi > 5 ? 'height: 50vh; overflow-y: scroll; overflow-x: hidden;' : ''">
                            <v-list-item class="px-0 mt-2" 
                              v-for="(sub, key) in item.sub_materi" :key="key"
                              @click="sub.content_jsonobject.type !== '' ? playing(sub) : ''">
                              <v-list-item-avatar color="transparent" size="24">
                                <v-icon v-if="sub.content_jsonobject.type === ''"
                                  color="#7B7B7B">
                                  mdi-pound-box
                                </v-icon>

                                <!-- <v-icon v-if="sub.content_jsonobject.type !== ''"
                                  :color="
                                    sub.materi_is_read && sub.id === player.data.id ? 'green' : 
                                    sub.materi_is_read && sub.id !== player.data.id ? '#FF3177' : '#cacaca'">
                                  mdi-checkbox-marked
                                </v-icon> -->

                                <v-icon v-if="sub.content_jsonobject.type !== ''"
                                  :color="
                                    sub.materi_is_read && sub.id === player.data.id && player.media.is_play ? 'green' : 
                                    (sub.materi_is_read && sub.id === player.data.id && !player.media.is_play) ||
                                    (sub.materi_is_read && sub.id !== player.data.id && !player.media.is_play) ||
                                    (sub.materi_is_read && sub.id !== player.data.id && player.media.is_play)
                                      ? '#FF3177' : '#cacaca'">
                                  mdi-checkbox-marked
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <div class="d-flex align-start">
                                  <div class="mr-4" style="margin-top: 3px;">
                                    <v-img 
                                      width="60"
                                      height="60"
                                      class="d-flex align-center justify-center"
                                      gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                      :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                      style="border-radius: 5px;">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#FF3177">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>

                                      <a v-if="sub.content_jsonobject.type === 'docs' || sub.content_jsonobject.type === 'audio'"
                                        :href="sub.content_jsonobject.value.url"
                                        target="_blank"
                                        class="d-flex align-center justify-center">
                                        <v-icon color="white">mdi-download</v-icon>
                                      </a>
                                    </v-img>
                                  </div>

                                  <div class="body-2">
                                    <p class="font-weight-bold text-capitalize mb-1">
                                      <span class="one-line">{{ sub.title }}</span>
                                    </p>

                                    <p class="font-weight-light line-text-first text-second mb-0">
                                      <span class="two-line" v-html="sub.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                    </p>
                                  </div>
                                </div>
                              </v-list-item-content>

                              <v-list-item-action v-if="sub.content_jsonobject.type !== ''">
                                <v-icon v-if="sub.id !== player.data.id || !player.media.is_play"
                                  color="#FF3177">
                                  {{ 
                                    sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                    sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                    sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                    sub.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                  }}
                                </v-icon>

                                <v-icon v-else
                                  color="#333333">
                                  mdi-stop-circle-outline
                                </v-icon>

                                <p class="caption text-capitalize color-first mb-0"
                                  :class="sub.id !== player.data.id || !player.media.is_play ? 'color-first' : 'text-first'">
                                  <span v-if="sub.content_jsonobject.type !== 'docs'">
                                    {{ sub.id !== player.data.id || !player.media.is_play ? 'play' : 'stop' }}
                                  </span>

                                  <span v-else>
                                    {{ sub.id !== player.data.id || !player.media.is_play ? 'baca' : 'tutup' }}
                                  </span>
                                </p>
                              </v-list-item-action>
                            </v-list-item>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-expansion-panel-content>

                    <v-divider style="border-color: rgb(0 0 0 / 8%);" v-if="index < detail.kelas_materi_jsonarray.length - 1"/>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-col>
          </v-row>
        </section>

        <!-- <section :class="detail.kelas_terkait_elearning.length > 0 ? 'mb-3' : ''">
          <div :class="rps !== 'sm' && rps !== 'xs' ? 'px-16' : 'px-3'">
            <v-row v-if="detail.kelas_terkait_elearning.length > 0">
              <v-col cols="12">
                <p class="title font-weight-bold mb-0">
                  <span class="color-first mr-1">
                    Kelas
                  </span>
                  E-Learning Terkait
                </p>

                <p class="font-weight-light text-second">
                  Kelas e-learning lainnya yang berkaitan dengan kelas ini dengan penawaran menarik.
                </p>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" md="2" sm="6"
                v-for="(item, index) in detail.kelas_terkait_elearning"
                :key="index"
                v-show="index < 4">
                <v-card
                  class="border-radius box-shadow"
                  :to="`/elearning/${item.kelas_slug}`">
                  <v-img
                    width="100%"
                    height="220"
                    :src="item.kelas_image_cover_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div class="pa-2">
                    <v-card-text>
                      <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                        <span class="two-line">
                          {{ item.kelas_nama }}
                        </span>
                      </p>
                      <p class="body-2 text-second mb-0">
                        <span class="one-line">
                          {{ item.kelas_kategori_title }}
                        </span>
                      </p>
                    </v-card-text>

                    <v-card-text>
                      <div class="d-flex align-center">
                        <div class="d-flex align-center">
                          <v-rating
                            :value="parseFloat(item.kelas_total_rating)"
                            background-color="yellow darken-2"
                            color="yellow darken-2"
                            dense
                            disabled
                            size="14">
                          </v-rating>
                          <span class="text-second caption font-weight-bold ml-1">
                            ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                          </span>
                        </div>

                        <v-spacer/>

                        <div class="text-second">
                          <span class="caption font-weight-bold">
                            {{ item.kelas_total_materi }}
                          </span>

                          <span class="caption">
                            Materi
                          </span>
                        </div>
                      </div>
                    </v-card-text>

                    <v-divider class="mb-2 mx-4"/>

                    <v-card-actions class="body-2 px-4">
                      <div class="green--text font-weight-bold" v-if="item.terkait_price < 1">
                        Gratis
                      </div>

                      <div class="color-first" v-else>
                        <span class="font-weight-bold">
                          {{ parseInt(item.terkait_price) | price }}
                        </span> 
                      </div>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-6" v-if="detail.kelas_terkait_elearning.length > 6">
              <v-col cols="12" class="text-right">
                <nuxt-link to="/elearning" class="color-first">
                  <span>
                    Lihat kelas lainnya
                  </span>
                  <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                </nuxt-link>
              </v-col>
            </v-row>
          </div>
        </section>

        <section :class="detail.kelas_terkait_zoom.length > 0 ? 'mb-16' : ''">
          <div :class="rps !== 'sm' && rps !== 'xs' ? 'px-16' : 'px-3'">
            <v-row v-if="detail.kelas_terkait_zoom.length > 0">
              <v-col cols="12">
                <p class="title font-weight-bold mb-0">
                  <span class="color-first mr-1">
                    Zoom
                  </span>
                  Class Terkait
                </p>

                <p class="body-1 font-weight-light text-second mb-0">
                  Kelas lainnya yang berkaitan dengan kelas ini dengan penawaran menarik.
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" sm="6"
                v-for="(item, index) in detail.kelas_terkait_zoom"
                :key="index"
                v-show="index < 4">
                <v-card
                  class="border-radius box-shadow"
                  :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}`"
                  target="_blank">
                  <v-img
                    width="100%"
                    height="220"
                    :src="item.kelas_image_cover_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div class="pa-2">
                    <v-card-text>
                      <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                        <span class="two-line">
                          {{ item.kelas_nama }}
                        </span>
                      </p>
                      <p class="body-2 text-second mb-0">
                        <span class="one-line">
                          {{ item.kelas_kategori_title }}
                        </span>
                      </p>
                    </v-card-text>

                    <v-card-text>
                      <div class="d-flex align-center">
                        <div class="d-flex align-center">
                          <v-rating
                            :value="parseFloat(item.kelas_rating_summary.value)"
                            background-color="yellow darken-2"
                            color="yellow darken-2"
                            dense
                            disabled
                            size="14">
                          </v-rating>
                          <span class="text-second caption font-weight-bold ml-1">
                            ({{ parseFloat(item.kelas_rating_summary.value).toFixed(1) }})
                          </span>
                        </div>

                        <v-spacer/>

                        <div class="text-second">
                          <span class="caption font-weight-bold">
                            {{ item.kelas_total_materi }}
                          </span>

                          <span class="caption">
                            Materi
                          </span>
                        </div>
                      </div>
                    </v-card-text>

                    <v-divider class="mb-2 mx-4"/>

                    <v-card-actions class="body-2 px-4">
                      <div class="green--text font-weight-bold" v-if="item.terkait_price < 1">
                        Gratis
                      </div>

                      <div class="color-first" v-else>
                        <span class="font-weight-bold">
                          {{ parseInt(item.terkait_price) | price }}
                        </span> 
                      </div>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-6" v-if="detail.kelas_terkait_zoom.length > 6">
              <v-col cols="12" class="text-right">
                <a href="https://kelas.bidankita.com/streaming" target="_blank" class="color-first">
                  <span>
                    Lihat kelas lainnya
                  </span>
                  <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                </a>
              </v-col>
            </v-row>
          </div>
        </section> -->
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    data () {
      return {
        filter: {
          title: ''
        },
        selected: {
          panel: 0,
          menu: 0
        },
        detail: {},
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        list: [],
        pagination: {},
        process: {
          run: false,
          other: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'collection/:slug',
          title: 'E-Learning Bidan Kita',
          description: 'E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Information: () => import('@/components/collection/information'),
      Announcement: () => import('@/components/collection/announcement'),
      Review: () => import('@/components/collection/review'),
      Subscribe: () => import('@/components/collection/subscribe'),
      Discus: () => import('@/components/collection/discus'),
      Players: () => import('@/components/Player')
    },
    computed: {
      menu() {
        let menu = [
          {
            label: 'Tentang Kelas',
            icon: 'mdi-book-information-variant'
          },
          {
            label: 'Diskusi',
            icon: 'mdi-forum'
          },
          {
            label: 'Pengumuman',
            icon: 'mdi-bullhorn'
          },
          {
            label: 'Ulasan',
            icon: 'mdi-star-half-full'
          },
          {
            label: 'Langganan',
            icon: 'mdi-credit-card-outline'
          }
        ]

        if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
          menu.splice(1, 0, {
            label: 'Materi',
            icon: 'mdi-bookshelf'
          })
        }

        return menu
      },
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      },
      headers() {
        return this.$store.state.header
      }
    },
    beforeCreate () {
      let params = {
        id: this.$route.params.id,
        device: 'mobile'
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/class/classes_collection/class_detail`, { params })
      .then((response) => {
        this.content = {
          url: `collection/${response.data.kelas_id}`,
          title: response.data.kelas_nama,
          description: response.data.kelas_deskripsi,
          image: response.data.kelas_image_jsonobejct.kelas_image_cover_url
        }
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          id: this.$route.params.id,
          device: 'mobile'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/classes_collection/class_detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data

            if (type === undefined) {
              this.isFirst()
              this.fetchOther()
            }
          }
        })
      },
      isFirst() {
        if (Object.keys(this.detail.kelas_materi_last_read_jsonobject).length < 1) {
          let item = this.detail.collection_kelas_playlist_jsonarray[0]
          this.player.media = {
            type: item.content_jsonobject.type,
            is_play: true
          }
          this.player.data = item

          this.reads()
        } else {
          let item = this.detail.kelas_materi_last_read_jsonobject
          item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail
          this.player.media = {
            type: item.content_jsonobject.type,
            is_play: true
          }
          this.player.data = item
          
          let last = this.detail.kelas_materi_jsonarray.findIndex((obj, index) => {
            if (this.detail.kelas_materi_last_read_jsonobject.par_id === '0') {
              return this.detail.kelas_materi_last_read_jsonobject.id === obj.id ? index : 0
            } else {
              return this.detail.kelas_materi_last_read_jsonobject.par_id === obj.id ? index : 0
            }
          })

          this.selected.panel = last < 0 ? 0 : last
        }
      },
      playing(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        let status = false
        if (item.id === this.player.data.id) {
          if(!this.player.media.is_play) {
            status = true
          } else {
            status = false
          }
        } else {
          status = true
        }

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: status
        }
        this.player.data = item

        this.reads()
      },
      async reads() {
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/class/classes_collection/last_read`, {
          id: this.player.data.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.fetch(true)

            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }
        })
      },
      async fetchOther() {
        this.process.other = true
        
        let params = {
          limit: 6,
          slug_kelas: this.detail.kelas_slug,
          id_kategori: this.detail.kelas_kategori_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/recommend`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.other = false
            this.list = response.data.results
            this.pagination = response.data.pagination
          }
        })
      }
    }
  }
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.materi-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>