<template>
  <div class="fill-height">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="dialog.share"
      width="375"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="body-1 font-weight-bold text-second">
              Share {{ detail.kelas_nama }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third">
              Klik icon di bawah untuk share event ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in list.sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                :network="item.network"
                :url="`${host}${$route.path}`"
                :title="detail.kelas_slug"
                :description="detail.kelas_nama"
                hashtags="e-learning,bidankita">
                <v-img
                  width="45"
                  height="45"
                  contain
                  alt="e-learning"
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#333333"
            elevation="3"
            class="body-2 font-weight-bold white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="dialog.snackbar"
      top
      color="#333333"
      vertical>
      <div class="body-1 font-weight-light white--text"
        v-html="dialog.stock">
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          class="body-1 text-capitalize"
          color="red"
          text
          v-bind="attrs"
          @click="dialog.snackbar = false">
          Ya, Saya mengerti
        </v-btn>
      </template>
    </v-snackbar>

    <v-layout wrap>
      <v-flex v-if="process.run && Object.keys(detail).length < 1">
        <section class="my-16">
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-skeleton-loader
                  type="article">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="4">
                <v-skeleton-loader
                  type="article">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="8">
                <v-skeleton-loader
                  class="skeleton-image-detail"
                  type="image">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="4">
                <v-skeleton-loader
                  type="article, list-item-three-line, article, actions">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="article">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-card flat v-for="i in 5" :key="i"
              class="mt-3 mb-6">
              <v-row>
                <v-col cols="2">
                  <v-skeleton-loader
                    type="article">
                  </v-skeleton-loader>
                </v-col>

                <v-col cols="7">
                  <v-skeleton-loader
                    type="article">
                  </v-skeleton-loader>
                </v-col>

                <v-col cols="3">
                  <v-skeleton-loader
                    type="list-item-two-line, actions">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </section>
      </v-flex>
      
      <v-flex v-if="!process.run &&  Object.keys(detail).length > 0">
        <section class="mt-16">
          <v-container class="pb-0">
            <v-row>
              <v-col class="pb-0">
                <v-breadcrumbs :items="items" class="pa-0">
                  <template v-slot:divider>
                    <v-icon color="grey">mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container>
            <v-row align="start">
              <v-col cols="8">
                <p class="text-h5 font-weight-bold text-second line-text-first mb-0">
                  <span class="color-first">[LIVE STREAM]</span> {{ detail.kelas_nama }}
                </p>

                <p class="body-1 font-weight-light text-second mb-0">
                  {{ detail.kelas_kategori_title }}
                </p>
              </v-col>

              <v-col cols="4" v-if="parseInt(detail.kelas_rating_summary.total) > 0">
                <div class="text-right">
                  <p class="text-h4 font-weight-bold text-second line-text-first mb-0 mr-1">
                    {{ parseFloat(detail.kelas_rating_summary.value).toFixed(1) }}
                  </p>

                  <div style="position: relative; top: -10px;">
                    <v-rating dense
                      size="20"
                      :value="parseFloat(detail.kelas_rating_summary.value)"
                      readonly
                      color="orange"
                      background-color="orange"
                      half-increments>
                    </v-rating>

                    <p class="body-1 font-weight-light text-second mt-1 mb-0 mr-1">
                      ({{ parseInt(detail.kelas_rating_summary.total) }})
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="8">
                <v-card v-if="player.media.is_play"
                  flat
                  color="black"
                  :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" 
                  style="border-radius: 15px;"
                  class="mb-3">
                  <Players 
                    :player="player.media" 
                    :play="player.data" 
                    :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" 
                    border="10px" />
                </v-card>

                <v-card v-else
                  flat
                  style="border-radius: 10px;">
                  <v-img
                    width="100%"
                    height="500"
                    class="d-flex align-center justify-center"
                    :src="detail.kelas_image_jsonobejct.kelas_image_cover_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <div v-if="detail.kelas_materi_jsonarray.length > 0"
                      @click="playing(detail.kelas_materi_jsonarray[0]); player.media.is_play = true;"
                      class="text-center cursor-pointer">
                      <v-icon color="#fff" size="100">mdi-play-circle</v-icon>
                    </div>

                    <div v-if="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '1' || detail.kelas_is_discount"
                      :class="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '1' ? 'badge-overlay-buy-large' : 'badge-overlay-discount-large'">
                      <span class="font-weight-bold"
                        :class="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '1' ? 'top-left-buy-large badge-buy-large buy-large' : 'top-left-discount-large badge-discount-large discount-large'">
                        <v-icon v-if="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '1'"
                          color="white"
                          large
                          style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                          mdi-check-bold
                        </v-icon>

                        <div v-else
                          style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                          -{{ detail.kelas_diskon_jsonobject.percent }}%
                        </div>
                      </span>
                    </div>
                  </v-img>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card flat
                  style="border-radius: 0 10px 10px 0;"
                  min-height="450">
                  <v-card-text class="pa-6">
                    <div class="d-flex align-center">
                      <div class="text-center mr-6">
                        <p class="title font-weight-bold mb-0">
                          {{ detail.kelas_schedule[selected.schedule].schedule_start_datetime | day }}

                          <span v-if="detail.kelas_schedule[selected.schedule].schedule_time_jsonarray.length > 1">
                            - {{ detail.kelas_schedule[selected.schedule].schedule_end_datetime | day }}
                          </span>
                        </p>

                        <p class="body-2 text-second text-uppercase mb-0">
                          {{ detail.kelas_schedule[selected.schedule].schedule_start_datetime | month }}
                        </p>

                        <p class="body-2 text-second mb-0">
                          {{ detail.kelas_schedule[selected.schedule].schedule_start_datetime | year }}
                        </p>
                      </div>

                      <div>
                        <div v-if="dateNow <= detail.kelas_schedule[selected.schedule].schedule_end_datetime">
                          <div v-if="detail.kelas_schedule[selected.schedule].schedule_end_datetime !== 'Selesai'">
                            <v-chip v-if="dateNow >= detail.kelas_schedule[selected.schedule].schedule_early_price_end_datetime"
                              style="border: 1px solid #6161614d;"
                              color="#61616133"
                              label
                              text-color="#616161"
                              class="font-weight-bold text-uppercase">
                              NORMAL PRICE
                            </v-chip>

                            <v-chip v-else
                              style="border: 1px solid #0080004d"
                              color="#4caf5033"
                              label
                              text-color="green"
                              class="font-weight-bold text-uppercase">
                              EARLY PRICE
                            </v-chip>

                            <div class="mt-2">
                              <span class="text-second" :class="rps !== 'xs' ? '' : 'caption'">
                                s.d. 
                                <span v-if="dateNow >= detail.kelas_schedule[selected.schedule].schedule_early_price_end_datetime">
                                  {{ detail.kelas_schedule[selected.schedule].schedule_normal_price_end_datetime | date }}
                                </span>

                                <span v-else>
                                  {{ detail.kelas_schedule[selected.schedule].schedule_early_price_end_datetime | date }}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div v-else>
                            <v-chip
                              style="border: 1px solid #f443364d;"
                              color="#f4433633"
                              label
                              :small="rps !== 'xs'"
                              :x-small="rps === 'xs'"
                              text-color="red"
                              class="font-weight-bold text-uppercase">
                              {{ detail.kelas_schedule[selected.schedule].schedule_end_datetime }}
                            </v-chip>

                            <div class="mt-2">
                              <span class="text-second" :class="rps !== 'xs' ? '' : 'caption'">
                                pada {{ detail.kelas_schedule[selected.schedule].schedule_end_datetime | date }} Pukul {{ detail.kelas_schedule[selected.schedule].schedule_end_datetime | time }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          <v-chip
                            style="border: 1px solid #f443364d;"
                            color="#f4433633"
                            label
                            :small="rps !== 'xs'"
                            :x-small="rps === 'xs'"
                            text-color="red"
                            class="font-weight-bold text-uppercase">
                            {{ detail.kelas_schedule[selected.schedule].schedule_status !== 'Selesai' ? 'Pendaftaran Berakhir' : detail.kelas_schedule[selected.schedule].schedule_status }}
                          </v-chip>

                          <div class="mt-2">
                            <span class="text-second" :class="rps !== 'xs' ? '' : 'caption'">
                              pada {{ detail.kelas_schedule[selected.schedule].schedule_end_datetime | date }} Pukul {{ detail.kelas_schedule[selected.schedule].schedule_end_datetime | time }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style="position: relative; top: -25px;"
                        :style="detail.kelas_schedule[selected.schedule].schedule_time_jsonarray.length > 1 ? 'right: -60px;' : 'right: -85px;'">
                        <div class="burst_special_price" v-if="detail.kelas_is_special_price">
                          <span>
                            Harga Spesial
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="mt-8 mb-3">
                      <p class="body-2 text-third mb-0">
                        TANGGAL LIVE STREAM
                      </p>

                      <p class="body-1 font-weight-bold mb-0">
                        {{ detail.kelas_schedule[selected.schedule].schedule_start_datetime | date }}
                      </p>
                    </div>

                    <div class="mb-3">
                      <p class="body-2 text-third mb-0">
                        JAM LIVE STREAM
                      </p>

                      <p class="body-1 font-weight-bold mb-0">
                        {{ detail.kelas_schedule[selected.schedule].schedule_start_datetime | time }} -
                        {{ detail.kelas_schedule[selected.schedule].schedule_end_datetime | time }}
                      </p>
                    </div>

                    <div class="mb-3">
                      <p class="body-2 text-third mb-0">
                        HARGA KELAS
                      </p>

                      <p class="body-1 font-weight-bold mb-0">
                        <span v-if="!detail.kelas_is_discount">
                          <span class="green--text" v-if="parseInt(detail.kelas_schedule[selected.schedule].schedule_early_price) < 1 && parseInt(detail.kelas_schedule[selected.schedule].schedule_normal_price) < 1">
                            Gratis
                          </span>

                          <span class="color-first" v-else>
                            <span v-if="dateNow < detail.kelas_schedule[selected.schedule].schedule_early_price_end_datetime">
                              {{ parseInt(detail.kelas_schedule[selected.schedule].schedule_early_price) | price }}
                            </span>

                            <span v-else>
                              {{ parseInt(detail.kelas_schedule[selected.schedule].schedule_normal_price) | price }}
                            </span>
                          </span>
                        </span>

                        <span class="orange--text" v-if="detail.kelas_is_discount">
                          <span v-if="dateNow < detail.kelas_schedule[selected.schedule].schedule_early_price_end_datetime">
                            <span class="body-2 text-second text-decoration-line-through mr-2">
                              {{ parseInt(detail.kelas_schedule[selected.schedule].schedule_early_price) | price }}
                            </span>

                            {{ parseInt(detail.kelas_schedule[selected.schedule].schedule_early_price) - ((parseInt(detail.kelas_schedule[selected.schedule].schedule_early_price) * detail.kelas_diskon_jsonobject.percent) / 100) | price }}
                          </span>

                          <span v-else>
                            <span class="body-2 text-second text-decoration-line-through mr-2">
                              {{ parseInt(detail.kelas_schedule[selected.schedule].schedule_normal_price) | price }}
                            </span>

                            {{ parseInt(detail.kelas_schedule[selected.schedule].schedule_normal_price) - ((parseInt(detail.kelas_schedule[selected.schedule].schedule_normal_price) * detail.kelas_diskon_jsonobject.percent) / 100) | price }}
                          </span>
                        </span>
                      </p>
                    </div>

                    <v-card-text class="px-0 pb-0" v-if="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '0'">
                      <v-btn
                        block
                        rounded
                        elevation="0"
                        color="#FF3177"
                        class="body-1 text-capitalize white--text"
                        :disabled="process.cart || process.buynow"
                        :loading="process.cart"
                        @click="addToCart('cart')">
                        Tambah Ke Keranjang
                      </v-btn>
                    </v-card-text>

                    <v-card-text class="px-0" v-if="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '0'">
                      <v-btn
                        outlined
                        block
                        rounded
                        elevation="0"
                        color="#FF3177"
                        class="body-1 text-capitalize color-first"
                        :disabled="process.buynow || process.cart"
                        :loading="process.buynow"
                        @click="addToCart('buynow')">
                        Beli Sekarang
                      </v-btn>
                    </v-card-text>

                    <v-card-text class="pa-0" v-if="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '0'">
                      <v-btn
                        outlined
                        block
                        rounded
                        elevation="0"
                        color="grey"
                        class="body-1 text-capitalize color-first"
                        :disabled="process.buynow || process.cart"
                        @click="dialog.share = true">
                        Bagikan
                      </v-btn>
                    </v-card-text>

                    <v-card-text v-if="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '1'"
                      class="body-2 font-weight-light font-italic line-text-first px-0">
                      "Yuk masuk kelas sekarang, ikuti materi-materi dari mentor yang berkompeten."
                    </v-card-text>

                    <v-card-text class="px-0" v-if="detail.kelas_schedule[selected.schedule].schedule_is_purchased === '1'">
                      <v-btn
                        outlined
                        block
                        rounded
                        elevation="0"
                        color="#FF3177"
                        class="body-1 text-capitalize color-first"
                        :href="`https://kelas.bidankita.com/collection/streaming/${detail.kelas_id}?schedule_id=${detail.kelas_schedule[selected.schedule].schedule_id}`"
                        target="_blank">
                        Masuk Kelas
                      </v-btn>
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="6" class="pt-0">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow
                  slider-size="4">
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2"
                    style="min-width: 50px; max-width: 125px;">
                    <span class="body-1 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item }}
                    </span>
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-tabs-items v-model="selected.menu"
                  style="background: transparent;">
                  <v-tab-item 
                    v-for="(item, index) in menu"
                    :key="index">
                    <Schedule v-if="selected.menu === 0"
                      :detail="detail" 
                      :select="selected.schedule" />
                    <Information v-if="selected.menu === 1"
                      :detail="detail" />
                    <Materi v-if="selected.menu === 2"
                      :detail="detail"
                      :panel="selected.panel"
                      :play="player"
                      @changePlay="playing($event)" 
                      @changePanel="selected.panel = $event" />
                    <Instructor v-if="selected.menu === 3"
                      :detail="detail" />
                    <Review v-if="selected.menu === 4"
                      :detail="detail" />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  export default {
    middleware: false,
    data () {
      return {
        items: [
          {
            text: 'Live Streaming',
            disabled: false,
            href: 'https://kelas.bidankita.com/streaming'
          },
          {
            text: 'Detail',
            disabled: true,
            href: '#'
          },
        ],
        dateNow: moment().format('YYYY-MM-DD H:m:s'),
        scroll: false,
        dialog: {
          auth: '',
          message: '',
          share: false,
          snackbar: false,
          stock: ''
        },
        menu: ['Jadwal Lainnya', 'Tentang Kelas', 'Materi', 'Instruktur', 'Rating & Ulasan'],
        selected: {
          menu: 0,
          schedule: 0,
          panel: 0
        },
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        detail: {},
        list: {
          sosmed: [
            { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
            { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
            { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
            { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
          ]
        },
        process: {
          run: false,
          cart: false,
          buynow: false,
          share: false,
          wishlist: false
        },
        message: '',
        // SEO
        content: {
          url: 'elearning:slug',
          title: 'E-Learning Bidan Kita',
          description: 'E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message'),
      Players: () => import('@/components/Player'),
      
      Schedule: () => import('@/components/streaming/schedule'),
      Information: () => import('@/components/streaming/information'),
      Materi: () => import('@/components/streaming/materi'),
      Instructor: () => import('@/components/streaming/instructor'),
      Review: () => import('@/components/streaming/review')
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    beforeCreate () {
      let params = {
        slug: this.$route.params.slug
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/detail`, { params })
      .then((response) => {
        this.content = {
          url: `elearning/${response.data.kelas_slug}`,
          title: response.data.kelas_nama,
          description: response.data.kelas_deskripsi,
          image: response.data.kelas_image_jsonobejct.kelas_image_cover_url
        }
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      onScroll(e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.scroll = top > window.innerHeight - 500
      },
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          slug: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data

            let data = this.detail.kelas_schedule
            for(let i = 0;i < data.length;i++) {
              if (data[i].schedule_quota_left > 0) {
                if (this.$route.query.schedule_id !== undefined || this.$route.query.schedule_id !== null) {
                  if (this.$route.query.schedule_id === data[i].schedule_id) {
                    this.selected.schedule = i
                    break
                  }
                } else {
                  this.selected.schedule = i
                  break
                }
              }
            }
          }
        })
      },
      playing(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: this.player.media.is_play
        }
        this.player.data = item
      },
      async addToCart(type) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          if (this.selected.schedule === undefined) {
            this.dialog.stock = 'Silahkan pilih jadwal terlebih dahulu.'
            this.dialog.snackbar = true
          } else {
            if (this.detail.kelas_schedule[this.selected.schedule].schedule_quota_left < 1) {
              this.dialog.stock = 'Kuota habis, silahkan pilih jadwal lainnya.'
              this.dialog.snackbar = true
            } else {
              this.saveCart(type)
            }
          }
        }
      },
      async saveCart(type) {
        this.process.cart = type === 'cart' ? true : false
        this.process.buynow = type === 'buynow' ? true : false

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: this.detail.kelas_id,
          schedule_id: this.detail.kelas_schedule[this.selected.schedule].schedule_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false
            this.process.buynow = false

            this.$store.commit('header/setCart', this.headers.cart + 1)

            if (type === 'cart') {
              this.dialog.message = 'success'
              this.message = 'Berhasil ditambahkan ke Keranjang.'
            } else {
              this.$router.push('/cart')
            }
          } else {
            this.process.cart = false
            this.process.buynow = false
            
            this.dialog.message = 'error'
            this.message = response.message
          }
        })
      },
      async addWishlist() {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveWishlist()
        }
      },
      async saveWishlist() {
        this.process.wishlist = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/wishlist/add_remove`, {
          kelas_id: this.detail.kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.wishlist = false
            
            this.dialog.message = 'wishlist'
            this.message = this.detail.kelas_is_wishlist === '1' ? 'Berhasil ditambahkan ke Wishlist.' : 'Berhasil dihapus dari Wishlist.'

            this.fetch(true)
          }
        })
      },
      async addShare() {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveShare()
        }
      },
      async saveShare() {
        this.process.share = true

        
      }
    }
  }
</script>

<style scoped>
.rating-progress {
  max-width: 70%;
  min-width: 70%;
}
.rating-progress-md {
  max-width: 60%;
  min-width: 60%;
}
.rating-progress-sm-xs {
  max-width: 50%;
  min-width: 50%;
}
.footer-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}
.on-scroll-ralative {
  position: relative; top: -350px;
}
.on-scroll-sticky {
  position: sticky; top: 100px;
}
.preview-lock {
  color: #cacaca;
}
</style>

<style>
.skeleton-image-detail .v-skeleton-loader__image {
  height: 500px !important;
}

.v-tabs-slider {
  border-radius: 50px !important;
}
</style>