<template>
  <div class="fill-height py-12" :class="rps !== 'xs' ? 'mt-4' : 'mt-2'">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="filter.dialog"
      width="500"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius pa-2">
        <v-card-text class="pa-0 text-right">
          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="filter.dialog = false; reset();">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text>
          <v-form>   
            <v-row>
              <v-col cols="12" md="8" sm="8" class="py-0">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  :items="mentor"
                  item-color="#FF3177"
                  item-text="mentor_kelas_user_nama"
                  item-value="mentor_kelas_user_id"
                  placeholder="Pengajar"
                  clearable
                  v-model="filter.mentor">
                  <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar size="30">
                        <v-img 
                          :src="item.mentor_image_cover_url !=='' ? item.mentor_image_cover_url : require('@/assets/image/member-default.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      <span class="text-capitalize ml-2">
                        {{ item.mentor_kelas_user_nama }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar size="30">
                        <v-img 
                          :src="item.mentor_image_cover_url !=='' ? item.mentor_image_cover_url : require('@/assets/image/member-default.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      <span class="text-capitalize ml-2">
                        {{ item.mentor_kelas_user_nama }}
                      </span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6" class="py-0">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  :items="[
                    {
                      text: 'Semua Sorting',
                      value: ''
                    },
                    {
                      text: 'Terbaru',
                      value: 'Terbaru'
                    },
                    {
                      text: 'Termahal',
                      value: 'Termahal'
                    },
                    {
                      text: 'Termurah',
                      value: 'Termurah'
                    },
                    {
                      text: 'Review Terbanyak',
                      value: 'Review Terbanyak'
                    }
                  ]"
                  item-text="text"
                  item-value="value"
                  item-color="#FF3177"
                  clearable
                  placeholder="Sorting"
                  v-model="filter.sort">
                </v-select>
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col cols="12" md="4" sm="4" class="py-0 text-right">
                <v-btn
                  block
                  rounded
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize white--text"
                  @click="filter.dialog = false; fetch();">
                  <v-icon class="mr-1">mdi-magnify</v-icon>
                  Cari
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-app-bar 
            app
            fixed
            flat
            color="#fff"
            :class="rps !== 'xs' && rps !== 'sm' ? 'px-12' : ''"
            style="z-index: 999;">
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12" class="d-flex align-center">
                  <div class="mr-6">
                    <v-btn
                      small
                      icon
                      color="#FF3177"
                      to="/">
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </div>

                  <div>
                    <span class="title one-line">
                      Special Price Kelas
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-app-bar>
        </section>

        <section style="background: #fff; box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.05) !important;"
          :style="rps !== 'xs' ? 'position: sticky; top: 64px; z-index: 10;' : ''">
          <v-divider style="border: 1px solid #f9f9f9;"/>

          <v-container class="pb-0" style="background: #fff; box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.05) !important;"
            :class="rps !== 'xs' ? 'pt-2' : 'pt-0'">
            <v-row align="start" justify="end">
              <v-col cols="12" md="3" class="py-0" v-if="rps !== 'xs'">
              </v-col>

              <v-col cols="12" md="4" :class="rps !== 'xs' ? 'py-0' : ''">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  slider-size="5"
                  dense
                  grow
                  class="d-flex justify-start tabs-stream">
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mx-1"
                    :style="index === 0 || index === 3 ? 'min-width: 55px; max-width: 55px;' : 
                      index === 1 || index === 2 ? 'min-width: 100px; max-width: 100px;' : 'min-width: 75px; max-width: 75px;'">
                    <span class="body-2 text-capitalize mb-2" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="12" md="5" class="d-flex align-center" :class="rps !== 'xs' ? 'py-0' : ''">
                <v-text-field
                  filled
                  rounded
                  dense 
                  single-line
                  hide-details
                  color="#7a7a7a"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari jadwal kelas . . ."
                  clearable
                  v-model="filter.title"
                  @click:clear="filter.title = ''; fetch();"
                  v-on:keyup.enter="fetch()">
                </v-text-field>

                <div class="body-2 text-capitalize text-third cursor-pointer ml-3"
                  @click="filter.dialog = true; fetchMentor();">
                  <v-icon size="20" color="#909090">mdi-filter</v-icon>
                  Filter
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="3" 
                :style="rps !== 'xs' ? 'position: relative; top: -80px;' : ''">
                <v-card class="box-shadow"
                  :style="rps !== 'xs' ? 'position: fixed; top: 65px; z-index: 100; width: 272px;' : ''">
                  <v-expansion-panels 
                    v-model="selected.panel"
                    multiple
                    flat
                    class="expand-stream">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold white--text pa-4 py-0" 
                        style="background: #FF3177; border-radius: 4px 4px 0px 0px;">
                        <span v-if="filter.month.length > 0">
                          {{ filter.month | monthyear }}
                        </span>

                        <span v-else>
                          Pilih Bulan
                        </span>

                        <template v-slot:actions>
                          <v-icon color="#FFF">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-date-picker
                          v-model="filter.month"
                          @input="fetch()"
                          type="month"
                          width="100%"
                          color="#FF3177"
                          no-title
                          scrollable>
                        </v-date-picker>

                        <v-btn v-if="filter.month.length > 0"
                          block
                          color="#FF3177"
                          elevation="0"
                          class="text-capitalize white--text"
                          style="border-radius: 0px;"
                          @click="filter.month = ''; fetch();">
                          <v-icon size="16" color="white" class="mr-1">mdi-calendar-month</v-icon>
                          Semua Bulan
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold pa-4 py-0">
                        Tipe
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-card flat>
                          <v-card-text class="py-0">
                            <v-divider class="mt-1 mb-2" style="border: 1px solid #FF3177;"/>

                            <v-skeleton-loader
                              v-for="(item, index) in process.type ? 3 : type"
                              :key="index"
                              class="ma-auto"
                              :loading="process.type"
                              type="list-item">
                              <div>
                                <div v-if="!process.type && type.length > 0">
                                  <v-list dense class="pa-0">
                                    <v-list-item 
                                      class="px-0"
                                      style="min-height: 35px;"
                                      @click="
                                        filter.type = item.value; 
                                        filter.label_type = item.label;
                                        filter.label_category = 'Semua Kategori';
                                        filter.category = '';
                                        filter.category_sub = '';">
                                      <v-list-item-content class="py-0">
                                        <v-list-item-title class="body-2 font-weight-light text-capitalize text-second">
                                          {{ item.label }}
                                        </v-list-item-title>
                                      </v-list-item-content>

                                      <v-list-item-action class="my-0">
                                        <v-icon size="20" :color="item.value === filter.type ? '#FF3177' : '#ff317733'">mdi-checkbox-marked</v-icon>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>
                                </div>
                              </div>
                            </v-skeleton-loader>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold pa-4 py-0">
                        Kategori
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-card flat>
                          <v-card-text class="py-0">
                            <v-divider class="mt-1 mb-2" style="border: 1px solid #FF3177;"/>

                            <div :style="!process.category ? 'height: 220px; overflow-y: auto; overflow-x: hidden;' : ''">
                              <v-skeleton-loader
                                v-for="(item, index) in process.category ? 3 : category"
                                :key="index"
                                class="ma-auto"
                                :loading="process.category"
                                type="list-item">
                                <div>
                                  <div v-if="!process.category && category.length > 0">
                                    <v-list dense class="pa-0">
                                      <v-list-item v-if="item.sub.length < 1"
                                        class="px-0"
                                        style="min-height: 35px;"
                                        @click="
                                          filter.category = item.value; 
                                          filter.label_category = item.label; 
                                          filter.category_sub = '';
                                          fetch();">
                                        <v-list-item-content class="py-0">
                                          <v-list-item-title class="body-2 font-weight-light text-capitalize text-second">
                                            {{ item.label }}
                                          </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action class="my-0">
                                          <v-icon size="20" 
                                            :color="item.value === filter.category && filter.category_sub === '' ? '#FF3177' : '#ff317733'">mdi-checkbox-marked</v-icon>
                                        </v-list-item-action>
                                      </v-list-item>
                                      
                                      <v-list-group v-else
                                        no-action 
                                        @click.stop
                                        color="#7a7a7a"
                                        class="list-group-stream px-0">
                                        <template v-slot:activator>
                                          <v-list-item-content class="py-0">
                                            <v-list-item-title class="body-2 font-weight-light text-capitalize text-second">
                                              {{ item.label }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </template>

                                        <v-list-item
                                          v-for="(sub, i) in item.sub"
                                          :key="i"
                                          class="px-0"
                                          style="min-height: 35px;"
                                          @click="
                                            filter.category_sub = sub.kelas_kategori_id; 
                                            filter.label_category = sub.kelas_kategori_title; 
                                            filter.category = '';
                                            fetch();">
                                          <v-list-item-content class="py-0">
                                            <v-list-item-title class="body-2 font-weight-light text-capitalize text-second pl-3">
                                              {{ sub.kelas_kategori_title }}
                                            </v-list-item-title>
                                          </v-list-item-content>

                                          <v-list-item-action class="my-0">
                                            <v-icon size="20" :color="sub.kelas_kategori_id === filter.category_sub ? '#FF3177' : '#ff317733'">mdi-checkbox-marked</v-icon>
                                          </v-list-item-action>
                                        </v-list-item>
                                      </v-list-group>
                                    </v-list>
                                  </div>
                                </div>
                              </v-skeleton-loader>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-col>

              <v-col cols="12" md="9">
                <div class="mb-8">
                  <p class="title font-weight-bold text-second mb-0">
                    Jadwal Kelas
                  </p>

                  <p class="body-2 font-weight-light text-second">
                    Semua Kelas
                    {{ filter.type === '' ? '' : filter.label_type }}
                    Di
                    {{ filter.category === '' ? filter.label_category : `Kategori ${filter.label_category}` }}
                  </p>
                </div>

                <div v-if="process.run">
                  <v-card 
                    v-for="i in 8" 
                    :key="i"
                    class="box-shadow mb-6">
                    <v-row align="center">
                      <v-col :cols="rps !== 'xs' ? 2 : 4">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.run"
                          type="article">
                        </v-skeleton-loader>
                      </v-col>
                      
                      <v-col cols="2" class="px-0" v-if="rps !== 'xs'">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.run"
                          height="150"
                          type="image">
                        </v-skeleton-loader>
                      </v-col>

                      <v-col :cols="rps !== 'xs' ? 8 : 8">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.run"
                          :type="rps !== 'xs' ? 'article, actions' : 'article'">
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>

                <v-card 
                  v-for="(item, index) in process.run ? 8 : list"
                  :key="index"
                  class="box-shadow mb-6"
                  style="border-radius: 5px;"
                  :style="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime ? 'opacity: 50%;' : ''"
                  :href="rps !== 'xs' ? '' : item.schedule_is_purchased === '0' ? 
                        `https://kelas.bidankita.com/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}` : 
                        `https://kelas.bidankita.com/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`"
                  target="_blank">
                  <v-card-text class="pa-0">
                    <v-row v-if="!process.run && list.length > 0">
                      <v-col :cols="rps !== 'xs' ? 2 : 4" class="d-flex align-center justify-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <div v-if="item.schedule_is_purchased === '1' || item.kelas_is_discount"
                          :class="item.schedule_is_purchased === '1' ? 'badge-overlay-buy' : 'badge-overlay-discount'">
                          <span class="font-weight-bold"
                            :class="item.schedule_is_purchased === '1' ? 'top-left-buy badge-buy buy' : 'top-left-discount badge-discount discount'">
                            <v-icon v-if="item.schedule_is_purchased === '1'"
                              color="white"
                              style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                              mdi-check-bold
                            </v-icon>

                            <div v-else
                              style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                              -{{ item.kelas_diskon_jsonobject.percent }}%
                            </div>
                          </span>
                        </div>
                        
                        <div style="position: absolute;" :style="rps !== 'xs' ? 'left: -18px;' : 'left: -8px;'">
                          <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                        </div>
                        
                        <v-spacer/>

                        <div class="text-center">
                          <p class="font-weight-bold text-first mb-0"
                            :class="rps !== 'xs' ? 'text-h5' : 'title'">
                            {{ item.schedule_start_datetime | day }}

                            <span v-if="item.schedule_time_jsonarray.length > 1">
                              - {{ item.schedule_end_datetime | day }}
                            </span>
                          </p>

                          <p class="text-second text-uppercase mb-0"
                            :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                            {{ item.schedule_start_datetime | month }}
                          </p>

                          <p class="text-second mb-0"
                            :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                            {{ item.schedule_start_datetime | year }}
                          </p>
                        </div>

                        <v-spacer/>

                        <v-divider vertical style="border-style: dashed;"/>
                      </v-col>
                      
                      <v-col cols="2" class="px-0" v-if="rps !== 'xs'">
                        <v-img
                          style="border-radius: 10px;"
                          width="100%"
                          height="150"
                          :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>

                      <v-col :cols="rps !== 'xs' ? 8 : 8" class="d-flex align-center"
                        :class="rps !== 'xs' ? 'px-5' : ''">
                        <div class="d-flex align-center" style="position: absolute;" :style="rps !== 'xs' ? 'right: -18px;' : 'right: -8px;'">
                          <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                        </div>

                        <v-row align="start">
                          <v-col :cols="rps !== 'xs' ? 9 : 12" class="py-0">
                            <div class="mb-2">
                              <div v-if="dateNow <= item.schedule_end_datetime">
                                <div v-if="item.schedule_status !== 'Selesai'">
                                  <v-chip v-if="dateNow > item.schedule_early_price_end_datetime"
                                    style="border: 1px solid #6161614d;"
                                    color="#61616133"
                                    label
                                    :small="rps !== 'xs'"
                                    :x-small="rps === 'xs'"
                                    text-color="#616161"
                                    class="font-weight-bold text-uppercase">
                                    NORMAL PRICE
                                  </v-chip>

                                  <v-chip v-else
                                    style="border: 1px solid #0080004d"
                                    color="#4caf5033"
                                    label
                                    :small="rps !== 'xs'"
                                    :x-small="rps === 'xs'"
                                    text-color="green"
                                    class="font-weight-bold text-uppercase">
                                    EARLY PRICE
                                  </v-chip>

                                  <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                    s.d. 
                                    <span v-if="dateNow >= item.schedule_early_price_end_datetime">
                                      {{ item.schedule_normal_price_end_datetime | date }}
                                    </span>

                                    <span v-else>
                                      {{ item.schedule_early_price_end_datetime | date }}
                                    </span>
                                  </span>
                                </div>

                                <div v-else>
                                  <v-chip
                                    style="border: 1px solid #f443364d;"
                                    color="#f4433633"
                                    label
                                    :small="rps !== 'xs'"
                                    :x-small="rps === 'xs'"
                                    text-color="red"
                                    class="font-weight-bold text-uppercase">
                                    {{ item.schedule_status }}
                                  </v-chip>

                                  <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                    pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                  </span>
                                </div>
                              </div>

                              <div v-else>
                                <v-chip
                                  style="border: 1px solid #f443364d;"
                                  color="#f4433633"
                                  label
                                  :small="rps !== 'xs'"
                                  :x-small="rps === 'xs'"
                                  text-color="red"
                                  class="font-weight-bold text-uppercase">
                                  {{ item.schedule_status !== 'Selesai' ? 'Pendaftaran Berakhir' : item.schedule_status }}
                                </v-chip>

                                <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                  pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                </span>
                              </div>
                            </div>

                            <div>
                              <p class="font-weight-bold text-second mb-0"
                                :class="rps !== 'xs' ? 'title' : 'body-2'">
                                <span class="one-line">
                                  {{ item.kelas_nama }}
                                </span>
                              </p>

                              <p class="font-weight-light font-italic text-second mb-0"
                                :class="rps !== 'xs' ? 'body-2' : 'caption'">
                                {{ item.kelas_kategori_title }}
                              </p>
                            </div>

                            <div class="burst_special_price_mini" v-if="rps === 'xs' && item.kelas_is_special_price"
                              style="position: absolute; top: 40px; right: 20px;">
                              <span>
                                Harga Spesial
                              </span>
                            </div>
                          </v-col>

                          <v-col cols="3" class="d-flex justify-center" v-if="rps !== 'xs'">
                            <div class="burst_special_price" v-if="item.kelas_is_special_price">
                              <span>
                                Harga Spesial
                              </span>
                            </div>
                          </v-col>

                          <v-col cols="12" class="pb-0" v-if="rps !== 'xs'">
                            <v-row align="center">
                              <v-col :cols="item.schedule_is_purchased === '0' ? 7 : 6" class="d-flex align-center py-0">
                                <div>
                                  <p class="caption text-third text-uppercase mb-0">
                                    JAM KELAS
                                  </p>

                                  <p class="body-2 font-weight-bold text-second mb-0">
                                    {{ item.schedule_start_datetime | time }} - {{ item.schedule_end_datetime | time }}
                                  </p>
                                </div>

                                <v-spacer v-if="item.schedule_is_purchased === '0'"/>

                                <v-divider vertical v-if="item.schedule_is_purchased === '0'"/>
                                
                                <v-spacer v-if="item.schedule_is_purchased === '0'"/>

                                <div v-if="item.schedule_is_purchased === '0'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    HARGA KELAS
                                  </p>

                                  <p class="body-2 font-weight-bold mb-0">
                                    <span v-if="!item.kelas_is_discount">
                                      <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                        Gratis
                                      </span>

                                      <span class="color-first" v-else>
                                        <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                          {{ parseInt(item.schedule_early_price) | price }}
                                        </span>

                                        <span v-else>
                                          {{ parseInt(item.schedule_normal_price) | price }}
                                        </span>
                                      </span>
                                    </span>

                                    <span class="orange--text" v-if="item.kelas_is_discount">
                                      <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                        {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>

                                      <span v-else>
                                        {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>
                                    </span>
                                  </p>
                                </div>

                                <v-spacer/>

                                <v-divider vertical/>

                                <v-spacer/>

                                <div v-if="item.schedule_is_purchased === '0'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    SISA KUOTA
                                  </p>

                                  <p class="body-2 text-second mb-0">
                                    <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                                  </p>
                                </div>

                                <div v-if="item.schedule_is_purchased === '1'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    INFO KELAS
                                  </p>

                                  <p class="body-2 font-weight-bold mb-0">
                                    <span
                                      :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                              item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                      {{ item.schedule_status }}
                                    </span>
                                  </p>
                                </div>
                              </v-col>

                              <v-col :cols="item.schedule_is_purchased === '0' ? 5 : 6" class="d-flex align-center justify-end py-0">
                                <v-btn v-if="item.schedule_is_purchased === '0'"
                                  rounded
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize white--text"
                                  :disabled="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime"
                                  :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}`"
                                  target="_blank">
                                  Lihat Jadwal
                                </v-btn>

                                <v-btn v-if="item.schedule_is_purchased === '1'"
                                  rounded
                                  outlined
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize"
                                  :href="`https://kelas.bidankita.com/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`"
                                  target="_blank">
                                  Masuk Kelas
                                </v-btn>

                                <v-btn
                                  fab
                                  width="40"
                                  height="40"
                                  elevation="0"
                                  class="mx-3"
                                  :disabled="process.wishlist || (item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime)"
                                  :loading="process.wishlist && selected.index === index"
                                  @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                    selected.index = index;
                                    addWishlist(item.kelas_id, item.kelas_is_wishlist);">
                                  <v-icon color="#FF3177">
                                    {{ !token ? 'mdi-heart-plus-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-plus-outline' : 'mdi-heart' }}
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="12" class="d-flex align-center pb-0" v-if="rps === 'xs'">
                            <div>
                              <p class="caption text-third text-uppercase mb-0">
                                HARGA KELAS
                              </p>

                              <p class="body-2 font-weight-bold mb-0">
                                <span v-if="!item.kelas_is_discount">
                                  <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                    Gratis
                                  </span>

                                  <span class="color-first" v-else>
                                    <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                      {{ parseInt(item.schedule_early_price) | price }}
                                    </span>

                                    <span v-else>
                                      {{ parseInt(item.schedule_normal_price) | price }}
                                    </span>
                                  </span>
                                </span>

                                <span class="orange--text" v-if="item.kelas_is_discount">
                                  <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                    {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                  </span>

                                  <span v-else>
                                    {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                  </span>
                                </span>
                              </p>
                            </div>

                            <v-spacer/>

                            <div class="pr-3" v-if="item.schedule_is_purchased === '0'">
                              <p class="caption text-third text-uppercase mb-0">
                                SISA KUOTA
                              </p>

                              <p class="body-2 text-second mb-0">
                                <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                              </p>
                            </div>

                            <div class="pr-3" v-if="item.schedule_is_purchased === '1'">
                              <p class="caption text-third text-uppercase mb-0">
                                INFO KELAS
                              </p>

                              <p class="body-2 font-weight-bold mb-0">
                                <span
                                  :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                          item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                  {{ item.schedule_status }}
                                </span>
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-row justify="center" align="center" v-if="!process.run && pagination.total_page < 1">
                  <v-col cols="12" class="mt-16">
                    <v-img 
                      class="mx-auto mt-16"
                      contain
                      width="175"
                      height="175"
                      :src="require('@/assets/image/empty.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <p class="text-h5 text-second font-weight-bold text-center">
                      Kelas yang kamu cari tidak ada!
                    </p>
                    <p class="body-1 font-weight-light mb-6 text-center">
                      Yuk cari kelas belajar lain di E-Learning Bidan Kita.
                    </p>

                    <div class="text-center">
                      <v-btn
                        rounded
                        elevation="0"
                        color="#FF3177"
                        class="text-capitalize white--text"
                        to="/elearning">
                        <v-icon class="mr-1">mdi-magnify</v-icon>
                        Cari Kelas Belajar
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <Pagination v-if="pagination.total_page > 1"
                  @changePage="fetch($event)" 
                  @reloadPage="fetch($event)"
                  :model="pagination" />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  export default {
    middleware: false,
    data () {
      return {
        dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
        dialog: {
          auth: '',
          message: ''
        },
        menu: [
          {
            label: 'Semua',
            value: ''
          },
          {
            label: 'Belum Mulai',
            value: 'Belum Mulai'
          },
          {
            label: 'Berlangsung',
            value: 'Sedang Berlangsung'
          },
          {
            label: 'Selesai',
            value: 'Selesai'
          }
        ],
        picker: false,
        filter: {
          dialog: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: '',
          mentor: '',
          sort: 'Terbaru',
          // month: moment().format('YYYY-MM')
          month: ''
        },
        type: [
          {
            label: 'Semua Tipe',
            value: ''
          }
        ],
        category: [
          {
            label: 'Semua Kategori',
            value: '',
            sub: []
          }
        ],
        selected: {
          menu: 0,
          panel: [1, 2],
          index: ''
        },
        mentor: [],
        list: [],
        pagination: {},
        process: {
          run: false,
          wishlist: false,
          type: false,
          category: false
        },
        message: '',
        // SEO
        content: {
          url: 'elearning',
          title: 'Kelas Bidan Kita',
          description: 'Kelas Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message'),
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      'filter.type': function(val) {
        this.fetch()
        // this.fetchCategory()
      },
      'selected.menu': function(val) {
        this.fetch()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.fetchType()
      this.fetchCategory()
      this.fetch()
    },
    methods: {
      async fetchType() {
        this.process.type = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/type`)
        .then((response) => {
          if (response.status === 200) {
            this.process.type = false

            let data = response.data.results
            for (let i in data) {
              this.type.push({
                label: data[i].kelas_type_title,
                value: data[i].kelas_type_id
              })
            }
          }
        })
      },
      async fetchCategory() {
        this.process.category = true

        let params = {
          type_id: this.filter.type,
          sort: 'kelas_kategori_title',
          dir: 'ASC'
        }
        
        this.category = [{
          label: 'Semua Kategori',
          value: '',
          sub: []
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/category_nested`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.category = false

            let data = response.data.results
            for (let i in data) {
              this.category.push({
                label: data[i].kelas_kategori_title,
                value: data[i].kelas_kategori_id,
                sub: data[i].kelas_kategori_child
              })
            }
          }
        })
      },
      async fetchMentor() {
        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/mentor`)
        .then((response) => {
          if (response.status === 200) {
            this.mentor = response.data.results
          }
        })
      },
      reset() {
        this.filter = {
          dialog: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: '',
          mentor: '',
          sort: 'Terbaru',
          month: moment().format('YYYY-MM')
        }

        this.fetch()
      },
      async fetch(pagination = { row: 20, page: 1 }) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        this.process.run = true

        let sort = this.filter.sort === 'Terbaru' ? 'schedule_start_datetime' : this.filter.sort === 'Review Terbanyak' ? 'kelas_total_rating' : 'schedule_normal_price',
            dir = this.filter.sort === 'Terbaru' || this.filter.sort === 'Termahal' || this.filter.sort === 'Review Terbanyak' ? 'DESC' : 'ASC'
        let params = {
          limit: pagination.row,
          page: pagination.page,
          sort: this.filter.sort === '' ? '' : sort,
          dir: this.filter.sort === '' ? '' : dir,
          id_mentor: this.filter.mentor,
          filter: [
            {
              type: 'string',
              field: 'kelas_type_id',
              value: this.filter.type
            },
            {
              type: 'string',
              field: 'kelas_kategori_id',
              value: this.filter.category
            },
            {
              type: 'string',
              field: 'kelas_kategori_child_id',
              value: this.filter.category_sub
            },
            {
              type: 'string',
              field: 'kelas_nama',
              value: this.filter.title,
              comparison: '<>'
            },
            {
              type: 'string',
              field: 'schedule_status',
              value: this.menu[this.selected.menu].value
            },
            {
              type: 'string',
              field: 'schedule_start_datetime',
              value: this.filter.month,
              comparison: '<>'
            }
          ]
        }

        this.list = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/special_price`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.push(v)
            })
            this.pagination = response.data.pagination

            this.selected.panel = this.$vuetify.breakpoint.name !== 'xs' ? [1, 2] : []
          }
        })
      },
      async addWishlist(kelas_id, kelas_is_wishlist) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveWishlist(kelas_id, kelas_is_wishlist)
        }
      },
      async saveWishlist(kelas_id, kelas_is_wishlist) {
        this.process.wishlist = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/wishlist/add_remove`, {
          kelas_id: kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.wishlist = false

            this.selected.index = ''
            
            this.dialog.message = 'wishlist'
            this.message = kelas_is_wishlist === '1' ? 'Berhasil ditambahkan ke Wishlist.' : 'Berhasil dihapus dari Wishlist.'
          }
        })
      }
    }
  }
</script>

<style scoped>
.search-style.v-text-field--outlined >>> fieldset {
  border-color: #dadada;
  border-left: 0px;
}
.on-hover-left {
  position: absolute;
  top: -15px;
  right: -290px;
  z-index: 9;
  border-radius: 5px !important;
}
.on-hover-left.arrow-left:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 226px;
  border-top: 10px solid transparent;
  border-right: 10px solid #ffffff;
  border-left: none;
  border-bottom: 10px solid transparent;
}
.on-hover-left.arrow-left:before {
  content: "";
  position: absolute;
  left: -11px;
  top: 225px;
  border-top: 11px solid transparent;
  border-right: 11px solid #dadada;
  border-left: none;
  border-bottom: 11px solid transparent;
}


.on-hover-right {
  position: absolute;
  top: -15px;
  left: -290px;
  z-index: 9;
  border-radius: 5px !important;
}
.on-hover-right.arrow-right:after {
  content: "";
  position: absolute;
  right: -10px;
  top: 226px;
  border-top: 10px solid transparent;
  border-right: none;
  border-left: 10px solid #ffffff;
  border-bottom: 10px solid transparent;
}
.on-hover-right.arrow-right:before {
  content: "";
  position: absolute;
  right: -11px;
  top: 225px;
  border-top: 11px solid transparent;
  border-right: none;
  border-left: 11px solid #dadada;
  border-bottom: 11px solid transparent;
}
</style>

<style>
.tabs-stream .v-tabs-slider {
  background-color: currentColor;
  height: 100%;
  width: 100%;
  border-radius: 20px 20px 0px 0px !important;
}
.expand-stream .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
}
.list-group-stream .v-list-group__header {
  padding: 0px;
}
</style>