<template>
  <div :class="$route.name === 'collection-streaming-id' ? 'mb-12' : 'fill-height'">
    <v-dialog v-model="dialog.announcement"
      width="750"
      scrollable
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius pa-2"
        v-if="Object.keys(announcement).length > 0">
        <v-card-text class="pa-0 pb-12">
          <v-btn
            style="position: absolute; z-index: 99; right: 8px;"
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="dialog.announcement = false; announcement = {};">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text style="height: 750px;">
          <p class="body-1 font-weight-bold mb-1">
            {{ announcement.announcement_title }}
          </p>

          <p class="body-2 text-second">
            {{ announcement.announcement_update_datetime | datetime }}
          </p>
          
          <div class="body-1">
            <span class="line-text-second" v-html="announcement.announcement_content_jsonobject.value"></span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="px-0" v-if="$route.name === 'collection-streaming-id'">
            <v-row class="mt-4">
              <v-col cols="12" class="py-0">
                <v-divider class="mb-10" style="border: 2px solid #e9e9e9" />

                <div class="d-flex align-end mb-1">
                  <div class="d-flex align-center">
                    <v-icon size="30" class="mr-1">mdi-bullhorn</v-icon>
                    <span class="title font-weight-bold mb-0">
                      <span class="color-first mr-1">
                        {{ detail.kelas_announcement_total }}
                      </span>
                      Pengumuman
                    </span>
                  </div>

                  <v-spacer/>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container style="background: #fff; border-radius: 5px;" class="pa-0">
            <v-row :class="$route.name === 'collection-streaming-id' ? 'px-4 pt-3' : ''">
              <v-col cols="12" md="6" sm="6" class="pb-0">
                <v-btn 
                  :block="rps === 'xs'"
                  rounded
                  color="#FF3177"
                  elevation="0"
                  class="body-1 text-capitalize white--text"
                  :disabled="process.readsall"
                  :loading="process.readsall"
                  @click="readsall()">
                  Tandai Baca Semua
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" sm="6" class="pb-0">
                <v-text-field
                  rounded
                  dense
                  hide-details
                  filled
                  clearable
                  color="#FF3177"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari Pengumuman . . ."
                  v-model="filter.title"
                  @click:clear="filter.title = ''; fetch();"
                  v-on:keyup.enter="fetch()">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-for="(item, index) in process.run ? 5 : list"
                  :key="index"
                  class="ma-auto"
                  :loading="process.run"
                  type="article">
                  <div>
                    <v-card v-if="!process.run && list.length > 0" 
                      flat
                      class="py-2"
                      @click="reads(item)">
                      <v-card-title class="body-1 d-flex align-center" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        <div>
                          {{ item.announcement_title }}
                        </div>

                        <v-spacer/>

                        <div class="body-2 d-flex align-center"
                          :class="!item.announcement_is_read ? 'text-second' : 'green--text'">
                          <v-icon small 
                            :color="!item.announcement_is_read ? '#7B7B7B' : 'green'" class="mr-1">
                            {{ !item.announcement_is_read ? 'mdi-eye-off-outline' : 'mdi-eye-check-outline' }}
                          </v-icon>
                          {{ !item.announcement_is_read ? 'Belum Dibaca' : 'Dibaca' }}
                        </div>
                      </v-card-title>

                      <v-card-subtitle class="text-second" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        {{ item.announcement_update_datetime | datetime }}
                      </v-card-subtitle>

                      <v-card-text class="body-1 font-weight-light" :class="$route.name === 'collection-streaming-id' ? 'py-0' : 'pa-0'">
                        <span class="line-text-second two-line" v-html="item.announcement_content_jsonobject.value"></span>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-skeleton-loader>

                <v-card-text class="text-center" v-if="pagination.total_data > 5 && limit < pagination.total_data">
                  <v-btn
                    small
                    elevation="0"
                    color="#f1f1f1"
                    class="caption text-second text-capitalize"
                    @click="limit += 5">
                    Lihat lebih banyak (+{{ pagination.total_data - limit}})
                    <v-icon small color="#7B7B7B" class="ml-1">mdi-chevron-down</v-icon>
                  </v-btn>
                </v-card-text>
              </v-col>
            </v-row>

            <v-row class="py-12" v-if="!process.run && pagination.total_page < 1">
              <v-col>
                <v-img 
                  class="mx-auto"
                  contain
                  width="150"
                  height="150"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Belum ada Pengumuman!
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk cek kelas lain yang menarik.
                </p>

                <div class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    to="/elearning">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Cari Kelas
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          announcement: false
        },
        filter: {
          title: ''
        },
        announcement: {},
        list: [],
        limit: 5,
        pagination: {},
        process: {
          run: false,
          readsall: false
        },
        error: {
          message: ''
        }
      }
    },
    watch: {
      'limit': function() {
        this.fetch(true)
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(type) {
        this.process.run = type === undefined ? true : false

        let params = {
          limit: this.limit,
          sort: 'announcement_update_datetime',
          dir: 'DESC',
          id_kelas: this.detail.kelas_id,
          filter: [
            {
              type: 'string',
              field: 'announcement_title',
              value: this.filter.title,
              comparison: '<>'
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/class/announcement`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.list = response.data.results
            this.pagination = response.data.pagination
          }
        })
      },
      async fetchAll() {
        let params = {
          id_kelas: this.detail.kelas_id
        }

        const data =  await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/class/announcement`, { params })
        
        if (data.status === 200) {
          let id = data.data.results.map((obj) => {
            return parseInt(obj.announcement_id)
          })

          return id
        }

      },
      async reads(item) {
        let forms = [parseInt(item.announcement_id)]

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/class/announcement/read`, {
          item: JSON.stringify(forms)
        })
        .then((response) => {
          if (response.status === 200) {
            this.dialog.announcement = true
            this.announcement = item

            this.fetch(true)
          }
        })
      },
      async readsall() {
        this.process.readsall = true

        let id = await this.fetchAll()
        
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/class/announcement/read`, {
          item: JSON.stringify(id)
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.readsall = false

            this.fetch(true)
          }
        })
      }
    }
  }
</script>