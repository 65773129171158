<template>
  <div class="py-12">
    <section>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12" md="6">
            <v-card
              class="border-radius box-shadow pa-12 mt-12">
              <v-row justify="center" align="center">
                <v-col cols="12" md="10">
                  <v-card-title class="justify-center py-12">
                    <v-avatar
                      color="#f0f0f0"
                      width="250"
                      height="250">
                      <v-icon style="font-size: 150px;" color="#ff3177">
                        {{ onPayment === 'finish' ? 'mdi-check-bold' : onPayment === 'unfinish' ? 'mdi-clock-outline' : 'mdi-close-thick' }}
                      </v-icon>
                    </v-avatar>
                  </v-card-title>
                  <v-card-text class="body-1 font-weight-bold text-center text-first">
                    <span v-if="onPayment === 'finish'">
                      Pembayaran Anda Berhasil. 
                    </span>
                    <span v-if="onPayment === 'unfinish'">
                      Pembayaran Anda Belum Selesai. 
                    </span>
                    <span v-if="onPayment === 'error'">
                      Periksa Pembayaran Anda. 
                    </span>
                  </v-card-text>
                  <v-card-text class="body-1 font-weight-light text-center text-second line-text-first pt-0 pb-5">
                    <span v-if="onPayment === 'finish'">
                      Pembayaran anda telah masuk ke sistem, silahkan menunggu konfirmasi dari Bidan Kita.
                    </span>
                    <span v-if="onPayment === 'unfinish'">
                      Silahkan melakukan pembayaran untuk mendapatkan layanan konten premium dari Bidan Kita. 
                    </span>
                    <span v-if="onPayment === 'error'">
                      Terjadi kesalahan pada pembayaran anda ke Bidan Kita, silahkan coba kembali. 
                    </span>
                  </v-card-text>
                  <v-card-text class="text-center pb-12">
                    <v-btn
                      rounded
                      width="200"
                      color="background-first"
                      elevation="3"
                      class="body-2 white--text text-capitalize"
                      to="/dashboard/transaction">
                      Kembali Ke Transaksi
                    </v-btn>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    data () {
      return {
        onPayment: '',
        // SEO
        content: {
          url: 'payment/:slug',
          title: 'Pembayaran Kelas E-Learning',
          description: 'Pembayaran Kelas E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png'),
        }
      }
    },
    head () {
      this.content.url = `payment/${this.$route.params.slug}`
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {
      
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.onPayment = this.$route.params.slug
    },
    methods: {

    }
  }
</script>