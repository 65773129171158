<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex v-if="process.run && Object.keys(detail).length < 1">
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-skeleton-loader
                  type="image, article, article, list-item-avatar-three-line, article, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="4">
                <v-skeleton-loader
                  type="article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>

      <v-flex v-if="!process.run &&  Object.keys(detail).length > 0">
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <p class="title one-line">
                  {{ detail.kelas_nama }}
                </p>

                <v-card 
                  flat
                  color="black"
                  :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" 
                  style="border-radius: 15px;"
                  class="mb-3">
                  <Players :player="player.media" :play="player.data" :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" />
                </v-card>

                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2"
                    style="min-width: 55px; max-width: 95px;">
                    <span class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="selected.menu">
                  <v-tab-item
                    v-for="(item, index) in menu"
                    :key="index">
                    <v-card v-if="rps === 'xl' || rps === 'lg' || rps === 'md'"
                      flat
                      class="py-6">
                      <Information :detail="detail" v-if="selected.menu === 0" />
                      <Discus :detail="detail" v-if="selected.menu === 1" />
                      <Announcement :detail="detail" v-if="selected.menu === 2" />
                      <Review :detail="detail" v-if="selected.menu === 3" />
                      <Subscribe :detail="detail" v-if="selected.menu === 4" />
                    </v-card>

                    <v-card v-if="rps === 'sm' || rps === 'xs'"
                      flat
                      :class="selected.menu !== 1 ? 'py-6' : ''">
                      <Information :detail="detail" v-if="selected.menu === 0" />
                      <Discus :detail="detail" v-if="selected.menu === 2" />
                      <Announcement :detail="detail" v-if="selected.menu === 3" />
                      <Review :detail="detail" v-if="selected.menu === 4" />
                      <Subscribe :detail="detail" v-if="selected.menu === 5" />
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>

              <v-col cols="12" md="4" v-if="rps === 'xl' || rps === 'lg' || rps === 'md' || selected.menu === 1">
                <v-card flat style="position: sticky; top: 90px;">
                  <v-list dense class="py-0" color="transparent">
                    <v-list-item class="px-0">
                      <v-list-item-content class="pt-0">
                        <v-list-item-title class="title font-weight-bold text-capitalize">
                          <p class="text-first mb-0">
                            <span class="color-first">
                              Materi
                            </span>
                            Kelas
                          </p>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action class="mt-0">
                        <div style="background: #f0f0f0; border-radius: 5px; padding: 5px 15px;">
                          <span class="body-1 font-weight-bold">
                            {{ detail.kelas_total_materi }}
                          </span>
                          <span class="body-2 text-second">
                            Materi
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider style="border-color: rgb(0 0 0 / 8%);"/>

                  <v-expansion-panels flat v-model="selected.panel">
                    <v-expansion-panel
                      v-for="(item, index) in detail.kelas_materi_jsonarray" 
                      :key="index">
                      <v-expansion-panel-header class="pa-0">
                        <v-list dense class="py-0" color="transparent">
                          <v-list-item class="py-0 pl-0">
                            <v-list-item-content :style="rps !== 'xs' ? 'width: 250px;' : ''">
                              <span class="body-1 font-weight-bold text-capitalize"
                                :class="selected.panel !== index ? 'one-line' : ''">
                                {{ item.title }}
                              </span>
                            </v-list-item-content>

                            <v-list-item-action>
                              <div style="border-radius: 5px; padding: 5px 15px;"
                                :style="item.materi_is_read ? 'background: #FF3177;' : 'background: #f0f0f0;'">
                                <span class="body-1 font-weight-bold"
                                  :class="item.materi_is_read ? 'white--text' : ''">
                                  {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                                </span>
                                <span class="body-2" :class="item.materi_is_read ? 'white--text' : 'text-second'">
                                  Materi
                                </span>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <template v-slot:actions>
                          <v-icon color="#FF3177">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content class="pa-0">
                        <v-divider style="border-color: rgb(0 0 0 / 8%);"/>

                        <v-list flat three-line dense class="py-0" color="transparent">
                          <v-list-item-group color="#FF3177">
                            <v-list-item class="px-0" color="#FF3177"
                              v-if="item.total_sub_materi < 1"
                              @click="item.content_jsonobject.type !== '' ? playing(item) : ''">
                              <v-list-item-avatar color="transparent" size="24">
                                <v-icon v-if="item.content_jsonobject.type === ''"
                                  color="#7B7B7B">
                                  mdi-pound-box
                                </v-icon>

                                <!-- <v-icon v-if="item.content_jsonobject.type !== ''"
                                  :color="
                                    item.materi_is_read && item.id === player.data.id ? 'green' : 
                                    item.materi_is_read && item.id !== player.data.id ? '#FF3177' : '#cacaca'">
                                  mdi-checkbox-marked
                                </v-icon> -->

                                <v-icon v-if="item.content_jsonobject.type !== ''"
                                  :color="
                                    item.materi_is_read && item.id === player.data.id && player.media.is_play ? 'green' : 
                                    (item.materi_is_read && item.id === player.data.id && !player.media.is_play) ||
                                    (item.materi_is_read && item.id !== player.data.id && !player.media.is_play) ||
                                    (item.materi_is_read && item.id !== player.data.id && player.media.is_play)
                                      ? '#FF3177' : '#cacaca'">
                                  mdi-checkbox-marked
                                </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <div class="d-flex align-start">
                                  <div class="mr-4">
                                    <v-img 
                                      width="60"
                                      height="60"
                                      class="d-flex align-center justify-center"
                                      gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                      :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                      style="border-radius: 5px;">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#FF3177">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>

                                      <a v-if="item.content_jsonobject.type === 'docs'"
                                        :href="item.content_jsonobject.value.url"
                                        target="_blank"
                                        class="d-flex align-center justify-center">
                                        <v-icon color="white">mdi-download</v-icon>
                                      </a>
                                    </v-img>
                                  </div>

                                  <v-hover v-slot="{ hover }">
                                    <div class="body-2">
                                      <p class="font-weight-bold text-capitalize mb-2" v-if="item.deskripsi === ''">
                                        <span :class="!hover ? 'one-line' : 'line-text-first'">{{ item.title }}</span>
                                      </p>

                                      <p class="font-weight-light line-text-first text-second mb-0">
                                        <span :class="!hover ? 'three-line' : 'line-text-second'" v-html="item.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                      </p>
                                    </div>
                                  </v-hover>
                                </div>
                              </v-list-item-content>

                              <v-list-item-action v-if="item.content_jsonobject.type !== ''">
                                <v-icon v-if="item.id !== player.data.id || !player.media.is_play"
                                  color="#FF3177">
                                  {{ 
                                    item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                    item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                    item.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                  }}
                                </v-icon>

                                <v-icon v-else
                                  color="#333333">
                                  mdi-stop-circle-outline
                                </v-icon>

                                <p class="caption text-capitalize color-first mb-0"
                                  :class="item.id !== player.data.id || !player.media.is_play ? 'color-first' : 'text-first'">
                                  <span v-if="item.content_jsonobject.type !== 'docs'">
                                    {{ item.id !== player.data.id || !player.media.is_play ? 'play' : 'stop' }}
                                  </span>

                                  <span v-else>
                                    {{ item.id !== player.data.id || !player.media.is_play ? 'baca' : 'tutup' }}
                                  </span>
                                </p>
                              </v-list-item-action>
                            </v-list-item>

                            <div v-if="item.total_sub_materi > 0">
                              <v-list-item class="px-0 mt-2" 
                                v-for="(sub, key) in item.sub_materi" :key="key"
                                @click="sub.content_jsonobject.type !== '' ? playing(sub) : ''">
                                <v-list-item-avatar color="transparent" size="24">
                                  <v-icon v-if="sub.content_jsonobject.type === ''"
                                    color="#7B7B7B">
                                    mdi-pound-box
                                  </v-icon>

                                  <!-- <v-icon v-if="sub.content_jsonobject.type !== ''"
                                    :color="
                                      sub.materi_is_read && sub.id === player.data.id ? 'green' : 
                                      sub.materi_is_read && sub.id !== player.data.id ? '#FF3177' : '#cacaca'">
                                    mdi-checkbox-marked
                                  </v-icon> -->

                                  <v-icon v-if="sub.content_jsonobject.type !== ''"
                                    :color="
                                      sub.materi_is_read && sub.id === player.data.id && player.media.is_play ? 'green' : 
                                      (sub.materi_is_read && sub.id === player.data.id && !player.media.is_play) ||
                                      (sub.materi_is_read && sub.id !== player.data.id && !player.media.is_play) ||
                                      (sub.materi_is_read && sub.id !== player.data.id && player.media.is_play)
                                       ? '#FF3177' : '#cacaca'">
                                    mdi-checkbox-marked
                                  </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <div class="d-flex align-start">
                                    <div class="mr-4">
                                      <v-img 
                                        width="60"
                                        height="60"
                                        class="d-flex align-center justify-center"
                                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                        :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>

                                        <a v-if="sub.content_jsonobject.type === 'docs'"
                                          :href="sub.content_jsonobject.value.url"
                                          target="_blank"
                                          class="d-flex align-center justify-center">
                                          <v-icon color="white">mdi-download</v-icon>
                                        </a>
                                      </v-img>
                                    </div>

                                    <v-hover v-slot="{ hover }">
                                      <div class="body-2">
                                        <p class="font-weight-bold text-capitalize mb-2">
                                          <span :class="!hover ? 'one-line' : 'line-text-first'">{{ sub.title }}</span>
                                        </p>

                                        <p class="font-weight-light line-text-first text-second mb-0">
                                          <span :class="!hover ? 'two-line' : 'line-text-second'" v-html="sub.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                        </p>
                                      </div>
                                    </v-hover>
                                  </div>
                                </v-list-item-content>

                                <v-list-item-action v-if="sub.content_jsonobject.type !== ''">
                                  <v-icon v-if="sub.id !== player.data.id || !player.media.is_play"
                                    color="#FF3177">
                                    {{ 
                                      sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                      sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                      sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                    }}
                                  </v-icon>

                                  <v-icon v-else
                                    color="#333333">
                                    mdi-stop-circle-outline
                                  </v-icon>

                                  <p class="caption text-capitalize color-first mb-0"
                                    :class="sub.id !== player.data.id || !player.media.is_play ? 'color-first' : 'text-first'">
                                    <span v-if="sub.content_jsonobject.type !== 'docs'">
                                      {{ sub.id !== player.data.id || !player.media.is_play ? 'play' : 'stop' }}
                                    </span>

                                    <span v-else>
                                      {{ sub.id !== player.data.id || !player.media.is_play ? 'baca' : 'tutup' }}
                                    </span>
                                  </p>
                                </v-list-item-action>
                              </v-list-item>
                            </div>
                          </v-list-item-group>
                        </v-list>
                      </v-expansion-panel-content>

                      <v-divider style="border-color: rgb(0 0 0 / 8%);" v-if="index < detail.kelas_materi_jsonarray.length - 1"/>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section :class="!process.other && list.length > 0 ? 'mb-16' : ''">
          <v-container>
            <v-row v-if="!process.other && list.length > 0">
              <v-col cols="12">
                <p class="title font-weight-bold mb-0">
                  <span class="color-first mr-1">
                    Kelas
                  </span>
                  Lainnya
                </p>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" md="3" sm="6"
                v-for="(item, index) in process.other ? 4 : list"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.other"
                  transition="scale-transition"
                  :tile="false"
                  type="card">
                  <div>
                    <v-card v-if="!process.other && list.length > 0"
                      class="border-radius box-shadow"
                      :to="item.kelas_is_purchased === '0' ? `/elearning/${item.kelas_slug}` : `/collection/elearning/${item.kelas_id}`">
                      <v-img
                        width="100%"
                        height="220"
                        :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#FF3177">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div class="burst" v-if="item.kelas_is_discount">
                        <span>
                          OFF {{ item.kelas_diskon_jsonobject.percent }}%
                        </span>
                      </div>

                      <div class="burst_free" v-if="!item.kelas_berbayar">
                        <span>
                          FREE
                        </span>
                      </div>

                      <div class="pa-2">
                        <v-card-text>
                          <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                            <span class="two-line">
                              {{ item.kelas_nama }}
                            </span>
                          </p>
                          <p class="body-2 text-second mb-0">
                            <span class="one-line">
                              {{ item.kelas_kategori_title }}
                            </span>
                          </p>
                        </v-card-text>

                        <v-card-text>
                          <div class="d-flex align-center">
                            <div class="d-flex align-center">
                              <v-rating
                                :value="parseFloat(item.kelas_total_rating)"
                                background-color="yellow darken-2"
                                color="yellow darken-2"
                                dense
                                disabled
                                size="18">
                              </v-rating>
                              <span class="text-second caption font-weight-bold ml-1">
                                ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                              </span>
                            </div>

                            <v-spacer/>

                            <div class="text-second">
                              <span class="font-weight-bold">
                                {{ item.kelas_total_materi }}
                              </span>
                              <span class="body-2">
                                Materi
                              </span>
                            </div>
                          </div>
                        </v-card-text>

                        <v-divider class="mb-2 mx-4"/>

                        <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '1'">
                          <div class="color-first">
                            Selengkapnya
                          </div>

                          <v-spacer/>

                          <div class="color-first">
                            <v-icon small color="#FF3177">mdi-arrow-right</v-icon>
                          </div>
                        </v-card-actions>

                        <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && !item.kelas_is_discount">
                          <div class="green--text font-weight-bold" v-if="!item.kelas_berbayar">
                            Gratis
                          </div>

                          <div class="color-first" v-else>
                            <span class="font-weight-bold">
                              {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                            </span> 
                            <span class="caption text-second">
                              /
                              <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                              </span>

                              <span v-else>
                                Lifetime
                              </span>
                            </span>
                          </div>
                        </v-card-actions>

                        <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && item.kelas_is_discount">
                          <div class="body-2 text-second" v-if="item.kelas_is_discount">
                            <span class="text-decoration-line-through">
                              {{ parseInt(item.kelas_harga_jsonobject.value) | price }}
                            </span>
                          </div>

                          <v-spacer/>

                          <div class="color-first">
                            <span class="font-weight-bold">
                              {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                            </span> 
                            <span class="caption text-second">
                              /
                              <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                              </span>

                              <span v-else>
                                Lifetime
                              </span>
                            </span>
                          </div>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row class="mt-6" v-if="!process.other && pagination.total_page > 1">
              <v-col cols="12" class="text-right">
                <nuxt-link to="/elearning" class="color-first">
                  <span>
                    Lihat kelas lainnya
                  </span>
                  <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                </nuxt-link>
              </v-col>
            </v-row>

            <!-- <v-row justify="center" align="center" v-if="!process.other && pagination.total_page < 1">
              <v-col cols="12" class="d-flex align-center my-12">
                <v-img 
                  class="my-12"
                  contain
                  width="150"
                  height="150"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row> -->
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    data () {
      return {
        filter: {
          title: ''
        },
        selected: {
          panel: 0,
          menu: 0
        },
        detail: {},
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        list: [],
        pagination: {},
        process: {
          run: false,
          other: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'collection/:slug',
          title: 'E-Learning Bidan Kita',
          description: 'E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Information: () => import('@/components/collection/information'),
      Announcement: () => import('@/components/collection/announcement'),
      Review: () => import('@/components/collection/review'),
      Subscribe: () => import('@/components/collection/subscribe'),
      Discus: () => import('@/components/collection/discus'),
      Players: () => import('@/components/Player')
    },
    computed: {
      menu() {
        let menu = [
          {
            label: 'Tentang Kelas',
            icon: 'mdi-book-information-variant'
          },
          {
            label: 'Diskusi',
            icon: 'mdi-forum'
          },
          {
            label: 'Pengumuman',
            icon: 'mdi-bullhorn'
          },
          {
            label: 'Ulasan',
            icon: 'mdi-star-half-full'
          },
          {
            label: 'Langganan',
            icon: 'mdi-credit-card-outline'
          }
        ]

        if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
          menu.splice(1, 0, {
            label: 'Materi',
            icon: 'mdi-bookshelf'
          })
        }

        return menu
      },
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      },
      headers() {
        return this.$store.state.header
      }
    },
    beforeCreate () {
      let params = {
        id: this.$route.params.id
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/class/classes_collection/class_detail`, { params })
      .then((response) => {
        this.content = {
          url: `collection/${response.data.kelas_id}`,
          title: response.data.kelas_nama,
          description: response.data.kelas_deskripsi,
          image: response.data.kelas_image_jsonobejct.kelas_image_cover_url
        }
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/classes_collection/class_detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data

            if (type === undefined) {
              this.isFirst()
              this.fetchOther()
            }
          }
        })
      },
      isFirst() {
        if (Object.keys(this.detail.kelas_materi_last_read_jsonobject).length < 1) {
          let item = this.detail.collection_kelas_playlist_jsonarray[0]
          this.player.media = {
            type: item.content_jsonobject.type,
            is_play: true
          }
          this.player.data = item

          this.reads()
        } else {
          let item = this.detail.kelas_materi_last_read_jsonobject
          item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail
          this.player.media = {
            type: item.content_jsonobject.type,
            is_play: true
          }
          this.player.data = item
          
          let last = this.detail.kelas_materi_jsonarray.findIndex((obj, index) => {
            if (this.detail.kelas_materi_last_read_jsonobject.par_id === '0') {
              return this.detail.kelas_materi_last_read_jsonobject.id === obj.id ? index : 0
            } else {
              return this.detail.kelas_materi_last_read_jsonobject.par_id === obj.id ? index : 0
            }
          })

          this.selected.panel = last < 0 ? 0 : last
        }
      },
      playing(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        let status = false
        if (item.id === this.player.data.id) {
          if(!this.player.media.is_play) {
            status = true
          } else {
            status = false
          }
        } else {
          status = true
        }

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: status
        }
        this.player.data = item

        this.reads()
      },
      async reads() {
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/class/classes_collection/last_read`, {
          id: this.player.data.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.fetch(true)

            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }
        })
      },
      async fetchOther() {
        this.process.other = true
        
        let params = {
          limit: 4,
          slug_kelas: this.detail.kelas_slug,
          id_kategori: this.detail.kelas_kategori_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/recommend`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.other = false
            this.list = response.data.results
            this.pagination = response.data.pagination
          }
        })
      }
    }
  }
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>