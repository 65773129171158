<template>
  <div class="fill-height py-12 mt-6">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Kontak Kami
              </v-col>
            </v-row>
            
            <v-row justify="center">
              <v-col cols="12" md="10" sm="10">
                <v-card flat>
                  <v-card-text class="body-1 font-weight-light line-text-second text-first">
                    <p class="mb-2">
                      Alamat
                    </p>

                    <p class="mb-2">
                      Jl. Piere Tendean No. 20 RT I RW VII Sikenong, Kel. Sidowayah, Kec. Klaten Tengah, Jawa Tengah 57413
                    </p>

                    <p class="mb-2">
                      Hotline (24 Jam) +62 813-4623-3500
                    </p>

                    <p class="mb-0">
                      Admin +62 851-001-1884
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        // SEO
        content: {
          url: 'site/contactus',
          title: 'Kontak Bidan Kita',
          description: 'Kontak Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>