<template>
  <div class="fill-height" :class="rps === 'sm' || rps === 'xs' ? 'mt-8' : 'mt-16 pt-12'">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="dialog.share"
      width="375"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="body-1 font-weight-bold text-second">
              Share {{ basic.kelas_nama }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third">
              Klik icon di bawah untuk share event ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in list.sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                :network="item.network"
                :url="`${host}${$route.path}`"
                :title="basic.kelas_slug"
                :description="basic.kelas_nama"
                hashtags="e-learning,bidankita">
                <v-img
                  width="45"
                  height="45"
                  contain
                  alt="e-learning"
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#333333"
            elevation="3"
            class="body-2 font-weight-bold white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.special"
      width="955"
      persistent
      scrollable>
      <v-card
        flat
        class="border-radius pa-2">
        <v-card-title class="pa-0 d-flex align-center" v-if="list.special.elearning.length > 0 || list.special.live.length > 0">
          <div class="title text-first pl-6">
            Special
            <span class="color-first ml-1">
              Price
            </span>
          </div>
          
          <v-spacer/>

          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="closed('not-cookie')">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="ma-4" style="border: 2px solid #f5f5f5;" v-if="list.special.elearning.length > 0 || list.special.live.length > 0"/>

        <v-card-text 
          :style="list.special.elearning.length > 0 && list.special.live.length > 0 ? 'height: 750px;' : ''"
          :class="list.special.elearning.length < 1 && list.special.live.length < 1 ? 'pb-0' : ''">
          <div class="d-flex align-center" v-if="list.special.elearning.length > 0">
            <div class="title">
              Kelas Elearning
            </div>

            <v-spacer/>

            <div v-if="!process.special.elearning && list.special.elearning.length > 5">
              <v-btn
                elevation="0"
                rounded
                text
                color="#FF3177"
                to="/elearning/special"
                class="body-1 text-capitalize">
                Lihat Semua {{ pagination.special.elearning.total_data }}
                <v-icon small class="ml-3">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>

          <no-ssr>
            <swiper class="swiper" 
              ref="swiperSpecialElearning"
              :options="swiperOption" 
              @slideChange="onSlideChangeSpecialElearning">
              <swiper-slide v-for="(item, index) in process.special.elearning ? 6 : list.special.elearning" :key="index" 
                class="py-8 px-3" style="width: auto;">
                <v-skeleton-loader
                  class="ma-auto"
                  width="275"
                  :loading="process.special.elearning"
                  type="card, article">
                  <div>
                    <v-hover
                      v-slot="{ hover }"
                      open-delay="200">
                      <div>
                        <v-card v-if="!process.special.elearning && list.special.elearning.length > 0"
                          class="border-radius box-shadow"
                          width="275"
                          :to="item.kelas_is_purchased === '0' ? `/elearning/${item.kelas_slug}` : `/collection/elearning/${item.kelas_id}`">
                          <v-img
                            width="100%"
                            height="220"
                            :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                            
                            <v-chip v-if="item.kelas_is_special_price"
                              small
                              class="font-weight-bold white--text px-2 ma-2"
                              color="primary"
                              style="position: absolute; top: 0; right: 0px;">
                              Special Price
                            </v-chip>
                          </v-img>

                          <div class="burst" v-if="item.kelas_is_discount">
                            <span>
                              OFF {{ item.kelas_diskon_jsonobject.percent }}%
                            </span>
                          </div>

                          <div class="burst_free" v-if="!item.kelas_berbayar">
                            <span>
                              FREE
                            </span>
                          </div>

                          <div class="pa-2">
                            <v-card-text style="width: auto;">
                              <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                                <span class="two-line">
                                  {{ item.kelas_nama }}
                                </span>
                              </p>
                              <p class="body-2 text-second mb-0">
                                <span class="one-line">
                                  {{ item.kelas_kategori_title }}
                                </span>
                              </p>
                            </v-card-text>

                            <v-card-text style="width: auto;">
                              <div class="d-flex align-center">
                                <div class="d-flex align-center">
                                  <v-rating
                                    :value="parseFloat(item.kelas_total_rating)"
                                    background-color="yellow darken-2"
                                    color="yellow darken-2"
                                    dense
                                    disabled
                                    size="18">
                                  </v-rating>
                                  <span class="text-second caption font-weight-bold ml-1">
                                    ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                  </span>
                                </div>

                                <v-spacer/>

                                <div class="text-second">
                                  <span class="font-weight-bold">
                                    {{ item.kelas_total_materi }}
                                  </span>
                                  <span class="body-2">
                                    Materi
                                  </span>
                                </div>
                              </div>
                            </v-card-text>

                            <v-divider class="mb-2 mx-4"/>

                            <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '1'">
                              <div class="color-first">
                                Selengkapnya
                              </div>

                              <v-spacer/>

                              <div class="color-first">
                                <v-icon small color="#FF3177">mdi-arrow-right</v-icon>
                              </div>
                            </v-card-actions>

                            <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && !item.kelas_is_discount">
                              <div class="green--text font-weight-bold" v-if="!item.kelas_berbayar">
                                Gratis
                              </div>

                              <div class="color-first" v-else>
                                <span class="font-weight-bold">
                                  {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                </span> 
                                <span class="caption text-second">
                                  /
                                  <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                    {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                    {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                  </span>

                                  <span v-else>
                                    Lifetime
                                  </span>
                                </span>
                              </div>
                            </v-card-actions>

                            <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && item.kelas_is_discount">
                              <div class="body-2 text-second" v-if="item.kelas_is_discount">
                                <span class="text-decoration-line-through">
                                  {{ parseInt(item.kelas_harga_jsonobject.value) | price }}
                                </span>
                              </div>

                              <v-spacer/>

                              <div class="color-first">
                                <span class="font-weight-bold">
                                  {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                </span> 
                                <span class="caption text-second">
                                  /
                                  <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                    {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                    {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                  </span>

                                  <span v-else>
                                    Lifetime
                                  </span>
                                </span>
                              </div>
                            </v-card-actions>
                          </div>
                        </v-card>

                        <v-expand-transition>
                          <div v-if="hover && rps !== 'xs'">
                            <v-card
                              width="100%"
                              height="475"
                              outlined
                              :class="index === 5 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>

                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>

                                <v-list flat dense>
                                  <v-list-item class="px-0">
                                    <v-list-item-avatar size="30" class="mr-2">
                                      <v-img :src="item.kelas_author.kelas_author_photo">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.kelas_author.kelas_author_nama }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>

                                <div style="height: 230px;">
                                  <p class="body-2 font-weight-light text-first line-text-first">
                                    <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                  </p>

                                  <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                    <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                    Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                  </p>
                                </div>
                              </v-card-text>

                              <v-card-text class="d-flex align-center justify-center"
                                style="position: absolute; bottom: 10px; width: auto;">
                                <v-btn v-if="item.kelas_is_purchased === '0'"
                                  rounded
                                  width="160"
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize white--text mr-2"
                                  :disabled="process.cart"
                                  :loading="process.cart"
                                  @click="addToCart(item.kelas_id)">
                                  <v-icon small class="mr-1">mdi-cart-arrow-down</v-icon>
                                  Keranjang
                                </v-btn>

                                <v-btn v-if="item.kelas_is_purchased === '1'"
                                  rounded
                                  width="160"
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize white--text mr-2"
                                  :to="`/collection/elearning/${item.kelas_id}`">
                                  Lihat Koleksi
                                </v-btn>

                                <v-btn
                                  fab
                                  width="40"
                                  height="40"
                                  elevation="0"
                                  :disabled="process.wishlist"
                                  :loading="process.wishlist"
                                  @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                    addWishlist(item.kelas_id, item.kelas_is_wishlist)">
                                  <v-icon color="#FF3177">
                                    {{ !token ? 'mdi-heart-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                                  </v-icon>
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-expand-transition>
                      </div>
                    </v-hover>
                  </div>
                </v-skeleton-loader>
              </swiper-slide>
            </swiper>
          </no-ssr>

          <div class="text-center mt-3" v-if="list.special.elearning.length > 5"> 
            <v-btn 
              v-for="i in rps !== 'xs' ? 3 : 5" 
              :key="i"
              :width="selected.special.elearning === i-1 ? 12 : 15"
              :height="selected.special.elearning === i-1 ? 17 : 15"
              elevation="0"
              x-small
              :rounded="selected.special.elearning === i-1"
              :fab="selected.special.elearning !== i-1"
              :color="selected.special.elearning === i-1 ? '#FF3177' : 'grey lighten-2'"
              class="mx-2"
              @click="onSlideChangeSpecialElearningIndex(i-1)">
            </v-btn>
          </div>

          <div class="d-flex align-center" v-if="list.special.live.length > 0">
            <div class="title">
              Kelas
            </div>

            <v-spacer/>

            <div v-if="!process.special.live && list.special.live.length > 5">
              <v-btn
                elevation="0"
                rounded
                text
                color="#FF3177"
                href="https://kelas.bidankita.com/streaming/special"
                target="_blank"
                class="body-1 text-capitalize">
                Lihat Semua {{ pagination.special.live.total_data }}
                <v-icon small class="ml-3">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>

          <no-ssr>
            <swiper class="swiper" 
              ref="swiperSpecialLive"
              :options="swiperOption" 
              @slideChange="onSlideChangeSpecialLive">
              <swiper-slide v-for="(item, index) in process.special.live ? 6 : list.special.live" :key="index" 
                class="py-8 px-3" style="width: auto;">
                <v-skeleton-loader
                  class="ma-auto"
                  width="275"
                  :loading="process.special.live"
                  type="card, article">
                  <div>
                    <v-hover
                      v-slot="{ hover }"
                      open-delay="200">
                      <div>
                        <v-card v-if="!process.special.live && list.special.live.length > 0"
                          class="border-radius box-shadow"
                          width="275"
                          :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}`"
                          target="_blank">
                          <v-img
                            width="100%"
                            height="220"
                            :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-chip v-if="item.kelas_is_special_price"
                              small
                              class="font-weight-bold white--text px-2 ma-2"
                              color="primary"
                              style="position: absolute; top: 0; right: 0px;">
                              Special Price
                            </v-chip>
                          </v-img>

                          <div class="burst" v-if="item.kelas_is_discount">
                            <span>
                              OFF {{ item.kelas_diskon_jsonobject.percent }}%
                            </span>
                          </div>

                          <!-- <div class="burst_free" v-if="!item.kelas_is_paid">
                            <span>
                              FREE
                            </span>
                          </div> -->

                          <div class="burst_free" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                            <span>
                              FREE
                            </span>
                          </div>

                          <div class="pa-2">
                            <v-card-text style="width: auto;">
                              <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                                <span class="two-line">
                                  {{ item.kelas_nama }} ({{item.schedule_start_datetime | date }})
                                </span>
                              </p>
                              <p class="body-2 text-second mb-0">
                                <span class="one-line">
                                  {{ item.kelas_kategori_title }}
                                </span>
                              </p>
                            </v-card-text>

                            <v-card-text style="width: auto;">
                              <div class="d-flex align-center">
                                <div class="d-flex align-center">
                                  <v-rating
                                    :value="parseFloat(item.kelas_total_rating)"
                                    background-color="yellow darken-2"
                                    color="yellow darken-2"
                                    dense
                                    disabled
                                    size="18">
                                  </v-rating>
                                  <span class="text-second caption font-weight-bold ml-1">
                                    ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                  </span>
                                </div>

                                <v-spacer/>

                                <div class="text-second" v-if="item.kelas_total_materi > 0">
                                  <span class="font-weight-bold">
                                    {{ item.kelas_total_materi }}
                                  </span>
                                  <span class="body-2">
                                    Materi
                                  </span>
                                </div>
                              </div>
                            </v-card-text>

                            <v-divider class="mb-2 mx-4"/>

                            <!-- <v-card-actions class="body-2 px-4">
                              <div class="color-first font-weight-bold">
                                <span v-if="item.kelas_harga_jsonobject.harga_min === item.kelas_harga_jsonobject.harga_max">
                                  {{ parseInt(item.kelas_harga_jsonobject.harga_max) | price }}
                                </span>

                                <span v-else>
                                  {{ parseInt(item.kelas_harga_jsonobject.harga_min) | price }}
                                  -
                                  {{ parseInt(item.kelas_harga_jsonobject.harga_max) | price }}
                                </span>
                              </div>
                            </v-card-actions> -->

                            <v-card-actions class="body-2 px-4" v-if="!item.kelas_is_discount">
                              <div class="green--text font-weight-bold" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                Gratis
                              </div>

                              <div class="color-first" v-else>
                                <span class="font-weight-bold">
                                  <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                    {{ parseInt(item.schedule_early_price) | price }}
                                  </span>

                                  <span v-else>
                                    {{ parseInt(item.schedule_normal_price) | price }}
                                  </span>
                                </span> 
                                <span class="caption text-second">
                                  /
                                  {{ dateNow >= item.schedule_early_price_end_datetime ? 'Normal' : 'Early' }}
                                </span>
                              </div>
                            </v-card-actions>

                            <v-card-actions class="body-2 px-4" v-if="item.kelas_is_discount">
                              <div class="body-2 text-second">
                                <span class="text-decoration-line-through">
                                  <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                    {{ parseInt(item.schedule_early_price) | price }}
                                  </span>

                                  <span v-else>
                                    {{ parseInt(item.schedule_normal_price) | price }}
                                  </span>
                                </span>
                              </div>

                              <v-spacer/>

                              <div class="color-first">
                                <span class="font-weight-bold">
                                <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                    {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                  </span>

                                  <span v-else>
                                    {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                  </span>
                                </span> 
                                <span class="caption text-second">
                                  /
                                  {{ dateNow >= item.schedule_early_price_end_datetime ? 'Normal' : 'Early' }}
                                </span>
                              </div>
                            </v-card-actions>
                          </div>
                        </v-card>

                        <v-expand-transition>
                          <div v-if="hover && rps !== 'xs'">
                            <v-card
                              width="100%"
                              height="475"
                              outlined
                              :class="index === 5 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>

                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>

                                <v-list flat dense>
                                  <v-list-item class="px-0">
                                    <v-list-item-avatar size="30" class="mr-2">
                                      <v-img :src="item.kelas_author.kelas_author_photo">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.kelas_author.kelas_author_nama }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>

                                <div style="height: 230px;">
                                  <p class="body-2 font-weight-light text-first line-text-first">
                                    <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                  </p>

                                  <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                    <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                    Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                  </p>
                                </div>
                              </v-card-text>

                              <v-card-text class="d-flex align-center justify-center"
                                style="position: absolute; bottom: 10px; width: auto;">
                                <v-btn
                                  rounded
                                  width="160"
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize white--text mr-2"
                                  :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}`"
                                  target="_blank">
                                  Lihat Jadwal
                                </v-btn>

                                <v-btn
                                  fab
                                  width="40"
                                  height="40"
                                  elevation="0"
                                  :disabled="process.wishlist"
                                  :loading="process.wishlist"
                                  @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                    addWishlist(item.kelas_id, item.kelas_is_wishlist)">
                                  <v-icon color="#FF3177">
                                    {{ !token ? 'mdi-heart-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                                  </v-icon>
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-expand-transition>
                      </div>
                    </v-hover>
                  </div>
                </v-skeleton-loader>
              </swiper-slide>
            </swiper>
          </no-ssr>

          <div class="text-center mt-3" v-if="list.special.live.length > 5"> 
            <v-btn 
              v-for="i in rps !== 'xs' ? 3 : 5" 
              :key="i"
              :width="selected.special.live === i-1 ? 12 : 15"
              :height="selected.special.live === i-1 ? 17 : 15"
              elevation="0"
              x-small
              :rounded="selected.special.live === i-1"
              :fab="selected.special.live !== i-1"
              :color="selected.special.live === i-1 ? '#FF3177' : 'grey lighten-2'"
              class="mx-2"
              @click="onSlideChangeSpecialLiveIndex(i-1)">
            </v-btn>
          </div>

          <div v-if="list.special.elearning.length < 1 && list.special.live.length < 1">
            <v-container>
              <v-divider class="mb-3" style="border: 3px solid #FF3177; width: 75px; border-radius: 10px;"/>
              <p class="text-h5 text-first">
                <span class="color-first">
                  Yuk Belajar
                </span>
                Kelas Dasar Bidan Kita
              </p>

              <v-row justify="center">
                <v-col cols="12" md="7" sm="7" :class="rps === 'xl' || rps === 'lg' ? 'pr-12' : ''">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.basic"
                    type="article, article, article">
                    <div>
                      <div v-if="!process.basic && Object.keys(basic).length > 0">
                        <p class="text-h5 text-first line-text-first mt-2 mb-6">
                          <span class="text-h4">{{ basic.kelas_nama}}</span> ({{ basic.kelas_kategori_title }})
                        </p>
                        <p class="text-first body-1 font-weight-light line-text-second mb-0">
                          <span class="five-line" v-html="basic.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                        </p>
                        
                        <div class="d-flex align-center" :class="rps === 'sm' || rps === 'xs' ? 'mt-6 mb-4' : 'mt-12'">
                          <v-btn v-if="basic.kelas_is_purchased === '0'"
                            rounded
                            width="160"
                            elevation="0"
                            color="#FF3177"
                            class="text-capitalize white--text"
                            :disabled="process.cart"
                            :loading="process.cart"
                            @click="addToCart(basic.kelas_id)">
                            <v-icon small class="mr-1">mdi-cart-arrow-down</v-icon>
                            Keranjang
                          </v-btn>

                          <v-btn v-if="basic.kelas_is_purchased === '1'"
                            rounded
                            width="160"
                            elevation="0"
                            color="#FF3177"
                            class="text-capitalize white--text"
                            :to="`/collection/elearning/${basic.kelas_id}`">
                            Lihat Koleksi
                          </v-btn>

                          <v-btn
                            fab
                            width="40"
                            height="40"
                            elevation="0"
                            :disabled="process.wishlist"
                            :loading="process.wishlist"
                            class="mx-2"
                            @click="basic.kelas_is_wishlist = basic.kelas_is_wishlist === '0' ? '1' : '0'; 
                              addWishlist(basic.kelas_id, basic.kelas_is_wishlist)">
                            <v-icon color="#FF3177">
                              {{ !token ? 'mdi-heart-outline' : basic.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                            </v-icon>
                          </v-btn>

                          <v-btn
                            fab
                            width="40"
                            height="40"
                            elevation="0"
                            @click="dialog.share = true">
                            <v-icon color="#FF3177">mdi-share-variant</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-col>

                <v-col cols="12" md="5" sm="5" :class="rps === 'xl' || rps === 'lg' ? 'pl-12' : ''">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.basic"
                    type="card, article">
                    <div>
                      <div v-if="!process.basic && Object.keys(basic).length > 0">
                        <v-card flat :to="basic.kelas_is_purchased === '0' ? `/elearning/${basic.kelas_slug}` : `/collection/elearning/${basic.kelas_id}`">
                          <v-img 
                            cover
                            width="100%"
                            height="400"
                            class="border-radius box-shadow"
                            :src="basic.kelas_image_jsonobejct.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-chip v-if="basic.kelas_is_special_price"
                              small
                              class="font-weight-bold white--text px-2 ma-2"
                              color="primary"
                              style="position: absolute; top: 0; right: 0px;">
                              Special Price
                            </v-chip>

                            <div class="caption font-weight-bold white--text text-capitalize pa-3"
                              style="
                                background: rgb(0 0 0 / 50%);
                                position: absolute;
                                bottom: 0px;
                                width: 100%;">
                              <div class="d-flex align-center">
                                <div class="d-flex align-center">
                                  <v-rating dense
                                    :value="parseFloat(basic.kelas_total_rating)"
                                    size="25"
                                    readonly
                                    color="yellow darken-2"
                                    background-color="yellow darken-2">
                                  </v-rating>
                                  <span class="white--text body-1 font-weight-bold ml-1">
                                    ({{ parseFloat(basic.kelas_total_rating).toFixed(1) }})
                                  </span>
                                </div>

                                <v-spacer/>

                                <div class="white--text body-1">
                                  <span class="font-weight-bold">
                                    {{ basic.kelas_total_materi }}
                                  </span>
                                  <span class="body-1">
                                    Materi
                                  </span>
                                </div>
                              </div>

                              <v-divider color="white" class="my-3" style="opacity: 50%;"/>

                              <div class="title px-1" v-if="!basic.kelas_is_discount">
                                <div class="white--text font-weight-bold" v-if="!basic.kelas_berbayar">
                                  Gratis
                                </div>

                                <div class="white--text" v-else>
                                  <span class="font-weight-bold">
                                    {{ parseInt(basic.kelas_harga_jsonobject.value_after_discount) | price }}
                                  </span> 
                                  <span class="body-2 white--text">
                                    /
                                    <span v-if="!basic.kelas_harga_jsonobject.is_lifetime">
                                      {{ basic.kelas_harga_jsonobject.subcribe_value }} 
                                      {{ basic.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : basic.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                    </span>

                                    <span v-else>
                                      Lifetime
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div class="title d-flex align-center px-1" v-if="basic.kelas_is_discount">
                                <div class="body-1 white--text" v-if="basic.kelas_is_discount">
                                  <span class="text-decoration-line-through">
                                    {{ parseInt(basic.kelas_harga_jsonobject.value) | price }}
                                  </span>
                                </div>

                                <v-spacer/>

                                <div class="white--text">
                                  <span class="font-weight-bold">
                                    {{ parseInt(basic.kelas_harga_jsonobject.value_after_discount) | price }}
                                  </span> 
                                  <span class="body-2 white--text">
                                    /
                                    <span v-if="!basic.kelas_harga_jsonobject.is_lifetime">
                                      {{ basic.kelas_harga_jsonobject.subcribe_value }} 
                                      {{ basic.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : basic.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                    </span>

                                    <span v-else>
                                      Lifetime
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </v-img>

                          <div class="burst" v-if="basic.kelas_is_discount">
                            <span>
                              OFF {{ basic.kelas_diskon_jsonobject.percent }}%
                            </span>
                          </div>

                          <div :class="rps === 'xs' ? 'burst_free' : 'burst_free_large'" v-if="!basic.kelas_berbayar">
                            <span>
                              FREE
                            </span>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <v-divider class="ma-4" style="border: 2px solid #f5f5f5;" v-if="list.special.elearning.length < 1 && list.special.live.length < 1"/>
        </v-card-text>

        <div :class="list.special.elearning.length < 1 && list.special.live.length < 1 ? 'pa-10 pt-1 pb-3' : 'pa-5'">
          <div class="text-right">
            <v-btn
              elevation="0"
              color="#FF3177"
              class="text-capitalize white--text"
              @click="closed('cookie')">
              Jangan Tampilkan Lagi
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <div class="d-flex align-center">
            <no-ssr>
              <carousel-3d 
                ref="carousel3dBanner"
                :autoplay="false"
                :perspective="0"
                :border="0"
                :space="1430"
                :width="1150"
                :height="rps !== 'xs' ? 500 : 650"
                :count="list.banner.length"
                :display="3"
                @after-slide-change="onAfterSlideChange">
                <!-- <v-btn v-if="rps === 'xl' || rps === 'lg' || rps === 'md'"
                  style="border: 4px solid white; position: absolute; top: 225px; left: -30px; z-index: 9999;"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="goToSlidePrev(selected.banner)">
                  <v-icon large color="white">mdi-chevron-left</v-icon>
                </v-btn> -->

                <slide v-for="(item, index) in process.banner ? 3 : list.banner" :key="index" :index="index"
                  style="background: none;"
                  :style="rps !== 'sm' && rps !== 'xs' ? 'border-radius: 20px;' : 'border-radius: 0px;'">
                  <v-skeleton-loader
                    class="ma-auto skeleton-banner"
                    :loading="process.banner"
                    type="image">
                    <div> 
                      <v-card v-if="!process.banner && list.banner.length > 0"
                        :height="rps === 'sm' ? 325 : rps === 'xs' ? 200 : 500"
                        color="transparent"
                        flat
                        :style="rps !== 'sm' && rps !== 'xs' ? 'border-radius: 10px;' : 'border-radius: 0px;'">
                        <v-img v-if="item.banner_content_jsonobject.file.type === 'image'"
                          class="ma-auto"
                          :height="rps === 'sm' ? 325 : rps === 'xs' ? 200 : 500"
                          :class="rps !== 'sm' && rps !== 'xs' ? 'pa-16' : 'pa-6'"
                          :gradient="selected.banner === index ? 'to left, rgba(225,225,225,0), rgba(0,0,0,0.75)' : ''"
                          :src="item.banner_content_jsonobject.file.value.url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <v-row align="center" :class="selected.banner === index ? 'white--text' : ''" 
                            :style="rps !== 'sm' && rps !== 'xs' ? 'height: 375px;' : 'height: 100px;'">
                            <v-col cols="12" md="7">
                              <p class="body-1 font-weight-light text-capitalize mb-6">
                                Yesie Aprillia
                              </p>

                              <p class="text-capitalize" 
                                :class="rps !== 'xs' ? 'text-h5 line-text-first' : 'body-1 font-weight-bold line-text-second'"
                                :style="rps !== 'xs' ? 'height: 75px;' : 'height: 60px;'">
                                <span class="two-line">
                                  {{ item.banner_title }}
                                </span>
                              </p>

                              <p v-if="item.banner_content_jsonobject.desc !== '' && (rps !== 'sm' && rps !== 'xs')"
                                class="body-1 font-weight-light line-text-second mt-6 mb-12" 
                                style="height: 100px;">
                                <span class="three-line">
                                  {{ item.banner_content_jsonobject.desc }}
                                </span>
                              </p>

                              <v-btn v-if="item.banner_content_jsonobject.action !==''"
                                elevation="0"
                                rounded
                                color="white"
                                class="body-2 text-capitalize color-first"
                                :to="`/elearning/${item.banner_content_jsonobject.action_data.kelas_slug}`">
                                Selengkapnya
                                <v-icon small class="ml-3" color="#FF3177">mdi-arrow-right</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-img>

                        <v-img v-if="item.banner_content_jsonobject.file.type !== 'image' && (selected.player === '' || selected.player_index !== index)"
                          class="ma-auto"
                          :height="rps === 'sm' ? 325 : rps === 'xs' ? 200 : 500"
                          :class="rps !== 'sm' && rps !== 'xs' ? 'pa-16' : 'pa-6'"
                          :gradient="selected.banner === index ? 'to left, rgba(225,225,225,0), rgba(0,0,0,0.75)' : ''"
                          :src="item.banner_content_jsonobject.thumbnail">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <v-row align="center" :class="selected.banner === index ? 'white--text' : ''"
                            :style="rps !== 'sm' && rps !== 'xs' ? 'height: 375px;' : 'height: 100px;'">
                            <v-col cols="12" md="7">
                              <p class="body-1 font-weight-light text-capitalize mb-6">
                                Yesie Aprillia
                              </p>

                              <p class="line-text-first text-capitalize" 
                                :class="rps !== 'xs' ? 'text-h5 line-text-first' : 'body-1 font-weight-bold line-text-second'"
                                :style="rps !== 'xs' ? 'height: 75px;' : 'height: 60px;'">
                                <span class="two-line">
                                  {{ item.banner_title }}
                                </span>
                              </p>

                              <p v-if="item.banner_content_jsonobject.desc !== '' && (rps !== 'sm' && rps !== 'xs')"
                                class="body-1 font-weight-light line-text-second mt-6 mb-12" 
                                style="height: 100px;">
                                <span class="three-line">
                                  {{ item.banner_content_jsonobject.desc }}
                                </span>
                              </p>

                              <div class="d-flex align-center">
                                <div>
                                  <v-btn v-if="item.banner_content_jsonobject.action !==''"
                                    elevation="0"
                                    rounded
                                    color="white"
                                    class="body-2 text-capitalize color-first"
                                    :to="`/elearning/${item.banner_content_jsonobject.action_data.kelas_slug}`">
                                    Selengkapnya
                                    <v-icon small class="ml-3" color="#FF3177">mdi-arrow-right</v-icon>
                                  </v-btn>
                                </div>

                                <v-spacer/>

                                <div v-if="rps === 'sm' || rps === 'xs'">
                                  <v-btn
                                    elevation="0"
                                    color="white"
                                    fab
                                    width="50"
                                    height="50"
                                    @click="selected.player = item.banner_content_jsonobject.file.type; selected.player_index = index;">
                                    <v-icon size="25" color="#FF3177">mdi-play</v-icon>
                                  </v-btn>
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="12" md="5" class="d-flex align-enter justify-center" v-if="rps !== 'sm' && rps !== 'xs'">
                              <v-btn
                                elevation="0"
                                color="white"
                                fab
                                width="90"
                                height="90"
                                @click="selected.player = item.banner_content_jsonobject.file.type; selected.player_index = index;">
                                <v-icon size="50" color="#FF3177">mdi-play</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-img>

                        <div v-if="item.banner_content_jsonobject.file.type === 'video' && selected.player === 'video' && selected.player_index === index">
                          <video
                            controls 
                            width="100%" 
                            height="500"
                            style="object-fit: cover;"
                            :poster="item.banner_content_jsonobject.file.thumbnail">
                            <source :src="item.banner_content_jsonobject.file.value.url">
                          </video>
                        </div>

                        <div v-if="item.banner_content_jsonobject.file.type === 'youtube' && selected.player === 'youtube' && selected.player_index === index">
                          <iframe 
                            width="100%"
                            height="500"
                            :src="`https://www.youtube.com/embed/${item.banner_content_jsonobject.file.value.id}?autoplay=1`" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowtransparency
                            allowfullscreen>
                          </iframe>
                        </div>
                      </v-card>
                    </div>
                  </v-skeleton-loader>
                </slide>

                <!-- <v-btn v-if="rps === 'xl' || rps === 'lg' || rps === 'md'"
                  style="border: 4px solid white; position: absolute; top: 225px; right: -30px; z-index: 9999;"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="goToSlideNext(selected.banner)">
                  <v-icon large color="white">mdi-chevron-right</v-icon>
                </v-btn> -->
              </carousel-3d>
            </no-ssr>
          </div>

          <div class="text-center" :class="rps !== 'xs' ? 'mt-6' : 'mt-3'"> 
            <v-btn 
              v-for="(item, index) in list.banner" 
              :key="index"
              :width="selected.banner === index ? 12 : 15"
              :height="selected.banner === index ? 17 : 15"
              elevation="0"
              x-small
              :rounded="selected.banner === index"
              :fab="selected.banner !== index"
              :color="selected.banner === index ? '#FF3177' : 'grey lighten-2'"
              class="mx-2"
              @click="goToSlide(index)">
            </v-btn>
          </div>
        </section>

        <section :class="rps === 'xs' ? 'mt-6' : 'mt-12'">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="3" sm="3" :class="rps === 'xs' ? '' : 'pr-0'">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="rps === 'xs' ? 'b_a br_a' : 'b_l b_t b_b br_l'"
                      elevation="0"
                      color="#f0f0f0"
                      block
                      height="40"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      {{ filter.label_type }}
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense>
                    <v-list-item-group
                      color="#FF3177">
                      <v-list-item class="hover-list"
                        v-for="(item, index) in list.type"
                        :key="index"
                        @click="filter.type = item.value; filter.label_type = item.label;">
                        <v-list-item-title class="body-2">
                          {{ item.label }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3" sm="3" :class="rps === 'xs' ? '' : 'px-0'">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="rps === 'xs' ? 'b_a br_a' : 'b_a br_n'"
                      elevation="0"
                      color="#f0f0f0"
                      block
                      height="40"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      <span style="max-width: 200px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;">
                        {{ filter.label_category }}
                      </span>
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense max-height="300">
                    <v-list-item-group
                      style="background: white;"
                      color="#FF3177">
                      <div 
                        v-for="(item, index) in list.category"
                        :key="index">
                        <v-list-item class="hover-list" v-if="item.sub.length < 1"
                          @click="
                            filter.category = item.value; 
                            filter.label_category = item.label; 
                            filter.category_sub = '';">
                          <v-list-item-title class="body-2">
                            {{ item.label }}
                          </v-list-item-title>
                        </v-list-item>

                        <div v-if="item.sub.length > 0">
                          <v-list-group no-action color="#FF3177" @click.stop>
                            <template v-slot:activator>
                              <v-list-item class="px-0">
                                <v-list-item-title>
                                  {{ item.label }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>

                            <v-list-item
                              class="px-0 hover-list"
                              v-for="(sub, i) in item.sub"
                              :key="i"
                              @click="
                                filter.category_sub = sub.kelas_kategori_id; 
                                filter.label_category = sub.kelas_kategori_title; 
                                filter.category = '';">
                              <v-list-item-title class="pl-8">
                                {{ sub.kelas_kategori_title }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list-group>
                        </div>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6" sm="6" :class="rps === 'xs' ? '' : 'pl-0'">
                <div class="d-flex align-center justify-end" style="width: 100%;">
                  <v-text-field
                    :class="rps === 'xs' ? 'br_a' : 'br_r search-style'"
                    outlined
                    dense 
                    single-line
                    hide-details
                    color="#dadada"
                    placeholder="Temukan kelas yang sesuai kebutuhan anda . . ."
                    clearable
                    v-model="filter.title"
                    @click:clear="filter.title = ''">
                  </v-text-field>

                  <v-btn
                    style="position: absolute; right: auto;"
                    elevation="0"
                    rounded
                    height="40"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    @click="search()">
                    <v-icon color="white">mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row justify="center" :class="rps === 'xs' ? 'mt-10' : 'my-10'">
              <v-col cols="12" md="6" sm="6" class="text-center">
                <p class="text-h5 text-first mb-2">
                  <span class="color-first">
                    Pertanyaan
                  </span>
                  Bidan Kita
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-0">
                 Anda berhak mendapatkan program edukasi yang telah disesuaikan dengan kebutuhan Anda.
                </p>
              </v-col>
            </v-row>

            <!-- <div class="d-flex align-center">
              <div style="position: relative; top: 150px; left: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperQuestion.$swiper.slidePrev()">
                  <v-icon color="white">mdi-chevron-left</v-icon>
                </v-btn>
              </div>

              <v-spacer/>

              <div style="position: relative; top: 150px; right: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperQuestion.$swiper.slideNext()">
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div> -->

            <no-ssr>
              <swiper class="swiper" 
                ref="swiperQuestion" 
                :options="swiperOption"
                @slideChange="onSlideChangeQuestion">
                <swiper-slide v-for="(item, index) in list.question" :key="index" 
                  class="py-8 px-3" style="width: auto;">
                  <v-skeleton-loader
                    class="ma-auto"
                    width="275"
                    height="200"
                    type="card, article">
                    <div>
                      <v-card
                        width="275"
                        height="200"
                        class="box-shadow border-radius pa-6">
                        <v-card-text class="pa-0">
                          <v-avatar size="60" color="#f0f0f0">
                            <v-img 
                              contain
                              width="60"
                              height="60"
                              :src="item.icon">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-card-text>

                        <!-- <p class="title font-weight-bold mt-6">
                          <span class="color-first">
                            Pertanyaan
                          </span>
                          <span class="text-first ml-1">
                            Bidan Kita
                          </span>
                        </p> -->

                        <div class="body-1 line-text-second text-first font-weight-light mt-6 mb-5" style="height: 100px;">
                          "{{ item.desc }}"
                        </div>

                        <!-- <v-btn
                          outlined
                          elevation="0"
                          rounded
                          color="grey"
                          class="text-capitalize">
                          Selengkapnya
                          <v-icon small class="ml-3" color="grey">mdi-arrow-right</v-icon>
                        </v-btn> -->
                      </v-card>
                    </div>
                  </v-skeleton-loader>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <div class="text-center mt-3"> 
              <v-btn 
                v-for="i in rps !== 'xs' ? 3 : 6" 
                :key="i"
                :width="selected.question === i-1 ? 12 : 15"
                :height="selected.question === i-1 ? 17 : 15"
                elevation="0"
                x-small
                :rounded="selected.question === i-1"
                :fab="selected.question !== i-1"
                :color="selected.question === i-1 ? '#FF3177' : 'grey lighten-2'"
                class="mx-2"
                @click="onSlideChangeQuestionIndex(i-1)">
              </v-btn>
            </div>

            <div class="text-center mt-10">
              <v-btn
                elevation="0"
                rounded
                color="#FF3177"
                class="text-capitalize white--text"
                to="/guide">
                Semua Panduan
              </v-btn>
            </div>
          </v-container>
        </section>

        <section class="mt-16">
          <v-container>
            <v-divider class="mb-3" style="border: 3px solid #FF3177; width: 75px; border-radius: 10px;"/>
            <p class="text-h5 text-first">
              <span class="color-first">
                Yuk Belajar
              </span>
              Kelas Dasar Bidan Kita
            </p>

            <v-row justify="center">
              <v-col cols="12" md="7" sm="7" :class="rps === 'xl' || rps === 'lg' ? 'pr-12' : ''">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.basic"
                  type="article, article, article">
                  <div>
                    <div v-if="!process.basic && Object.keys(basic).length > 0">
                      <p class="text-h5 text-first line-text-first mt-2 mb-6">
                        <span class="text-h4">{{ basic.kelas_nama}}</span> ({{ basic.kelas_kategori_title }})
                      </p>
                      <p class="text-first body-1 font-weight-light line-text-second mb-0">
                        <span class="five-line" v-html="basic.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                      </p>
                      
                      <div class="d-flex align-center" :class="rps === 'sm' || rps === 'xs' ? 'mt-6 mb-4' : 'mt-12'">
                        <v-btn v-if="basic.kelas_is_purchased === '0'"
                          rounded
                          width="160"
                          elevation="0"
                          color="#FF3177"
                          class="text-capitalize white--text"
                          :disabled="process.cart"
                          :loading="process.cart"
                          @click="addToCart(basic.kelas_id)">
                          <v-icon small class="mr-1">mdi-cart-arrow-down</v-icon>
                          Keranjang
                        </v-btn>

                        <v-btn v-if="basic.kelas_is_purchased === '1'"
                          rounded
                          width="160"
                          elevation="0"
                          color="#FF3177"
                          class="text-capitalize white--text"
                          :to="`/collection/elearning/${basic.kelas_id}`">
                          Lihat Koleksi
                        </v-btn>

                        <v-btn
                          fab
                          width="40"
                          height="40"
                          elevation="0"
                          :disabled="process.wishlist"
                          :loading="process.wishlist"
                          class="mx-2"
                          @click="basic.kelas_is_wishlist = basic.kelas_is_wishlist === '0' ? '1' : '0'; 
                            addWishlist(basic.kelas_id, basic.kelas_is_wishlist)">
                          <v-icon color="#FF3177">
                            {{ !token ? 'mdi-heart-outline' : basic.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                          </v-icon>
                        </v-btn>

                        <v-btn
                          fab
                          width="40"
                          height="40"
                          elevation="0"
                          @click="dialog.share = true">
                          <v-icon color="#FF3177">mdi-share-variant</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="5" sm="5" :class="rps === 'xl' || rps === 'lg' ? 'pl-12' : ''">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.basic"
                  type="card, article">
                  <div>
                    <div v-if="!process.basic && Object.keys(basic).length > 0">
                      <v-card flat :to="basic.kelas_is_purchased === '0' ? `/elearning/${basic.kelas_slug}` : `/collection/elearning/${basic.kelas_id}`">
                        <v-img 
                          cover
                          width="100%"
                          height="400"
                          class="border-radius box-shadow"
                          :src="basic.kelas_image_jsonobejct.kelas_image_cover_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <v-chip v-if="basic.kelas_is_special_price"
                            small
                            class="font-weight-bold white--text px-2 ma-2"
                            color="primary"
                            style="position: absolute; top: 0; right: 0px;">
                            Special Price
                          </v-chip>

                          <div class="caption font-weight-bold white--text text-capitalize pa-3"
                            style="
                              background: rgb(0 0 0 / 50%);
                              position: absolute;
                              bottom: 0px;
                              width: 100%;">
                            <div class="d-flex align-center">
                              <div class="d-flex align-center">
                                <v-rating dense
                                  :value="parseFloat(basic.kelas_total_rating)"
                                  size="25"
                                  readonly
                                  color="yellow darken-2"
                                  background-color="yellow darken-2">
                                </v-rating>
                                <span class="white--text body-1 font-weight-bold ml-1">
                                  ({{ parseFloat(basic.kelas_total_rating).toFixed(1) }})
                                </span>
                              </div>

                              <v-spacer/>

                              <div class="white--text body-1">
                                <span class="font-weight-bold">
                                  {{ basic.kelas_total_materi }}
                                </span>
                                <span class="body-1">
                                  Materi
                                </span>
                              </div>
                            </div>

                            <v-divider color="white" class="my-3" style="opacity: 50%;"/>

                            <div class="title px-1" v-if="!basic.kelas_is_discount">
                              <div class="white--text font-weight-bold" v-if="!basic.kelas_berbayar">
                                Gratis
                              </div>

                              <div class="white--text" v-else>
                                <span class="font-weight-bold">
                                  {{ parseInt(basic.kelas_harga_jsonobject.value_after_discount) | price }}
                                </span> 
                                <span class="body-2 white--text">
                                  /
                                  <span v-if="!basic.kelas_harga_jsonobject.is_lifetime">
                                    {{ basic.kelas_harga_jsonobject.subcribe_value }} 
                                    {{ basic.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : basic.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                  </span>

                                  <span v-else>
                                    Lifetime
                                  </span>
                                </span>
                              </div>
                            </div>

                            <div class="title d-flex align-center px-1" v-if="basic.kelas_is_discount">
                              <div class="body-1 white--text" v-if="basic.kelas_is_discount">
                                <span class="text-decoration-line-through">
                                  {{ parseInt(basic.kelas_harga_jsonobject.value) | price }}
                                </span>
                              </div>

                              <v-spacer/>

                              <div class="white--text">
                                <span class="font-weight-bold">
                                  {{ parseInt(basic.kelas_harga_jsonobject.value_after_discount) | price }}
                                </span> 
                                <span class="body-2 white--text">
                                  /
                                  <span v-if="!basic.kelas_harga_jsonobject.is_lifetime">
                                    {{ basic.kelas_harga_jsonobject.subcribe_value }} 
                                    {{ basic.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : basic.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                  </span>

                                  <span v-else>
                                    Lifetime
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-img>

                        <div class="burst" v-if="basic.kelas_is_discount">
                          <span>
                            OFF {{ basic.kelas_diskon_jsonobject.percent }}%
                          </span>
                        </div>

                        <div :class="rps === 'xs' ? 'burst_free' : 'burst_free_large'" v-if="!basic.kelas_berbayar">
                          <span>
                            FREE
                          </span>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section class="mt-16">
          <v-container>
            <v-row justify="center" v-if="process.class || list.class.length > 0">
              <v-col cols="12" md="6" sm="6" class="text-center">
                <p class="text-h5 text-first mb-2">
                  <span class="color-first">
                    E-Learning
                  </span>
                  Bidan Kita
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-0">
                 Berikut ini berbagai program layanan belajar yang bisa Anda ikuti.
                </p>
              </v-col>
            </v-row>

            <!-- <div class="d-flex align-center" v-if="list.class.length > 4">
              <div style="position: relative; top: 275px; left: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperClass.$swiper.slidePrev()">
                  <v-icon color="white">mdi-chevron-left</v-icon>
                </v-btn>
              </div>

              <v-spacer/>

              <div style="position: relative; top: 275px; right: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperClass.$swiper.slideNext()">
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div> -->

            <no-ssr>
              <swiper class="swiper" 
                ref="swiperClass"
                :options="swiperOption" 
                @slideChange="onSlideChangeClass">
                <swiper-slide v-for="(item, index) in process.class ? 6 : list.class" :key="index" 
                  class="py-8 px-3" style="width: auto;">
                  <v-skeleton-loader
                    class="ma-auto"
                    width="275"
                    :loading="process.class"
                    type="card, article">
                    <div>
                      <v-hover
                        v-slot="{ hover }"
                        open-delay="200">
                        <div>
                          <v-card v-if="!process.class && list.class.length > 0"
                            class="border-radius box-shadow"
                            width="275"
                            :to="item.kelas_is_purchased === '0' ? `/elearning/${item.kelas_slug}` : `/collection/elearning/${item.kelas_id}`">
                            <v-img
                              width="100%"
                              height="220"
                              :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                              
                              <v-chip v-if="item.kelas_is_special_price"
                                small
                                class="font-weight-bold white--text px-2 ma-2"
                                color="primary"
                                style="position: absolute; top: 0; right: 0px;">
                                Special Price
                              </v-chip>
                            </v-img>

                            <div class="burst" v-if="item.kelas_is_discount">
                              <span>
                                OFF {{ item.kelas_diskon_jsonobject.percent }}%
                              </span>
                            </div>

                            <div class="burst_free" v-if="!item.kelas_berbayar">
                              <span>
                                FREE
                              </span>
                            </div>

                            <div class="pa-2">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>
                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>
                              </v-card-text>

                              <v-card-text style="width: auto;">
                                <div class="d-flex align-center">
                                  <div class="d-flex align-center">
                                    <v-rating
                                      :value="parseFloat(item.kelas_total_rating)"
                                      background-color="yellow darken-2"
                                      color="yellow darken-2"
                                      dense
                                      disabled
                                      size="18">
                                    </v-rating>
                                    <span class="text-second caption font-weight-bold ml-1">
                                      ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                    </span>
                                  </div>

                                  <v-spacer/>

                                  <div class="text-second">
                                    <span class="font-weight-bold">
                                      {{ item.kelas_total_materi }}
                                    </span>
                                    <span class="body-2">
                                      Materi
                                    </span>
                                  </div>
                                </div>
                              </v-card-text>

                              <v-divider class="mb-2 mx-4"/>

                              <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '1'">
                                <div class="color-first">
                                  Selengkapnya
                                </div>

                                <v-spacer/>

                                <div class="color-first">
                                  <v-icon small color="#FF3177">mdi-arrow-right</v-icon>
                                </div>
                              </v-card-actions>

                              <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && !item.kelas_is_discount">
                                <div class="green--text font-weight-bold" v-if="!item.kelas_berbayar">
                                  Gratis
                                </div>

                                <div class="color-first" v-else>
                                  <span class="font-weight-bold">
                                    {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                  </span> 
                                  <span class="caption text-second">
                                    /
                                    <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                      {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                      {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                    </span>

                                    <span v-else>
                                      Lifetime
                                    </span>
                                  </span>
                                </div>
                              </v-card-actions>

                              <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && item.kelas_is_discount">
                                <div class="body-2 text-second" v-if="item.kelas_is_discount">
                                  <span class="text-decoration-line-through">
                                    {{ parseInt(item.kelas_harga_jsonobject.value) | price }}
                                  </span>
                                </div>

                                <v-spacer/>

                                <div class="color-first">
                                  <span class="font-weight-bold">
                                    {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                  </span> 
                                  <span class="caption text-second">
                                    /
                                    <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                      {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                      {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                    </span>

                                    <span v-else>
                                      Lifetime
                                    </span>
                                  </span>
                                </div>
                              </v-card-actions>
                            </div>
                          </v-card>

                          <v-expand-transition>
                            <div v-if="hover && rps !== 'xs'">
                              <v-card
                                width="100%"
                                height="475"
                                outlined
                                :class="index === 5 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                                <v-card-text style="width: auto;">
                                  <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                    <span class="two-line">
                                      {{ item.kelas_nama }}
                                    </span>
                                  </p>

                                  <p class="body-2 text-second mb-0">
                                    <span class="one-line">
                                      {{ item.kelas_kategori_title }}
                                    </span>
                                  </p>

                                  <v-list flat dense>
                                    <v-list-item class="px-0">
                                      <v-list-item-avatar size="30" class="mr-2">
                                        <v-img :src="item.kelas_author.kelas_author_photo">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#FF3177">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.kelas_author.kelas_author_nama }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>

                                  <div style="height: 230px;">
                                    <p class="body-2 font-weight-light text-first line-text-first">
                                      <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                    </p>

                                    <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                      <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                      Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                    </p>
                                  </div>
                                </v-card-text>

                                <v-card-text class="d-flex align-center justify-center"
                                  style="position: absolute; bottom: 10px; width: auto;">
                                  <v-btn v-if="item.kelas_is_purchased === '0'"
                                    rounded
                                    width="160"
                                    elevation="0"
                                    color="#FF3177"
                                    class="text-capitalize white--text mr-2"
                                    :disabled="process.cart"
                                    :loading="process.cart"
                                    @click="addToCart(item.kelas_id)">
                                    <v-icon small class="mr-1">mdi-cart-arrow-down</v-icon>
                                    Keranjang
                                  </v-btn>

                                  <v-btn v-if="item.kelas_is_purchased === '1'"
                                    rounded
                                    width="160"
                                    elevation="0"
                                    color="#FF3177"
                                    class="text-capitalize white--text mr-2"
                                    :to="`/collection/elearning/${item.kelas_id}`">
                                    Lihat Koleksi
                                  </v-btn>

                                  <v-btn
                                    fab
                                    width="40"
                                    height="40"
                                    elevation="0"
                                    :disabled="process.wishlist"
                                    :loading="process.wishlist"
                                    @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                      addWishlist(item.kelas_id, item.kelas_is_wishlist)">
                                    <v-icon color="#FF3177">
                                      {{ !token ? 'mdi-heart-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                                    </v-icon>
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </div>
                          </v-expand-transition>
                        </div>
                      </v-hover>
                    </div>
                  </v-skeleton-loader>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <div class="text-center mt-3" v-if="list.class.length > 5"> 
              <v-btn 
                v-for="i in rps !== 'xs' ? 3 : 5" 
                :key="i"
                :width="selected.class === i-1 ? 12 : 15"
                :height="selected.class === i-1 ? 17 : 15"
                elevation="0"
                x-small
                :rounded="selected.class === i-1"
                :fab="selected.class !== i-1"
                :color="selected.class === i-1 ? '#FF3177' : 'grey lighten-2'"
                class="mx-2"
                @click="onSlideChangeClassIndex(i-1)">
              </v-btn>
            </div>

            <div class="text-center mt-10" v-if="!process.class && list.class.length > 5">
              <v-btn
                elevation="0"
                rounded
                color="#FF3177"
                to="/elearning"
                class="text-capitalize white--text">
                Lihat Semuanya
                <v-icon small class="ml-3" color="#FFFFFF">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-container>
        </section>

        <section class="mt-16">
          <v-container>
            <v-divider class="mb-3" style="border: 3px solid #FF3177; width: 75px; border-radius: 10px;"/>
            <p class="text-h5 text-first">
              <span class="color-first">
                Pengajar
              </span>
              Bidan Kita
            </p>

            <v-row justify="center">
              <v-col cols="12" md="6" sm="6">
                <v-row>
                  <v-col cols="12" md="7" sm="7">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.mentor"
                      type="card, article">
                      <div>
                        <v-img v-if="!process.mentor && list.mentor.length > 0"
                          cover
                          :width="rps !== 'xs' ? 375 : '100%'"
                          height="375"
                          class="border-radius box-shadow"
                          :src="list.mentor[0].mentor_image_cover_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <div class="body-1 font-weight-bold white--text text-capitalize pa-6 py-3"
                            style="
                              background: rgb(0 0 0 / 50%);
                              position: absolute;
                              bottom: 0px;
                              width: 100%;">
                            <p class="ml-1 mb-0">
                              {{ list.mentor[0].mentor_kelas_user_nama }}
                            </p>

                            <div class="d-flex align-center">
                              <v-rating dense
                                :value="5"
                                small
                                readonly
                                color="yellow darken-2"
                                background-color="yellow darken-2">
                              </v-rating>
                              <span class="white--text caption font-weight-bold ml-1">
                                ({{ 5 }})
                              </span>
                            </div>
                          </div>
                        </v-img>
                      </div>
                    </v-skeleton-loader>
                  </v-col>

                  <v-col cols="12" md="5" sm="5">
                    <v-row>
                      <v-col cols="6" md="12" sm="12" 
                        v-for="(item, index) in process.mentor ? 2 : list.mentor"
                        :key="index"
                        :class="rps !== 'xs' && index < 2 ? 'pt-0' : ''"
                        v-show="!process.mentor ? index > 0 : 2">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.mentor"
                          type="image">
                          <div>
                            <v-img v-if="!process.mentor && list.mentor.length > 0"
                              cover
                              :width="rps !== 'xs' ? 175 : '100%'"
                              height="175"
                              class="border-radius box-shadow"
                              :src="item.mentor_image_cover_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <div class="caption font-weight-bold white--text text-capitalize pa-2"
                                style="
                                  background: rgb(0 0 0 / 50%);
                                  position: absolute;
                                  bottom: 0px;
                                  width: 100%;">
                                <p class="one-line mb-0">
                                  {{ item.mentor_kelas_user_nama }}
                                </p>

                                <!-- <div class="d-flex align-center">
                                  <v-rating dense
                                    :value="5"
                                    small
                                    readonly
                                    color="yellow darken-2"
                                    background-color="yellow darken-2">
                                  </v-rating>
                                  <span class="white--text caption font-weight-bold ml-1">
                                    ({{ 5 }})
                                  </span>
                                </div> -->
                              </div>
                            </v-img>
                          </div>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <p class="text-h5 text-first line-text-first mt-2 mb-6">
                  <span class="text-h4">Pengajar Spesialis</span> Yang Siap Membantu Keluhan Anda!
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-2">
                  "All About Preparation" Persiapkan diri Anda bersama kami dengan mengikuti berbagai event kelas, kegiatan maupun treatment bersama team Bidan Kita baik ONLINE maupun OFFLINE demi tercapainya cita citamu untuk mendapatkan pengalaman positif lahir minim trauma (Gentle Birth).
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-12">
                  Yuk segera ikuti kelas - kelas menarik untuk belajar mu hanya di <b>E-Learning Bidan Kita</b>.
                </p>
                <nuxt-link to="/instructor" class="color-first">
                  <span>
                    Lihat pengajar lainnya
                  </span>
                  <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                </nuxt-link>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section :class="list.streaming.length > 0 ? 'mt-16' : ''">
          <v-container>
            <v-row justify="center" v-if="process.streaming || list.streaming.length > 0">
              <v-col cols="12" md="6" sm="6" class="text-center">
                <p class="text-h5 text-first mb-2">
                  <span class="color-first">
                    Live Streaming
                  </span>
                  Bidan Kita
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-0">
                 Berikut ini berbagai program layanan live streaming yang bisa Anda ikuti.
                </p>
              </v-col>
            </v-row>

            <no-ssr>
              <swiper class="swiper" 
                ref="swiperStreaming"
                :options="swiperOption" 
                @slideChange="onSlideChangeStreaming">
                <swiper-slide v-for="(item, index) in process.streaming ? 6 : list.streaming" :key="index" 
                  class="py-8 px-3" style="width: auto;">
                  <v-skeleton-loader
                    class="ma-auto"
                    width="275"
                    :loading="process.streaming"
                    type="card, article">
                    <div>
                      <v-hover
                        v-slot="{ hover }"
                        open-delay="200">
                        <div>
                          <v-card v-if="!process.streaming && list.streaming.length > 0"
                            class="border-radius box-shadow"
                            width="275"
                            :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}`"
                            target="_blank">
                            <v-img
                              width="100%"
                              height="220"
                              :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <v-chip v-if="item.kelas_is_special_price"
                                small
                                class="font-weight-bold white--text px-2 ma-2"
                                color="primary"
                                style="position: absolute; top: 0; right: 0px;">
                                Special Price
                              </v-chip>
                            </v-img>

                            <div class="burst" v-if="item.kelas_is_discount">
                              <span>
                                OFF {{ item.kelas_diskon_jsonobject.percent }}%
                              </span>
                            </div>

                            <!-- <div class="burst_free" v-if="!item.kelas_is_paid">
                              <span>
                                FREE
                              </span>
                            </div> -->

                            <div class="burst_free" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                              <span>
                                FREE
                              </span>
                            </div>

                            <div class="pa-2">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                                  <span class="two-line">
                                    {{ item.kelas_nama }} ({{item.schedule_start_datetime | date }})
                                  </span>
                                </p>
                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>
                              </v-card-text>

                              <v-card-text style="width: auto;">
                                <div class="d-flex align-center">
                                  <div class="d-flex align-center">
                                    <v-rating
                                      :value="parseFloat(item.kelas_total_rating)"
                                      background-color="yellow darken-2"
                                      color="yellow darken-2"
                                      dense
                                      disabled
                                      size="18">
                                    </v-rating>
                                    <span class="text-second caption font-weight-bold ml-1">
                                      ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                    </span>
                                  </div>

                                  <v-spacer/>

                                  <div class="text-second" v-if="item.kelas_total_materi > 0">
                                    <span class="font-weight-bold">
                                      {{ item.kelas_total_materi }}
                                    </span>
                                    <span class="body-2">
                                      Materi
                                    </span>
                                  </div>
                                </div>
                              </v-card-text>

                              <v-divider class="mb-2 mx-4"/>

                              <!-- <v-card-actions class="body-2 px-4">
                                <div class="color-first font-weight-bold">
                                  <span v-if="item.kelas_harga_jsonobject.harga_min === item.kelas_harga_jsonobject.harga_max">
                                    {{ parseInt(item.kelas_harga_jsonobject.harga_max) | price }}
                                  </span>

                                  <span v-else>
                                    {{ parseInt(item.kelas_harga_jsonobject.harga_min) | price }}
                                    -
                                    {{ parseInt(item.kelas_harga_jsonobject.harga_max) | price }}
                                  </span>
                                </div>
                              </v-card-actions> -->

                              <v-card-actions class="body-2 px-4" v-if="!item.kelas_is_discount">
                                <div class="green--text font-weight-bold" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                  Gratis
                                </div>

                                <div class="color-first" v-else>
                                  <span class="font-weight-bold">
                                    <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                      {{ parseInt(item.schedule_early_price) | price }}
                                    </span>

                                    <span v-else>
                                      {{ parseInt(item.schedule_normal_price) | price }}
                                    </span>
                                  </span> 
                                  <span class="caption text-second">
                                    /
                                    {{ dateNow >= item.schedule_early_price_end_datetime ? 'Normal' : 'Early' }}
                                  </span>
                                </div>
                              </v-card-actions>

                              <v-card-actions class="body-2 px-4" v-if="item.kelas_is_discount">
                                <div class="body-2 text-second">
                                  <span class="text-decoration-line-through">
                                    <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                      {{ parseInt(item.schedule_early_price) | price }}
                                    </span>

                                    <span v-else>
                                      {{ parseInt(item.schedule_normal_price) | price }}
                                    </span>
                                  </span>
                                </div>

                                <v-spacer/>

                                <div class="color-first">
                                  <span class="font-weight-bold">
                                  <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                      {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                    </span>

                                    <span v-else>
                                      {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                    </span>
                                  </span> 
                                  <span class="caption text-second">
                                    /
                                    {{ dateNow >= item.schedule_early_price_end_datetime ? 'Normal' : 'Early' }}
                                  </span>
                                </div>
                              </v-card-actions>
                            </div>
                          </v-card>

                          <v-expand-transition>
                            <div v-if="hover && rps !== 'xs'">
                              <v-card
                                width="100%"
                                height="475"
                                outlined
                                :class="index === 5 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                                <v-card-text style="width: auto;">
                                  <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                    <span class="two-line">
                                      {{ item.kelas_nama }}
                                    </span>
                                  </p>

                                  <p class="body-2 text-second mb-0">
                                    <span class="one-line">
                                      {{ item.kelas_kategori_title }}
                                    </span>
                                  </p>

                                  <v-list flat dense>
                                    <v-list-item class="px-0">
                                      <v-list-item-avatar size="30" class="mr-2">
                                        <v-img :src="item.kelas_author.kelas_author_photo">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#FF3177">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.kelas_author.kelas_author_nama }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>

                                  <div style="height: 230px;">
                                    <p class="body-2 font-weight-light text-first line-text-first">
                                      <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                    </p>

                                    <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                      <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                      Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                    </p>
                                  </div>
                                </v-card-text>

                                <v-card-text class="d-flex align-center justify-center"
                                  style="position: absolute; bottom: 10px; width: auto;">
                                  <v-btn
                                    rounded
                                    width="160"
                                    elevation="0"
                                    color="#FF3177"
                                    class="text-capitalize white--text mr-2"
                                    :href="`https://kelas.bidankita.com/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}`"
                                    target="_blank">
                                    Lihat Jadwal
                                  </v-btn>

                                  <v-btn
                                    fab
                                    width="40"
                                    height="40"
                                    elevation="0"
                                    :disabled="process.wishlist"
                                    :loading="process.wishlist"
                                    @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                      addWishlist(item.kelas_id, item.kelas_is_wishlist)">
                                    <v-icon color="#FF3177">
                                      {{ !token ? 'mdi-heart-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                                    </v-icon>
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </div>
                          </v-expand-transition>
                        </div>
                      </v-hover>
                    </div>
                  </v-skeleton-loader>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <div class="text-center mt-3" v-if="list.streaming.length > 5"> 
              <v-btn 
                v-for="i in rps !== 'xs' ? 3 : 5" 
                :key="i"
                :width="selected.streaming === i-1 ? 12 : 15"
                :height="selected.streaming === i-1 ? 17 : 15"
                elevation="0"
                x-small
                :rounded="selected.streaming === i-1"
                :fab="selected.streaming !== i-1"
                :color="selected.streaming === i-1 ? '#FF3177' : 'grey lighten-2'"
                class="mx-2"
                @click="onSlideChangeStreamingIndex(i-1)">
              </v-btn>
            </div>

            <div class="text-center mt-10" v-if="!process.streaming && list.streaming.length > 5">
              <v-btn
                elevation="0"
                rounded
                color="#FF3177"
                href="https://kelas.bidankita.com/streaming"
                target="_blank"
                class="text-capitalize white--text">
                Lihat Semuanya
                <v-icon small class="ml-3" color="#FFFFFF">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-container>
        </section>

        <section>
          <v-container :class="rps !== 'sm' && rps !== 'xs' ? 'pa-8' : 'pa-0'">
            <v-img
              width="100%"
              :height="rps !== 'sm' && rps !== 'xs' ? 'auto' : '600'"
              class="align-center"
              alt="elearning"
              :src="require('@/assets/background/home_download_background_oval.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
              
              <v-row justify="center" align="center">
                <v-col cols="12" md="6" sm="6">
                  <v-card
                    flat
                    color="transparent"
                    :class="rps === 'xs' ? 'px-6' : 'px-4'">
                    <p class="title font-weight-bold white--text">
                      Download Aplikasi nya Sekarang
                    </p>

                    <p class="body-1 font-weight-light white--text line-text-second">
                      Silahkan download aplikasi kami di playstore untuk memudahkan anda mengakses layanan kami.
                    </p>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" sm="4">
                  <v-row justify="center" v-if="rps !== 'sm' && rps !== 'xs'">
                    <v-col cols="12" md="5"
                      v-for="(item, index) in download"
                      :key="index">
                      <a href="https://play.google.com/store/apps/details?id=com.elearningbidankita" target="_blank">
                        <v-img
                          alt="elearning"
                          contain
                          width="150"
                          height="50"
                          :src="item">
                        </v-img>
                      </a>
                    </v-col>
                  </v-row>

                  <div v-if="rps === 'sm' || rps === 'xs'"
                    class="d-flex justify-center mb-8">
                    <a href="https://play.google.com/store/apps/details?id=com.elearningbidankita" target="_blank"
                      v-for="(item, index) in download"
                      :key="index">
                      <v-img
                        alt="elearning"
                        contain
                        width="150"
                        height="50"
                        :src="item">
                      </v-img>
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-img>
          </v-container>
        </section>

        <section :class="rps === 'xs' ? 'mt-8' : 'mt-12'">
          <v-container>
            <no-ssr>
              <swiper class="swiper" :options="swiperOption" ref="swiperCategory">
                <swiper-slide v-for="(item, index) in process.category || process.type ? 10 : list.category" :key="index"
                  v-show="index > 0 && (process.category || process.type ? 10 : index < 6)"
                  class="py-6 pl-1 pr-4" style="width: auto;">
                  <v-skeleton-loader
                    class="ma-auto"
                    width="175"
                    height="175"
                    :loading="process.category || process.type"
                    type="image">
                    <div>
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="box-shadow border-radius text-center pa-6"
                          :class="{ 'card-color': hover }"
                          width="125"
                          height="125"
                          @click="filter.label_category = item.label; filter.category = item.value; fetchTag()">
                          <v-icon size="60" :class="{ 'card-color': hover }">mdi-bookshelf</v-icon>
                          
                          <div class="two-line mt-3">
                            {{ item.label }}
                          </div>
                        </v-card>
                      </v-hover>
                    </div>
                  </v-skeleton-loader>
                </swiper-slide>

                <swiper-slide v-if="!process.category && !process.type"
                  class="py-6 px-3" style="width: auto;">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      class="box-shadow border-radius text-center pa-6"
                      :class="{ 'card-color': hover }"
                      width="125"
                      height="125"
                      to="/elearning">
                      <v-icon size="60" :class="{ 'card-color': hover }">mdi-dots-vertical</v-icon>
                      
                      <div class="two-line mt-3">
                        Lihat Semua Kategori Lainnya
                      </div>
                    </v-card>
                  </v-hover>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <v-row class="my-3">
              <v-col cols="12" md="2" sm="2" class="py-0"
                v-for="(item, index) in process.tag ? 5 : 0" 
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.tag"
                  type="list-item-two-line">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="10" sm="10" v-if="!process.tag && list.tag.length > 0">
                <div class="d-flex align-center" style="flex-wrap: wrap;">
                  <div class="mr-4 mb-5"
                    v-for="(item, index) in list.tag" 
                    :key="index">
                    <nuxt-link :to="`/elearning/${item.kelas_slug}`">
                      <div class="body-1 one-line text-second py-1 px-3" style="border-radius: 50px; background: #f0f0f0;">
                        {{ item.kelas_nama }}
                      </div>
                    </nuxt-link>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section :class="rps === 'xs' ? 'mt-8' : 'mt-16'">
          <v-container>
            <v-row justify="center" v-if="process.discount || list.discount.length > 0">
              <v-col cols="12" md="6" sm="6" class="text-center">
                <p class="text-h5 text-first mb-2">
                  <span class="color-first">
                    Promo
                  </span>
                  E-Learning
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-0">
                 Spesial diskon untuk Anda, Yuk mulai belajar Sekarang. 
                </p>
              </v-col>
            </v-row>

            <!-- <div class="d-flex align-center" v-if="list.discount.length > 4">
              <div style="position: relative; top: 275px; left: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperDiscount.$swiper.slidePrev()">
                  <v-icon color="white">mdi-chevron-left</v-icon>
                </v-btn>
              </div>

              <v-spacer/>

              <div style="position: relative; top: 275px; right: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperDiscount.$swiper.slideNext()">
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div> -->

            <no-ssr>
              <swiper class="swiper" 
                ref="swiperDiscount"
                :options="swiperOption" 
                @slideChange="onSlideChangeDiscount">
                <swiper-slide v-for="(item, index) in process.discount ? 10 : list.discount" :key="index" 
                  class="py-8 px-3" style="width: auto;">
                  <v-skeleton-loader
                    class="ma-auto"
                    width="275"
                    :loading="process.discount"
                    type="card, article">
                    <div>
                      <v-hover
                        v-slot="{ hover }"
                        open-delay="200">
                        <div>
                          <v-card v-if="!process.discount && list.discount.length > 0"
                            class="border-radius box-shadow"
                            width="275"
                            :to="item.kelas_is_purchased === '0' ? `/elearning/${item.kelas_slug}` : `/collection/elearning/${item.kelas_id}`">
                            <v-img
                              width="100%"
                              height="220"
                              :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <v-chip v-if="item.kelas_is_special_price"
                                small
                                class="font-weight-bold white--text px-2 ma-2"
                                color="primary"
                                style="position: absolute; top: 0; right: 0px;">
                                Special Price
                              </v-chip>
                            </v-img>

                            <div class="burst" v-if="item.kelas_is_discount">
                              <span>
                                OFF {{ item.kelas_diskon_jsonobject.percent }}%
                              </span>
                            </div>

                            <div class="burst_free" v-if="!item.kelas_berbayar">
                              <span>
                                FREE
                              </span>
                            </div>

                            <div class="pa-2">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize" style="height: 40px;">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>
                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>
                              </v-card-text>

                              <v-card-text style="width: auto;">
                                <div class="d-flex align-center">
                                  <div class="d-flex align-center">
                                    <v-rating
                                      :value="parseFloat(item.kelas_total_rating)"
                                      background-color="yellow darken-2"
                                      color="yellow darken-2"
                                      dense
                                      disabled
                                      size="18">
                                    </v-rating>
                                    <span class="text-second caption font-weight-bold ml-1">
                                      ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                    </span>
                                  </div>

                                  <v-spacer/>

                                  <div class="text-second">
                                    <span class="font-weight-bold">
                                      {{ item.kelas_total_materi }}
                                    </span>
                                    <span class="body-2">
                                      Materi
                                    </span>
                                  </div>
                                </div>
                              </v-card-text>

                              <v-divider class="mb-2 mx-4"/>

                              <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '1'">
                                <div class="color-first">
                                  Selengkapnya
                                </div>

                                <v-spacer/>

                                <div class="color-first">
                                  <v-icon small color="#FF3177">mdi-arrow-right</v-icon>
                                </div>
                              </v-card-actions>

                              <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && !item.kelas_is_discount">
                                <div class="green--text font-weight-bold" v-if="!item.kelas_berbayar">
                                  Gratis
                                </div>

                                <div class="color-first" v-else>
                                  <span class="font-weight-bold">
                                    {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                  </span> 
                                  <span class="caption text-second">
                                    /
                                    <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                      {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                      {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                    </span>

                                    <span v-else>
                                      Lifetime
                                    </span>
                                  </span>
                                </div>
                              </v-card-actions>

                              <v-card-actions class="body-2 px-4" v-if="item.kelas_is_purchased === '0' && item.kelas_is_discount">
                                <div class="body-2 text-second" v-if="item.kelas_is_discount">
                                  <span class="text-decoration-line-through">
                                    {{ parseInt(item.kelas_harga_jsonobject.value) | price }}
                                  </span>
                                </div>

                                <v-spacer/>

                                <div class="color-first">
                                  <span class="font-weight-bold">
                                    {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                  </span> 
                                  <span class="caption text-second">
                                    /
                                    <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                      {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                      {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                    </span>

                                    <span v-else>
                                      Lifetime
                                    </span>
                                  </span>
                                </div>
                              </v-card-actions>
                            </div>
                          </v-card>

                          <v-expand-transition>
                            <div v-if="hover && rps !== 'xs'">
                              <v-card
                                width="100%"
                                height="475"
                                outlined
                                :class="index === 5 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                                <v-card-text style="width: auto;">
                                  <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                    <span class="two-line">
                                      {{ item.kelas_nama }}
                                    </span>
                                  </p>

                                  <p class="body-2 text-second mb-0">
                                    <span class="one-line">
                                      {{ item.kelas_kategori_title }}
                                    </span>
                                  </p>

                                  <v-list flat dense>
                                    <v-list-item class="px-0">
                                      <v-list-item-avatar size="30" class="mr-2">
                                        <v-img :src="item.kelas_author.kelas_author_photo">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#FF3177">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.kelas_author.kelas_author_nama }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>

                                  <div style="height: 230px;">
                                    <p class="body-2 font-weight-light text-first line-text-first">
                                      <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                    </p>

                                    <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                      <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                      Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                    </p>
                                  </div>
                                </v-card-text>

                                <v-card-text class="d-flex align-center justify-center"
                                  style="position: absolute; bottom: 10px; width: auto;">
                                  <v-btn v-if="item.kelas_is_purchased === '0'"
                                    rounded
                                    width="160"
                                    elevation="0"
                                    color="#FF3177"
                                    class="text-capitalize white--text mr-2"
                                    :disabled="process.cart"
                                    :loading="process.cart"
                                    @click="addToCart(item.kelas_id)">
                                    <v-icon small class="mr-1">mdi-cart-arrow-down</v-icon>
                                    Keranjang
                                  </v-btn>

                                  <v-btn v-if="item.kelas_is_purchased === '1'"
                                    rounded
                                    width="160"
                                    elevation="0"
                                    color="#FF3177"
                                    class="text-capitalize white--text mr-2"
                                    :to="`/collection/elearning/${item.kelas_id}`">
                                    Lihat Koleksi
                                  </v-btn>

                                  <v-btn
                                    fab
                                    width="40"
                                    height="40"
                                    elevation="0"
                                    :disabled="process.wishlist"
                                    :loading="process.wishlist"
                                    @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                      addWishlist(item.kelas_id, item.kelas_is_wishlist)">
                                    <v-icon color="#FF3177">
                                      {{ !token ? 'mdi-heart-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                                    </v-icon>
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </div>
                          </v-expand-transition>
                        </div>
                      </v-hover>
                    </div>
                  </v-skeleton-loader>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <div class="text-center mt-3" v-if="list.discount.length > 5"> 
              <v-btn 
                v-for="i in rps !== 'xs' ? 3 : 5" 
                :key="i"
                :width="selected.discount === i-1 ? 12 : 15"
                :height="selected.discount === i-1 ? 17 : 15"
                elevation="0"
                x-small
                :rounded="selected.discount === i-1"
                :fab="selected.discount !== i-1"
                :color="selected.discount === i-1 ? '#FF3177' : 'grey lighten-2'"
                class="mx-2"
                @click="onSlideChangeDiscountIndex(i-1)">
              </v-btn>
            </div>

            <div class="text-center mt-10" v-if="!process.discount && list.discount.length > 5">
              <v-btn
                elevation="0"
                rounded
                color="#FF3177"
                to="/elearning"
                class="text-capitalize white--text">
                Lihat Semuanya
                <v-icon small class="ml-3" color="#FFFFFF">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-container>
        </section>

        <section class="mt-16">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="6" sm="6" class="text-center">
                <p class="text-h5 text-first mb-2">
                  <span class="color-first">
                    Apa Kata 
                  </span>
                  Mereka
                  ?
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-0">
                 Kepuasan Anda mengikuti kelas bidan kita.
                </p>
              </v-col>
            </v-row>

            <!-- <div class="d-flex align-center" v-if="list.feedback.length > 3">
              <div style="position: relative; top: 325px; left: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperFeedback.$swiper.slidePrev()">
                  <v-icon color="white">mdi-chevron-left</v-icon>
                </v-btn>
              </div>

              <v-spacer/>

              <div style="position: relative; top: 325px; right: -5px; z-index: 9;">
                <v-btn
                  style="border: 2px solid white;"
                  width="35"
                  height="35"
                  fab
                  color="#FF3177"
                  elevation="0"
                  @click="$refs.swiperFeedback.$swiper.slideNext()">
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div> -->

            <no-ssr>
              <swiper class="swiper" 
                ref="swiperFeedback"
                :options="swiperOption" 
                @slideChange="onSlideChangeFeedback">
                <swiper-slide 
                  v-for="(item, index) in process.feedback ? 10 : list.feedback" 
                  :key="index" 
                  class="py-8 px-3" 
                  style="width: auto;">
                  <v-skeleton-loader
                    class="ma-auto"
                    width="275"
                    :loading="process.feedback"
                    type="article, list-item-avatar-three-line">
                    <div>
                      <v-card v-if="!process.feedback && list.feedback.length > 0"
                        class="pa-6 border-radius box-shadow" 
                        width="316">
                        <v-img 
                          contain
                          width="50" 
                          :src="require('@/assets/icon/home_testimony_quotes_img.png')">
                        </v-img>

                        <div class="body-1 py-5 font-weight-light line-text-second" style="min-height: 290px;">
                          <span class="nine-line">"{{ item.testimony }}"</span>
                        </div>

                        <div>
                          <v-avatar size="75">
                            <v-img
                              contain
                              :src="item.image_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </div>

                        <div class="mt-6">
                          <p class="body-1 font-weight-bold mb-2">
                            {{ item.name }}
                          </p>

                          <p class="body-2 font-weight-bold text-third mb-0">
                            Member Bidan Kita
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </v-skeleton-loader>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <div class="text-center mt-3" v-if="list.feedback.length > 5"> 
              <v-btn 
                v-for="i in rps !== 'xs' ? (list.feedback.length - 3) : list.feedback.length" 
                :key="i"
                :width="selected.feedback === i-1 ? 12 : 15"
                :height="selected.feedback === i-1 ? 17 : 15"
                elevation="0"
                x-small
                :rounded="selected.feedback === i-1"
                :fab="selected.feedback !== i-1"
                :color="selected.feedback === i-1 ? '#FF3177' : 'grey lighten-2'"
                class="mx-2"
                @click="onSlideChangeFeedbackIndex(i-1)">
              </v-btn>
            </div>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dateNow: moment().format('YYYY-MM-DD H:m:s'),
        dialog: {
          auth: '',
          message: '',
          share: false,
          special: false
        },
        swiperOption: {
          slidesPerView: 'auto'
        },
        selected: {
          banner: 0,
          player: '',
          player_index: '',
          question: 0,
          class: 0,
          streaming: 0,
          discount: 0,
          feedback: 0,
          special: {
            elearning: 0,
            live: 0
          }
        },
        filter: {
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: ''
        },
        download: [
          require("@/assets/logo/play_store.png")
        ],
        basic: {},
        list: {
          banner: {},
          type: [],
          category: [{
            label: 'Semua Kategori',
            value: '',
            sub: []
          }],
          question: [
            {
              icon:"https://freeiconshop.com/wp-content/uploads/edd/person-girl-flat.png",
              desc:"Budhe, aku pengen banget lahiran normal, tapi aku bingung harus mulai belajar dari mana ya?",
            },
            {
              icon:"https://jual-properti.com/wp-content/uploads/2020/12/039_girl_avatar_profile_woman_headband-512-19babd11.png",
              desc:"Budhe, umur kehamilanku sudah 39w, tapi aku belum ada tanda tanda, aku harus ngapain?",
            },
            {
              icon:"https://fastbuildingintegrators.com/wp-content/uploads/2020/03/050_girl_avatar_profile_woman_suit_student_officer-512.png",
              desc:"Budhe, lahiran kemaren aku Sesar, lahiran yang ini aku pengen normal bisa nggak ya?",
            },
            {
              icon:"https://hkgroup.co.id/assets/images/friends-production/works/219961.png",
              desc:"Budhe, biar pas lahiran gak robek tu, caranya bagaimana ya?",
            },
            {
              icon:"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7b6f5523240799.5632176f43717.png",
              desc:"Tulang kemaluanku sakit bude! Apalagi kalau aku ganti posisi, Aku kudu pie?",
            },
            {
              icon:"https://i.pinimg.com/originals/a6/58/32/a65832155622ac173337874f02b218fb.png",
              desc:"Budhe, aku udah 37 minggu, tapi kepala janinku belum masuk panggul. katanya harus sesar, aku harus gimana?",
            }
          ],
          class: [],
          streaming: [],
          mentor: [],
          discount: [],
          feedback: [],
          tag: [],
          sosmed: [
            { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
            { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
            { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
            { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
          ],
          special: {
            elearning: [],
            live: []
          }
        },
        pagination: {
          special: {
            elearning: {},
            live: {}
          }
        },
        process: {
          banner: false,
          type: false,
          category: false,
          class: false,
          streaming: false,
          mentor: false,
          discount: false,
          feedback: false,
          tag: false,
          cart: false,
          wishlist: false,
          basic: false,
          special: {
            elearning: false,
            live: false
          }
        },
        message: '',
        // SEO
        content: {
          url: '',
          title: 'Beranda',
          description: 'Beranda E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message')
    },
    watch: {
      'filter.type': function(val) {
        this.filter.label_category = 'Semua Kategori'
        this.filter.category = ''
        this.filter.category_sub = ''
        
        this.fetchCategory()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      this.dialog.special = Cookie.get('special') === undefined ? false : true

      this.fetch()
    },
    methods: {
      fetch() {
        // SPECIAL PRICE
        this.fetchSpecialElearning()
        this.fetchSpecialLive()
        
        this.fetchBanner()
        this.fetchType()
        this.fetchBasic()
        this.fetchClass()
        this.fetchStreaming()
        this.fetchMentor()
        this.fetchDiscount()
        this.fetchTag()
        this.fetchFeedback()
      },
      async fetchBanner() {
        this.process.banner = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/banner`)
        .then((response) => {
          if (response.status === 200) {
            this.process.banner = false
            this.list.banner = response.data.results
          }
        })
      },
      async fetchType() {
        this.process.type = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/type`)
        .then((response) => {
          if (response.status === 200) {
            this.process.type = false

            let data = response.data.results
            for (let i in data) {
              this.list.type.push({
                label: data[i].kelas_type_title,
                value: data[i].kelas_type_id
              })
            }

            this.filter.label_type = this.list.type[1].label
            this.filter.type = this.list.type[1].value
          }
        })
      },
      async fetchCategory() {
        this.process.category = true

        let params = {
          type_id: this.filter.type,
          sort: 'kelas_kategori_title',
          dir: 'ASC'
        }
        
        this.list.category = [{
          label: 'Semua Kategori',
          value: '',
          sub: []
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/category_nested`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.category = false
        
            let data = response.data.results
            for (let i in data) {
              this.list.category.push({
                label: data[i].kelas_kategori_title,
                value: data[i].kelas_kategori_id,
                sub: data[i].kelas_kategori_child
              })
            }
          }
        })
      },
      async fetchClass() {
        this.process.class = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        this.list.class = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.class = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.class.push(v)
            })
          }
        })
      },
      async fetchStreaming() {
        this.process.streaming = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        this.list.streaming = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.streaming = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.streaming.push(v)
            })
          }
        })
      },
      async fetchMentor() {
        this.process.mentor = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/mentor`)
        .then((response) => {
          if (response.status === 200) {
            this.process.mentor = false

            this.list.mentor = response.data.results
          }
        })
      },
      async fetchDiscount() {
        this.process.discount = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/kelas_by_diskon`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.discount = false
            this.list.discount = response.data.results
          }
        })
      },
      async fetchTag() {
        this.process.tag = true

        let params = {
          limit: 10,
          sort: 'kelas_create_datetime',
          dir: 'DESC',
          filter: [
            {
              type: 'string',
              field: 'kelas_kategori_id',
              value: this.filter.category
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.tag = false
            this.list.tag = response.data.results
          }
        })
      },
      async fetchFeedback() {
        this.process.feedback = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/testimony`)
        .then((response) => {
          if (response.status === 200) {
            this.process.feedback = false
            this.list.feedback = response.data.results
          }
        })
      },
      async fetchBasic() {
        this.process.basic = true

        let params = {
          limit: 1,
          filter: [
            {
              type: 'string',
              field: 'kelas_id',
              value: 3,
              // comparison: '<>'
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.basic = false
            this.basic = response.data.pagination.total_data > 0 ? response.data.results[0] : {}
          }
        })
      },
      onAfterSlideChange(index) {
        this.selected.banner = index

        this.stop()
      },
      goToSlide(index) {
        this.selected.banner = index
        this.$refs.carousel3dBanner.goSlide(index)

        this.stop()
      },
      goToSlidePrev(index) {
        this.selected.banner = index === 0 ? this.list.banner.length - 1 : this.selected.banner - 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)

        this.stop()
      },
      goToSlideNext(index) {
        this.selected.banner = index === this.list.banner.length - 1 ? 0 : this.selected.banner + 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)

        this.stop()
      },
      search() {
        this.$router.push(`/elearning?title=${this.filter.title}&label_type=${this.filter.label_type}&type=${this.filter.type}&label_category=${this.filter.label_category}&category=${this.filter.category}&category_sub=${this.filter.category_sub}`)
      },
      stop() {
        let iframe = this.$el.querySelector( 'iframe')
        let video = this.$el.querySelector( 'video' )
        if ( iframe ) {
          let iframeSrc = iframe.src
          iframe.src = iframeSrc
        }
        if ( video ) {
          video.pause()
        }

        this.selected.player = ''
        this.selected.player_index = ''
      },
      onSlideChangeQuestion() {
        this.selected.question = this.$refs.swiperQuestion.$swiper.activeIndex
      },
      onSlideChangeQuestionIndex(index) {
        this.$refs.swiperQuestion.$swiper.slideTo(index, 1000, false)
        this.selected.question = index
      },
      onSlideChangeClass() {
        this.selected.class = this.$refs.swiperClass.$swiper.activeIndex
      },
      onSlideChangeClassIndex(index) {
        this.$refs.swiperClass.$swiper.slideTo(index, 1000, false)
        this.selected.class = index
      },
      onSlideChangeStreaming() {
        this.selected.streaming = this.$refs.swiperStreaming.$swiper.activeIndex
      },
      onSlideChangeStreamingIndex(index) {
        this.$refs.swiperStreaming.$swiper.slideTo(index, 1000, false)
        this.selected.streaming = index
      },
      onSlideChangeDiscount() {
        this.selected.discount = this.$refs.swiperDiscount.$swiper.activeIndex
      },
      onSlideChangeDiscountIndex(index) {
        this.$refs.swiperDiscount.$swiper.slideTo(index, 1000, false)
        this.selected.discount = index
      },
      onSlideChangeFeedback() {
        this.selected.feedback = this.$refs.swiperFeedback.$swiper.activeIndex
      },
      onSlideChangeFeedbackIndex(index) {
        this.$refs.swiperFeedback.$swiper.slideTo(index, 1000, false)
        this.selected.feedback = index
      },
      async addToCart(kelas_id) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveCart(kelas_id)
        }
      },
      async saveCart(kelas_id) {
        this.process.cart = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false

            this.$store.commit('header/setCart', this.headers.cart + 1)

            this.dialog.message = 'success'
            this.message = 'Berhasil ditambahkan ke Keranjang.'
          } else {
            this.process.cart = false
            
            this.dialog.message = 'error'
            this.message = response.message
          }
        })
      },
      async addWishlist(kelas_id, kelas_is_wishlist) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveWishlist(kelas_id, kelas_is_wishlist)
        }
      },
      async saveWishlist(kelas_id, kelas_is_wishlist) {
        this.process.wishlist = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/wishlist/add_remove`, {
          kelas_id: kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.wishlist = false
            
            this.dialog.message = 'wishlist'
            this.message = kelas_is_wishlist === '1' ? 'Berhasil ditambahkan ke Wishlist.' : 'Berhasil dihapus dari Wishlist.'
          }
        })
      },
      closed(type) {
        this.dialog.special = false

        if (type === 'cookie') Cookie.remove('special')
      },
      async fetchSpecialElearning() {
        this.process.special.elearning = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        this.list.special.elearning = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/basic/special_price`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.special.elearning = false

            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.special.elearning.push(v)
            })
            this.pagination.special.elearning = response.data.pagination
          }
        })
      },
      onSlideChangeSpecialElearning() {
        this.selected.special.elearning = this.$refs.swiperSpecialElearning.$swiper.activeIndex
      },
      onSlideChangeSpecialElearningIndex(index) {
        this.$refs.swiperSpecialElearning.$swiper.slideTo(index, 1000, false)
        this.selected.special.elearning = index
      },
      async fetchSpecialLive() {
        this.process.special.live = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        this.list.special.live = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/special_price`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.special.live = false

            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.special.live.push(v)
            })
            this.pagination.special.live = response.data.pagination
          }
        })
      },
      onSlideChangeSpecialLive() {
        this.selected.special.live = this.$refs.swiperSpecialLive.$swiper.activeIndex
      },
      onSlideChangeSpecialLiveIndex(index) {
        this.$refs.swiperSpecialLive.$swiper.slideTo(index, 1000, false)
        this.selected.special.live = index
      }
    }
  }
</script>

<style scoped>
.search-style.v-text-field--outlined >>> fieldset {
  border-color: #dadada;
  border-left: 0px;
}
.on-hover-left {
  position: absolute;
  top: -15px;
  right: -290px;
  z-index: 9;
  border-radius: 5px !important;
}
.on-hover-left.arrow-left:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 226px;
  border-top: 10px solid transparent;
  border-right: 10px solid #ffffff;
  border-left: none;
  border-bottom: 10px solid transparent;
}
.on-hover-left.arrow-left:before {
  content: "";
  position: absolute;
  left: -11px;
  top: 225px;
  border-top: 11px solid transparent;
  border-right: 11px solid #dadada;
  border-left: none;
  border-bottom: 11px solid transparent;
}


.on-hover-right {
  position: absolute;
  top: -15px;
  left: -290px;
  z-index: 9;
  border-radius: 5px !important;
}
.on-hover-right.arrow-right:after {
  content: "";
  position: absolute;
  right: -10px;
  top: 226px;
  border-top: 10px solid transparent;
  border-right: none;
  border-left: 10px solid #ffffff;
  border-bottom: 10px solid transparent;
}
.on-hover-right.arrow-right:before {
  content: "";
  position: absolute;
  right: -11px;
  top: 225px;
  border-top: 11px solid transparent;
  border-right: none;
  border-left: 11px solid #dadada;
  border-bottom: 11px solid transparent;
}
</style>

<style>
.skeleton-banner .v-skeleton-loader__image {
  height: 500px !important;
}
</style>