<template>
  <div class="fill-height py-12 mt-6">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Kebijakan Privasi
              </v-col>
            </v-row>
            
            <v-row justify="center">
              <v-col cols="12" md="10" sm="10">
                <v-card flat>
                  <v-card-text class="body-1 font-weight-light line-text-second text-first">
                    Adanya Kebijakan Privasi ini adalah komitmen nyata dari Bidankita.com untuk menghargai dan melindungi setiap informasi pribadi Pengguna situs Bidankita.com ini. Kebijakan ini menjadi acuan yang mengatur dan melindungi penggunaan data dan informasi penting para pengguna situs toko online ini, yang telah dikumpulkan pada saat mendaftar, mengakses dan menggunakan layanan disini, seperti alamat e-mail, nomor telepon, foto, gambar, dan lain-lain. 
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      Kebijakan privasi kami :
                    </p>

                    <div class="body-1 font-weight-light line-text-second">
                      <ul>
                        <li>
                          Seluruh informasi pribadi yang Anda berikan kepada Bidankita.com hanya akan digunakan dan dilindungi oleh Bidankita.com. Setiap informasi yang Anda berikan terbatas untuk tujuan proses yang berkaitan dengan Bidankita.com dan tanpa tujuan lainnya. 
                        </li>
                        <li>
                          Kami dapat mengubah Kebijakan Privasi ini dari waktu ke waktu dengan melakukan pengurangan ataupun penambahan ketentuan pada halaman ini. Perubahan terhadap kebijakan ini akan diumumkan melalui situs Bidankita.com atau melalui alamat dari media lain yang Anda berikan kepada kami. Anda dianjurkan untuk membaca Kebijakan Privasi ini secara berkala agar mengetahui perubahan-perubahan terbaru. 
                        </li>
                        <li>
                          Kami sangat memperhatikan betul keamanan dan privasi pelanggan kami, dan kami hanya akan mengumpulkan informasi pribadi Anda yang hanya kami perlukan untuk kepentingan internal kami saja. Perlindungan data dan informasi pelanggan merupakan privasi yang harus kami jaga penuh untuk menjaga kepercayaan Anda terhadap kami. 
                        </li>
                        <li>
                          Kami hanya akan menggunakan data dan informasi Anda sebagaimana yang dinyatakan dalam Kebijakan Privasi berikut. Kami hanya akan mengumpulkan dan menggunakan informasi yang berhubungan dengan transaksi kami dengan Anda. 
                        </li>
                        <li>
                          Bidankita.com tidak bertanggung jawab atas pertukaran data yang dilakukan sendiri di antara pengguna situs 
                        </li>
                        <li>
                          Kami hanya akan menyimpan informasi privasi Anda sepanjang kami diwajibkan oleh hukum atau selama informasi tersebut masih berhubungan dengan tujuan awal pengumpulan informasi tersebut. 
                        </li>
                      </ul>
                    </div>
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      Pengumpulan Informasi Pribadi :
                    </p>

                    <div class="body-1 font-weight-light line-text-second">
                      <ul>
                        <li>
                          Kami tidak menjual, membagi atau memperjualbelikan informasi pribadi pelanggan yang dikumpulkan secara online melalui ataupun kepada pihak ketiga. 
                        </li>
                        <li>
                          Informasi pribadi yang dikumpulkan secara online akan dibagi didalam perusahaan hanya untuk kepentingan internal kami, ketika Anda membuat akun di web kami, informasi pribadi yang kami kumpulkan termasuk: 

                          <div>
                            <ol>
                              <li>Nama</li>
                              <li>Alamat Surat Elektronik / Email</li>
                              <li>Telepon dan atau kontak lainnya</li>
                              <li>Alamat Pengiriman</li>
                              <li>Nomor Rekening</li>
                            </ol> 
                          </div>
                        </li>
                        <li>
                          Informasi yang akan kami kumpulkan dari Anda akan digunakan untuk hal-hal seperti: 

                          <div>
                            <ol>
                              <li>Mengirimkan produk yang telah Anda beli di web kami</li>
                              <li>Menginformasikan kepada Anda tentang pengiriman barang dan bantuan oleh cutomer service kami</li>
                              <li>Memberikan informasi produk yang relevan kepada Anda</li>
                              <li>
                                Memproses pesanan Anda dan memberikan layanan dan informasi yang ditawarkan melalui situs kami dan atas permintaan Anda
                              </li>
                              <li>Melakukan konfirmasi pembayaran dari Anda dan atau pengembalian pembayaran</li>
                            </ol> 
                          </div>
                        </li>
                        <li>
                          Kami akan menggunakan informasi yang Anda berikan untuk memverifikasi dan menjalankan transaksi finasial yang berhubungan dengan pembayaran online yang telah Anda lakukan, mengidentifikasi pengunjung dalam situs kami, menjalankan penelitian demografis pengguna kami, mengirimkan informasi, mengirimkan informasi yang kami anggap penting bagi Anda atau informasi yang telah Anda minta dari kami, seperti informasi tentang produk dan layanan kami, apabila Anda telah menyatakan bahwa Anda tidak keberatan menerima informasi-informasi seperti ini. 
                        </li>
                        <li>
                          Kami hanya dapat memberikan nama dan alamat Anda kepada pihak ketiga untuk tujuan pengiriman produk kepada Anda yaitu kepada kurir/ekspedisi guna pengiriman pesanan Anda. 
                        </li>
                        <li>
                          Pembayaran yang Anda buat melalu situs akan diproses oleh kami secara langsung. Anda hanya boleh memberikan informasi pembayaran kepada kami atas informasi yang akurat dan tidak menyesatkan dan Anda harus memberitahukan informasi dan perubahan-perubahan terbaru pada kami. 
                        </li>
                        <li>
                          Rincian pesanan Anda akan kami simpan dan kami dapat mengaksesnya secara langsung. Anda juga dapat mengakses informasi ini dengan masuk ke dalam akun website Bidankita.com Anda. Di sana Anda dapat melihat rincian dari pesanan yang telah dilakukan, pesanan yang masih terbuka dan pesanan yang segera akan dikirimkan dan mendaftarkan rincian alamat, rincian bank dan newsletter yang Anda langgani. Anda harus memperlakukan akses data personal ini secara rahasia dan tidak memberikannya kepada pihak ketiga yang tidak sah. Kami tidak bertanggungjawab atas penyalahgunaan password kecuali penyalahgunaan ini adalah kesalahan kami. 
                        </li>
                      </ul>
                    </div>
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      Perubahan pada Kebijakan Privasi
                    </p>

                    <p class="body-1 font-weight-light line-text-second">
                      Kami memiliki hak untuk mengganti dan mengubah Pernyataan Privasi pada waktu kapan saja. Semua perubahan kebijakan akan diumumkan di situs kami. 
                    </p>
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      Pengaduan Pelanggaran Privasi
                    </p>

                    <p class="body-1 font-weight-light line-text-second">
                      Bila Anda merasa tidak puas dengan bagaimana kami menangani keluhan atau aduan Anda, jangan ragu-ragu untuk menghubungi kami di halaman kontak: <a href="https://keluarga.bidankita.com/site/contactus" target="_blank">https://keluarga.bidankita.com/site/contactus</a>
                    </p>
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      Hak Olstore ID
                    </p>

                    <p class="body-1 font-weight-light line-text-second">
                      Anda memahami dan menyetujui bahwa Bidankita.com memiliki hak untuk mengungkapkan informasi pribadi Anda pada setiap hukum, peraturan, pemerintahan, pajak, penegak hukum atau pemerintah atau pemilik hak terkait, jika Bidankita.com memiliki alasan wajar yang dapat dipercaya bahwa pengungkapan informasi pribadi Anda diperlukan untuk kewajiban apapun, sebagai persyaratan atau pengaturan, baik sukarela atau wajib sebagai akibat dari pesanan, pemeriksaan dan atau permintaan pihak terkait. Sejauh diizinkan oleh hukum yang berlaku, dalam hal ini Anda setuju untuk tidak melakukan tuntutan apapun terhadap Bidankita.com untuk pengungkapkan informasi pribadi Anda.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        // SEO
        content: {
          url: 'site/privacy',
          title: 'Kebijakan Privasi Bidan Kita',
          description: 'Kebijakan Privasi Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>