<template>
  <div class="fill-height">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="dialog.player"
      width="1175"
      persistent>
      <v-card flat style="border-radius: 10px;">
        <v-row class="ma-0">
          <v-col cols="12" md="7" :class="rps === 'xl' || rps === 'lg' && rps === 'md' ? 'pl-0 py-0' : 'pa-0'">
            <v-card 
              flat
              color="transparent">
              <Players 
                :player="player.media" 
                :play="player.data" 
                :height="rps === 'xl' || rps === 'lg' ? 700 : rps === 'md' ? 720 : rps === 'sm' ? 400 : 300" 
                :border="rps === 'xl' || rps === 'lg' || rps === 'md' ? `10px 0px 0px 10px` : '10px'" />
            </v-card>
          </v-col>
          
          <v-col cols="12" md="5" class="px-6">
            <v-card-title class="d-flex align-center px-0">
              <div class="title text-first">
                <span class="color-first">
                  Preview
                </span>
                Kelas
              </div>

              <v-spacer/>

              <div class="cursor-pointer" @click="dialog.player = false; player.media = { type: '', is_play: false }; player.data = {};">
                <v-icon color="#FF3177">mdi-close</v-icon>
              </div>
            </v-card-title>

            <v-card-text class="body-1 font-weight-bold line-text-first px-0">
              {{ detail.kelas_nama }}
            </v-card-text>

            <v-divider/>

            <v-list flat three-line dense color="transparent"
              style="height: 460px; overflow-y: auto; overflow-x: hidden;">
              <v-list-item-group>
                <v-list-item :class="detail.kelas_preview_jsonarray.length > 5 ? 'pl-0' : 'px-0'" 
                  v-for="(item, index) in detail.kelas_preview_jsonarray" 
                  :key="index"
                  @click="playing(item)">
                  <v-list-item-content>
                    <div class="d-flex align-start">
                      <div>
                        <v-img
                          width="65"
                          height="65"
                          class="d-flex align-center justify-center"
                          gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                          :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                          style="border-radius: 5px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <v-hover v-slot="{ hover }">
                        <div class="body-2 mx-6">
                          <p class="font-weight-bold text-capitalize mb-2">
                            <span :class="!hover ? 'one-line' : 'line-text-first'">{{ item.title }}</span>
                          </p>

                          <p class="font-weight-light line-text-first text-second mb-0">
                            <span :class="!hover ? 'two-line' : 'line-text-second'" v-html="item.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                          </p>
                        </div>
                      </v-hover>
                    </div>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="text-center">
                      <v-icon v-if="item.title !== player.data.title || !player.media.is_play"
                        color="#FF3177">
                        {{ 
                          item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                          item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                          item.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                        }}
                      </v-icon>

                      <v-icon v-else
                        color="#333333">
                        mdi-stop-circle-outline
                      </v-icon>

                      <p class="caption text-capitalize color-first mb-0"
                        :class="item.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                        <span v-if="item.content_jsonobject.type !== 'docs'">
                          {{ item.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                        </span>
                        <span v-else>
                          {{ item.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                        </span>
                      </p>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-divider/>

            <v-card-text class="body-2 font-weoght-bold px-0">
              Beli kelas ini, untuk akses semua materi.
            </v-card-text>

            <v-card-actions class="px-0 pt-0 pb-6">
              <v-btn
                :width="rps === 'xl' || rps === 'lg' ? 210 : rps === 'md' ? 175 : rps === 'sm' ? 225 : 120"
                elevation="0"
                color="#FF3177"
                class="text-capitalize white--text"
                :disabled="process.cart || process.buynow"
                :loading="process.cart"
                @click="addToCart('cart')">
                <v-icon small class="mr-1">mdi-cart-arrow-down</v-icon>
                Keranjang
              </v-btn>

              <v-spacer/>

              <v-btn
                :width="rps === 'xl' || rps === 'lg' ? 210 : rps === 'md' ? 175 : rps === 'sm' ? 225 : 120"
                outlined
                elevation="0"
                color="#FF3177"
                class="text-capitalize color-first"
                :disabled="process.buynow || process.cart"
                :loading="process.buynow"
                @click="addToCart('buynow')">
                <v-icon small class="mr-1">mdi-credit-card-outline</v-icon>
                Beli
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.share"
      width="375"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="body-1 font-weight-bold text-second">
              Share {{ detail.kelas_nama }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third">
              Klik icon di bawah untuk share event ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in list.sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                :network="item.network"
                :url="`${host}${$route.path}`"
                :title="detail.kelas_slug"
                :description="detail.kelas_nama"
                hashtags="e-learning,bidankita">
                <v-img
                  width="45"
                  height="45"
                  contain
                  alt="e-learning"
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#333333"
            elevation="3"
            class="caption white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="dialog.snackbar"
      top
      color="#333333"
      vertical>
      <div class="body-1 font-weight-light white--text"
        v-html="dialog.stock">
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          class="body-1 text-capitalize"
          color="red"
          text
          v-bind="attrs"
          @click="dialog.snackbar = false">
          Ya, Saya mengerti
        </v-btn>
      </template>
    </v-snackbar>

    <v-layout wrap>
      <v-flex v-if="process.run && Object.keys(detail).length < 1">
        <section class="my-16">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="image">
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="8">
                <v-skeleton-loader
                  type="article, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="4">
                <v-skeleton-loader
                  type="image, article, table-heading, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
      
      <v-flex v-if="!process.run &&  Object.keys(detail).length > 0">
        <section class="mt-16">
          <div v-if="rps === 'sm' || rps === 'xs'">
            <v-card-text>
              <div class="d-flex align-center">
                <div class="d-flex align-center">
                  <v-rating
                    :value="parseFloat(detail.kelas_rating_summary.total)"
                    background-color="yellow darken-2"
                    color="yellow darken-2"
                    dense
                    disabled
                    size="18">
                  </v-rating>
                  <span class="text-second caption font-weight-bold ml-1">
                    ({{ parseFloat(detail.kelas_rating_summary.total).toFixed(1) }})
                  </span>
                </div>

                <v-spacer/>

                <div class="text-second" v-if="detail.kelas_total_materi > 0">
                  <span class="font-weight-bold">
                    {{ detail.kelas_total_materi }}
                  </span>
                  <span class="body-2">
                    Materi
                  </span>
                </div>
              </div>
            </v-card-text>
          </div>
          
          <v-img
            width="100%"
            height="50vh"
            :src="detail.kelas_image_jsonobejct.kelas_image_banner_url">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#FF3177">
                </v-progress-circular>
              </v-row>
            </template>

            <div style="background: rgb(255 49 119 / 60%); width: 100%; height: 100%;">
              <v-container style="width: 100%; height: 100%;">
                <v-row align="start" justify="start" style="width: 100%; height: 100%;">
                  <v-col cols="12" md="7" style="width: 100%; height: 100%;">
                    <v-card flat color="transparent" style="width: 100%; height: 100%;">
                      <div class="body-1  white--text">
                        {{ detail.kelas_type_title }}
                        <v-icon color="white">mdi-chevron-right</v-icon>
                        {{ detail.kelas_kategori_title }}
                        <span v-if="detail.kelas_kategori_child_title !== ''">
                          <v-icon color="white">mdi-chevron-right</v-icon>
                          {{ detail.kelas_kategori_child_title }}
                        </span>
                      </div>
                      
                      <v-card-text :class="rps === 'sm' || rps === 'xs' ? 'title' : 'text-h4'"
                        class="line-text-first text-capitalize font-weight-bold white--text px-0 mt-12"
                        style="min-height: 250px;">
                        <span class="four-line">
                          {{ detail.kelas_nama }}
                        </span>
                      </v-card-text>

                      <v-card-text class="px-0" style="position: absolute; bottom: 0px;">
                        <v-btn
                          elevation="0"
                          color="white"
                          class="body-1 text-capitalize color-first mr-3"
                          @click="dialog.share = true">
                          <v-icon small class="mr-1">mdi-share-variant</v-icon>
                          Bagikan
                        </v-btn>

                        <v-btn
                          elevation="0"
                          color="white"
                          class="body-1 text-capitalize color-first"
                          :disabled="process.wishlist"
                          :loading="process.wishlist"
                          @click="addWishlist">
                          <v-icon small class="mr-1">
                            {{ detail.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                          </v-icon>
                          Wishlist
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-img>
        </section>

        <section>
          <div v-if="rps === 'sm' || rps === 'xs'">
            <v-card-actions class="body-1 px-4 my-1">
              <div class="color-first font-weight-bold">
                <span v-if="detail.kelas_harga_jsonobject.harga_min === detail.kelas_harga_jsonobject.harga_max">
                  {{ parseInt(detail.kelas_harga_jsonobject.harga_max) | price }}
                </span>

                <span v-else>
                  {{ parseInt(detail.kelas_harga_jsonobject.harga_min) | price }}
                  -
                  {{ parseInt(detail.kelas_harga_jsonobject.harga_max) | price }}
                </span>
              </div>
            </v-card-actions>

            <div>
              <v-card-text class="pt-0">
                <v-btn
                  block
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize white--text"
                  :disabled="process.cart || process.buynow"
                  :loading="process.cart"
                  @click="addToCart('cart')">
                  Tambah Ke Keranjang
                </v-btn>
              </v-card-text>

              <v-card-text class="py-0">
                <v-btn
                  block
                  outlined
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize color-first"
                  :disabled="process.buynow || process.cart"
                  :loading="process.buynow"
                  @click="addToCart('buynow')">
                  Beli Sekarang
                </v-btn>
              </v-card-text>
            </div>
          </div>
          
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2"
                    style="min-width: 55px; max-width: 75px;">
                    <span class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item }}
                    </span>
                  </v-tab>
                </v-tabs>

                <!-- <v-tabs-items v-model="selected.menu">
                  <v-tab-item
                    v-for="(item, index) in menu"
                    :key="index"> -->
                    <v-card flat v-if="selected.menu < 1">
                      <v-row>
                        <v-col cols="12" class="px-0">
                          <v-card flat>
                            <v-card-title class="text-capitalize">
                              {{ detail.kelas_nama }}
                            </v-card-title>

                            <v-card-text class="body-1 font-weight-light">
                              Berikut adalah jadwal kelas <b>Live Streaming</b> :
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-expansion-panels flat v-model="selected.schedule">
                        <v-expansion-panel
                          v-for="(item, index) in detail.kelas_schedule" 
                          :key="index"
                          :style="selected.schedule === index ? 'border-top: 5px solid #FF3177;' : ''">
                          <v-expansion-panel-header style="background: #f0f0f0;" hide-actions>
                            <v-list dense class="py-0" color="transparent">
                              <v-list-item class="pa-0">
                                <v-list-item-content :style="rps === 'xs' ? 'width: 100px;' : ''">
                                  <div class="d-flex align-center mb-2">
                                    <div>
                                      <span class="body-2 d-flex align-center font-weight-bold text-capitalize"
                                        :class="selected.schedule !== index ? 'one-line' : ''">
                                        <v-icon size="20" class="mr-1">mdi-calendar-month</v-icon>
                                        {{ item.schedule_start_datetime | date }}
                                      </span>
                                    </div>

                                    <v-spacer/>

                                    <div v-if="selected.schedule === index">
                                      <v-icon color="#FF3177">mdi-check-bold</v-icon>
                                    </div>
                                  </div>

                                  <v-row>
                                    <v-col cols="12" md="4">
                                      <div v-if="dateNow < item.schedule_early_price_end_datetime">
                                        <p class="body-2 green--text mb-2">
                                          EARLY PRICE
                                        </p>

                                        <p class="body-1 font-weight-bold green--text mb-0">
                                          {{ item.schedule_early_price | price }}
                                        </p>
                                      </div>
                                      
                                      <div v-else>
                                        <p class="body-2 text-second mb-2">
                                          NORMAL PRICE
                                        </p>

                                        <p class="body-1 font-weight-bold mb-0">
                                          {{ item.schedule_normal_price | price }}
                                        </p>
                                      </div>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                      <p class="body-2 text-second mb-2">
                                        BERAKHIR
                                      </p>

                                      <p class="body-1 font-weight-light mb-0">
                                        <!-- {{ item.schedule_end_datetime | date }} -->
                                        <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                          {{ item.schedule_early_price_end_datetime | date }}
                                        </span>

                                        <span v-else>
                                          {{ item.schedule_normal_price_end_datetime | date }}
                                        </span>
                                      </p>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                      <p class="body-2 text-second mb-2">
                                        SISA KUOTA
                                      </p>

                                      <p class="body-1 mb-0">
                                        <span class="red--text font-weight-bold" v-if="item.schedule_quota_left < 1">
                                          HABIS
                                        </span>

                                        <span v-else>
                                          <span class="font-weight-bold">
                                            {{ item.schedule_quota_left }}
                                          </span> 
                                          dari
                                          <span class="font-weight-bold">
                                            {{ item.schedule_quota }}
                                          </span>
                                        </span>
                                      </p>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-header>

                          <v-divider
                            :style="selected.schedule === index ? 'border: 2px solid #f0f0f0' : 'border: 2px solid #FFF'"
                            v-if="index < detail.kelas_schedule.length - 1"/>

                          <v-expansion-panel-content>
                            <v-timeline
                              align-center
                              dense>
                              <v-timeline-item
                                color="#FF3177"
                                small
                                v-for="(sub, key) in item.schedule_time_jsonarray"
                                :key="key">
                                <v-row align="center">
                                  <v-col cols="4">
                                    <strong>{{ sub.date | date }}</strong>
                                  </v-col>

                                  <v-col cols="4">
                                    <strong>{{ sub.start }}</strong>
                                    <div class="caption">
                                      Mulai
                                    </div>
                                  </v-col>

                                  <v-col cols="4">
                                    <strong>{{ sub.end }}</strong>
                                    <div class="caption">
                                      Selesai
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-timeline-item>
                            </v-timeline>
                              
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>

                    <v-card flat v-else>
                      <v-row>
                        <v-col cols="12" class="px-0">
                          <v-card flat>
                            <v-card-title class="text-capitalize">
                              {{ detail.kelas_nama }}
                            </v-card-title>

                            <v-card-text class="body-1 font-weight-light line-text-second">
                              <span v-html="detail.kelas_deskripsi"></span>
                            </v-card-text>

                            <v-card-text v-if="detail.kelas_total_materi > 0">
                              <v-icon>mdi-bookshelf</v-icon>
                              <span class="text-second body-1 font-weight-bold mr-1">
                                {{ detail.kelas_total_materi }}
                              </span> 
                              <span class="text-second body-2 font-weight-light">
                                Materi
                              </span>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <div v-if="rps === 'sm' || rps === 'xs'">
                        <p class="title mt-6 px-4">
                          Instruktur
                        </p>

                        <v-card
                          class="box-shadow border-radius">
                          <div class="pa-2">
                            <v-card-text class="d-flex align start">
                              <div>
                                <v-avatar size="50">
                                  <v-img
                                    :src="detail.kelas_mentor_jsonarray[0].kelas_mentor_image_url"
                                    alt="mentor elearning bidan kita">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#FF3177">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>
                              </div>

                              <v-spacer/>

                              <div>
                                <div class="d-flex align-center mb-2">
                                  <div>
                                    <v-rating
                                      :value="5"
                                      background-color="yellow darken-2"
                                      color="yellow darken-2"
                                      dense
                                      disabled
                                      size="18">
                                    </v-rating>
                                  </div>
                                  <div class="text-second caption font-weight-bold ml-1">
                                    (5)
                                  </div>
                                </div>
                              </div>
                            </v-card-text>

                            <v-card-text>
                              <p class="body-1 font-weight-bold text-capitalize mb-2">
                                {{ detail.kelas_mentor_jsonarray[0].kelas_mentor_nama }}
                              </p>

                              <p class="body-2 font-weight-light line-text-second mb-0">
                                N.A
                              </p>
                            </v-card-text>

                            <!-- <v-card-text>
                              <v-btn
                                rounded
                                outlined
                                elevation="0"
                                color="grey"
                                class="text-capitalize">
                                <div>
                                  Selengkapnya
                                </div>

                                <v-spacer/>

                                <div>
                                  <v-icon small color="grey" class="ml-2">mdi-arrow-right</v-icon>
                                </div>
                              </v-btn>
                            </v-card-text> -->
                          </div>
                        </v-card>

                        <p class="body-1 mt-6 mb-0 px-4" v-if="detail.kelas_mentor_jsonarray.length > 1">
                          Instruktur Lainnya
                        </p>

                        <v-list two-line>
                          <v-list-item
                            class="px-0"
                            v-for="(item, index) in detail.kelas_mentor_jsonarray" 
                            :key="index"
                            v-show="index > 0">
                            <v-list-item-avatar size="50">
                              <v-img
                                :src="item.kelas_mentor_image_url"
                                alt="mentor elearning bidan kita">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold text-capitalize mb-2">
                                {{ item.kelas_mentor_nama }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="body-2 font-weight-light line-text-second">
                                N.A
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>

                      <v-row>
                        <v-col cols="12" class="px-0">
                          <v-expansion-panels flat v-model="selected.panel">
                            <v-expansion-panel
                              v-for="(item, index) in detail.kelas_materi_jsonarray" 
                              :key="index">
                              <v-expansion-panel-header style="background: #f0f0f0;">
                                <v-list dense class="py-0" color="transparent">
                                  <v-list-item class="py-0 pl-0">
                                    <v-list-item-content :style="rps === 'xs' ? 'width: 100px;' : ''">
                                      <span class="body-1 font-weight-bold text-capitalize"
                                        :class="selected.panel !== index ? 'one-line' : ''">
                                        {{ item.title }}
                                      </span>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <template v-slot:actions>
                                  <div style="background: #ffffff; border-radius: 5px; padding: 5px 15px;">
                                    <span class="body-2 font-weight-bold">
                                      {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                                    </span>
                                    <span class="caption text-second">
                                      Materi
                                    </span>
                                    <v-icon color="#7B7B7B" small>
                                      $expand
                                    </v-icon>
                                  </div>
                                </template>
                              </v-expansion-panel-header>

                              <v-expansion-panel-content class="pa-0">
                                <v-list flat three-line dense class="py-0" color="transparent">
                                  <v-list-item-group>
                                    <v-list-item 
                                      :class="{
                                        'my-6': item.total_sub_materi > 0,
                                        'mt-6': item.total_sub_materi < 1,
                                        'px-0': rps === 'xl' || rps === 'lg'
                                      }"
                                      @click="item.preview ? playing(item) : ''">
                                      <v-list-item-content>
                                        <div class="d-flex align-start">
                                          <div class="mr-6">
                                            <v-img
                                              :width="rps !== 'xs' ? 200 : 65"
                                              :height="rps !== 'xs' ? 140 : 65"
                                              class="d-flex align-center justify-center"
                                              gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                              :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                              style="border-radius: 5px;">
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height"
                                                  align="center"
                                                  justify="center">
                                                  <v-progress-circular 
                                                    indeterminate 
                                                    color="#FF3177">
                                                  </v-progress-circular>
                                                </v-row>
                                              </template>

                                              <div class="d-flex align-center justify-center" v-if="!item.preview && item.content_jsonobject.type !== ''">
                                                <v-icon :style="rps !== 'xs' ? 'font-size: 50px;' : 'font-size: 30px;'" color="#FF3177">mdi-lock</v-icon>
                                              </div>
                                            </v-img>
                                          </div>
                                          
                                          <div class="font-weight-light line-text-second text-second"
                                            :class="rps !== 'xs' ? 'body-1' : 'caption'">
                                            <span v-if="rps !== 'xs'" 
                                              v-html="item.deskripsi !== '' ? item.deskripsi : `Materi ${item.title}`">
                                            </span> 

                                            <span v-if="rps === 'xs'" class="three-line" 
                                              v-html="item.deskripsi !== '' ? item.deskripsi.replace(/<\/?[^>]+>/ig, '') : `Materi ${item.title}`">
                                            </span>
                                          </div>
                                        </div>
                                      </v-list-item-content>

                                      <v-list-item-action class="ml-6" v-if="item.content_jsonobject.type !== ''">
                                        <div class="text-center">
                                          <v-icon 
                                            :large="rps !== 'xs'"
                                            :color="item.preview ? '#FF3177' : '#cacaca'">
                                            {{ 
                                              item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                              item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                              item.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                            }}
                                          </v-icon>
                                          <p class="caption text-capitalize mb-0"
                                            :class="item.preview ? 'color-first' : 'preview-lock'">
                                            <span v-if="item.content_jsonobject.type !== 'docs'">
                                              {{ item.preview ? 'preview' : 'play' }}
                                            </span>
                                            <span v-else>
                                              {{ item.preview ? 'preview' : 'baca' }}
                                            </span>
                                          </p>
                                        </div>
                                      </v-list-item-action>
                                    </v-list-item>

                                    <div v-if="item.total_sub_materi > 0">
                                      <v-list-item class="mt-6" :class="rps === 'xl' || rps === 'lg' ? 'px-0' : ''"
                                        v-for="(sub, key) in item.sub_materi" :key="key"
                                        @click="sub.preview ? playing(sub) : ''">
                                        <v-list-item-content>
                                          <div class="d-flex align-start">
                                            <div 
                                              :class="rps !== 'xs' ? 'mr-6' : 'mr-3'" 
                                              style="border-left: 10px solid #f0f0f0; border-radius: 5px;"
                                              :style="rps !== 'xs' ? 'height: 90px;' : 'height: 55px;'">
                                            </div>

                                            <div :class="rps !== 'xs' ? 'mr-10' : 'mr-5'">
                                              <v-img
                                                :width="rps !== 'xs' ? 150 : 55"
                                                :height="rps !== 'xs' ? 90 : 55"
                                                class="d-flex align-center justify-center"
                                                gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                                :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                                style="border-radius: 5px;">
                                                <template v-slot:placeholder>
                                                  <v-row
                                                    class="fill-height"
                                                    align="center"
                                                    justify="center">
                                                    <v-progress-circular 
                                                      indeterminate 
                                                      color="#FF3177">
                                                    </v-progress-circular>
                                                  </v-row>
                                                </template>

                                                <div class="d-flex align-center justify-center" v-if="!sub.preview && sub.content_jsonobject.type !== ''">
                                                  <v-icon :style="rps !== 'xs' ? 'font-size: 50px;' : 'font-size: 30px;'" color="#FF3177">mdi-lock</v-icon>
                                                </div>
                                              </v-img>
                                            </div>
                                            
                                            <div>
                                              <p class="font-weight-bold text-capitalize mb-2"
                                                :class="rps !== 'xs' ? 'body-1' : 'caption'">
                                                <span :class="rps !== 'xs' ? '' : 'one-line'">
                                                  {{ sub.title }}
                                                </span>
                                              </p>

                                              <p class="font-weight-light line-text-second text-second mb-0"
                                                :class="rps !== 'xs' ? 'body-1' : 'caption'">
                                                <span v-if="rps !== 'xs'" 
                                                  v-html="sub.deskripsi">
                                                </span> 

                                                <span v-if="rps === 'xs'" class="two-line" 
                                                  v-html="sub.deskripsi.replace(/<\/?[^>]+>/ig, '')">
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </v-list-item-content>

                                        <v-list-item-action class="ml-6" v-if="sub.content_jsonobject.type !== ''">
                                          <div class="text-center">
                                            <v-icon 
                                              :large="rps !== 'xs'"
                                              :color="sub.preview ? '#FF3177' : '#cacaca'">
                                              {{ 
                                                sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                                sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                                sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                              }}
                                            </v-icon>
                                            <p class="caption text-capitalize mb-0"
                                              :class="sub.preview ? 'color-first' : 'preview-lock'">
                                              <span v-if="sub.content_jsonobject.type !== 'docs'">
                                                {{ sub.preview ? 'preview' : 'play' }}
                                              </span>
                                              <span v-else>
                                                {{ sub.preview ? 'preview' : 'baca' }}
                                              </span>
                                            </p>
                                          </div>
                                        </v-list-item-action>
                                      </v-list-item>
                                    </div>
                                  </v-list-item-group>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>

                      <v-row align="center" v-if="!process.review && list.review.length > 0">
                        <v-col cols="6">
                          <p class="font-weight-bold mb-0"
                            :class="rps === 'sm' || rps === 'xs' ? 'body-1' : 'title'">
                            <span class="color-first mr-1">
                              Review
                            </span>
                            & Ulasan
                          </p>
                        </v-col>

                        <v-col cols="6" class="body-1 text-right">
                          <span class="text-first font-weight-bold">
                            {{ pagination.review.total_data }}
                          </span>
                          <span class="body-2 font-weight-light text-first">
                            Ulasan
                          </span>
                        </v-col>
                      </v-row>

                      <v-divider v-if="!process.review && list.review.length > 0"/>

                      <v-row v-if="!process.review && list.review.length > 0">
                        <v-col cols="12">
                          <v-card-text>
                            <v-row class="align-center">
                              <v-col cols="12" md="2" class="text-center">
                                <div class="text-h2 font-weight-bold mb-2">
                                  {{ parseFloat(total_rating).toFixed(1) }}
                                </div>

                                <v-rating dense
                                  size="15"
                                  :value="total_rating"
                                  readonly
                                  color="#FF3177"
                                  background-color="#FF3177"
                                  half-increments>
                                </v-rating>

                                <div class="body-2 font-weight-light text-second mt-1">
                                  Rating Kelas
                                </div>
                              </v-col>

                              <v-col cols="12" md="10">
                                <div 
                                  v-for="(item, index) in data_rating"
                                  :key="index">
                                  <div class="d-flex align-center">
                                    <div :class="rps === 'xl' || rps === 'lg' ? 'rating-progress' : rps === 'md' ? 'rating-progress-md' : 'rating-progress-sm-xs'">
                                      <v-progress-linear
                                        color="#FF3177"
                                        background-color="#ffe6ee"
                                        height="10"
                                        rounded
                                        :value="item.percent">
                                      </v-progress-linear>
                                    </div>

                                    <div class="mr-auto mb-1 pl-8">
                                      <v-icon 
                                        :small="rps === 'lg' || rps === 'md'"
                                        :size="rps === 'sm' || rps === 'xs' ? 12 : 0"
                                        color="#FF3177" 
                                        v-for="(s, i) in 5 - index" 
                                        :key="i">
                                        mdi-star
                                      </v-icon>
                                    </div>
                                    
                                    <div 
                                      class="body-1 font-weight-light ml-auto grey--text text--darken-1">
                                      <span>
                                        {{ parseInt(item.percent) }}%
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-col>
                      </v-row>

                      <v-row v-if="!process.review && list.review.length > 0">
                        <v-col cols="12" class="d-flex align-center">
                          <div class="mr-2" v-show="rps !== 'xs'"
                            v-for="(item, index) in rating" :key="index">
                            <v-btn 
                              outlined
                              rounded
                              :color="selected.rating === item.value ? '#FF3177' : 'grey'"
                              class="body-1 text-capitalize"
                              @click="selected.rating = item.value; fetchReview();">
                              <v-icon small class="mr-1" :color="selected.rating === item.value ? '#FF3177' : 'grey'">
                                {{ selected.rating === item.value ? 'mdi-star-check' : 'mdi-star-outline' }}
                              </v-icon>
                              {{ item.label }}
                            </v-btn>
                          </div>

                          <v-select v-show="rps === 'xs'"
                            dense
                            outlined
                            color="#FF3177"
                            hide-details
                            single-line
                            :items="rating"
                            item-text="label"
                            item-value="value"
                            item-color="#FF3177"
                            v-model="selected.rating"
                            v-on:change="fetch()">
                          </v-select>
                        </v-col>
                      </v-row>

                      <p class="body-1 font-weight-bold mt-6" v-if="!process.review && list.review.length > 0">
                        Semua Ulasan ({{ pagination.review.total_data }})
                      </p>

                      <v-skeleton-loader
                        v-for="(item, index) in process.review ? 5 : list.review" :key="index"
                        class="ma-auto"
                        :loading="process.review"
                        type="list-item-avatar-three-line">
                        <div>
                          <v-row v-if="!process.review && list.review.length > 0">
                            <v-col cols="12">
                              <v-list dense three-line>
                                <v-list-item class="px-0">
                                  <v-list-item-avatar size="50">
                                    <v-img
                                      :src="item.rating_user_image_url !=='' ? item.rating_user_image_url : require('@/assets/image/member-default.jpg')">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#FF3177">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-list-item-avatar>

                                  <v-list-item-content>
                                    <v-list-item-title class="body-1 font-weight-bold mb-2 d-flex align-center">
                                      <div>
                                        {{ item.rating_user_name }}
                                      </div>

                                      <v-spacer/>

                                      <div class="d-flex align-center">
                                        <v-rating dense
                                          :value="parseInt(item.rating_value)"
                                          small
                                          readonly
                                          color="yellow darken-2"
                                          background-color="yellow darken-2">
                                        </v-rating>
                                        <span class="text-second caption font-weight-bold ml-1">
                                          ({{ parseFloat(item.rating_value).toFixed(1) }})
                                        </span>
                                      </div>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="caption font-weight-light">
                                      {{ item.rating_update_datetime | ago }}
                                    </v-list-item-subtitle>

                                    <p class="body-2 font-weight-light line-text-second mt-1 mb-0">
                                      {{ item.rating_content_jsonobject.value }}
                                    </p>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                        </div>
                      </v-skeleton-loader>

                      <v-card-text class="text-center" v-if="pagination.review.total_data > 8 && limit < pagination.review.total_data">
                        <v-btn
                          small
                          elevation="0"
                          color="#f1f1f1"
                          class="caption text-second text-capitalize"
                          @click="limit += 8">
                          Lihat lebih banyak (+{{ pagination.review.total_data - limit}})
                          <v-icon small color="#7B7B7B" class="ml-1">mdi-chevron-down</v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  <!-- </v-tab-item>
                </v-tabs-items> -->
              </v-col>

              <v-col cols="12" md="4" class="pl-12" v-if="rps === 'xl' || rps === 'lg' || rps === 'md'">
                <div :class="scroll ? 'on-scroll-sticky' : 'on-scroll-ralative'" v-scroll="onScroll">
                  <v-card
                    class="box-shadow border-radius">
                    <v-img
                      width="100%"
                      height="250"
                      :src="detail.kelas_image_jsonobejct.kelas_image_cover_url">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div class="burst" v-if="detail.kelas_is_discount">
                      <span>
                        OFF {{ detail.kelas_diskon_jsonobject.percent }}%
                      </span>
                    </div>

                    <div class="burst_free" v-if="!detail.kelas_is_paid">
                      <span>
                        FREE
                      </span>
                    </div>

                    <div class="pa-2">
                      <v-card-text>
                        <p class="title font-weight-bold line-text-first text-first text-capitalize">
                          {{ detail.kelas_nama }}
                        </p>
                        <p class="body-1 text-second mb-0">
                          {{ detail.kelas_kategori_title }}
                        </p>
                      </v-card-text>

                      <div>
                        <v-card-text class="pb-0">
                          <v-btn
                            block
                            elevation="0"
                            color="#FF3177"
                            class="text-capitalize white--text"
                            :disabled="process.cart || process.buynow"
                            :loading="process.cart"
                            @click="addToCart('cart')">
                            Tambah Ke Keranjang
                          </v-btn>
                        </v-card-text>

                        <v-card-text>
                          <v-btn
                            outlined
                            block
                            elevation="0"
                            color="#FF3177"
                            class="text-capitalize color-first"
                            :disabled="process.buynow || process.cart"
                            :loading="process.buynow"
                            @click="addToCart('buynow')">
                            Beli Sekarang
                          </v-btn>
                        </v-card-text>
                      </div>

                      <v-card-text>
                        <div class="d-flex align-center">
                          <div class="d-flex align-center">
                            <v-rating
                              :value="parseFloat(detail.kelas_rating_summary.total)"
                              background-color="yellow darken-2"
                              color="yellow darken-2"
                              dense
                              disabled
                              size="18">
                            </v-rating>
                            <span class="text-second caption font-weight-bold ml-1">
                              ({{ parseFloat(detail.kelas_rating_summary.total).toFixed(1) }})
                            </span>
                          </div>

                          <v-spacer/>

                          <div class="text-second" v-if="detail.kelas_total_materi > 0">
                            <span class="font-weight-bold">
                              {{ detail.kelas_total_materi }}
                            </span>
                            <span class="body-2">
                              Materi
                            </span>
                          </div>
                        </div>
                      </v-card-text>

                      <v-divider class="mb-2 mx-4"/>

                      <v-card-actions class="body-1 px-4">
                        <div class="color-first font-weight-bold">
                          <span v-if="detail.kelas_harga_jsonobject.harga_min === detail.kelas_harga_jsonobject.harga_max">
                            {{ parseInt(detail.kelas_harga_jsonobject.harga_max) | price }}
                          </span>

                          <span v-else>
                            {{ parseInt(detail.kelas_harga_jsonobject.harga_min) | price }}
                            -
                            {{ parseInt(detail.kelas_harga_jsonobject.harga_max) | price }}
                          </span>
                        </div>
                      </v-card-actions>
                    </div>
                  </v-card>

                  <p class="title mt-6">
                    Instruktur
                  </p>

                  <v-card
                    class="box-shadow border-radius">
                    <div class="pa-2">
                      <v-card-text class="d-flex align start">
                        <div>
                          <v-avatar size="50">
                            <v-img
                              :src="detail.kelas_mentor_jsonarray[0].kelas_mentor_image_url"
                              alt="mentor elearning bidan kita">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </div>

                        <v-spacer/>

                        <div>
                          <div class="d-flex align-center mb-2">
                            <div>
                              <v-rating
                                :value="5"
                                background-color="yellow darken-2"
                                color="yellow darken-2"
                                dense
                                disabled
                                size="18">
                              </v-rating>
                            </div>
                            <div class="text-second caption font-weight-bold ml-1">
                              (5)
                            </div>
                          </div>
                        </div>
                      </v-card-text>

                      <v-card-text>
                        <p class="body-1 font-weight-bold text-capitalize mb-2">
                          {{ detail.kelas_mentor_jsonarray[0].kelas_mentor_nama }}
                        </p>

                        <p class="body-2 font-weight-light line-text-second mb-0">
                          N.A
                        </p>
                      </v-card-text>

                      <!-- <v-card-text>
                        <v-btn
                          rounded
                          outlined
                          elevation="0"
                          color="grey"
                          class="text-capitalize">
                          <div>
                            Selengkapnya
                          </div>

                          <v-spacer/>

                          <div>
                            <v-icon small color="grey" class="ml-2">mdi-arrow-right</v-icon>
                          </div>
                        </v-btn>
                      </v-card-text> -->
                    </div>
                  </v-card>

                  <p class="body-1 mt-6 mb-0" v-if="detail.kelas_mentor_jsonarray.length > 1">
                    Instruktur Lainnya
                  </p>

                  <v-list two-line>
                    <v-list-item
                      class="px-0"
                      v-for="(item, index) in detail.kelas_mentor_jsonarray" 
                      :key="index"
                      v-show="index > 0">
                      <v-list-item-avatar size="50">
                        <v-img
                          :src="item.kelas_mentor_image_url"
                          alt="mentor elearning bidan kita">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold text-capitalize mb-2">
                          {{ item.kelas_mentor_nama }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-2 font-weight-light line-text-second">
                          N.A
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  export default {
    middleware: false,
    data () {
      return {
        dateNow: moment().format('YYYY-MM-DD H:m:s'),
        scroll: false,
        dialog: {
          auth: '',
          message: '',
          player: false,
          share: false,
          snackbar: false,
          stock: ''
        },
        menu: ['Jadwal', 'Informasi'],
        selected: {
          menu: 0,
          schedule: 0,
          panel: 0,
          rating: ''
        },
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        detail: {},
        rating: [
          {
            label: 'Semua',
            value: ''
          },
          {
            label: 5,
            value: 5
          },
          {
            label: 4,
            value: 4
          },
          {
            label: 3,
            value: 3
          },
          {
            label: 2,
            value: 2
          },
          {
            label: 1,
            value: 1
          }
        ],
        total_rating: 0,
        data_rating: [],
        list: {
          review: [],
          sosmed: [
            { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
            { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
            { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
            { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
          ]
        },
        limit: 8,
        pagination: {
          review: {}
        },
        process: {
          run: false,
          cart: false,
          buynow: false,
          review: false,
          share: false,
          wishlist: false
        },
        message: '',
        // SEO
        content: {
          url: 'elearning:slug',
          title: 'E-Learning Bidan Kita',
          description: 'E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message'),
      Players: () => import('@/components/Player')
    },
    watch: {
      'limit': function() {
        this.fetchReview(false)
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    beforeCreate () {
      let params = {
        slug: this.$route.params.slug
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/detail`, { params })
      .then((response) => {
        this.content = {
          url: `elearning/${response.data.kelas_slug}`,
          title: response.data.kelas_nama,
          description: response.data.kelas_deskripsi,
          image: response.data.kelas_image_jsonobejct.kelas_image_cover_url
        }
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      onScroll(e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.scroll = top > window.innerHeight - 500
      },
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          slug: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data
            
            let data = this.detail.kelas_schedule
            for(let i = 0;i < data.length;i++) {
              if (data[i].schedule_quota_left > 0) {
                this.selected.schedule = i
                break
              }
            }
            
            this.fetchReview()
          }
        })
      },
      async fetchReview(type) {
        this.process.review = type === undefined ? true : false
        
        let params = {
          limit: this.limit,
          id: this.detail.kelas_id,
          filter: [
            {
              type: 'string',
              field: 'rating_value',
              value: this.selected.rating
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/rating`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.review = false

            this.total_rating = parseFloat(response.data.total_rating)
            this.data_rating = response.data.data_rating

            this.list.review = response.data.results
            this.pagination.review = response.data.pagination
          }
        })
      },
      playing(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        let status = false
        if (item.title === this.player.data.title) {
          if(!this.player.media.is_play) {
            status = true
          } else {
            status = false
          }
        } else {
          status = true
        }

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: status
        }
        this.player.data = item

        this.dialog.player = true
      },
      async addToCart(type) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          if (this.selected.schedule === undefined) {
            this.dialog.stock = 'Silahkan pilih jadwal terlebih dahulu.'
            this.dialog.snackbar = true
          } else {
            if (this.detail.kelas_schedule[this.selected.schedule].schedule_quota_left < 1) {
              this.dialog.stock = 'Kuota habis, silahkan pilih jadwal lainnya.'
              this.dialog.snackbar = true
            } else {
              this.saveCart(type)
            }
          }
        }
      },
      async saveCart(type) {
        this.process.cart = type === 'cart' ? true : false
        this.process.buynow = type === 'buynow' ? true : false

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: this.detail.kelas_id,
          schedule_id: this.detail.kelas_schedule[this.selected.schedule].schedule_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false
            this.process.buynow = false

            this.$store.commit('header/setCart', this.headers.cart + 1)

            if (type === 'cart') {
              this.dialog.message = 'success'
              this.message = 'Berhasil ditambahkan ke Keranjang.'
            } else {
              this.$router.push('/cart')
            }
          } else {
            this.process.cart = false
            this.process.buynow = false
            
            this.dialog.message = 'error'
            this.message = response.message
          }
        })
      },
      async addWishlist() {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveWishlist()
        }
      },
      async saveWishlist() {
        this.process.wishlist = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/wishlist/add_remove`, {
          kelas_id: this.detail.kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.wishlist = false
            
            this.dialog.message = 'wishlist'
            this.message = this.detail.kelas_is_wishlist === '1' ? 'Berhasil ditambahkan ke Wishlist.' : 'Berhasil dihapus dari Wishlist.'

            this.fetch(true)
          }
        })
      },
      async addShare() {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveShare()
        }
      },
      async saveShare() {
        this.process.share = true

        
      }
    }
  }
</script>

<style scoped>
.rating-progress {
  max-width: 70%;
  min-width: 70%;
}
.rating-progress-md {
  max-width: 60%;
  min-width: 60%;
}
.rating-progress-sm-xs {
  max-width: 50%;
  min-width: 50%;
}
.footer-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}
.on-scroll-ralative {
  position: relative; top: -350px;
}
.on-scroll-sticky {
  position: sticky; top: 100px;
}
.preview-lock {
  color: #cacaca;
}
</style>