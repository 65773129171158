import { render, staticRenderFns } from "./_slugNew.vue?vue&type=template&id=5f44eef7&scoped=true&"
import script from "./_slugNew.vue?vue&type=script&lang=js&"
export * from "./_slugNew.vue?vue&type=script&lang=js&"
import style0 from "./_slugNew.vue?vue&type=style&index=0&id=5f44eef7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f44eef7",
  null
  
)

export default component.exports