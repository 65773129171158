<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex v-if="process.run && Object.keys(detail).length < 1">
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-skeleton-loader
                  type="image, article, article, list-item-avatar-three-line, article, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="4">
                <v-skeleton-loader
                  type="article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>

      <v-flex v-if="!process.run &&  Object.keys(detail).length > 0">
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" :md="detail.kelas_total_materi > 0 || detail.schedule_materi_total > 0 ? 8 : 12">
                <p class="title one-line">
                  {{ detail.kelas_nama }}
                </p>
                
                <v-card v-if="player.media.is_play"
                  flat
                  color="black"
                  :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" 
                  style="border-radius: 15px;"
                  class="mb-3">
                  <Players :player="player.media" :play="player.data" :height="rps !== 'xs' ? 500 : player.media.type !== 'docs' ? 300 : 375" />
                </v-card>

                <v-card v-else
                  flat
                  color="black"
                  :height="rps !== 'xs' ? 500 : 300" 
                  style="border-radius: 15px;"
                  class="mb-3 d-flex align-center justify-center">
                  <v-img
                    class="d-flex align-center justify-center"
                    :height="rps !== 'xs' ? 500 : 300" 
                    gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                    :src="detail.kelas_image_jsonobejct.kelas_image_cover_url">

                    <v-card-text class="text-center">
                      <div>
                        <v-icon :size="rps === 'xs' ? 75 : 125"
                          :color="detail.schedule_status === 'Belum Mulai' ? 'white' :
                                  detail.schedule_status === 'Sedang Berlangsung' ? 'green' :
                                  detail.schedule_status === 'Selesai' ? 'blue' :
                                  detail.schedule_status === 'Dibatalkan' ? 'red' : 'orange'">
                          {{ 
                            detail.schedule_status === 'Belum Mulai' ? 'mdi-information-outline' :
                            detail.schedule_status === 'Sedang Berlangsung' ? 'mdi-play-circle-outline' :
                            detail.schedule_status === 'Selesai' ? 'mdi-check-circle-outline' :
                            detail.schedule_status === 'Dibatalkanc' ? 'mdi-close-circle-outline' : 'mdi-swap-horizontal-circle-outline'
                          }}
                        </v-icon>

                        <p class="text-uppercase font-weight-bold mt-3"
                          :class="rps === 'xs' ? 'text-h5' : 'text-h4'">
                          <span :class="detail.schedule_status === 'Belum Mulai' ? 'white--text' :
                                  detail.schedule_status === 'Sedang Berlangsung' ? 'green--text' :
                                  detail.schedule_status === 'Selesai' ? 'blue--text' :
                                  detail.schedule_status === 'Dibatalkan' ? 'red--text' : 'orange--text'">
                            {{ detail.schedule_status }}
                          </span>
                        </p>

                        <p class="title font-weight-light">
                          <span :class="detail.schedule_status === 'Belum Mulai' ? 'white--text' :
                                  detail.schedule_status === 'Sedang Berlangsung' ? 'green--text' :
                                  detail.schedule_status === 'Selesai' ? 'blue--text' :
                                  detail.schedule_status === 'Dibatalkan' ? 'red--text' : 'orange--text'">
                            <span v-if="detail.schedule_status === 'Belum Mulai'">
                              Akan dimulai pada
                            </span>

                            <span v-if="detail.schedule_status === 'Sedang Berlangsung'">
                              Live Streaming sedang berlangsung
                            </span>

                            <span v-if="detail.schedule_status === 'Selesai'">
                              Live Streaming telah berakhir
                            </span>

                            <span v-if="detail.schedule_status === 'Dibatalkan'">
                              Live Streaming dibatalkan
                            </span>

                            <span v-if="detail.schedule_status === 'Reschedule'">
                              Live Streaming di jadwalkan ulang
                            </span>
                          </span>
                        </p>

                        <p class="title font-weight-bold white--text">
                          <span :class="detail.schedule_status === 'Belum Mulai' ? 'white--text' :
                                  detail.schedule_status === 'Sedang Berlangsung' ? 'green--text' :
                                  detail.schedule_status === 'Selesai' ? 'blue--text' :
                                  detail.schedule_status === 'Dibatalkan' ? 'red--text' : 'orange--text'">
                            <span v-if="detail.schedule_status === 'Belum Mulai'">
                              {{ detail.schedule_start_datetime | date }}
                              Pukul
                              {{ detail.schedule_start_datetime | time }}
                            </span>
                          </span>
                        </p>
                      </div>

                      <v-btn v-if="detail.schedule_status === 'Sedang Berlangsung'"
                        elevation="0"
                        rounded
                        color="green"
                        class="body-1 text-capitalize white--text pl-2 mt-3"
                        :href="detail.schedule_zoom_link"
                        target="_blank">
                        <v-icon class="mr-1" color="white">mdi-record</v-icon>
                        Bergabung Sekarang
                      </v-btn>

                      <v-btn v-if="detail.schedule_status === 'Reschedule'"
                        elevation="0"
                        rounded
                        color="orange"
                        class="body-1 text-capitalize white--text mt-3"
                        :href="`https://kelas.bidankita.com/collection/streaming`"
                        target="_blank">
                        Lihat Jadwal Pengganti
                        <v-icon class="ml-1" color="white">mdi-arrow-right</v-icon>
                      </v-btn>

                      <v-btn v-if="detail.schedule_status === 'Selesai' || detail.schedule_status === 'Dibatalkan'"
                        elevation="0"
                        rounded
                        :color="detail.schedule_status === 'Selesai' ? 'blue' : 'red'"
                        class="body-1 text-capitalize white--text mt-3"
                        :href="`https://kelas.bidankita.com/streaming/${detail.kelas_slug}`"
                        target="_blank">
                        Lihat Jadwal Lainnya
                        <v-icon class="ml-1" color="white">mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-img>
                </v-card>

                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow>
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mr-2"
                    style="min-width: 55px; max-width: 95px;">
                    <span class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="selected.menu">
                  <v-tab-item
                    v-for="(item, index) in menu"
                    :key="index">
                    <v-card v-if="rps === 'xl' || rps === 'lg' || rps === 'md'"
                      flat
                      class="py-6">
                      <Schedule :detail="detail" v-if="selected.menu === 0" />
                      <Information :detail="detail" v-if="selected.menu === 1" />
                      <Discus :detail="detail" v-if="selected.menu === 2" />
                      <Announcement :detail="detail" v-if="selected.menu === 3" />
                      <Review :detail="detail" v-if="selected.menu === 4" />
                    </v-card>

                    <v-card v-if="rps === 'sm' || rps === 'xs'"
                      flat
                      :class="selected.menu !== 1 ? 'py-6' : ''">
                      <Schedule :detail="detail" v-if="selected.menu === 0" />
                      <Information :detail="detail" v-if="selected.menu === 2" />
                      <Discus :detail="detail" v-if="selected.menu === 3" />
                      <Announcement :detail="detail" v-if="selected.menu === 4" />
                      <Review :detail="detail" v-if="selected.menu === 5" />
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>

              <v-col cols="12" md="4" v-if="(rps === 'xl' || rps === 'lg' || rps === 'md' || selected.menu === 1) && (detail.kelas_total_materi > 0 || detail.schedule_materi_total > 0)">
                <v-card flat style="position: sticky; top: 90px;">
                  <v-list dense class="py-0" color="transparent">
                    <v-list-item class="px-0">
                      <v-list-item-content class="pt-0">
                        <v-list-item-title class="title font-weight-bold text-capitalize">
                          <v-menu
                            offset-y>
                            <template v-slot:activator="{ attrs, on }">
                              <v-btn
                                text
                                class="title font-weight-bold text-capitalize px-0"
                                v-bind="attrs"
                                v-on="on">
                                <span class="text-first">
                                  <span class="color-first">
                                    Materi
                                  </span>
                                  {{ selected.menu_materi }}
                                  <v-icon>mdi-chevron-down</v-icon>
                                </span>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item
                                v-for="(item, index) in menu_materi"
                                :key="index"
                                link
                                @click="
                                  selected.menu_materi = item; 
                                  selected.panel = 0; 
                                  player = {
                                    media: {
                                      type: '',
                                      is_play: false
                                    },
                                    data: {}
                                  };">
                                <v-list-item-title>
                                  {{ item }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action class="mt-0">
                        <div style="background: #f0f0f0; border-radius: 5px; padding: 5px 15px;">
                          <span class="body-1 font-weight-bold">
                            {{ selected.menu_materi === 'Umum' ? detail.kelas_total_materi : detail.schedule_materi_total }}
                          </span>
                          <span class="body-2 text-second">
                            Materi
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-divider style="border-color: rgb(0 0 0 / 8%);"/>

                  <!-- MATERI KELAS -->
                  <v-expansion-panels flat v-if="selected.menu_materi === 'Umum'" v-model="selected.panel">
                    <v-expansion-panel
                      v-for="(item, index) in detail.kelas_materi_jsonarray" 
                      :key="index">
                      <v-expansion-panel-header class="pa-0">
                        <v-list dense class="py-0" color="transparent">
                          <v-list-item class="py-0 pl-0">
                            <v-list-item-content :style="rps !== 'xs' ? 'width: 250px;' : ''">
                              <span class="body-1 font-weight-bold text-capitalize"
                                :class="selected.panel !== index ? 'one-line' : ''">
                                {{ item.title }}
                              </span>
                            </v-list-item-content>

                            <v-list-item-action>
                              <div style="border-radius: 5px; padding: 5px 15px;"
                                :style="item.materi_is_read ? 'background: #FF3177;' : 'background: #f0f0f0;'">
                                <span class="body-1 font-weight-bold"
                                  :class="item.materi_is_read ? 'white--text' : ''">
                                  {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                                </span>
                                <span class="body-2" :class="item.materi_is_read ? 'white--text' : 'text-second'">
                                  Materi
                                </span>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <template v-slot:actions>
                          <v-icon color="#FF3177">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content class="pa-0">
                        <v-divider style="border-color: rgb(0 0 0 / 8%);"/>

                        <v-list flat three-line dense class="py-0" color="transparent">
                          <v-list-item-group color="#FF3177">
                            <v-list-item class="px-0" color="#FF3177"
                              v-if="item.total_sub_materi < 1"
                              @click="item.content_jsonobject.type !== '' ? playing(item) : ''">
                              <v-list-item-content>
                                <div class="d-flex align-start">
                                  <div class="mr-4">
                                    <v-img 
                                      width="60"
                                      height="60"
                                      class="d-flex align-center justify-center"
                                      gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                      :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                      style="border-radius: 5px;">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#FF3177">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>

                                      <a v-if="item.content_jsonobject.type === 'docs'"
                                        :href="item.content_jsonobject.value.url"
                                        target="_blank"
                                        class="d-flex align-center justify-center">
                                        <v-icon color="white">mdi-download</v-icon>
                                      </a>
                                    </v-img>
                                  </div>

                                  <v-hover v-slot="{ hover }">
                                    <div class="body-2">
                                      <p class="font-weight-bold text-capitalize mb-2" v-if="item.deskripsi === ''">
                                        <span :class="!hover ? 'one-line' : 'line-text-first'">{{ item.title }}</span>
                                      </p>

                                      <p class="font-weight-light line-text-first text-second mb-0">
                                        <span :class="!hover ? 'three-line' : 'line-text-second'" v-html="item.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                      </p>
                                    </div>
                                  </v-hover>
                                </div>
                              </v-list-item-content>

                              <v-list-item-action v-if="item.content_jsonobject.type !== ''">
                                <v-icon v-if="item.title !== player.data.title || !player.media.is_play"
                                  color="#FF3177">
                                  {{ 
                                    item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                    item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                    item.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                  }}
                                </v-icon>

                                <v-icon v-else
                                  color="#333333">
                                  mdi-stop-circle-outline
                                </v-icon>

                                <p class="caption text-capitalize color-first mb-0"
                                  :class="item.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                                  <span v-if="item.content_jsonobject.type !== 'docs'">
                                    {{ item.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                                  </span>

                                  <span v-else>
                                    {{ item.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                                  </span>
                                </p>
                              </v-list-item-action>
                            </v-list-item>

                            <div v-if="item.total_sub_materi > 0">
                              <v-list-item class="px-0 mt-2" 
                                v-for="(sub, key) in item.sub_materi" :key="key"
                                @click="sub.content_jsonobject.type !== '' ? playing(sub) : ''">
                                <v-list-item-content>
                                  <div class="d-flex align-start">
                                    <div class="mr-4">
                                      <v-img 
                                        width="60"
                                        height="60"
                                        class="d-flex align-center justify-center"
                                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                        :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>

                                        <a v-if="sub.content_jsonobject.type === 'docs'"
                                          :href="sub.content_jsonobject.value.url"
                                          target="_blank"
                                          class="d-flex align-center justify-center">
                                          <v-icon color="white">mdi-download</v-icon>
                                        </a>
                                      </v-img>
                                    </div>

                                    <v-hover v-slot="{ hover }">
                                      <div class="body-2">
                                        <p class="font-weight-bold text-capitalize mb-2">
                                          <span :class="!hover ? 'one-line' : 'line-text-first'">{{ sub.title }}</span>
                                        </p>

                                        <p class="font-weight-light line-text-first text-second mb-0">
                                          <span :class="!hover ? 'two-line' : 'line-text-second'" v-html="sub.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                        </p>
                                      </div>
                                    </v-hover>
                                  </div>
                                </v-list-item-content>

                                <v-list-item-action v-if="sub.content_jsonobject.type !== ''">
                                  <v-icon v-if="sub.title !== player.data.title || !player.media.is_play"
                                    color="#FF3177">
                                    {{ 
                                      sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                      sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                      sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                    }}
                                  </v-icon>

                                  <v-icon v-else
                                    color="#333333">
                                    mdi-stop-circle-outline
                                  </v-icon>

                                  <p class="caption text-capitalize color-first mb-0"
                                    :class="sub.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                                    <span v-if="sub.content_jsonobject.type !== 'docs'">
                                      {{ sub.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                                    </span>

                                    <span v-else>
                                      {{ sub.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                                    </span>
                                  </p>
                                </v-list-item-action>
                              </v-list-item>
                            </div>
                          </v-list-item-group>
                        </v-list>
                      </v-expansion-panel-content>

                      <v-divider style="border-color: rgb(0 0 0 / 8%);" v-if="index < detail.kelas_materi_jsonarray.length - 1"/>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <div v-if="selected.menu_materi === 'Umum' && detail.kelas_materi_jsonarray.length < 1"
                    class="d-flex align-start text-second my-6">
                    <v-icon class="mr-1">mdi-information-outline</v-icon>
                    <span>
                      Tidak ada <b class="mx-1">Materi Umum</b> pada pertemuan ini.
                    </span>
                  </div>

                  <!-- MATERI KHUSUS -->
                  <div v-if="selected.menu_materi === 'Khusus' && detail.schedule_materi_jsonarray.length < 1"
                    class="d-flex align-start text-second my-6">
                    <v-icon class="mr-1">mdi-information-outline</v-icon>
                    <span>
                      Tidak ada <b class="mx-1">Materi Khusus</b> pada pertemuan ini.
                    </span>
                  </div>

                  <v-expansion-panels flat v-if="selected.menu_materi === 'Khusus' && detail.schedule_materi_jsonarray.length > 0" 
                    v-model="selected.panel">
                    <v-expansion-panel
                      v-for="(item, index) in detail.schedule_materi_jsonarray" 
                      :key="index">
                      <v-expansion-panel-header class="pa-0">
                        <v-list dense class="py-0" color="transparent">
                          <v-list-item class="py-0 pl-0">
                            <v-list-item-content :style="rps !== 'xs' ? 'width: 250px;' : ''">
                              <span class="body-1 font-weight-bold text-capitalize"
                                :class="selected.panel !== index ? 'one-line' : ''">
                                {{ item.title }}
                              </span>
                            </v-list-item-content>

                            <v-list-item-action>
                              <div style="border-radius: 5px; padding: 5px 15px;"
                                :style="item.materi_is_read ? 'background: #FF3177;' : 'background: #f0f0f0;'">
                                <span class="body-1 font-weight-bold"
                                  :class="item.materi_is_read ? 'white--text' : ''">
                                  {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                                </span>
                                <span class="body-2" :class="item.materi_is_read ? 'white--text' : 'text-second'">
                                  Materi
                                </span>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <template v-slot:actions>
                          <v-icon color="#FF3177">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content class="pa-0">
                        <v-divider style="border-color: rgb(0 0 0 / 8%);"/>

                        <v-list flat three-line dense class="py-0" color="transparent">
                          <v-list-item-group color="#FF3177">
                            <v-list-item class="px-0" color="#FF3177"
                              v-if="item.total_sub_materi < 1"
                              @click="item.content_jsonobject.type !== '' ? playing(item) : ''">
                              <v-list-item-content>
                                <div class="d-flex align-start">
                                  <div class="mr-4">
                                    <v-img 
                                      width="60"
                                      height="60"
                                      class="d-flex align-center justify-center"
                                      gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                      :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                      style="border-radius: 5px;">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#FF3177">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>

                                      <a v-if="item.content_jsonobject.type === 'docs'"
                                        :href="item.content_jsonobject.value.url"
                                        target="_blank"
                                        class="d-flex align-center justify-center">
                                        <v-icon color="white">mdi-download</v-icon>
                                      </a>
                                    </v-img>
                                  </div>

                                  <v-hover v-slot="{ hover }">
                                    <div class="body-2">
                                      <p class="font-weight-bold text-capitalize mb-2" v-if="item.deskripsi === ''">
                                        <span :class="!hover ? 'one-line' : 'line-text-first'">{{ item.title }}</span>
                                      </p>

                                      <p class="font-weight-light line-text-first text-second mb-0">
                                        <span :class="!hover ? 'three-line' : 'line-text-second'" v-html="item.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                      </p>
                                    </div>
                                  </v-hover>
                                </div>
                              </v-list-item-content>

                              <v-list-item-action v-if="item.content_jsonobject.type !== ''">
                                <v-icon v-if="item.title !== player.data.title || !player.media.is_play"
                                  color="#FF3177">
                                  {{ 
                                    item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                    item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                    item.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                  }}
                                </v-icon>

                                <v-icon v-else
                                  color="#333333">
                                  mdi-stop-circle-outline
                                </v-icon>

                                <p class="caption text-capitalize color-first mb-0"
                                  :class="item.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                                  <span v-if="item.content_jsonobject.type !== 'docs'">
                                    {{ item.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                                  </span>

                                  <span v-else>
                                    {{ item.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                                  </span>
                                </p>
                              </v-list-item-action>
                            </v-list-item>

                            <div v-if="item.total_sub_materi > 0">
                              <v-list-item class="px-0 mt-2" 
                                v-for="(sub, key) in item.sub_materi" :key="key"
                                @click="sub.content_jsonobject.type !== '' ? playing(sub) : ''">
                                <v-list-item-content>
                                  <div class="d-flex align-start">
                                    <div class="mr-4">
                                      <v-img 
                                        width="60"
                                        height="60"
                                        class="d-flex align-center justify-center"
                                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                        :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>

                                        <a v-if="sub.content_jsonobject.type === 'docs'"
                                          :href="sub.content_jsonobject.value.url"
                                          target="_blank"
                                          class="d-flex align-center justify-center">
                                          <v-icon color="white">mdi-download</v-icon>
                                        </a>
                                      </v-img>
                                    </div>

                                    <v-hover v-slot="{ hover }">
                                      <div class="body-2">
                                        <p class="font-weight-bold text-capitalize mb-2">
                                          <span :class="!hover ? 'one-line' : 'line-text-first'">{{ sub.title }}</span>
                                        </p>

                                        <p class="font-weight-light line-text-first text-second mb-0">
                                          <span :class="!hover ? 'two-line' : 'line-text-second'" v-html="sub.deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                        </p>
                                      </div>
                                    </v-hover>
                                  </div>
                                </v-list-item-content>

                                <v-list-item-action v-if="sub.content_jsonobject.type !== ''">
                                  <v-icon v-if="sub.title !== player.data.title || !player.media.is_play"
                                    color="#FF3177">
                                    {{ 
                                      sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                      sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                      sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' : 'mdi-youtube'
                                    }}
                                  </v-icon>

                                  <v-icon v-else
                                    color="#333333">
                                    mdi-stop-circle-outline
                                  </v-icon>

                                  <p class="caption text-capitalize color-first mb-0"
                                    :class="sub.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                                    <span v-if="sub.content_jsonobject.type !== 'docs'">
                                      {{ sub.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                                    </span>

                                    <span v-else>
                                      {{ sub.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                                    </span>
                                  </p>
                                </v-list-item-action>
                              </v-list-item>
                            </div>
                          </v-list-item-group>
                        </v-list>
                      </v-expansion-panel-content>

                      <v-divider style="border-color: rgb(0 0 0 / 8%);" v-if="index < detail.schedule_materi_jsonarray.length - 1"/>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    data () {
      return {
        filter: {
          title: ''
        },
        selected: {
          panel: 0,
          menu: 0,
          menu_materi: 'Umum'
        },
        menu_materi: ['Umum', 'Khusus'],
        detail: {},
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        list: [],
        pagination: {},
        process: {
          run: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'collection/:slug',
          title: 'E-Learning Bidan Kita',
          description: 'E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Schedule: () => import('@/components/collection/schedule'),
      Information: () => import('@/components/collection/information'),
      Announcement: () => import('@/components/collection/announcement'),
      Review: () => import('@/components/collection/review'),
      Discus: () => import('@/components/collection/discus'),
      Players: () => import('@/components/Player')
    },
    computed: {
      menu() {
        let menu = [
          {
            label: 'Jadwal',
            icon: 'mdi-calendar-month'
          },
          {
            label: 'Tentang Kelas',
            icon: 'mdi-book-information-variant'
          },
          {
            label: 'Diskusi',
            icon: 'mdi-forum'
          },
          {
            label: 'Pengumuman',
            icon: 'mdi-bullhorn'
          },
          {
            label: 'Ulasan',
            icon: 'mdi-star-half-full'
          }
        ]

        if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
          menu.splice(1, 0, {
            label: 'Materi',
            icon: 'mdi-bookshelf'
          })
        }

        return menu
      },
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      },
      headers() {
        return this.$store.state.header
      }
    },
    beforeCreate () {
      let params = {
        kelas_id: this.$route.params.id,
        schedule_id: this.$route.query.schedule_id
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/member/class/live/detail`, { params })
      .then((response) => {
        this.content = {
          url: `collection/${response.data.kelas_id}`,
          title: response.data.kelas_nama,
          description: response.data.kelas_deskripsi,
          image: response.data.kelas_image_jsonobejct.kelas_image_cover_url
        }
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(type) {
        this.process.run = type === undefined ? true : false
        
        let params = {
          kelas_id: this.$route.params.id,
          schedule_id: this.$route.query.schedule_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/class/live/detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.detail = response.data
          }
        })
      },
      playing(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        let status = false
        if (item.title === this.player.data.title) {
          if(!this.player.media.is_play) {
            status = true
          } else {
            status = false
          }
        } else {
          status = true
        }

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: status
        }
        this.player.data = item
      }
    }
  }
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>