<template>
  <div :class="$route.name === 'collection-streaming-id' ? 'my-5' : 'fill-height'">
    <v-dialog v-model="dialog.question"
      width="450"
      persistent>
      <v-card
        flat
        class="border-radius pa-2">
        <v-card-text class="pa-0 d-flex align-center">
          <div class="title text-first pl-6">
            Posting
            <span class="color-first ml-1">
              Pertanyaan
            </span>
          </div>
          
          <v-spacer/>

          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="resetQuestion(); $refs.observer.reset();">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <div class="pa-5">
          <ValidationObserver ref="observer">
            <div>
              <ValidationProvider name="Pilih Kategori pertanyaan disini" rules="required" v-slot="{ errors }">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  :items="list.category"
                  item-color="#FF3177"
                  placeholder="Pilih Kategori pertanyaan disini"
                  clearable
                  v-model="formQuestion.pertanyaan_kategori"
                  :error-messages="errors"
                  required>
                </v-select>
              </ValidationProvider>

              <ValidationProvider name="Tuliskan judul pertanyaan disini" rules="required" v-slot="{ errors }">
                <v-text-field
                  placeholder="Tuliskan judul pertanyaan disini"
                  color="#FF3177"
                  dense
                  single-line 
                  outlined
                  v-model="formQuestion.pertanyaan_judul"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider name="Tuliskan pertanyaan disini" rules="required" v-slot="{ errors }">
                <v-textarea
                  placeholder="Tuliskan pertanyaan disini"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  color="#FF3177"
                  v-model="formQuestion.pertanyaan_content_jsonobject.value"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>
            </div>
          </ValidationObserver>

          <v-alert 
            type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>

          <div class="text-right">
            <v-btn
              elevation="0"
              color="#FF3177"
              class="text-capitalize white--text"
              :disabled="process.questionForm"
              :loading="process.questionForm"
              @click="saveQuestion">
              Posting Pertanyaan
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.questionDelete"
      width="350"
      persistent>
      <v-card
        flat
        class="border-radius pa-2">
        <v-card-text class="pa-0 d-flex align-center">
          <div class="title text-first pl-6">
            Hapus
            <span class="color-first ml-1">
              Pertanyaan
            </span>
          </div>
          
          <v-spacer/>

          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="dialog.questionDelete = false; detailQuestion = {}">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <div class="pa-5">
          <p class="body-1 font-weight-light line-text-second mb-6">
            Apakah anda yakin menghapus pertanyaan <b>{{ detailQuestion.pertanyaan_judul  }}</b>?
          </p>

          <v-alert 
            type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>

          <div class="text-right">
            <v-btn
              elevation="0"
              color="#FF3177"
              class="text-capitalize white--text"
              :disabled="process.questionDelete"
              :loading="process.questionDelete"
              @click="questionDelete">
              Hapus Pertanyaan
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.answer"
      width="450"
      persistent>
      <v-card
        flat
        class="border-radius pa-2">
        <v-card-text class="pa-0 d-flex align-center">
          <div class="title text-first pl-6">
            Ubah
            <span class="color-first ml-1">
              Jawaban
            </span>
          </div>
          
          <v-spacer/>

          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="dialog.answer = false; resetAnswer(); $refs.observer.reset();">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <div class="pa-5">
          <ValidationObserver ref="observer">
            <div>
              <ValidationProvider name="Tuliskan jawabanmu disini" rules="required" v-slot="{ errors }">
                <v-text-field
                  placeholder="Tuliskan jawabanmu disini"
                  color="#FF3177"
                  dense
                  single-line 
                  outlined
                  v-model="formAnswer.jawaban_content_jsonobject.value"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
            </div>
          </ValidationObserver>

          <v-alert 
            type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>

          <div class="text-right">
            <v-btn
              elevation="0"
              color="#FF3177"
              class="text-capitalize white--text"
              :disabled="process.answerForm"
              :loading="process.answerForm"
              @click="saveAnswer">
              Ubah Jawaban
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.answerDelete"
      width="350"
      persistent>
      <v-card
        flat
        class="border-radius pa-2">
        <v-card-text class="pa-0 d-flex align-center">
          <div class="title text-first pl-6">
            Hapus
            <span class="color-first ml-1">
              Jawaban
            </span>
          </div>
          
          <v-spacer/>

          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="dialog.answerDelete = false; detailAnswer = {}">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <div class="pa-5">
          <p class="body-1 font-weight-light line-text-second mb-6" v-if="Object.keys(detailAnswer).length > 0">
            Apakah anda yakin menghapus pertanyaan <b>{{ detailAnswer.jawaban_content_jsonobject.value  }}</b>?
          </p>

          <v-alert 
            type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert>

          <div class="text-right">
            <v-btn
              elevation="0"
              color="#FF3177"
              class="text-capitalize white--text"
              :disabled="process.answerDelete"
              :loading="process.answerDelete"
              @click="answerDelete">
              Hapus Jawaban
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section v-if="Object.keys(is_answer).length < 1">
          <v-container class="px-0" v-if="$route.name === 'collection-streaming-id'">
            <v-row class="mt-4">
              <v-col cols="12" class="py-0">
                <v-divider class="mb-10" style="border: 2px solid #e9e9e9" />

                <div class="d-flex align-end mb-1">
                  <div class="d-flex align-center">
                    <v-icon size="30" class="mr-1">mdi-chat-processing</v-icon>
                    <span class="title font-weight-bold mb-0">
                      <span class="color-first mr-1">
                        {{ detail.kelas_total_diskusi }}
                      </span>
                      Diskusi
                    </span>
                  </div>

                  <v-spacer/>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container style="background: #fff; border-radius: 5px;" class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-card flat class="mb-6" :class="$route.name === 'collection-streaming-id' ? 'px-3 pt-3' : ''">
                  <div>
                    <p class="body-2 font-weight-light mb-3">
                      Apa yang anda pikirkan ?
                    </p>
                    <v-text-field
                      placeholder="Tuliskan pertanyaan anda disini."
                      color="#FF3177"
                      dense
                      single-line 
                      hide-details="auto"
                      outlined
                      readonly
                      @click="resetQuestion()">
                    </v-text-field>
                  </div>
                </v-card>

                <v-skeleton-loader
                  v-for="(item, index) in process.question ? 5 : list.question"
                  :key="index"
                  class="ma-auto"
                  :loading="process.question"
                  type="article">
                  <div>
                    <v-card v-if="!process.question && list.question.length > 0" 
                      flat
                      class="py-2">
                      <v-card-text class="pa-0">
                        <v-list dense>
                          <v-list-item :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                            <v-list-item-avatar size="50">
                              <v-img
                                :src="item.pertanyaan_user_image_url !== '' ? item.pertanyaan_user_image_url : require('@/assets/image/member-default.jpg')">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="body-1 font-weight-light text-capitalize mb-2">
                                {{ item.pertanyaan_user_name }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="caption font-weight-light">
                                {{ item.pertanyaan_input_datetime | ago }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-text class="pb-0 text-capitalize" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        <p class="body-1 font-weight-bold text-first mb-2">
                          {{ item.pertanyaan_judul }}
                        </p>

                        <p class="body-2 color-first mb-0">
                          {{ item.pertanyaan_kategori }}
                        </p>
                      </v-card-text>

                      <v-card-text :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        <p class="body-1 font-weight-light line-text-second mb-0" v-if="item.pertanyaan_content_jsonobject.type === 'text'"
                          v-html="item.pertanyaan_content_jsonobject.value">
                        </p>
                      </v-card-text>

                      <v-card-text class="d-flex align-center" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        <div @click="is_answer = item; fetchAnswer();">
                          <p class="body-1 font-weight-bold orange--text mb-0 cursor-pointer" v-if="parseInt(item.total_jawaban) < 1">
                            <v-icon class="mr-1" color="orange">mdi-comment-text-multiple-outline</v-icon>
                            Berikan Tanggapan
                          </p>

                          <p class="body-1 font-weight-bold color-first mb-0 cursor-pointer" v-if="parseInt(item.total_jawaban) > 0">
                            <v-icon class="mr-1" color="#FF3177">mdi-comment-text-multiple-outline</v-icon>
                            ({{ parseInt(item.total_jawaban) }}) Tanggapan
                          </p>
                        </div>

                        <v-spacer/>

                        <div class="d-flex align-center" v-if="user.user_id === item.pertanyaan_user_id">
                          <div
                            class="text-center cursor-pointer"
                            @click="resetQuestion(item)">
                            <div>
                              <v-icon size="18" color="blue">mdi-pencil-outline</v-icon>
                              <p class="caption blue--text text-capitalize mb-0">
                                Ubah
                              </p>
                            </div>
                          </div>

                          <div
                            class="text-center cursor-pointer ml-3"
                            @click="dialog.questionDelete = true; detailQuestion = item;">
                            <div>
                              <v-icon size="18" color="red">mdi-delete-outline</v-icon>
                              <p class="caption red--text text-capitalize mb-0">
                                Hapus
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-card-text>

                      <v-divider class="mt-2" v-if="index < list.question.length - 1"/>
                    </v-card>
                  </div>
                </v-skeleton-loader>

                <v-card-text class="text-center" v-if="pagination.question.total_data > 5 && limit < pagination.question.total_data">
                  <v-btn
                    small
                    elevation="0"
                    color="#f1f1f1"
                    class="caption text-second text-capitalize"
                    @click="limit += 5">
                    Lihat lebih banyak (+{{ pagination.question.total_data - limit}})
                    <v-icon small color="#7B7B7B" class="ml-1">mdi-chevron-down</v-icon>
                  </v-btn>
                </v-card-text>

                <v-row class="py-12" v-if="!process.question && pagination.question.total_page < 1">
                  <v-col>
                    <v-img 
                      class="mx-auto"
                      contain
                      width="150"
                      height="150"
                      :src="require('@/assets/image/empty.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <p class="text-h5 text-second font-weight-bold text-center">
                      Belum ada Diskusi!
                    </p>
                    <p class="body-1 font-weight-light mb-6 text-center">
                      Yuk mulai diskusi dengan member kami.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section v-if="Object.keys(is_answer).length > 0">
          <v-container style="background: #fff; border-radius: 5px;" class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-card 
                  flat
                  class="py-2">
                  <v-card-text class="pa-0">
                    <v-list dense>
                      <v-list-item :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        <v-list-item-avatar size="50">
                          <v-img
                            :src="is_answer.pertanyaan_user_image_url !== '' ? is_answer.pertanyaan_user_image_url : require('@/assets/image/member-default.jpg')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="body-1 font-weight-light text-capitalize mb-2">
                            {{ is_answer.pertanyaan_user_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="caption font-weight-light">
                            {{ is_answer.pertanyaan_input_datetime | ago }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>

                  <v-card-text class="pb-0 text-capitalize" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                    <p class="body-1 font-weight-bold text-first mb-2">
                      {{ is_answer.pertanyaan_judul }}
                    </p>

                    <p class="body-2 color-first mb-0">
                      {{ is_answer.pertanyaan_kategori }}
                    </p>
                  </v-card-text>

                  <v-card-text :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                    <p class="body-1 font-weight-light line-text-second mb-0" v-if="is_answer.pertanyaan_content_jsonobject.type === 'text'"
                      v-html="is_answer.pertanyaan_content_jsonobject.value">
                    </p>
                  </v-card-text>

                  <div class="d-flex align-center mb-3" :class="$route.name === 'collection-streaming-id' ? 'px-4' : ''">
                    <div class="primary--text cursor-pointer"
                      @click="is_answer = {}; fetchQuestion();">
                      <v-icon color="blue" small>mdi-arrow-left</v-icon>
                      Kembali
                    </div>

                    <v-spacer/>

                    <div class="body-1 font-weight-bold color-first mb-0">
                      <v-icon class="mr-1" color="#FF3177">mdi-comment-text-multiple-outline</v-icon>
                      ({{ parseInt(is_answer.total_jawaban) }}) Tanggapan
                    </div>
                  </div>

                  <v-divider class="mt-4 mb-6" />

                  <ValidationObserver ref="observer">
                    <div :class="$route.name === 'collection-streaming-id' ? 'px-4' : ''">
                      <ValidationProvider name="Tuliskan jawabanmu disini" rules="required" v-slot="{ errors }">
                        <v-text-field v-if="!dialog.answer"
                          placeholder="Tuliskan jawabanmu disini"
                          color="#FF3177"
                          dense
                          single-line 
                          outlined
                          v-model="formAnswer.jawaban_content_jsonobject.value"
                          :error-messages="errors"
                          required>
                        </v-text-field>

                        <v-text-field v-else
                          placeholder="Tuliskan jawabanmu disini"
                          color="#FF3177"
                          dense
                          single-line 
                          outlined>
                        </v-text-field>
                      </ValidationProvider>
                    </div>
                  </ValidationObserver>

                  <v-alert 
                    :class="$route.name === 'collection-streaming-id' ? 'mx-4' : ''"
                    type="error" text dense prominent 
                    v-show="error.message.length > 0"
                    v-html="error.message">
                  </v-alert>

                  <div class="text-right" :class="$route.name === 'collection-streaming-id' ? 'px-4' : ''">
                    <v-btn
                      elevation="0"
                      color="#FF3177"
                      class="text-capitalize white--text"
                      :disabled="process.answerForm && !dialog.answer"
                      :loading="process.answerForm && !dialog.answer"
                      @click="saveAnswer">
                      Posting Jawaban
                    </v-btn>
                  </div>
                </v-card>

                <v-skeleton-loader
                  v-for="(item, index) in process.answer ? 5 : list.answer"
                  :key="index"
                  class="ma-auto"
                  :loading="process.answer"
                  type="article">
                  <div>
                    <v-card v-if="!process.answer && list.answer.length > 0" 
                      flat
                      class="py-2">
                      <v-card-text class="pa-0">
                        <v-list dense>
                          <v-list-item :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                            <v-list-item-avatar size="50">
                              <v-img
                                :src="item.jawaban_user_image_url !== '' ? item.jawaban_user_image_url : require('@/assets/image/member-default.jpg')">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="body-1 font-weight-light text-capitalize mb-2">
                                {{ item.jawaban_user_name }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="caption font-weight-light">
                                {{ item.jawaban_input_datetime | ago }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-text :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        <p class="body-1 font-weight-light line-text-second mb-0" v-if="item.jawaban_content_jsonobject.type === 'text'"
                          v-html="item.jawaban_content_jsonobject.value">
                        </p>
                      </v-card-text>

                      <v-card-text class="d-flex align-center" :class="$route.name === 'collection-streaming-id' ? '' : 'px-0'">
                        <v-spacer/>

                        <div class="d-flex align-center" v-if="user.user_id === item.jawaban_user_id">
                          <div
                            class="text-center cursor-pointer"
                            @click="dialog.answer = true; resetAnswer(item);">
                            <div>
                              <v-icon size="18" color="blue">mdi-pencil-outline</v-icon>
                              <p class="caption blue--text text-capitalize mb-0">
                                Ubah
                              </p>
                            </div>
                          </div>

                          <div
                            class="text-center cursor-pointer ml-3"
                            @click="dialog.answerDelete = true; detailAnswer = item;">
                            <div>
                              <v-icon size="18" color="red">mdi-delete-outline</v-icon>
                              <p class="caption red--text text-capitalize mb-0">
                                Hapus
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-card-text>

                      <v-divider class="mt-2" v-if="index < list.question.length - 1"/>
                    </v-card>
                  </div>
                </v-skeleton-loader>
                
                <v-card-text class="text-center" v-if="pagination.answer.total_data > 5 && limit < pagination.answer.total_data">
                  <v-btn
                    small
                    elevation="0"
                    color="#f1f1f1"
                    class="caption text-second text-capitalize"
                    @click="limit += 5">
                    Lihat lebih banyak (+{{ pagination.answer.total_data - limit}})
                    <v-icon small color="#7B7B7B" class="ml-1">mdi-chevron-down</v-icon>
                  </v-btn>
                </v-card-text>

                <v-row class="py-12" v-if="!process.answer && pagination.answer.total_page < 1">
                  <v-col>
                    <v-img 
                      class="mx-auto"
                      contain
                      width="150"
                      height="150"
                      :src="require('@/assets/image/empty.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <p class="text-h5 text-second font-weight-bold text-center">
                      Belum ada Jawaban!
                    </p>
                    <p class="body-1 font-weight-light mb-6 text-center">
                      Yuk beri jawaban pertanyaan diatas.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          question: false,
          questionDelete: false,
          answer: false,
          answerDelete: false
        },
        is_answer: {},
        formQuestion: {
          id: '',
          pertanyaan_kelas_id : '',
          pertanyaan_schedule_id: '',
          pertanyaan_kategori : '',
          pertanyaan_judul: '',
          pertanyaan_content_jsonobject : {
            type: 'text',
            value: ''
          }
        },
        formAnswer: {
          id: '',
          jawaban_pertanyaan_id: '',
          jawaban_content_jsonobject: {
            type: 'text',
            value: ''
          },
          jawaban_is_active: '1'
        },
        detailQuestion: {},
        detailAnswer: {},
        list: {
          category: [],
          question: [],
          answer: []
        },
        limit: 5,
        pagination: {
          question: {},
          answer: {}
        },
        process: {
          question: false,
          questionForm: false,
          questionDelete: false,
          answer: false,
          answerForm: false,
          answerDelete: false
        },
        error: {
          message: ''
        }
      }
    },
    watch: {
      'limit': function() {
        if (Object.keys(this.is_answer).length < 1) {
          this.fetchQuestion(true)
        } else {
          this.fetchAnswer(true)
        }
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetchQuestion()
    },
    methods: {
      async fetchQuestion(type) {
        this.process.question = type === undefined ? true : false

        let params = {
          limit: this.limit,
          sort: 'pertanyaan_update_datetime',
          dir: 'DESC',
          id_kelas: this.detail.kelas_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/discussion/pertanyaan`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.question = false
            this.list.question = response.data.results
            this.pagination.question = response.data.pagination

            this.fetchCategory()
          }
        })
      },
      async fetchCategory() {
        let params = {
          id_kelas: this.detail.kelas_id
        }

        this.list.category = ['Pertanyaan Umum']

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/discussion/pertanyaan/kategori`, { params })
        .then((response) => {
          if (response.status === 200) {
            response.data.results.map((obj) => {
              this.list.category.push(obj.kategori_title)
            })
          }
        })
      },
      resetQuestion(item) {
        this.dialog.question = !this.dialog.question ? true : false
        
        this.formQuestion = {
          id: item !== undefined ? item.pertanyaan_id : '',
          pertanyaan_kelas_id : this.detail.kelas_id,
          pertanyaan_schedule_id: this.$route.query.schedule_id === undefined ? '' : this.$route.query.schedule_id,
          pertanyaan_kategori : item !== undefined ? item.pertanyaan_kategori : '',
          pertanyaan_judul: item !== undefined ? item.pertanyaan_judul : '',
          pertanyaan_content_jsonobject : {
            type: 'text',
            value: item !== undefined ? item.pertanyaan_content_jsonobject.value : ''
          }
        }
        
        this.process.questionForm = false

        this.error.message = ''
      },
      async saveQuestion() {
        this.error.message = ''

        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          
          this.process.questionForm = true

          let forms = Object.assign({}, this.formQuestion)
          forms.pertanyaan_content_jsonobject = JSON.stringify(forms.pertanyaan_content_jsonobject)

          let url = forms.id === '' ? `${process.env.PREFIX_PROXY}/class/discussion/pertanyaan/add` : `${process.env.PREFIX_PROXY}/class/discussion/pertanyaan/update`

          await this.$axios.$post(url, forms)
          .then((response) => {
            if (response.status === 200) {
              this.resetQuestion()
              this.fetchQuestion(true)
            } else {
              this.process.questionForm = false
              this.error.message = response.message
            }
          })
        }
      },
      async questionDelete() {
        this.error.message = ''
          
        this.process.questionDelete = true

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/class/discussion/pertanyaan/delete`, {
          item: JSON.stringify([this.detailQuestion.pertanyaan_id])
        })
        .then((response) => {
          this.process.questionDelete = false

          if (response.status === 200) {
            this.dialog.questionDelete = false
            
            this.fetchQuestion(true)
          } else {
            this.error.message = response.message
          }
        })
      },
      async fetchAnswer(type) {
        this.process.answer = type === undefined ? true : false

        let params = {
          limit: this.limit,
          sort: 'jawaban_update_datetime',
          dir: 'DESC',
          id_pertanyaan: this.is_answer.pertanyaan_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/discussion/jawaban`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.answer = false
            this.list.answer = response.data.results
            this.pagination.answer = response.data.pagination
          }
        })
      },
      resetAnswer(item) {
        this.formAnswer = {
          id: item !== undefined ? item.jawaban_id : '',
          jawaban_pertanyaan_id: item !== undefined ? item.jawaban_pertanyaan_id : '',
          jawaban_content_jsonobject : {
            type: 'text',
            value: item !== undefined ? item.jawaban_content_jsonobject.value : ''
          },
          jawaban_is_active: item !== undefined ? item.jawaban_is_active : '1'
        }
        
        this.process.answerForm = false

        this.error.message = ''
      },
      async saveAnswer() {
        this.error.message = ''

        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          
          this.process.answerForm = true

          let forms = Object.assign({}, this.formAnswer)
          forms.jawaban_pertanyaan_id = this.is_answer.pertanyaan_id
          forms.jawaban_content_jsonobject = JSON.stringify(forms.jawaban_content_jsonobject)

          this.$refs.observer.reset()

          let url = forms.id === '' ? `${process.env.PREFIX_PROXY}/class/discussion/jawaban/add` : `${process.env.PREFIX_PROXY}/class/discussion/jawaban/update`

          await this.$axios.$post(url, forms)
          .then((response) => {
            if (response.status === 200) {
              this.dialog.answer = false

              this.resetAnswer()
              this.fetchAnswer(true)
            } else {
              this.process.answerForm = false
              this.error.message = response.message
            }
          })
        }
      },
      async answerDelete() {
        this.error.message = ''
          
        this.process.answerDelete = true

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/class/discussion/jawaban/delete`, {
          item: JSON.stringify([this.detailAnswer.jawaban_id])
        })
        .then((response) => {
          this.process.answerDelete = false

          if (response.status === 200) {
            this.dialog.answerDelete = false

            this.fetchAnswer(true)
          } else {
            this.error.message = response.message
          }
        })
      },
    }
  }
</script>