<template>
  <div>
    <v-dialog v-model="dialog.register"
      width="900"
      persistent>
      <v-card 
        :height="error.message.length > 0 ? 700 : 618"
        color="#fff"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <!-- <v-col cols="6" class="d-flex justify-center align-center pa-12" v-if="$vuetify.breakpoint.name !== 'xs'">
            <v-img 
              contain
              :src="require('@/assets/image/register-img.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col> -->

          <v-col cols="7" class="d-flex justify-center align-center pa-0" v-if="$vuetify.breakpoint.name !== 'xs'">
            <iframe v-if="dialog.register"
              style="border-radius: 15px 0px 0px 15px;"
              width="100%"
              :height="error.message.length > 0 ? 700 : 618"
              src="https://www.youtube.com/embed/yetXron-eDg?autoplay=true"
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowtransparency
              allowfullscreen>
            </iframe>
          </v-col>

          <v-col :cols="$vuetify.breakpoint.name !== 'xs' ? 5 : 12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.register = false; $emit('close'); reset(); $refs.observer.reset();">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">DAFTAR</div>
                  <v-divider color="white"></v-divider>
                </div>
                <ValidationObserver ref="observer">
                  <v-form
                    class="mb-5">
                    <ValidationProvider name="Nama Lengkap" rules="required" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Nama Lengkap"
                        prepend-inner-icon="mdi-account-outline"
                        v-model="form.name"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Email"
                        prepend-inner-icon="mdi-email-outline"
                        v-model.trim="form.email"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Nomor Telepon" rules="required|min:10|max:13" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        type="number"
                        placeholder="Nomor Telepon"
                        prepend-inner-icon="mdi-cellphone-android"
                        v-model.trim="form.mobilephone" 
                        :counter="13"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Password" vid="confirm" rules="required|min:5|max:20" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Password"
                        prepend-inner-icon="mdi-form-textbox-password"
                        v-model.trim="form.password"
                        :type="show.password ? 'text' : 'password'"
                        :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.password = !show.password"
                        :counter="20"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Konfirmasi Password" rules="required|min:5|max:20|confirmed:confirm" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Konfirmasi Password"
                        ref="password"
                        prepend-inner-icon="mdi-lock-reset"
                        v-model.trim="form.password_confirm"
                        :type="show.password_confirm ? 'text' : 'password'"
                        :append-icon="show.password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.password_confirm = !show.password_confirm"
                        :counter="20"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
                <div class="text-center">
                  <v-alert 
                    type="error" 
                    text
                    dense
                    prominent
                    v-show="error.message.length > 0"
                    v-html="error.message">
                  </v-alert>
                  <v-btn
                    rounded
                    block
                    color="#333333"
                    elevation="3"
                    class="caption font-weight-bold white--text text-capitalize"
                    :disabled="process.run || process.google"
                    :loading="process.run"
                    @click="save('register')">
                    Daftar Sekarang
                  </v-btn>
                </div>
                <div class="text-center mt-3">
                  <small class="text-second">
                    Sudah punya akun ?
                    <span class="color-first cursor-pointer"
                      @click="dialog.register = false; dialog.login = true; reset(); $refs.observer.reset();">
                      Login disini
                    </span>
                  </small>
                </div>

                <div class="d-flex align-center mt-3 mb-4">
                  <v-divider/>
                  <span class="caption font-weight-bold mx-3">
                    Atau
                  </span>
                  <v-divider/>
                </div>

                <v-btn
                  rounded
                  block
                  color="grey lighten-4"
                  elevation="3"
                  class="caption font-weight-bold text-capitalize"
                  :disabled="process.google || process.run"
                  :loading="process.google"
                  @click="google">
                  <v-avatar size="20">
                    <v-img
                      :src="require('@/assets/sosmed/login_google.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <span class="ml-2">
                    Login Dengan Google
                  </span>
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="dialog.verification"
      width="900"
      persistent>
      <v-card 
        :height="error.message.length > 0 ? 415 : 334"
        color="#fff"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <!-- <v-col cols="6" class="d-flex justify-center align-center pa-12" v-if="$vuetify.breakpoint.name !== 'xs'">
            <v-img 
              contain
              :src="require('@/assets/image/register-img.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col> -->

          <v-col cols="7" class="d-flex justify-center align-center pa-0" v-if="$vuetify.breakpoint.name !== 'xs'">
            <iframe v-if="dialog.verification"
              style="border-radius: 15px 0px 0px 15px;"
              width="100%"
              :height="error.message.length > 0 ? 415 : 334"
              src="https://www.youtube.com/embed/yetXron-eDg?autoplay=true"
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowtransparency
              allowfullscreen>
            </iframe>
          </v-col>

          <v-col :cols="$vuetify.breakpoint.name !== 'xs' ? 5 : 12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.verification = false; $emit('close'); reset(); $refs.observer.reset();">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">VERIFIKASI ULANG</div>
                  <v-divider color="white"></v-divider>
                </div>
                <p class="caption text-second text-center line-text-first pb-3">
                  Silahkan masukkan email anda, kami akan mengirim link verifikasi ke email anda.
                </p>
                <ValidationObserver ref="observer">
                  <v-form
                    class="mb-5">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Email"
                        prepend-inner-icon="mdi-email-outline"
                        v-model.trim="form.email"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
                <div class="text-center">
                  <v-alert 
                    type="error" 
                    text
                    dense
                    prominent
                    v-show="error.message.length > 0"
                    v-html="error.message">
                  </v-alert>
                  <v-btn
                    rounded
                    block
                    color="#333333"
                    elevation="3"
                    class="caption font-weight-bold white--text text-capitalize"
                    :disabled="process.run"
                    :loading="process.run"
                    @click="save('verification')">
                    Kirim Ulang
                  </v-btn>
                </div>
                <div class="text-center mt-3">
                  <small class="text-second">
                    Sudah punya akun ?
                    <span class="color-first cursor-pointer"
                      @click="dialog.verification = false; dialog.login = true; reset(); $refs.observer.reset();">
                      Login disini
                    </span>
                  </small>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.login"
      width="900"
      persistent>
      <v-card 
        :height="error.message.length > 0 ? 580 : 480"
        color="#fff"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <!-- <v-col cols="6" class="d-flex justify-center align-center pa-12" v-if="$vuetify.breakpoint.name !== 'xs'">
            <v-img 
              contain
              :src="require('@/assets/image/login-img.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col> -->

          <v-col cols="7" class="d-flex justify-center align-center pa-0" v-if="$vuetify.breakpoint.name !== 'xs'">
            <iframe v-if="dialog.login"
              style="border-radius: 15px 0px 0px 15px;"
              width="100%"
              :height="error.message.length > 0 ? 580 : 480"
              src="https://www.youtube.com/embed/yetXron-eDg?autoplay=true"
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowtransparency
              allowfullscreen>
            </iframe>
          </v-col>

          <v-col :cols="$vuetify.breakpoint.name !== 'xs' ? 5 : 12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.login = false; $emit('close'); reset(); $refs.observer.reset();">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>

              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">LOGIN</div>
                  <v-divider color="white"></v-divider>
                </div>
                <ValidationObserver ref="observer">
                  <v-form
                    class="mb-5">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Email"
                        prepend-inner-icon="mdi-email-outline"
                        v-model.trim="form.email"
                        @keypress="form.username = form.email"
                        @blur="form.username = form.email"
                        @change="form.username = form.email"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Password" rules="required|min:5|max:20" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Password"
                        prepend-inner-icon="mdi-form-textbox-password"
                        v-model.trim="form.password"
                        :type="show.password ? 'text' : 'password'"
                        :append-icon="show.password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.password = !show.password"
                        :counter="20"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
                <div class="text-center">
                  <small class="text-second">
                    Lupa password ?
                    <span class="text-first cursor-pointer" @click="dialog.login = false; dialog.reset = true; reset(); $refs.observer.reset();">
                      Klik disini
                    </span>
                  </small>
                </div>
                <div class="text-center mb-3">
                  <small class="text-second">
                    Belum menerima verifikasi email ?
                    <span class="text-first cursor-pointer" @click="dialog.login = false; dialog.verification = true; reset(); $refs.observer.reset();">
                      Klik disini
                    </span>
                  </small>
                </div>
                <div class="text-center">
                  <v-alert 
                    type="error" 
                    text
                    dense
                    prominent
                    v-show="error.message.length > 0"
                    v-html="error.message">
                  </v-alert>
                  <v-btn
                    rounded
                    block
                    color="#FF3177"
                    elevation="3"
                    class="caption font-weight-bold white--text text-capitalize"
                    :disabled="process.run || process.google"
                    :loading="process.run"
                    @click="save('login')">
                    Login
                  </v-btn>
                </div>
                <div class="text-center mt-3">
                  <small class="text-second">
                    Belum punya akun ?
                    <span class="text-first cursor-pointer"
                      @click="dialog.login = false; dialog.register = true; reset(); $refs.observer.reset();">
                      Daftar disini
                    </span>
                  </small>
                </div>
                
                <div class="d-flex align-center mt-3 mb-4">
                  <v-divider/>
                  <span class="caption font-weight-bold mx-3">
                    Atau
                  </span>
                  <v-divider/>
                </div>

                <v-btn
                  rounded
                  block
                  color="grey lighten-4"
                  elevation="3"
                  class="caption font-weight-bold text-capitalize"
                  :disabled="process.google || process.run"
                  :loading="process.google"
                  @click="google">
                  <v-avatar size="20">
                    <v-img
                      :src="require('@/assets/sosmed/login_google.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#FF3177">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <span class="ml-2">
                    Login Dengan Google
                  </span>
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.reset"
      width="900"
      persistent>
      <v-card 
        :height="error.message.length > 0 ? 415 : 334"
        color="#fff"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <!-- <v-col cols="6" class="d-flex justify-center align-center pa-12" v-if="$vuetify.breakpoint.name !== 'xs'">
            <v-img 
              contain
              :src="require('@/assets/image/register-img.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col> -->
          
          <v-col cols="7" class="d-flex justify-center align-center pa-0" v-if="$vuetify.breakpoint.name !== 'xs'">
            <iframe v-if="dialog.reset"
              style="border-radius: 15px 0px 0px 15px;"
              width="100%"
              :height="error.message.length > 0 ? 415 : 334"
              src="https://www.youtube.com/embed/yetXron-eDg?autoplay=true"
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowtransparency
              allowfullscreen>
            </iframe>
          </v-col>

          <v-col :cols="$vuetify.breakpoint.name !== 'xs' ? 5 : 12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.reset = false; $emit('close'); reset(); $refs.observer.reset();">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">RESET PASSWORD</div>
                  <v-divider color="white"></v-divider>
                </div>
                <p class="caption text-second text-center line-text-first pb-3">
                  Silahkan masukkan email anda, kami akan mengirim link reset password ke email anda.
                </p>
                <ValidationObserver ref="observer">
                  <v-form
                    class="mb-5">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        dense 
                        color="#FF3177"
                        placeholder="Email"
                        prepend-inner-icon="mdi-email-outline"
                        v-model.trim="form.email"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
                <div class="text-center">
                  <v-alert 
                    type="error" 
                    text
                    dense
                    prominent
                    v-show="error.message.length > 0"
                    v-html="error.message">
                  </v-alert>
                  <v-btn
                    rounded
                    block
                    color="#333333"
                    elevation="3"
                    class="caption font-weight-bold white--text text-capitalize"
                    :disabled="process.run"
                    :loading="process.run"
                    @click="save('reset')">
                    Reset Password
                  </v-btn>
                </div>
                <div class="text-center mt-3">
                  <small class="text-second">
                    Sudah punya akun ?
                    <span class="color-first cursor-pointer"
                      @click="dialog.reset = false; dialog.login = true; reset(); $refs.observer.reset();">
                      Login disini
                    </span>
                  </small>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.success"
      width="650"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <v-col cols="6" class="d-flex justify-center align-center pa-12" v-if="$vuetify.breakpoint.name !== 'xs'">
            <v-img 
              contain
              :src="require('@/assets/image/register-img.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.name !== 'xs' ? 6 : 12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.success = false; $emit('close'); reset(); $refs.observer.reset();">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">BERHASIL</div>
                  <v-divider color="white"></v-divider>
                </div>
                <div class="d-flex justify-center">
                  <div class="sa">
                    <div class="sa-success">
                      <div class="sa-success-tip"></div>
                      <div class="sa-success-long"></div>
                      <div class="sa-success-placeholder"></div>
                      <div class="sa-success-fix"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center caption text-second line-text-first mb-8">
                  {{ success.message }}
                </div>
                <div class="text-center mt-3">
                  <small class="text-second" v-if="success.message !== 'Reservasi berhasil disimpan, periksa di halaman profil anda.'">
                    Sudah punya akun ?
                    <span class="color-first cursor-pointer"
                      @click="dialog.success = false; dialog.register = false; dialog.login = true; reset(); $refs.observer.reset();">
                      Login disini
                    </span>
                  </small>
                  <small class="text-second" v-if="success.message === 'Reservasi berhasil disimpan, periksa di halaman profil anda.'">
                    Lihat reservasi ?
                    <nuxt-link to="/dashboard/reservation" class="color-first cursor-pointer">
                      Klik disini
                    </nuxt-link>
                  </small>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      dialogauth: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        dialog: {
          register: false,
          login: false,
          verification: false,
          reset: false,
          success: false,
        },
        show: {
          password: false,
          password_confirm: false
        },
        form: {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          role: 'user',
          image: ''
        },
        process: {
          run: false,
          google: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        }
      }
    },
    watch: {
      'dialogauth': function(val) {
        if (val === 'register') {
          this.form.email = this.email !== '' ? this.email : ''
          this.dialog.register = true
        } else if (val === 'login') {
          this.dialog.login = true
        } else if (val === 'verification') {
          this.dialog.verification = true
        } else if (val === 'success') {
          this.success.message = this.message !== '' ? this.message : ''
          this.dialog.success = true
        }
      }
    },
    mounted () {

    },
    methods: {
      reset() {
        this.form = {
          name: '',
          username: '',
          email: '',
          mobilephone: '',
          password: '',
          password_confirm: '',
          device: 'web',
          fcm_token: '',
          role: 'user',
          image: ''
        }
        
        this.process.run = false

        this.error.message = ''
      },
      async save(type) {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          if (type === 'register') {
            this.register()
          } else if (type === 'verification') {
            this.verification()
          } else if (type === 'login') {
            this.login()
          } else {
            this.resetPassword()
          }
        }
      },
      async register() {
        this.process.run = true
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/register`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.dialog.register = false
            this.dialog.success = true
            this.success.message = 'Silahkan cek email anda untuk verifikasi akun yang telah anda daftarkan.'
          } else {
            this.process.run = false
            this.error.message = response.message
          }
        })
      },
      async verification() {
        this.process.run = true
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/register/resend_email_verification`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.dialog.verification = false
            this.dialog.success = true
            this.success.message = 'Silahkan cek email anda untuk verifikasi akun yang telah anda daftarkan.'
          } else {
            this.process.run = false
            this.error.message = response.message
          }
        })
      },
      async login() {
        this.process.run = true

        this.form.username = this.form.email !== '' ? this.form.email : ''
         
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/login`, this.form)
        .then((response) => {
          if (response.status === 200) {
            // MUTATING TO STORE FOR CLIENT RENDERING
            let user_edit = false
            if (response.data.user.user_roles === 'partner') {
              let role = response.data.user.user_partnership_selected.partnership_user_role
              
              if (role === 'Pemilik' || role === 'Admin') {
                user_edit = true
              }
            }
            
            let user = {
              user_id: response.data.user.user_id,
              user_name: response.data.user.user_name,
              user_username: response.data.user.user_username,
              user_email: response.data.user.user_email,
              user_mobilephone: response.data.user.user_mobilephone,
              user_image_url: response.data.user.user_image_url,
              user_is_verified: response.data.user.user_is_verified,
              user_roles: response.data.user.user_roles,
              user_partnership_selected: response.data.user.user_partnership_selected,
              user_edit: user_edit
            }

            // JIKA BELUM TERDAFTAR DI GOOGLE AUTH
            this.$firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password)
            .then(resGoogle => {

              Cookie.set('token', response.data.token, { domain: 'bidankita.com' })
              Cookie.set('user', JSON.stringify(user), { domain: 'bidankita.com' })
              Cookie.set('profile', JSON.stringify(response.data.user.user_profile), { domain: 'bidankita.com' })

              // localStorage.setItem('token', response.data.token);
              // localStorage.setItem('user', JSON.stringify(user));
              // localStorage.setItem('profile', JSON.stringify(response.data.user.user_profile));

              if (response.data.user.user_roles === 'user') {
                window.location = this.$route.path === '/' ? '/home' : this.$route.path
              } else {
                window.location = process.env.HOST_NAME_PROD
              }
            }).catch(error => {

              // JIKA SUDAH TERDAFTAR DI GOOGLE AUTH
              this.$firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
              .then(resGoogle => {
                
                Cookie.set('token', response.data.token, { domain: 'bidankita.com' })
                Cookie.set('user', JSON.stringify(user), { domain: 'bidankita.com' })
                Cookie.set('profile', JSON.stringify(response.data.user.user_profile), { domain: 'bidankita.com' })

                // localStorage.setItem('token', response.data.token);
                // localStorage.setItem('user', JSON.stringify(user));
                // localStorage.setItem('profile', JSON.stringify(response.data.user.user_profile));
                
                if (response.data.user.user_roles === 'user') {
                  window.location = this.$route.path === '/' ? '/home' : this.$route.path
                } else {
                  window.location = process.env.HOST_NAME_PROD
                }
              }).catch(error => {
                
                Cookie.set('token', response.data.token, { domain: 'bidankita.com' })
                Cookie.set('user', JSON.stringify(user), { domain: 'bidankita.com' })
                Cookie.set('profile', JSON.stringify(response.data.user.user_profile), { domain: 'bidankita.com' })

                // localStorage.setItem('token', response.data.token);
                // localStorage.setItem('user', JSON.stringify(user));
                // localStorage.setItem('profile', JSON.stringify(response.data.user.user_profile));
                
                if (response.data.user.user_roles === 'user') {
                  window.location = this.$route.path === '/' ? '/home' : this.$route.path
                } else {
                  window.location = process.env.HOST_NAME_PROD
                }
              })
            })
          } else {
            this.process.run = false
            this.error.message = response.message
          }
        })
      },
      async resetPassword() {
        this.process.run = true
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/login/reset_password`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.dialog.reset = false
            this.dialog.success = true
            this.success.message = 'Silahkan cek email anda, kami mengirimkan link untuk mereset password akun anda.'
          } else {
            this.process.run = false
            this.error.message = response.message
          }
        })
      },
      async google() {
        let provider = new this.$firebase.auth.GoogleAuthProvider()
        await this.$firebase.auth().signInWithPopup(provider)
        .then(response => {
          let data = response.additionalUserInfo.profile
          this.form.name = data.name
          this.form.email = data.email
          this.form.image = data.picture

          this.loginSSO()
        }).catch(error => {
          this.$snotify.error(error.message)
          console.log(error)
        })
      },
      async loginSSO() {
        this.process.google = true

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/login/google`, this.form)
        .then((response) => {
          if (response.status === 200) {
            // MUTATING TO STORE FOR CLIENT RENDERING
            let user_edit = false
            if (response.data.user.user_roles === 'partner') {
              let role = response.data.user.user_partnership_selected.partnership_user_role
              
              if (role === 'Pemilik' || role === 'Admin') {
                user_edit = true
              }
            }
            
            let user = {
              user_id: response.data.user.user_id,
              user_name: response.data.user.user_name,
              user_username: response.data.user.user_username,
              user_email: response.data.user.user_email,
              user_mobilephone: response.data.user.user_mobilephone,
              user_image_url: response.data.user.user_image_url,
              user_is_verified: response.data.user.user_is_verified,
              user_roles: response.data.user.user_roles,
              user_partnership_selected: response.data.user.user_partnership_selected,
              user_edit: user_edit
            }
            
            Cookie.set('token', response.data.token, { domain: 'bidankita.com' })
            Cookie.set('user', JSON.stringify(user), { domain: 'bidankita.com' })
            Cookie.set('profile', JSON.stringify(response.data.user.user_profile), { domain: 'bidankita.com' })
            
            // localStorage.setItem('token', response.data.token);
            // localStorage.setItem('user', JSON.stringify(user));
            // localStorage.setItem('profile', JSON.stringify(response.data.user.user_profile));

            if (response.data.user.user_roles === 'user') {
              window.location = this.$route.path === '/' ? '/home' : this.$route.path
            } else {
              window.location = process.env.HOST_NAME_PROD
            }
          } else {
            this.process.google = false
            this.error.message = response.message
          }
        })
      }
    }
  }
</script>

<style lang="scss">
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
  &-success {
    border-radius: 50%;
    border: 4px solid #FF3177;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: #fff;
    width: 80px;
    &:after, &:before {
      background: #fff;
      content: '';
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }
    &:before {
      border-radius: 40px 0 0 40px;
      width: 26px;
      height: 80px;
      top: -17px;
      left: 5px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }
    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
      animation: rotatePlaceholder 4.25s ease-in;
    }
    &-placeholder {
      border-radius: 50%;
      border: 4px solid rgb(255 62 142 / 25%);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }
    &-fix {
      background-color: #fff;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }
    &-tip, &-long {
      background-color: #FF3177;
      border-radius: 2px;
      height: 5px;
      position: absolute;
      z-index: 2;
    }
    &-tip {
      left: 14px;
      top: 46px;
      transform: rotate(45deg);
      width: 25px;
      animation: animateSuccessTip .75s;
    }
    &-long {
      right: 8px;
      top: 38px;
      transform: rotate(-45deg);
      width: 47px;
      animation: animateSuccessLong .75s;
    }
  }
}
@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}
</style>