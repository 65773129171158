<template>
  <div class="fill-height">
    <DialogMessage :dialogmessage="dialog.message" :message="error.message" @close="dialog.message = ''" />

    <v-layout wrap>
      <v-flex>
        <section>
          <v-divider class="mt-3" width="50px" :style="detail.collection_kelas_status ? 'border: 2px solid #4bb04f;' : 'border: 2px solid #FF3177;'"/>

          <div class="body-1 font-weight-bold ma-3 mx-0 mb-2"
            :class="detail.collection_kelas_status ? 'green--text' : 'color-first'">
            <v-icon class="mr-1" :color="detail.collection_kelas_status ? 'green' : '#FF3177'">
              {{ detail.collection_kelas_status ? 'mdi-calendar-clock' : 'mdi-calendar-remove' }}
            </v-icon>
            Langganan {{ detail.collection_kelas_status ? 'Aktif' : 'Habis' }}
          </div>

          <v-card-text class="px-0">
            <p class="body-1 font-weight-bold text-first">
              Masa Aktif :
            </p>

            <div v-if="!detail.kelas_harga_jsonobject.is_lifetime">
              <p class="body-1 font-weight-light mb-2">
                <v-icon small class="mr-1" color="green">mdi-arrow-right-thick</v-icon>
                Mulai dari <b>{{ detail.collection_kelas_langganan_dari_datetime | datetime }}</b>
              </p>

              <p class="body-1 font-weight-light">
                <v-icon small class="mr-1" color="red">mdi-arrow-right-thick</v-icon>
                Berakhir pada <b>{{ detail.collection_kelas_langganan_sampai_datetime | datetime }}</b>
              </p>

              <p class="text-h5 font-weight-light text-first mt-12 mb-6">
                Perpanjang sekarang?
              </p>

              <div v-if="detail.kelas_is_discount">
                <v-chip
                  class="font-weight-bold px-1 mr-1"
                  color="red"
                  label
                  text-color="white">
                  {{ detail.kelas_diskon_jsonobject.percent }}%
                </v-chip>

                <span class="title font-weight-light text-decoration-line-through text-second">
                  {{ parseInt(detail.kelas_harga_jsonobject.value) | price }}
                </span>
              </div>
            </div>

            <p class="headline font-weight-bold mt-3 mb-8">
              <span class="green--text" v-if="!detail.kelas_berbayar">
                Gratis
              </span>

              <span class="color-first" v-else>
                {{ detail.kelas_harga_jsonobject.value_after_discount | price }}
              </span>

              <span class="body-1 color-first">
                <span v-if="!detail.kelas_harga_jsonobject.is_lifetime">
                  / {{ detail.kelas_harga_jsonobject.subcribe_value }} 
                  {{ detail.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : detail.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                </span>

                <span v-else>
                  / Lifetime
                </span>
              </span>
            </p>

            <v-btn v-if="!detail.kelas_harga_jsonobject.is_lifetime"
              rounded
              elevation="0"
              color="orange"
              class="body-2 text-capitalize white--text"
              :disabled="process.run"
              :loading="process.run"
              @click="save">
              Perpanjang Masa Aktif Kelas
            </v-btn>
          </v-card-text>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          message: ''
        },
        process: {
          run: false
        },
        error: {
          message: ''
        }
      }
    },
    components: {
      DialogMessage: () => import('@/components/dialog/message')
    },
    computed: {
      headers() {
        return this.$store.state.header
      }
    },
    methods: {
      async save() {
        this.process.run = true

        this.error.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: this.detail.kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            this.dialog.message = 'success'
            this.error.message = 'Berhasil ditambahkan ke Keranjang.'

            this.$store.commit('header/setCart', this.headers.cart + 1)
          } else {
            this.process.run = false
            this.dialog.message = 'error'
            this.error.message = response.message
          }
        })
      }
    }
  }
</script>