<template>
  <div class="fill-height py-12 mt-6">
    <v-dialog v-model="dialog"
      width="650"
      scrollable
      persistent>
      <v-card v-if="Object.keys(selected.event).length > 0"
        flat
        class="border-radius">
        <v-toolbar
          color="#FF3177"
          dark>
          <v-toolbar-title class="body-1 font-weight-bold text-capitalize">
            {{ selected.event.name }}
          </v-toolbar-title>

          <v-spacer/>

          <v-btn icon
            @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-3">
          <v-card-text class="body-1 text-capitalize font-weight-bold pb-0">
            {{ selected.event.name }}
          </v-card-text>

          <v-card-text class="body-2 font-weight-light text-first">
            <p class="mb-2">
              <v-icon small class="mr-1">mdi-calendar-month</v-icon>
              Tanggal <b>{{ selected.event.date | date }}</b>
            </p>

            <p>
              <v-icon small class="mr-1">mdi-clock-time-four-outline</v-icon>
              Pukul <b>{{ selected.event.start | time }}</b> s/d <b>{{ selected.event.end | time }}</b>
            </p>

            <p v-if="selected.event.type !== ''">
              <a :href="`/elearning/${selected.event.action.kelas_slug}`" target="_blank">
                Kunjungi Kelas {{ selected.event.action.kelas_name }}
              </a>
            </p>

            <p class="mb-0">
              <span v-html="selected.event.desc"></span>
            </p>
          </v-card-text>

          <v-card-text class="pt-0">
            <p class="body-1 text-capitalize font-weight-bold mb-2">
              Pembicara :
            </p>

            <v-list dense>
              <v-list-item class="px-0"
                v-for="(item, index) in selected.event.person"
                :key="index">
                <v-list-item-avatar size="35">
                  <v-img
                    contain
                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member-default.jpg')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="body-2 font-weight-light text-capitalize">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Agenda
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section v-if="!filter.calendar">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="6" sm="6">
                <div class="d-flex align-center justify-end" style="width: 100%;">
                  <v-text-field
                    class="br_a search-style"
                    outlined
                    dense 
                    single-line
                    hide-details
                    color="#dadada"
                    placeholder="Temukan agenda menarik untuk anda . . ."
                    clearable
                    v-model="filter.title"
                    @click:clear="filter.title = ''; fetch();"
                    v-on:keyup.enter="fetch()">
                  </v-text-field>

                  <v-btn
                    style="position: absolute; right: auto;"
                    elevation="0"
                    rounded
                    height="40"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    @click="fetch()">
                    <v-icon color="white">mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container v-if="filter.calendar">
            <v-row justify="center">
              <v-col cols="12" class="py-0">
                <v-card flat>
                  <v-card-text class="pa-0">
                    <v-toolbar flat color="white">
                      <v-btn outlined 
                        class="mr-4 text-capitalize" 
                        color="grey darken-2" 
                        @click="date.now = ''">
                        <v-icon small class="mr-1">mdi-calendar-month</v-icon>
                        Hari Ini
                      </v-btn>

                      <v-btn v-if="rps !== 'xs'"
                        fab text 
                        color="grey darken-2" 
                        @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>

                      <v-btn v-if="rps !== 'xs'"
                        fab text 
                        color="grey darken-2" 
                        @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>

                      <v-toolbar-title v-if="$refs.calendar && rps !== 'xs'">
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>

                      <v-spacer/>

                      <v-menu
                        bottom
                        right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            color="grey darken-2"
                            v-bind="attrs"
                            v-on="on">
                            <span class="text-capitalize">
                              {{ filter.label }}
                            </span>
                            <v-icon right>
                              mdi-menu-down
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item 
                            v-for="(item, index) in menu"
                            :key="index"
                            @click="filter.label = item.label; filter.type = item.value;">
                            <v-list-item-title>
                              {{ item.label }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ml-3 cursor-pointer"
                            v-bind="attrs"
                            v-on="on"
                            @click="filter.calendar = false">
                            mdi-format-list-bulleted-square
                          </v-icon>
                        </template>
                        <span>
                          Tampilan List
                        </span>
                      </v-tooltip>
                    </v-toolbar>

                    <v-toolbar flat color="white" v-if="rps === 'xs'">
                      <v-btn
                        fab text 
                        color="grey darken-2" 
                        @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>

                      <v-spacer/>

                      <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>

                      <v-spacer/>

                      <v-btn
                        fab text 
                        color="grey darken-2" 
                        @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </v-card-text>

                  <v-card-text class="py-0">
                    <v-sheet height="600">
                      <v-calendar
                        ref="calendar"
                        color="#ffcbdc"
                        :type="filter.type"
                        v-model="date.now"
                        :events="list"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay">
                      </v-calendar>

                      <v-menu
                        v-model="selected.open"
                        :close-on-content-click="false"
                        :activator="selected.element"
                        offset-x>
                        <v-card v-if="Object.keys(selected.event).length > 0"
                          width="350"
                          flat>
                          <v-toolbar
                            :color="selected.event.color"
                            dark>
                            <v-toolbar-title class="body-1 font-weight-bold text-capitalize">
                              {{ selected.event.name }}
                            </v-toolbar-title>

                            <v-spacer/>

                            <v-btn icon
                              @click="selected.open = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <v-card-text class="body-1 text-capitalize font-weight-bold pb-0">
                            {{ selected.event.name }}
                          </v-card-text>

                          <v-card-text class="body-2 font-weight-light text-first">
                            <p class="mb-2">
                              <v-icon small class="mr-1">mdi-calendar-month</v-icon>
                              Tanggal <b>{{ selected.event.date | date }}</b>
                            </p>

                            <p>
                              <v-icon small class="mr-1">mdi-clock-time-four-outline</v-icon>
                              Pukul <b>{{ selected.event.start | time }}</b> s/d <b>{{ selected.event.end | time }}</b>
                            </p>

                            <p v-if="selected.event.type !== ''">
                              <a :href="`/elearning/${selected.event.action.kelas_slug}`" target="_blank">
                                Kunjungi Kelas {{ selected.event.action.kelas_name }}
                              </a>
                            </p>

                            <p class="mb-0">
                              <span v-html="selected.event.desc"></span>
                            </p>
                          </v-card-text>

                          <v-card-text class="pt-0">
                            <p class="body-1 text-capitalize font-weight-bold mb-2">
                              Pembicara :
                            </p>

                            <v-list dense>
                              <v-list-item class="px-0"
                                v-for="(item, index) in selected.event.person"
                                :key="index">
                                <v-list-item-avatar size="35">
                                  <v-img
                                    contain
                                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member-default.jpg')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#FF3177">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title class="body-2 font-weight-light text-capitalize">
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="!filter.calendar">
            <v-row 
              justify="center"
              align="end">
              <v-col cols="12" md="6" sm="6" :class="rps === 'xs' ? 'text-center' : ''">
                <span class="body-1 font-weight-bold text-second">
                  Semua Agenda {{ filter.category === 0 ? '' : filter.category === 1 ? 'Kelas' : 'Praktek' }}
                </span>
              </v-col>

              <v-col cols="12" md="4" sm="4" class="d-flex align-center" :class="rps === 'xs' ? 'justify-center' : 'justify-end'">
                <v-tabs
                  v-model="filter.category"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow
                  class="d-flex" :class="rps === 'xs' ? 'justify-start' : 'justify-end'">
                  <v-tab
                    v-for="(item, index) in category"
                    :key="index"
                    class="px-0 mx-1"
                    style="min-width: 55px; max-width: 55px;">
                    <span class="body-2 text-capitalize" :class="filter.category === index ? 'font-weight-bold' : 'text-third'">
                      {{ item }}
                    </span>
                  </v-tab>
                </v-tabs>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-3 cursor-pointer"
                      v-bind="attrs"
                      v-on="on"
                      @click="filter.calendar = true">
                      mdi-calendar-month
                    </v-icon>
                  </template>
                  <span>
                    Tampilan Kalender
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row justify="center" class="py-6">
              <v-col cols="12" md="10" sm="10"
                v-for="(item, index) in process.run ? 8 : list"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.run"
                  type="article">
                  <div>
                    <v-card v-if="!process.run && list.length > 0"
                      class="border-radius box-shadow px-2">
                      <v-card-text class="pb-0">
                        <p class="body-1 font-weight-bold line-text-first text-first text-capitalize mb-2">
                          <span class="two-line">
                            {{ item.name }}
                          </span>
                        </p>
                        
                        <p class="body-1 font-weight-light text-second mb-0">
                          <span class="one-line">
                            {{ item.category }}
                          </span>
                        </p>
                      </v-card-text>

                      <v-card-text>
                        <v-row align="center">
                          <v-col cols="12" md="7" sm="7" class="py-0">
                            <p class="body-2 text-capitalize font-weight-bold mb-2">
                              Pembicara :
                            </p>

                            <v-list dense>
                              <v-list-item class="px-0">
                                <v-list-item-avatar size="35">
                                  <v-img
                                    contain
                                    :src="item.person[0].image_url !== '' ? item.person[0].image_url : require('@/assets/image/member-default.jpg')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#FF3177">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title class="body-1 font-weight-bold text-capitalize">
                                    {{ item.person[0].name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                          <v-col cols="12" md="5" sm="5" class="py-0 text-right">
                            <p class="mb-2">
                              <v-icon small class="mr-1">mdi-calendar-month</v-icon>
                              Tanggal <b>{{ item.date | date }}</b>
                            </p>

                            <p class="mb-0">
                              <v-icon small class="mr-1">mdi-clock-time-four-outline</v-icon>
                              Pukul <b>{{ item.start | time }}</b> s/d <b>{{ item.end | time }}</b>
                            </p>
                          </v-col>
                        </v-row>

                        <p class="body-1 font-weight-light line-text-second mt-3 mb-0" v-if="item.desc !== ''">
                          <span class="three-line" v-html="item.desc"></span>
                        </p>
                      </v-card-text>

                      <v-card-text class="text-right color-first pt-0 cursor-pointer"
                        @click="dialog = true; selected.event = item">
                        Selengkapnya
                        <v-icon color="#FF3177" small>mdi-arrow-right</v-icon>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row justify="center" align="center" v-if="!process.run && pagination.total_page < 1">
              <v-col cols="12" class="mt-12">
                <v-img 
                  class="mx-auto mt-12"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Belum ada Agenda!
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk lihat kelas belajar di Bidan Kita.
                </p>

                <div class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    to="/elearning">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Lihat Kelas Belajar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            
            <Pagination v-if="pagination.total_page > 1"
              @changePage="fetch($event)" 
              @reloadPage="fetch($event)"
              :model="pagination" />
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from 'moment';
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dialog: false,
        menu: [
          {
            label: 'Bulan',
            value: 'month'
          },
          {
            label: 'Minggu',
            value: 'week'
          },
          {
            label: 'Hari',
            value: 'day'
          },
          {
            label: '4 Hari',
            value: '4day'
          }
        ],
        category: ['Semua', 'Kelas', 'Praktek'],
        filter: {
          title: '',
          category: 0,
          calendar: true,
          label: 'Bulan',
          type: 'month'
        },
        picker: {
          date: false
        },
        date: {
          min: moment().format('Y-MM-DD'),
          now: moment().format('Y-MM-DD')
        },
        selected: {
          open: false,
          element: null,
          event: {}
        },
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        list: [],
        pagination: {},
        process: {
          run: false
        },
        // SEO
        content: {
          url: 'agenda',
          title: 'Agenda Bidan Kita',
          description: 'Agenda Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      'filter.calendar': function() {
        this.filter.title = ''
        this.filter.category = 0

        this.fetch()
      },
      'filter.category': function() {
        this.fetch()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(pagination = { row: 20, page: 1 }) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })

        this.process.run = true

        let params = {
          limit: this.filter.calendar ? 100000 : pagination.row,
          page: pagination.page,
          filter: [
            {
              type: 'string',
              field: 'agenda_name',
              value: this.filter.title,
              comparison: '<>'
            },
            {
              type: 'string',
              field: 'agenda_category_name',
              value: this.filter.category === 0 ? '' : this.filter.category === 1 ? 'Kelas' : 'Praktek'
            }
          ]
        }

        this.list = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/agenda/list`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false

            let data = response.data.results

            data.map((obj) => {
              this.list.push({
                name: obj.agenda_name,
                desc: obj.agenda_description,
                person: obj.agenda_person,
                category: obj.agenda_category_name,
                type: obj.agenda_action_type,
                action: obj.agenda_action_data,
                date: obj.agenda_date,
                start: obj.agenda_start_datetime,
                end: obj.agenda_end_datetime,
                color: this.colors[this.rnd(0, this.colors.length - 1)]
              })
            })

            this.pagination = response.data.pagination
          }
        })
      },
      viewDay ({ date }) {
        this.focus = date
        this.filter.type = 'day'
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selected.event = event
          this.selected.element = nativeEvent.target
          setTimeout(() => {
            this.selected.open = true
          }, 10)
        }

        if (this.selected.open) {
          this.selected.open = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      }
    }
  }
</script>

<style scoped>
.search-style.v-text-field--outlined >>> fieldset {
  border-color: #dadada;
}
</style>