<template>
  <div class="mb-2">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="pa-0">
            <v-row class="mt-4">
              <v-col cols="12" class="py-0">
                <v-divider class="mb-10" style="border: 2px solid #e9e9e9" />

                <div class="d-flex align-end mb-1">
                  <div class="d-flex align-center">
                    <v-icon size="30" class="mr-1">mdi-album</v-icon>
                    <span class="title font-weight-bold mb-0">
                      <span class="color-first mr-1">
                        {{ detail.schedule_materi_total }}
                      </span>
                      Rekaman
                    </span>
                  </div>

                  <v-spacer/>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="detail.schedule_materi_jsonarray.length > 0">
              <v-col cols="12">
                <v-expansion-panels flat v-model="selected.panel">
                  <v-expansion-panel
                    v-for="(item, index) in detail.schedule_materi_jsonarray" 
                    :key="index"
                    class="border-radius mb-3"
                    :class="selected.panel === index ? 'box-shadow' : ''">
                    <v-expansion-panel-header class="border-radius" style="background: #f0f0f0;">
                      <v-list dense class="py-0" color="transparent">
                        <v-list-item class="py-0 pl-0">
                          <v-list-item-content :style="rps === 'xs' ? 'width: 100px;' : 'width: 400px;'">
                            <span class="body-1 font-weight-bold text-capitalize"
                              :class="selected.panel !== index ? 'one-line' : ''">
                              {{ item.title }}
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <template v-slot:actions>
                        <div class="d-flex align-center" style="border-radius: 5px; padding: 5px 15px;">
                          <span>
                            <v-icon size="20" class="mr-1" color="#000">mdi-book-open-blank-variant</v-icon>
                          </span>
                          <span class="body-2 font-weight-bold mr-1">
                            {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                          </span>
                          <span class="caption text-first mr-1">
                            Materi
                          </span>
                          <v-icon color="#000" small>
                            $expand
                          </v-icon>
                        </div>
                      </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="pa-0 pb-4"
                      :class="rps === 'xs' ? 'px-0' : 'px-4'">
                      <v-list flat three-line dense class="py-0" color="transparent">
                        <v-list-item-group>
                          <v-list-item 
                            :class="{
                              'my-6': item.total_sub_materi > 0,
                              'mt-6': item.total_sub_materi < 1,
                              'px-0': rps === 'xl' || rps === 'lg'
                            }"
                            @click="item.content_jsonobject.type !== '' ? changePlay(item) : ''">
                            <v-list-item-content>
                              <div class="d-flex align-center">
                                <div v-if="item.content_jsonobject.type !== ''"
                                  class="text-center">
                                  <v-icon v-if="item.title !== player.data.title || !player.media.is_play"
                                    large
                                    color="#FF3177">
                                    {{ 
                                      item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                      item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                      item.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                      item.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                    }}
                                  </v-icon>

                                  <v-icon v-else
                                    large
                                    color="#333333">
                                    mdi-stop-circle-outline
                                  </v-icon>

                                  <p class="caption text-capitalize color-first mb-0"
                                    :class="item.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                                    <span v-if="item.content_jsonobject.type !== 'docs'">
                                      {{ item.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                                    </span>

                                    <span v-else>
                                      {{ item.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                                    </span>
                                  </p>
                                </div>

                                <div v-if="item.content_jsonobject.type !== ''"
                                  class="mx-3"
                                  style="border-left: 7px solid #f0f0f0; border-radius: 5px; height: 75px;">
                                </div>
                                
                                <div class="mr-6">
                                  <v-img
                                    width="75"
                                    height="75"
                                    class="d-flex align-center justify-center"
                                    gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                    :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                    style="border-radius: 5px;">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#FF3177">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <div class="body-1 font-weight-light text-second"
                                  style="line-height: 1.75;">
                                  <span
                                    class="three-line"
                                    v-html="item.deskripsi === '' ? `Materi ${item.title}` : item.deskripsi.replace(/<\/?[^>]+>/ig, '')">
                                  </span>
                                </div>
                              </div>
                            </v-list-item-content>
                          </v-list-item>

                          <div v-if="item.total_sub_materi > 0">
                            <v-list-item :class="rps === 'xl' || rps === 'lg' ? 'px-0' : ''"
                              v-for="(sub, key) in item.sub_materi" :key="key"
                              @click="sub.content_jsonobject.type !== '' ? changePlay(sub) : ''">
                              <v-list-item-content>
                                <div class="d-flex align-center">
                                  <div v-if="sub.content_jsonobject.type !== ''"
                                    class="text-center">
                                    <v-icon v-if="sub.title !== player.data.title || !player.media.is_play"
                                      large
                                      color="#FF3177">
                                      {{ 
                                        sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                        sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                        sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                        sub.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                      }}
                                    </v-icon>

                                    <v-icon v-else
                                      large
                                      color="#333333">
                                      mdi-stop-circle-outline
                                    </v-icon>

                                    <p class="caption text-capitalize color-first mb-0"
                                      :class="sub.title !== player.data.title || !player.media.is_play ? 'color-first' : 'text-first'">
                                      <span v-if="sub.content_jsonobject.type !== 'docs'">
                                        {{ sub.title !== player.data.title || !player.media.is_play ? 'play' : 'stop' }}
                                      </span>

                                      <span v-else>
                                        {{ sub.title !== player.data.title || !player.media.is_play ? 'baca' : 'tutup' }}
                                      </span>
                                    </p>
                                  </div>
                                  
                                  <div v-if="sub.content_jsonobject.type !== ''"
                                    class="mx-3" 
                                    style="border-left: 7px solid #f0f0f0; border-radius: 5px; height: 50px;">
                                  </div>

                                  <div class="mr-6">
                                    <v-img
                                      width="50"
                                      height="50"
                                      class="d-flex align-center justify-center"
                                      gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                      :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                      style="border-radius: 5px;">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            indeterminate 
                                            color="#FF3177">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </div>
                                  
                                  <div>
                                    <p class="body-1 font-weight-bold text-capitalize mb-1"
                                      :class="rps !== 'xs' ? 'body-1' : 'body-1'">
                                      <span class="one-line">
                                        {{ sub.title }}
                                      </span>
                                    </p>

                                    <p class="body-1 font-weight-light line-text-first text-second mb-0">
                                      <span 
                                        class="one-line" 
                                        v-html="sub.deskripsi === '' ? sub.title : sub.deskripsi.replace(/<\/?[^>]+>/ig, '')">
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <v-row v-else
              class="py-12">
              <v-col>
                <v-img 
                  class="mx-auto"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Tidak ada materi khusus!
                </p>
                <p class="body-1 font-weight-light text-center mb-2">
                  Materi khusus tidak tersedia dikelas ini.
                </p>
                <p class="body-1 font-weight-light text-center mb-2">
                  Batas akses materi khusus pada pertemuan ini berakhir pada :
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Tanggal
                  <b>{{ detail.schedule_materi_expired_datetime | date }}</b>
                  Pukul
                  <b>{{ detail.schedule_materi_expired_datetime | time }}</b>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      panel: {
        type: Number,
        default: 0
      },
      play: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        selected: {
          panel: 0
        },
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        }
      }
    },
    components: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      this.selected.panel = this.panel
      this.player = this.play
    },
    methods: {
      changePlay(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        let status = false
        if (item.title === this.player.data.title) {
          if(!this.player.media.is_play) {
            status = true
          } else {
            status = false
          }
        } else {
          status = true
        }

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: status
        }

        this.player.data = item

        // this.$emit('changePlay', item)
        this.$emit('changePlay', this.player)
        this.$emit('changePanel', this.selected.panel)
      }
    }
  }
</script>