<template>
  
</template>

<script>
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  mounted(){
    Cookie.remove('token')
    Cookie.remove('user')
    Cookie.remove('profile')
    Cookie.remove('fcm')
    Cookie.remove('notif')

    // localStorage.removeItem('user')
    // localStorage.removeItem('profile')
    // localStorage.removeItem('token')

    this.logoutGoogle()

    let redirect = this.$route.query.redirect
    redirect = redirect ? redirect.split(',') : []
    let last = this.$route.query.last

    if(redirect.length > 0){
      let link = redirect[0]
      redirect.shift()
      let nextLink = redirect.toString()
      window.location.replace(`${link}?redirect=${nextLink}&last=${last}`)
    }else{
      window.location.replace(last)
    }
  },
  methods: {
    async logoutGoogle() {
      await this.$firebase.auth().signOut().then(() => {
        // window.location = '/'
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>

<style>

</style>